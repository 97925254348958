var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("PLUS会员设置")]),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { request: _vm.request, pagination: false }
        },
        [
          _c("el-table-column", {
            attrs: { label: "会员名称", prop: "plusMemberName" }
          }),
          _c("el-table-column", {
            attrs: { label: "申购金额（元）", prop: "priceAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "划线价（元）", prop: "originalPrice" }
          }),
          _c("el-table-column", {
            attrs: { label: "会员有效期", prop: "validDays" }
          }),
          _c("el-table-column", {
            attrs: { label: "赠送天数", prop: "rewardDays" }
          }),
          _c("el-table-column", {
            attrs: { label: "单日费用（元/天）", prop: "dayAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.onShelf
                      ? _c("span", [_vm._v("在售")])
                      : _c("span", [_vm._v("待售")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    !scope.row.onShelf
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onShelves(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 上架 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onUnshelve(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 下架 ")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          attrs: {
            title: "年度会员",
            visible: _vm.dialogVisible,
            rules: _vm.formRules,
            formData: _vm.dialogFormData,
            isEdit: true,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            "update:formData": function($event) {
              _vm.dialogFormData = $event
            },
            "update:form-data": function($event) {
              _vm.dialogFormData = $event
            },
            confirm: _vm.callSavePlusMember
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "申购金额：", prop: "priceAmount" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "", type: "number" },
                  model: {
                    value: _vm.dialogFormData.priceAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogFormData, "priceAmount", $$v)
                    },
                    expression: "dialogFormData.priceAmount"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "划线价：", formData: _vm.dialogFormData } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "", type: "number" },
                  model: {
                    value: _vm.dialogFormData.originalPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogFormData, "originalPrice", $$v)
                    },
                    expression: "dialogFormData.originalPrice"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "赠送天数：", formData: _vm.dialogFormData } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "", type: "number" },
                  model: {
                    value: _vm.dialogFormData.rewardDays,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogFormData, "rewardDays", $$v)
                    },
                    expression: "dialogFormData.rewardDays"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("天")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单日费用：", prop: "dayAmount" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "", type: "number" },
                  model: {
                    value: _vm.dialogFormData.dayAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogFormData, "dayAmount", $$v)
                    },
                    expression: "dialogFormData.dayAmount"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "销售状态：", prop: "onShelf" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.dialogFormData.onShelf,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogFormData, "onShelf", $$v)
                  },
                  expression: "dialogFormData.onShelf"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }