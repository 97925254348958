//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PaymentConfirmed from './PaymentConfirmed.vue';
import ReturnApplicationReviewed from './ReturnApplicationReviewed.vue';
import PaymentApproved from './PaymentApproved.vue';
import PaymentReviewFailed from './PaymentReviewFailed.vue';
import ReturnedRecords from './ReturnedRecords.vue';
import RefuseReturn from './RefuseReturn.vue';
export default {
  meta: {
    title: '门店保证金',
    auth: true,
    authorityValue: 'gym-bond-list'
  },
  name: 'shop-deposit-list',
  components: {
    PaymentConfirmed: PaymentConfirmed,
    ReturnApplicationReviewed: ReturnApplicationReviewed,
    PaymentApproved: PaymentApproved,
    PaymentReviewFailed: PaymentReviewFailed,
    ReturnedRecords: ReturnedRecords,
    RefuseReturn: RefuseReturn
  },
  data: function data() {
    return {
      activeName: 'PaymentConfirmed'
    };
  },
  methods: {},
  mounted: function mounted() {}
};