var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("门店保证金缴纳审核")]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 4 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "缴纳编号" } }, [
                    _vm._v(_vm._s(_vm.record.depositNo))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "提交时间" } }, [
                    _vm._v(_vm._s(_vm.record.depositTime))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                    _vm._v(_vm._s(_vm.record.companyName))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "门店名称" } },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.goRoute(
                                "/settled-stores-detail?gymId=" +
                                  _vm.record.gymId
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.record.gymName))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { direction: "vertical", column: 4, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "转账金额(元）" } },
                    [_vm._v(_vm._s(_vm.record.depositAmount))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "开户名称" } }, [
                    _vm._v(_vm._s(_vm.record.payerAccountName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "开户账号" } }, [
                    _vm._v(_vm._s(_vm.record.payerAccount))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "开户银行支行" } },
                    [_vm._v(_vm._s(_vm.record.payerBankName))]
                  )
                ],
                1
              ),
              _c(
                "el-descriptions",
                { staticClass: "d2-mt", attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "转账凭证" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "200px", height: "200px" },
                        attrs: {
                          src: _vm.record.depositImage,
                          fit: "fill",
                          "preview-src-list": [_vm.record.depositImage]
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "d2-mt",
              attrs: { shadow: "never", "body-style": { padding: "20px" } }
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.form.depositStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "depositStatus", $$v)
                            },
                            expression: "form.depositStatus"
                          }
                        },
                        [_vm._v("审核通过")]
                      ),
                      _vm.form.collectImage && _vm.form.depositStatus == 2
                        ? _c("el-image", {
                            staticStyle: {
                              width: "100px",
                              height: "100px",
                              "margin-right": "20px"
                            },
                            attrs: { src: _vm.form.collectImage }
                          })
                        : _vm._e(),
                      _vm.form.depositStatus == 2
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.openUploadModal }
                            },
                            [_vm._v("上传收款凭证")]
                          )
                        : _vm._e(),
                      _c("base-upload", {
                        ref: "upload",
                        attrs: { token: _vm.qnToken },
                        on: { confirm: _vm.setCover }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.form.depositStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "depositStatus", $$v)
                            },
                            expression: "form.depositStatus"
                          }
                        },
                        [_vm._v("审核不通过")]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.depositStatus == 3,
                            expression: "form.depositStatus == 3"
                          }
                        ],
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: _vm.form.rejectReason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectReason", $$v)
                          },
                          expression: "form.rejectReason"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.goBack()
                            }
                          }
                        },
                        [_vm._v("返回")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }