import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Title from '../title';
import { mapGetters } from 'vuex';
export default {
  components: {
    Title: Title
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/databig', ['getLeftOpt'])), {}, {
    tabData: function tabData() {
      var _this$getLeftOpt = this.getLeftOpt,
          calorie = _this$getLeftOpt.calorie,
          second = _this$getLeftOpt.second,
          plan = _this$getLeftOpt.plan;
      var tabData = [{
        label: '卡路里',
        number: this.setNum(calorie),
        key: 'k-ll',
        color: '#EC4848'
      }, {
        label: '总时长',
        number: this.setNum(second),
        key: 'x-lz',
        color: '#F29128'
      }, {
        label: '定制数',
        number: this.setNum(plan),
        key: 'y-sjh',
        color: '#16D5BF'
      }].map(function (v) {
        return _objectSpread(_objectSpread({}, v), {}, {
          icon: require("../../assets/image/".concat(v.key, ".png"))
        });
      });
      return tabData;
    }
  }),
  methods: {
    setNum: function setNum(e) {
      if (!e || isNaN(e)) {
        return 0;
      } // 此处为防止字符串形式的数值进来，因为toFixed方法只能用于数值型数


      e = Number(e);
      return e.toFixed(2);

      if (Math.abs(e) > 100000000) {
        return (e / 100000000).toFixed(2);
      } else if (Math.abs(e) > 10000) {
        return (e / 10000).toFixed(2);
      } else {
        return e.toFixed(2);
      }
    }
  }
};