var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        labelWidth: "0",
        fullTitle: "取消订单",
        visible: _vm.dialogVisible,
        rules: _vm.formRules,
        formData: _vm.dialogFormData,
        isEdit: true,
        width: "600px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "update:formData": function($event) {
          _vm.dialogFormData = $event
        },
        "update:form-data": function($event) {
          _vm.dialogFormData = $event
        },
        confirm: _vm.callTrainingCancel
      }
    },
    [
      _c("p", [_vm._v("取消课程后，将原路退回学员已付款，确定要取消么？")]),
      _c(
        "el-form-item",
        { staticClass: "mt-20", attrs: { label: "", prop: "rejectReason" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 5,
              placeholder: "输入取消原因（必填）"
            },
            model: {
              value: _vm.dialogFormData.rejectReason,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "rejectReason", $$v)
              },
              expression: "dialogFormData.rejectReason"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }