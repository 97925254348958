var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("邀请注册奖励设置")]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "邀请新用户，新用户注册后，奖励老用户" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "", min: "0", type: "number" },
                  model: {
                    value: _vm.form.invitationReward,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "invitationReward", $$v)
                    },
                    expression: "form.invitationReward"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每天拉新人数审核限制" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "", min: "0", type: "number" },
                  model: {
                    value: _vm.form.inviteCountAuditDay,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "inviteCountAuditDay", $$v)
                    },
                    expression: "form.inviteCountAuditDay"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("人")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每周拉新人数审核限制" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "", min: "0", type: "number" },
                  model: {
                    value: _vm.form.inviteCountAuditWeek,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "inviteCountAuditWeek", $$v)
                    },
                    expression: "form.inviteCountAuditWeek"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("人")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每月拉新人数审核限制" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "", min: "0", type: "number" },
                  model: {
                    value: _vm.form.inviteCountAuditMonth,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "inviteCountAuditMonth", $$v)
                    },
                    expression: "form.inviteCountAuditMonth"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("人")])],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }