/**
 * 动作相关
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    ACTIONS_LIST: function ACTIONS_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/action/actions',
        data: data
      });
    },
    ACTIONS_DETAIL: function ACTIONS_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/action/actionDetails',
        data: data
      });
    },
    ACTIONS_TOGGLESHOW: function ACTIONS_TOGGLESHOW() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/action/showAction',
        data: data
      });
    },
    ACTIONS_DEL: function ACTIONS_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/action/deleteAction',
        data: data
      });
    },
    ACTIONS_SAVE: function ACTIONS_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/action/saveAction',
        data: data
      });
    }
  };
});