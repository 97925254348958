var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单编号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.orderTimeRange.start,
                  max: _vm.form.orderTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户电话" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入客户电话" },
                model: {
                  value: _vm.form.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phoneNumber", $$v)
                  },
                  expression: "form.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单账号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入下单账号" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单来源" } },
            [
              _c("base-select", {
                attrs: { options: _vm.orderPlatform },
                model: {
                  value: _vm.form.orderPlatform,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderPlatform", $$v)
                  },
                  expression: "form.orderPlatform"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物流单号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入物流单号" },
                model: {
                  value: _vm.form.expressNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "expressNumber", $$v)
                  },
                  expression: "form.expressNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入货号" },
                model: {
                  value: _vm.form.goodsModel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsModel", $$v)
                  },
                  expression: "form.goodsModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推荐人" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入推荐人" },
                model: {
                  value: _vm.form.recommender,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "recommender", $$v)
                  },
                  expression: "form.recommender"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入店铺名称" },
                model: {
                  value: _vm.form.storeName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeName", $$v)
                  },
                  expression: "form.storeName"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", prop: "orderNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "下单时间", prop: "orderTime", width: "170px" }
          }),
          _c("el-table-column", {
            attrs: { label: "付款时间", prop: "payTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "下单账号", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "商家备注", prop: "storeRemark" }
          }),
          _c("el-table-column", {
            attrs: { label: "订单状态", prop: "orderStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("statusFil")(row.orderStatus)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "支付方式", prop: "payWay2" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("ftValue2label")(row.payWay2, _vm.payWay))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onDetail(scope.row.orderId)
                          }
                        }
                      },
                      [_vm._v(" 订单详情 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }