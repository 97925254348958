//
//
//
//
//
//
//
//
//
var title = '私教课收益说明';
export default {
  meta: {
    title: title,
    auth: true,
    authorityValue: 'training-order-management'
  },
  name: 'personal-benefit-description',
  components: {},
  data: function data() {
    return {
      title: title,
      articleContent: ''
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getQnToken();
    },
    handleSubmit: function handleSubmit() {
      console.log('articleContent', this.articleContent);
    }
  }
};