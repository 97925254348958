var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("订单查询与跟踪")]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card", lazy: "" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.tab, function(l) {
          return _c(
            "el-tab-pane",
            { key: l.id, attrs: { label: l.label, name: l.type } },
            [
              l.type == _vm.activeName
                ? _c(_vm.activeName, { tag: "component" })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }