var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("私教直播审核记录")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "申请编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入申请编号搜索" },
                model: {
                  value: _vm.form.auditNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "auditNo", $$v)
                  },
                  expression: "form.auditNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "教练" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  placeholder: "输入教练真实姓名、昵称、用户编号、手机号码搜索"
                },
                model: {
                  value: _vm.form.coachName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "coachName", $$v)
                  },
                  expression: "form.coachName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dict.auditStatus },
                model: {
                  value: _vm.form.auditStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "auditStatus", $$v)
                  },
                  expression: "form.auditStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "教练连线时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.applyTimeRange.start,
                  max: _vm.form.applyTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.auditTimeRange.start,
                  max: _vm.form.auditTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "申请编号", prop: "auditNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "审核结果", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            scope.row.auditStatus,
                            _vm.dict.auditStatus
                          )
                        )
                      )
                    ]),
                    scope.row.auditStatus == 3
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              title: "不通过的原因",
                              width: "200",
                              trigger: "click",
                              content: _vm._f("rejectReasons")(
                                scope.row.rejectReasons
                              )
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference"
                              },
                              [_vm._v("查看原因")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "教练姓名", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "user-detail",
                                query: {
                                  userId: scope.row.coachUserId,
                                  type: "coach"
                                }
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.coachName) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "教练连线时间", prop: "applyTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDateTime")(scope.row.applyTime))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "平台审核时间", prop: "auditTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDateTime")(scope.row.auditTime))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "审核备注", prop: "auditRemark" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.seeReplay(scope.row)
                          }
                        }
                      },
                      [_vm._v(" 查看回放 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }