export var orderStatus = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '待付款'
}, {
  value: 2,
  label: '待出库'
}, {
  value: 3,
  label: '已出库'
}, {
  value: 4,
  label: '已签收'
}, {
  value: 5,
  label: '已完成'
}, {
  value: 6,
  label: '已取消'
}, {
  value: 7,
  label: '已取消'
}, {
  value: 8,
  label: '已取消'
}];
export var orderPlatform = [{
  value: 'app',
  label: 'APP'
}, {
  value: 'h5',
  label: 'H5'
}, {
  value: 'mini',
  label: '微信小程序'
}];
export var overdueReason = [{
  value: 1,
  label: '商家原因与消费者达成一致'
}, {
  value: 2,
  label: '消费者原因与商家达成一致'
}, {
  value: 3,
  label: '系统原因'
}, {
  value: 4,
  label: '紧急异常时间'
}, {
  value: 5,
  label: '国际大型会议/政策'
}, {
  value: 6,
  label: '天气原因'
}, {
  value: 7,
  label: '大促期间、节假日'
}];