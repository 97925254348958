var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "csBox btn-text can-hover",
      on: {
        click: function($event) {
          return _vm.goRoute("/aed-screen")
        }
      }
    },
    [
      _c("el-image", {
        staticStyle: { width: "24px", height: "24px" },
        attrs: { src: _vm.img }
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inBox" }, [
      _c("div", [_vm._v("急救大屏")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }