// 首页大屏接口
export default (function (_ref) {
  var request = _ref.request;
  return {
    HOME_GYM_REALTIME: function HOME_GYM_REALTIME() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/realTime-coach',
        data: data
      });
    },
    HOME_TRADE_REALTIME: function HOME_TRADE_REALTIME() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/realTime-trade',
        data: data
      });
    },
    HOME_COM_REALTIME: function HOME_COM_REALTIME() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/realTime-community',
        data: data
      });
    },
    HOME_COURSE_REALTIME: function HOME_COURSE_REALTIME() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/realTime-course',
        data: data
      });
    },
    HOME_TODO_TRANSFER: function HOME_TODO_TRANSFER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/todoList-transfer',
        data: data
      });
    },
    HOME_TODO_REPORT: function HOME_TODO_REPORT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/todoList-report',
        data: data
      });
    },
    HOME_USER_TREND: function HOME_USER_TREND() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/userTrends',
        data: data
      });
    },
    HOME_TODO_GYM: function HOME_TODO_GYM() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/todoList-gym',
        data: data
      });
    },
    HOME_TODO_STORE: function HOME_TODO_STORE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/todoList-store',
        data: data
      });
    },
    HOME_TODO_COURSE: function HOME_TODO_COURSE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/todoList-course',
        data: data
      });
    },
    HOME_TODO_COACH: function HOME_TODO_COACH() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/todoList-coach',
        data: data
      });
    },
    HOME_USER_COUNT: function HOME_USER_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/userCount',
        data: data
      });
    },
    // HOME_USER_COUNT(data = {}) {
    //   return request({
    //     url: '/admin/index/userCount',
    //     data
    //   })
    // },
    //消息列表
    HOME_MESSAGES: function HOME_MESSAGES() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/messages',
        data: data
      });
    },
    //消息详情
    HOME_MESSAGES_DETAILS: function HOME_MESSAGES_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/messageDetails',
        data: data
      });
    },
    //已读全部消息
    HOME_MESSAGES_READALLMSG: function HOME_MESSAGES_READALLMSG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/readAllMsg',
        data: data
      });
    },
    //点击统计
    HOME_MESSAGES_clickCount: function HOME_MESSAGES_clickCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/clickCount',
        data: data
      });
    },
    //下载后启动app统计
    HOME_MESSAGES_startAppCount: function HOME_MESSAGES_startAppCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/index/startAppCount',
        data: data
      });
    }
  };
});