import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  auditStatus: '',
  auditNo: '',
  nickname: '',
  applyTimeRange: {
    start: '',
    end: ''
  },
  auditTimeRange: {
    start: '',
    end: ''
  }
};
export default {
  meta: {
    title: '教练复审记录',
    auth: true,
    authorityValue: 'coach-live-audit-list'
  },
  name: 'live-audit-record',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'coach_liveAuditList',
        params: cloneDeep(initForm)
      },
      auditStatus: [{
        label: '全部',
        value: ''
      }, {
        label: '已通过',
        value: 2
      }, {
        label: '未通过',
        value: 3
      }]
    };
  },
  filters: {
    auditStatus: function auditStatus(val) {
      if (val == 1) return '待审核';
      if (val == 2) return '审核通过';
      if (val == 3) return '审核不通过';
    }
  },
  methods: {
    seeReplay: function seeReplay(url) {
      var urls = url.replace('http', 'https');
      this.goRoute('/live-audit-record-details?playbackUrl=' + urls);
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};