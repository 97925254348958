import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 纠纷列表 */
    dispute_disputes: createAction(request, '/admin/dispute/disputes'),

    /** 纠纷详情 */
    dispute_disputeDetails: createAction(request, '/admin/dispute/disputeDetails'),

    /** 处理纠纷 */
    dispute_dealDispute: createAction(request, '/admin/dispute/dealDispute')
  };
});