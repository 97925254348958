var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dv-full-screen-container", { style: _vm.style }, [
    _c(
      "div",
      { on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave } },
      [
        _c(
          "div",
          { staticClass: "centent" },
          [
            _c("CenterMap", {
              ref: "CenterMap",
              on: { clickLabelMarker: _vm.clickLabelMarker }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header flex-center-between",
            staticStyle: { "justify-content": "center" }
          },
          [
            _c("HeaderTitle", {
              attrs: { bilibili: _vm.bilibili },
              on: {
                "update:bilibili": function($event) {
                  _vm.bilibili = $event
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "bottom flex-center-between" }, [
          _c(
            "div",
            { staticClass: "left flex-center-between" },
            [
              !_vm.isLeftWarn
                ? _c("LeftWarn", { on: { update: _vm.update } })
                : _vm._e(),
              _vm.getAedActive ? _c("LeftOnUser") : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right flex-center-between" },
            [
              !_vm.isRightUser ? _c("RightOffUser") : _vm._e(),
              _vm.getAedActive
                ? _c("RightMsg", {
                    staticClass: "rightmsg",
                    attrs: { show: !_vm.isRightUser }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]
    ),
    _c("iframe", {
      staticStyle: { display: "none" },
      attrs: { allow: "autoplay", src: "音频地址" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }