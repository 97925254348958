var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar-uploader" },
    [
      _c(
        "base-gallery",
        _vm._b(
          {
            attrs: {
              srcList: _vm.uploadFileUrls,
              imgWidth: _vm.$attrs.imgWidth || "100px",
              imgHeight: _vm.$attrs.imgHeight || "150px",
              canDelete: true
            },
            on: {
              "update:srcList": function($event) {
                _vm.uploadFileUrls = $event
              },
              "update:src-list": function($event) {
                _vm.uploadFileUrls = $event
              }
            }
          },
          "base-gallery",
          _vm.$attrs,
          false
        )
      ),
      _vm.uploadFileUrls.length < _vm.maxCount
        ? _c("i", {
            staticClass: "el-icon-plus avatar-uploader-icon",
            on: { click: _vm.openUploadModal }
          })
        : _vm._e(),
      _c("base-upload", {
        ref: "upload",
        attrs: { type: "image" },
        on: { confirm: _vm.handleUpload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }