//
//
//
//
//
//
//
//
//
var img = require('@/assets/images/news.png');

export default {
  name: 'd2-header-customerService',
  data: function data() {
    return {
      img: img
    };
  },
  methods: {}
};