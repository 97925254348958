import { render, staticRenderFns } from "./detail.page.vue?vue&type=template&id=5d4d767a&scoped=true&"
import script from "./detail.page.vue?vue&type=script&lang=js&"
export * from "./detail.page.vue?vue&type=script&lang=js&"
import style0 from "./detail.page.vue?vue&type=style&index=0&id=5d4d767a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4d767a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yaolian_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d4d767a')) {
      api.createRecord('5d4d767a', component.options)
    } else {
      api.reload('5d4d767a', component.options)
    }
    module.hot.accept("./detail.page.vue?vue&type=template&id=5d4d767a&scoped=true&", function () {
      api.rerender('5d4d767a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/personal-trainer/data-statistics-coach/class-store-live/detail.page.vue"
export default component.exports