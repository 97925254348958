var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v(" 取消订单管理 ")]),
      _c("el-alert", {
        staticClass: "d2-mb",
        attrs: {
          title:
            "超时系统自动审核条件：1.未出库超1天不处理（自然日）；2.已出库超1天不处理（自然日）",
          type: "warning",
          closable: false,
          "show-icon": ""
        }
      }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单编号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.applyTimeRange.start,
                  max: _vm.form.applyTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户账号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.userNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userNo", $$v)
                  },
                  expression: "form.userNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.auditTimeRange.start,
                  max: _vm.form.auditTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.consumerName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "consumerName", $$v)
                  },
                  expression: "form.consumerName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card", lazy: "" },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tab, function(l) {
              return _c(
                "el-tab-pane",
                { key: l.id, attrs: { label: l.label, name: l.type } },
                [
                  l.type == _vm.activeName
                    ? _c(_vm.activeName, {
                        ref: "baseTable",
                        refInFor: true,
                        tag: "component",
                        attrs: { initForm: _vm.initForm }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }