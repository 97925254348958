var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("提现失败通知")]),
      _c("el-alert", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          title:
            "用户提现失败，系统管理员将收到短信通知，后台填写要接收短信提醒的手机号码，填写多个手机号码，短信通知多人",
          type: "info",
          closable: false
        }
      }),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        _vm._l(_vm.form.transferNoticePhones, function(l, i) {
          return _c(
            "el-form-item",
            { key: i, attrs: { label: "手机号码" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "350px" },
                  attrs: { placeholder: "手机号码", type: "text" },
                  model: {
                    value: l.phone,
                    callback: function($$v) {
                      _vm.$set(l, "phone", $$v)
                    },
                    expression: "l.phone"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", icon: "el-icon-plus" },
                          on: { click: _vm.add }
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", icon: "el-icon-minus" },
                          on: { click: _vm.del }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }