var commonSub1 = [{
  id: '100006301',
  name: '上肢',
  subList: []
}, {
  id: '100006302',
  name: '下肢',
  subList: []
}, {
  id: '100006303',
  name: '综合',
  subList: []
}];
var obj = {}; // 健身训练类型没有级别的限制

var temp = {
  id: '10000006',
  name: '老年保健',
  subLables: ['系列', '部位'],
  subList: [{
    id: '100006101',
    name: '太极',
    subList: commonSub1
  }, {
    id: '100006102',
    name: '核心功能',
    subList: commonSub1
  }, {
    id: '100006103',
    name: '抗阻运动',
    subList: commonSub1
  }, {
    id: '100006104',
    name: '疾病预防',
    subList: commonSub1
  }]
};
export default temp;