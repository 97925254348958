import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Title from '../title';
export default {
  components: {
    Title: Title
  },
  filters: {
    showProgressColor: function showProgressColor(val) {
      return val = "el-bg-inner-".concat(val);
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/databig', ['getRightProgress'])), {}, {
    topData: function topData() {
      var _this$getRightProgres = this.getRightProgress,
          serviceTimes = _this$getRightProgres.serviceTimes,
          total = _this$getRightProgres.total;
      var data = [{
        label: '门店总数',
        number: total
      }, {
        label: '累计服务人次',
        number: serviceTimes
      }];
      return data;
    },
    tabData: function tabData() {
      var areas = this.getRightProgress.areas;
      var tabData = [{
        label: '广东省',
        number: 0,
        key: 'p1',
        color: 'linear-gradient(82deg, #FE4747, #F40B0B)'
      }, {
        label: '北京市',
        number: 0,
        key: 'p2',
        color: 'linear-gradient(82deg, #F7B34D, #FF9800)'
      }, {
        label: '上海市',
        number: 0,
        key: 'p3',
        color: 'linear-gradient(82deg, #F9DD5A, #FFD200)'
      }, {
        label: "\u6E56\u5357\u7701",
        number: 0,
        key: 'p4',
        color: 'linear-gradient(82deg, #16F5FF, #17A1FF)'
      }, {
        label: "\u4E1C\u5317\u7701",
        number: 0,
        key: 'p5',
        color: 'linear-gradient(82deg, #16F5FF, #17A1FF)'
      }].map(function (v) {
        return _objectSpread(_objectSpread({}, v), {}, {
          icon: require("../../assets/image/".concat(v.key, ".png"))
        });
      });
      areas.forEach(function (v, i) {
        var item = tabData[i];
        item.label = v.label;
        item.number = v.value;
      });
      return tabData.splice(0, areas.length) || [];
    }
  }),
  methods: {
    setPercentage: function setPercentage(num) {
      var max = Math.max.apply(Math, this.tabData.map(function (item, index) {
        return item.number;
      }));
      return num > 100 ? 100 : parseInt((num / 1).toFixed(0));
    }
  }
};