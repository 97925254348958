import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  actionNo: '',
  actionName: '',
  actionType: 0,
  part: '',
  categoryId: '',
  browsePeopleRange: {
    start: '',
    end: ''
  },
  browseCountRange: {
    start: '',
    end: ''
  },
  commentCountRange: {
    start: '',
    end: ''
  },
  favoriteCountRange: {
    start: '',
    end: ''
  },
  createTimeRange: {
    start: '',
    end: ''
  }
};
export default {
  data: function data() {
    return {
      selects: [],
      visible: false,
      initForm: initForm,
      res: [],
      editVisible: false,
      form: cloneDeep(initForm),
      headStyle: function headStyle() {
        return 'text-align:center';
      },
      request: {
        api: 'ACTIONS_LIST',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    submitSelection: function submitSelection() {
      this.$emit('confirm', this.selects);
      this.visible = false;
    },
    onSelectionChange: function onSelectionChange(res) {
      console.log('onSelectionChange', res);
      this.selects = res;
    },
    open: function open() {
      this.visible = true;
    },
    actionCateStr: function actionCateStr(row) {
      var _this = this;

      var out = [];
      row.categoryIds.map(function (item) {
        out.push(_this.dict.courseCates[item] || '');
      });
      return out.join(',');
    },
    actionPartStr: function actionPartStr(row) {
      return row.bodyParts.join(',');
    },
    actionTypeStr: function actionTypeStr(row) {
      return this.dict.actionTypesMap[row.actionType] || '';
    },
    handleSearch: function handleSearch() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      console.log('values:', values);
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData(reset);
    }
  },
  mounted: function mounted() {// this.handleSearch()
  }
};