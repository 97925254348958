var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("签到奖励")]),
      _c(
        "page-content",
        [
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "签到流水号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.signNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "signNo", $$v)
                      },
                      expression: "form.signNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户" } },
                [
                  _c("el-input", {
                    staticStyle: { wdith: "300px" },
                    attrs: { placeholder: "输入用户昵称/ID/手机号码搜索" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖励日期" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.createTimeRange.start,
                      max: _vm.form.createTimeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.createTimeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.createTimeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签到奖励类型" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.options },
                    model: {
                      value: _vm.form.signDays,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "signDays", $$v)
                      },
                      expression: "form.signDays"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "extra" },
                  on: { click: _vm.download },
                  slot: "extra"
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c("TableTitleBar", { attrs: { title: "查询统计：" } }, [
            _c("span", { staticClass: "space" }, [
              _vm._v("签到奖励总额" + _vm._s(_vm.extraData.rewardAmount) + "元")
            ]),
            _c("span", { staticClass: "space" }, [
              _vm._v("共" + _vm._s(_vm.extraData.total) + "笔")
            ])
          ]),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "签到流水号", prop: "signNo" }
              }),
              _c("el-table-column", {
                attrs: { label: "用户", prop: "nickname" }
              }),
              _c("el-table-column", {
                attrs: { label: "签到奖励金（元）", prop: "rewardAmount" }
              }),
              _c("el-table-column", {
                attrs: { label: "奖励时间", prop: "createTime", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v(" " + _vm._s(scope.row.createTime))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "签到奖励类型", prop: "signDays" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " 连续签到" + _vm._s(scope.row.signDays) + "天"
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                { attrs: { label: "收支方向", prop: "xxx" } },
                [[_c("span", [_vm._v(" 支出")])]],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }