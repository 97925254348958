var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("发票详情")]),
      _c(
        "el-card",
        { staticStyle: { "padding-left": "100px" } },
        [
          _vm.record.auditStatus !== 3
            ? _c("el-tag", { attrs: { type: "warning" } }, [
                _vm._v(
                  _vm._s(
                    _vm.record.auditStatus == 1
                      ? "发票待审核"
                      : _vm.record.auditStatus == 2
                      ? "已开票"
                      : ""
                  )
                )
              ])
            : _vm._e(),
          _vm.record.auditStatus == 3
            ? _c(
                "div",
                { staticStyle: { "font-size": "small" } },
                [
                  _c(
                    "el-tag",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { type: "danger" }
                    },
                    [_vm._v("驳回")]
                  ),
                  _c("span", [
                    _vm._v("驳回原因：" + _vm._s(_vm.record.rejectReason))
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-descriptions",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                title: "发票信息",
                column: 1,
                contentStyle: { "font-weight": "bold" }
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "订单编号" } }, [
                _vm._v(_vm._s(_vm.record.orderNo))
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请来源" } }, [
                _vm._v(
                  _vm._s(
                    _vm.record.applyType == 1
                      ? "下单申请"
                      : _vm.record.applyType == 2
                      ? "申请开票"
                      : ""
                  )
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请发票类型" } }, [
                _vm._v(
                  _vm._s(
                    _vm.record.invoiceType == 1
                      ? "普通发票"
                      : _vm.record.invoiceType == 2
                      ? "增值税专用发票"
                      : ""
                  )
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "发票内容" } }, [
                _vm._v(
                  _vm._s(
                    _vm.record.invoiceContent == 1
                      ? "商品明细"
                      : _vm.record.invoiceContent == 2
                      ? "商品类型"
                      : ""
                  )
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "抬头名称" } }, [
                _vm._v(_vm._s(_vm.record.invoiceTitle))
              ]),
              _c("el-descriptions-item", { attrs: { label: "纳税人识别号" } }, [
                _vm._v(_vm._s(_vm.record.taxpayerIdNumber))
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "购买方注册地址" } },
                [_vm._v(_vm._s(_vm.record.taxpayerAddress))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "购买方注册电话" } },
                [_vm._v(_vm._s(_vm.record.invoicePayeePhone))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "购买方开户银行" } },
                [_vm._v(_vm._s(_vm.record.invoiceBankName))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "购买方开户账号" } },
                [_vm._v(_vm._s(_vm.record.invoiceBankAccount))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "商品信息" } },
                [
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "600px" },
                        attrs: {
                          data: _vm.record.goodsItemList,
                          border: "",
                          "header-cell-style": { background: "#F6F6F6" }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsName",
                            label: "商品名称",
                            width: "180"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsCount",
                            label: "数量",
                            width: "180"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "goodsPrice", label: "单价" }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "goodsPrice", label: "参考金额" }
                        })
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm.record.auditStatus != 1 && _vm.record.invoiceType == 2
            ? _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    title: "收票人信息",
                    column: 1,
                    contentStyle: { "font-weight": "bold" }
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "收票人信息" } },
                    [_vm._v(_vm._s(_vm.record.invoicePayeeAddress))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.record.auditStatus != 1 && _vm.record.invoiceType == 2
            ? _c(
                "div",
                { staticClass: "bottom" },
                [
                  _vm.record.auditStatus !== 3
                    ? _c(
                        "el-card",
                        { staticStyle: { width: "50%" } },
                        [
                          _c(
                            "el-descriptions",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: {
                                title: "开票信息",
                                column: 1,
                                contentStyle: { "font-weight": "bold" }
                              }
                            },
                            [
                              _vm.record.auditStatus !== 3
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "发票代码" } },
                                    [_vm._v(_vm._s(_vm.record.invoiceCode))]
                                  )
                                : _vm._e(),
                              _vm.record.auditStatus !== 3
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "发票号码" } },
                                    [_vm._v(_vm._s(_vm.record.invoiceNumber))]
                                  )
                                : _vm._e(),
                              _vm.record.auditStatus !== 3
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "开票金额" } },
                                    [_vm._v(_vm._s(_vm.record.invoiceAmount))]
                                  )
                                : _vm._e(),
                              _vm.record.auditStatus !== 3
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "物流公司" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("expressCompany")(
                                            _vm.record.expressCompany
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.record.auditStatus !== 3
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "物流单号" } },
                                    [_vm._v(_vm._s(_vm.record.expressNumber))]
                                  )
                                : _vm._e(),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "审核时间" } },
                                [_vm._v(_vm._s(_vm.record.auditTime))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.record.auditStatus !== 3
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { width: "50%" },
                          attrs: {
                            shadow: "never",
                            "body-style": { padding: "20px", height: "200px" }
                          }
                        },
                        [
                          _c(
                            "el-timeline",
                            { staticClass: "d2-pl-5" },
                            _vm._l(_vm.record.invoiceExpressInfos, function(
                              activity,
                              index
                            ) {
                              return _c(
                                "el-timeline-item",
                                {
                                  key: index,
                                  attrs: {
                                    "hide-timestamp": "",
                                    type: activity.type,
                                    timestamp: activity.timestamp,
                                    placement: "top",
                                    color: activity.color
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "dot",
                                        fn: function() {
                                          return [
                                            _c("div", {
                                              staticClass: "dot",
                                              class: { "is-new": !index }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "timeline-item",
                                      style: { color: !index ? "#fa5454" : "" }
                                    },
                                    [
                                      _c("span", { staticClass: "d2-mb-5" }, [
                                        _vm._v(_vm._s(activity.time))
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(activity.context))
                                      ])
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.record.auditStatus != 1 && _vm.record.invoiceType == 1
            ? _c(
                "el-descriptions",
                {
                  attrs: {
                    title: "开票信息",
                    column: 1,
                    contentStyle: { "font-weight": "bold" }
                  }
                },
                [
                  _vm.record.auditStatus !== 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票代码" } },
                        [_vm._v(_vm._s(_vm.record.invoiceCode))]
                      )
                    : _vm._e(),
                  _vm.record.auditStatus !== 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票号码" } },
                        [_vm._v(_vm._s(_vm.record.invoiceNumber))]
                      )
                    : _vm._e(),
                  _vm.record.auditStatus !== 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "开票金额" } },
                        [_vm._v(_vm._s(_vm.record.invoiceAmount))]
                      )
                    : _vm._e(),
                  _c("el-descriptions-item", { attrs: { label: "审核时间" } }, [
                    _vm._v(_vm._s(_vm.record.auditTime))
                  ]),
                  _vm.record.auditStatus !== 3
                    ? _c("el-descriptions-item", { attrs: { label: "发票" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#409eff",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadfun(
                                  "发票.pdf",
                                  _vm.record.invoiceImageUrl
                                )
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.record.invoiceImageUrl))]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.record.auditStatus === 1 && _vm.record.invoiceType == 1
            ? _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "发票审核", column: 1 }
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      [
                        _c(
                          "el-form",
                          {
                            ref: "formName",
                            staticClass: "demo-ruleForm",
                            attrs: {
                              model: _vm.form,
                              rules: _vm.rules,
                              "label-width": "100px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "审核意见：",
                                  prop: "auditStatus"
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.auditStatus,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "auditStatus", $$v)
                                      },
                                      expression: "form.auditStatus"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("通过")
                                    ]),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("驳回")
                                    ])
                                  ],
                                  1
                                ),
                                _vm.form.auditStatus == 3
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 2,
                                        placeholder: "请输入驳回原因"
                                      },
                                      model: {
                                        value: _vm.form.rejectReason,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "rejectReason",
                                            $$v
                                          )
                                        },
                                        expression: "form.rejectReason"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票代码：",
                                      prop: "invoiceCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.invoiceCode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "invoiceCode", $$v)
                                        },
                                        expression: "form.invoiceCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票号码：",
                                      prop: "invoiceNumber"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.invoiceNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceNumber",
                                            $$v
                                          )
                                        },
                                        expression: "form.invoiceNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票金额：",
                                      prop: "invoiceAmount"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.invoiceAmount,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceAmount",
                                            $$v
                                          )
                                        },
                                        expression: "form.invoiceAmount"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "上传发票：",
                                      prop: "invoiceImageUrl"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      _vm._b(
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            "on-success": _vm.handleSuccess,
                                            "before-upload": _vm.beforeUpload
                                          }
                                        },
                                        "el-upload",
                                        _vm.uploadData,
                                        false
                                      ),
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            }
                                          },
                                          [_vm._v("点击上传")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "el-upload__tip",
                                            attrs: { slot: "tip" },
                                            slot: "tip"
                                          },
                                          [_vm._v("请上传发票PDF发票文件")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  { on: { click: this.goBack } },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.confirmOk }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.record.auditStatus === 1 && _vm.record.invoiceType == 2
            ? _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "发票审核", column: 1 }
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      [
                        _c(
                          "el-form",
                          {
                            ref: "formName2",
                            staticClass: "demo-ruleForm",
                            attrs: {
                              model: _vm.form,
                              rules: _vm.rules,
                              "label-width": "100px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "审核意见：",
                                  prop: "auditStatus"
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.auditStatus,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "auditStatus", $$v)
                                      },
                                      expression: "form.auditStatus"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("通过")
                                    ]),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("驳回")
                                    ])
                                  ],
                                  1
                                ),
                                _vm.form.auditStatus == 3
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 2,
                                        placeholder: "请输入驳回原因"
                                      },
                                      model: {
                                        value: _vm.form.rejectReason,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "rejectReason",
                                            $$v
                                          )
                                        },
                                        expression: "form.rejectReason"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票代码：",
                                      prop: "invoiceCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.invoiceCode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "invoiceCode", $$v)
                                        },
                                        expression: "form.invoiceCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票号码：",
                                      prop: "invoiceNumber"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.invoiceNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceNumber",
                                            $$v
                                          )
                                        },
                                        expression: "form.invoiceNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票金额：",
                                      prop: "invoiceAmount"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.invoiceAmount,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceAmount",
                                            $$v
                                          )
                                        },
                                        expression: "form.invoiceAmount"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "物流公司：",
                                      prop: "expressCompany"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.form.expressCompany,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "expressCompany",
                                              $$v
                                            )
                                          },
                                          expression: "form.expressCompany"
                                        }
                                      },
                                      _vm._l(_vm.express, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.company,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.auditStatus == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "物流单号",
                                      prop: "expressNumber"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.expressNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "expressNumber",
                                            $$v
                                          )
                                        },
                                        expression: "form.expressNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  { on: { click: this.goBack } },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.confirmOk2 }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }