import "core-js/modules/es.array.concat";
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      countTimeTimer: null,
      currentTime: ''
    };
  },
  beforeCreate: function beforeCreate() {
    clearInterval(this.countTimeTimer);
  },
  created: function created() {
    this.initTime();
  },
  methods: {
    // 初始化【时间】
    initTime: function initTime() {
      var _this = this;

      var initT = function initT() {
        var date = new Date();
        var y = date.getFullYear();
        var M = date.getMonth() + 1;
        var d = date.getDate();
        var H = date.getHours();
        var m = date.getMinutes();
        var s = date.getSeconds();

        var az = function az(n) {
          return (n > 9 ? '' : '0') + n;
        };

        _this.currentTime = "".concat(y, "\u5E74").concat(az(M), "\u6708").concat(az(d), "\u65E5 ").concat(az(H), ":").concat(az(m), ":").concat(az(s));
      };

      initT();
      clearInterval(this.countTimeTimer);
      this.countTimeTimer = setInterval(function () {
        initT();
      }, 1000);
    }
  }
};