var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.knowledgeId ? "编辑" : "新增") + "救助知识")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "救助知识标题", prop: "knowledgeTitle" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.knowledgeTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "knowledgeTitle", $$v)
                  },
                  expression: "form.knowledgeTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属知识分类" } },
            [
              _c("base-select", {
                attrs: { options: _vm.typeList },
                model: {
                  value: _vm.form.typeId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "typeId", $$v)
                  },
                  expression: "form.typeId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "救助知识排序", prop: "knowledgeSort" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "", min: "1", type: "number" },
                on: { input: _vm.changeInt },
                model: {
                  value: _vm.form.knowledgeSort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "knowledgeSort", $$v)
                  },
                  expression: "form.knowledgeSort"
                }
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v("说明：数值越小，排列越前")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否在APP展示", prop: "knowledgeShow" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.knowledgeShow,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "knowledgeShow", $$v)
                  },
                  expression: "form.knowledgeShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "救助知识内容", prop: "knowledgeContent" } },
            [
              _c("base-editor", {
                attrs: { content: _vm.form.knowledgeContent },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "knowledgeContent", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }