var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.add()
            }
          }
        },
        [_vm._v("新增食物")]
      ),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "食物名称" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "食物名称" },
                model: {
                  value: _vm.form.foodName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "foodName", $$v)
                  },
                  expression: "form.foodName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "食物名称", prop: "foodName" }
          }),
          _c("el-table-column", {
            attrs: { label: "可食用比例", prop: "edibleRate" }
          }),
          _c("el-table-column", {
            attrs: { label: "能量", prop: "foodEnergy" }
          }),
          _c("el-table-column", {
            attrs: { label: "蛋白质含量", prop: "foodProtein" }
          }),
          _c("el-table-column", {
            attrs: { label: "水分含量", prop: "foodMoisture" }
          }),
          _c("el-table-column", {
            attrs: { label: "脂肪含量", prop: "foodFat" }
          }),
          _c("el-table-column", {
            attrs: { label: "膳食纤维含量", prop: "foodFibre" }
          }),
          _c("el-table-column", {
            attrs: { label: "碳水化合物含量", prop: "foodCarbohydrate" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row.foodId)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "close-on-click-modal": false },
          on: {
            close: function($event) {
              _vm.dialogVisible = false
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "食物名称", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodName,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodName", $$v)
                      },
                      expression: "form1.foodName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "可食用比例", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.edibleRate,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "edibleRate", $$v)
                      },
                      expression: "form1.edibleRate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "能量", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodEnergy,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodEnergy", $$v)
                      },
                      expression: "form1.foodEnergy"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "蛋白质含量", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodProtein,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodProtein", $$v)
                      },
                      expression: "form1.foodProtein"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "水分含量", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodMoisture,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodMoisture", $$v)
                      },
                      expression: "form1.foodMoisture"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "脂肪含量", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodFat,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodFat", $$v)
                      },
                      expression: "form1.foodFat"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "膳食纤维含量", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodFibre,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodFibre", $$v)
                      },
                      expression: "form1.foodFibre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "碳水化合物含量", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form1.foodCarbohydrate,
                      callback: function($$v) {
                        _vm.$set(_vm.form1, "foodCarbohydrate", $$v)
                      },
                      expression: "form1.foodCarbohydrate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }