export default [{
  title: '用户钱包余额',
  authorityValue: "user-wallet-manage",
  iconSvg: '财务结算',
  children: [{
    path: '/app-user-wallet-list',
    title: '用户钱包余额',
    authorityValue: "user-wallet-list"
  }, {
    path: '/app-user-wallet-charge',
    title: '钱包充值记录',
    authorityValue: "user-recharges"
  }, {
    path: '/app-user-withdrawal',
    title: '用户提现',
    authorityValue: "user-transfers"
  }]
}];