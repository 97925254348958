var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "auth-settings" },
    [
      _c("template", { slot: "header" }, [_vm._v("权限设置")]),
      _c("el-cascader-panel", {
        staticStyle: { "min-height": "50vh" },
        attrs: { props: _vm.props, options: _vm.dataSource },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.selectedKeys,
          callback: function($$v) {
            _vm.selectedKeys = $$v
          },
          expression: "selectedKeys"
        }
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }