import "core-js/modules/es.array.fill";
import "core-js/modules/es.array.join";
import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
import Vue from 'vue';
export default Vue.extend({
  props: {
    fontSizeMin: {
      type: Number,
      default: 25
    },
    fontSizeMax: {
      type: Number,
      default: 30
    },
    backgroundColorMin: {
      type: Number,
      default: 255
    },
    backgroundColorMax: {
      type: Number,
      default: 255
    },
    colorMin: {
      type: Number,
      default: 0
    },
    colorMax: {
      type: Number,
      default: 160
    },
    lineColorMin: {
      type: Number,
      default: 100
    },
    lineColorMax: {
      type: Number,
      default: 255
    },
    dotColorMin: {
      type: Number,
      default: 0
    },
    dotColorMax: {
      type: Number,
      default: 255
    },
    contentWidth: {
      type: Number,
      default: 120
    },
    contentHeight: {
      type: Number,
      default: 34
    }
  },
  data: function data() {
    return {
      identifyCode: ''
    };
  },
  mounted: function mounted() {
    this.createdCode();
  },
  methods: {
    clearCanvas: function clearCanvas() {
      var c = document.getElementById('s-canvas');
      var cxt = c.getContext('2d');
      cxt.clearRect(0, 0, c.width, c.height);
    },
    // 生成4个随机数
    createdCode: function createdCode() {
      this.clearCanvas();
      var len = 4;
      var codeList = [];
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789';
      var charsLen = chars.length;

      for (var i = 0; i < len; i++) {
        codeList.push(chars.charAt(Math.floor(Math.random() * charsLen)));
      }

      this.identifyCode = codeList.join('');
      this.$emit('getIdentifyCode', this.identifyCode.toLowerCase());
      this.drawPic();
    },
    // 生成一个随机数
    randomNum: function randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 生成一个随机的颜色
    randomColor: function randomColor(min, max) {
      var r = this.randomNum(min, max);
      var g = this.randomNum(min, max);
      var b = this.randomNum(min, max);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    },
    drawPic: function drawPic() {
      var canvas = document.getElementById('s-canvas');
      var ctx = canvas.getContext('2d');
      ctx.textBaseline = 'bottom'; // 绘制背景
      // ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax)

      ctx.fillStyle = 'rgba(255, 255, 255, 0)'; // 设置为透明色
      // ctx.globalAlpha = 0

      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight); // 绘制文字

      for (var i = 0; i < this.identifyCode.length; i++) {
        this.drawText(ctx, this.identifyCode[i], i);
      }

      this.drawLine(ctx); // this.drawDot(ctx)
    },
    drawText: function drawText(ctx, txt, i) {
      // ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax)
      ctx.fillStyle = '#3886FF'; // 字体颜色

      ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei';
      var x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1));
      var y = this.randomNum(this.fontSizeMax, this.contentHeight - 5);
      var deg = this.randomNum(-45, 45); // 修改坐标原点和旋转角度

      ctx.translate(x, y);
      ctx.rotate(deg * Math.PI / 180);
      ctx.fillText(txt, 0, 0); // 恢复坐标原点和旋转角度

      ctx.rotate(-deg * Math.PI / 180);
      ctx.translate(-x, -y);
    },
    // 绘制干扰线
    drawLine: function drawLine(ctx) {
      for (var i = 0; i < 5; i++) {
        ctx.strokeStyle = this.randomColor(this.lineColorMin, this.lineColorMax);
        ctx.beginPath();
        ctx.moveTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight));
        ctx.lineTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight));
        ctx.stroke();
      }
    },
    // 绘制干扰点
    drawDot: function drawDot(ctx) {
      for (var i = 0; i < 80; i++) {
        ctx.fillStyle = this.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
    }
  }
});