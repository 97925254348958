var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("img", {
      staticClass: "t-icon",
      attrs: { src: require("../../assets/image/t-icon.png"), alt: "" }
    }),
    _c("span", { staticClass: "title" }, [_vm._t("default")], 2),
    _c("img", {
      staticClass: "t-line",
      attrs: { src: require("../../assets/image/t-line.png"), alt: "" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }