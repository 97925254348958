var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        fullTitle: "屏蔽帖子",
        visible: _vm.dialogVisible,
        rules: _vm.formRules,
        formData: _vm.dialogFormData,
        isEdit: true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "update:formData": function($event) {
          _vm.dialogFormData = $event
        },
        "update:form-data": function($event) {
          _vm.dialogFormData = $event
        },
        confirm: _vm.onSubmit
      }
    },
    [
      _c("p", [_vm._v("确定要取消屏蔽么")]),
      _c(
        "el-form-item",
        { staticClass: "mt-20", attrs: { label: "备注", prop: "logRemark" } },
        [
          _c("el-input", {
            attrs: { placeholder: "", type: "textarea", rows: 3 },
            model: {
              value: _vm.dialogFormData.logRemark,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "logRemark", $$v)
              },
              expression: "dialogFormData.logRemark"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }