//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    text: {
      default: ''
    }
  },
  name: 'hidden-text',
  data: function data() {
    return {
      showTooltip: false
    };
  },
  methods: {
    showTips: function showTips(obj) {
      /* obj为鼠标移入时的事件对象 */

      /* currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除 */
      var TemporaryTag = document.createElement('span');
      TemporaryTag.innerText = this.text;
      TemporaryTag.className = 'getTextWidth';
      document.querySelector('body').appendChild(TemporaryTag);
      var currentWidth = document.querySelector('.getTextWidth').offsetWidth;
      document.querySelector('.getTextWidth').remove();
      /* cellWidth为表格容器的宽度 */

      var cellWidth = obj.target.offsetWidth;
      /* 当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行 */

      currentWidth <= 2 * cellWidth ? this.showTooltip = false : this.showTooltip = true;
    }
  }
};