/**
 * 后端的给的接口也不对，我真是服了，我重新整个数据，看同一个文件夹的excel
 */
import obj1 from './catesChunk/1';
import obj2 from './catesChunk/2';
import obj3 from './catesChunk/3';
import obj4 from './catesChunk/4';
import obj5 from './catesChunk/5';
import obj6 from './catesChunk/6';
import obj7 from './catesChunk/7';
import obj8 from './catesChunk/8';
import obj9 from './catesChunk/9';
export var catesAll = [obj1, obj2, obj3, obj4, obj5, obj6, // obj7,
obj8, obj9];