//
//
//
//
import EditorPreview from '@/components/editor-preview';
export default {
  meta: {
    title: '救助事件详情',
    auth: true,
    authorityValue: 'AED-knowledge-manage'
  },
  name: 'fist-aid-knowledge-detail',
  components: {
    EditorPreview: EditorPreview
  },
  data: function data() {
    return {
      request: {
        api: 'aed_rescueKnowledgeDetails',
        params: {
          knowledgeId: this.$route.query.knowledgeId
        },
        target: 'knowledgeContent'
      }
    };
  },
  methods: {}
};