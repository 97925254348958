//
//
//
//
import EditorPreview from '@/components/editor-preview';
export default {
  meta: {
    title: '基金会事迹',
    auth: true,
    authorityValue: 'foundation-deeds'
  },
  name: 'foundation—story-detail',
  components: {
    EditorPreview: EditorPreview
  },
  data: function data() {
    return {
      request: {
        api: 'aed_foundationDeedDetails',
        params: {
          foundationDeedId: this.$route.query.foundationDeedId
        },
        target: 'foundationDeedContent'
      }
    };
  },
  methods: {}
};