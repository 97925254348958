var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("el-card", { staticClass: "d2-mb", attrs: { shadow: "never" } }, [
        _c(
          "div",
          {
            staticClass: "flex-center-between",
            staticStyle: { display: "flex", "justify-content": "space-between" }
          },
          [
            _c("span", [
              _vm._v(
                "税务信息更新" +
                  _vm._s(_vm.courseAuditStr(_vm.ulData.auditStatus))
              )
            ]),
            _c(
              "div",
              [
                _vm.ulData.auditStatus == 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.layer.dialogVisible = true
                          }
                        }
                      },
                      [_vm._v(" 审核 ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "el-card",
        { staticClass: "d2-mt", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                column: 5,
                direction: "vertical",
                colon: false,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "审批编号" } }, [
                _vm._v(" " + _vm._s(_vm.ulData.auditNo) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "审核状态" } }, [
                _vm._v(
                  " " + _vm._s(_vm.courseAuditStr(_vm.ulData.auditStatus)) + " "
                )
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "门店名称" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.goRoute(
                            "/settled-stores-detail?gymId=" + _vm.ulData.gymId
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.ulData.gymName) + " ")]
                  )
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                _vm._v(" " + _vm._s(_vm.ulData.companyName) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatDateTime")(_vm.ulData.createTime)) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "el-descriptions",
            { attrs: { column: 1, title: "门店税务信息" } },
            [
              _c("el-descriptions-item", { attrs: { label: "纳税人类型" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      ["一般纳税人", "小规模纳税人"][
                        _vm.ulData.taxpayerType - 1
                      ]
                    ) +
                    " "
                )
              ]),
              _vm.ulData.taxpayerType == 1
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "一般纳税人资格证" } },
                    [
                      _c("base-gallery", {
                        staticClass: "mt-10",
                        attrs: { srcList: [_vm.ulData.taxpayerCertImage] }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-descriptions-item", { attrs: { label: "合同编号" } }, [
                _vm._v(" " + _vm._s(_vm.ulData.contractNo) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "开户行" } }, [
                _vm._v(" " + _vm._s(_vm.ulData.bankName) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "账号" } }, [
                _vm._v(" " + _vm._s(_vm.ulData.bankAccount) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "支行名称" } }, [
                _vm._v(" " + _vm._s(_vm.ulData.bankBranchName) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.ulData.auditStatus > 1
        ? _c(
            "el-card",
            {
              staticStyle: {
                "margin-top": "20px",
                width: "80%",
                "font-size": "small"
              }
            },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("记录")
              ]),
              _vm._l(_vm.ulData.logs, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.logType === 2
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("备注：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    item.logType === 1
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("审核：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _vm.ulData.auditStatus == 3
                              ? _c("el-col", [
                                  _vm._v(
                                    "原因：" + _vm._s(_vm.ulData.rejectReason)
                                  )
                                ])
                              : _vm._e(),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "税务信息更新审核",
            visible: _vm.layer.dialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.layer, "dialogVisible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.layer.auditStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.layer, "auditStatus", $$v)
                        },
                        expression: "layer.auditStatus"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("审核通过")
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("审核不通过")
                      ])
                    ],
                    1
                  ),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.layer.auditStatus == 3,
                        expression: "layer.auditStatus == 3"
                      }
                    ],
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.layer.rejectReason,
                      callback: function($$v) {
                        _vm.$set(_vm.layer, "rejectReason", $$v)
                      },
                      expression: "layer.rejectReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.layer.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.layerOnSubimt }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "d2-mt",
          on: {
            click: function($event) {
              return _vm.goBack()
            }
          }
        },
        [_vm._v("返回")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }