import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 获取PLUS门店收益设置 */
    plus_getPlusSetting: createAction(request, '/admin/plus/getPlusSetting'),

    /** 保存PLUS门店收益设置 */
    plus_savePlusSetting: createAction(request, '/admin/plus/savePlusSetting'),

    /** plus会员设置 */
    plus_plusMembers: createAction(request, '/admin/plus/plusMembers'),

    /** 上架/下架 */
    plus_onShelf: createAction(request, '/admin/plus/onShelf'),

    /** 保存会员 */
    plus_savePlusMember: createAction(request, '/admin/plus/savePlusMember'),

    /** 保存邀请好友赚钱攻略 */
    plus_saveInvitationGuide: createAction(request, '/admin/plus/saveInvitationGuide'),

    /** 保存邀请好友赚钱规则 */
    plus_saveInvitationRule: createAction(request, '/admin/plus/saveInvitationRule'),

    /** 获取邀请好友赚钱规则 */
    plus_getInvitationRule: createAction(request, '/admin/plus/getInvitationRule'),

    /** 获取邀请好友赚钱攻略 */
    plus_getInvitationGuide: createAction(request, '/admin/plus/getInvitationGuide'),

    /** PLUS会员门店收益 */
    plus_gymPlusConsumes: createAction(request, '/admin/plus/gymPlusConsumes'),

    /** PLUS会员订单列表 */
    plus_plusOrders: createAction(request, '/admin/plus/plusOrders'),

    /** 会员使用记录 */
    plus_plusConsumeRecords: createAction(request, '/admin/plus/plusConsumeRecords'),

    /** 要炼Plus会员退款记录 */
    plus_plusRefundList: createAction(request, '/admin/plus/plusRefundList'),

    /** 要炼Plus会员汇总 */
    plus_plusStatistics: createAction(request, '/admin/plus/plusStatistics'),

    /** 要炼Plus返现记录 */
    plus_plusCashbackList: createAction(request, '/admin/plus/plusCashbackList')
  };
});