import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import _toConsumableArray from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { uniqueId } from 'lodash';
import appUserWallet from './modules/appUserWallet';
import dispute from './modules/dispute';
import plusMember from './modules/plusMember';
import storeManagement from './modules/storeManagement';
import userManagement from './modules/userManagement';
import course from './modules/course';
import live from './modules/live';
import actLive from './modules/actLive';
import permissionManagement from './modules/permissionManagement';
import firstAidManagement from './modules/firstAidManagement';
import financeManagement from './modules/financeManagement';
import shopCentre from './modules/shopCentre';
import teamService from './modules/teamService';
import personal from './modules/personal';
import article from './modules/article';
import vote from './modules/vote';
/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */

function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
}

export var menuHeader = supplementPath([{
  title: '数据大屏',
  iconSvg: '数据大屏',
  path: '/data-screen',
  authorityName: '访问数据大屏',
  authorityValue: 'large-screen-data'
}, {
  title: '救助大屏',
  iconSvg: '急救大屏',
  path: '/aed-screen',
  authorityName: '访问救助大屏',
  authorityValue: 'large-screen-aed'
}, {
  title: '资讯大屏',
  iconSvg: '资讯大屏',
  path: '/news-screen',
  authorityName: '访问资讯大屏',
  authorityValue: 'large-screen-news'
}]);
export var menuAside = supplementPath([{
  path: '/index',
  title: '首页',
  iconSvg: '首页',
  authorityValue: 'admin-router-page'
}].concat(_toConsumableArray(userManagement), _toConsumableArray(course), _toConsumableArray(live), _toConsumableArray(actLive), _toConsumableArray(personal), _toConsumableArray(shopCentre), _toConsumableArray(storeManagement), [{
  title: '智能设备管理',
  authorityValue: 'equipment-management',
  path: '/equipment',
  iconSvg: '设备管理'
}, {
  title: '器械管理',
  authorityValue: 'lease-equipment-management',
  path: '/device-management',
  iconSvg: '设备管理'
}], _toConsumableArray(plusMember), _toConsumableArray(article), _toConsumableArray(firstAidManagement), _toConsumableArray(financeManagement), _toConsumableArray(dispute), _toConsumableArray(teamService), _toConsumableArray(vote), _toConsumableArray(permissionManagement))); //代理商路由

export var menuAsideAgent = supplementPath([{
  title: '门店账单查询',
  path: '/shop-bill-query',
  authorityValue: 'gym-bill-list',
  iconSvg: '财务结算'
}, {
  title: '门店明细账单查询',
  path: '/shop-detail-bill-query',
  authorityValue: 'gym-bill-logs',
  iconSvg: '财务结算'
}]);