//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import WaitAudit from './waitAudit.vue';
import AuditAll from './auditAll.vue';
export default {
  meta: {
    title: '教练线下认证',
    auth: true,
    authorityValue: 'coach-auth-audit'
  },
  name: 'coach-offline-certification',
  components: {
    WaitAudit: WaitAudit,
    AuditAll: AuditAll
  },
  data: function data() {
    return {
      activeName: 'wait-audit'
    };
  }
};