import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'base-gallery',
  props: {
    canDelete: {
      type: Boolean,
      default: false
    },
    srcList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    imgWidth: {
      default: '150px'
    },
    imgHeight: {
      default: '150px'
    }
  },
  data: function data() {
    return {
      style: "height: ".concat(this.imgHeight, "; margin-right: 20px;width: ").concat(this.imgWidth || 'auto')
    };
  },
  methods: {
    onDel: function onDel(i) {
      this.$emit('update:srcList', this.srcList.filter(function (item, index) {
        return index !== i;
      }));
    }
  }
};