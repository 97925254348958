import "core-js/modules/es.array.some";
import "core-js/modules/es.array.splice";
import Vue from 'vue';
import { cloneDeep, isBoolean } from 'lodash'; //比对

function comparison(el, binding) {
  var value = cloneDeep(binding.value);
  if (isBoolean(value)) return !value && el.remove();
  var status = value.splice(0, 1);
  var show = value.some(function (v) {
    return status == v;
  });
  !show && el.remove();
}

Vue.directive('run', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function inserted(el, binding) {
    comparison(el, binding);
  },
  update: function update(el, binding) {
    comparison(el, binding);
  }
});