export default [{
  title: '活动直播管理',
  iconSvg: '活动直播管理',
  authorityValue: "activity-live-management",
  children: [{
    path: '/actlive-cate',
    title: '活动直播分类',
    authorityValue: "activity-live-category-manage"
  }, {
    path: '/cate-live-list',
    title: '活动直播管理',
    authorityValue: "activity-live-manage"
  }]
}];