var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(
          _vm._s(_vm.title) +
            " - " +
            _vm._s(
              _vm._f("ftValue2label")(_vm.form.liveStatus, _vm.dict.liveStatus)
            )
        )
      ]),
      _c(
        "div",
        { attrs: { flex: "main" } },
        [
          _c(
            "el-card",
            {
              attrs: { shadow: "never", "body-style": { padding: "20px" } },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("div", { attrs: { flex: "main:justify" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.form.courseName))]),
                        _c("span", [
                          _vm._v(
                            " 直播时间：" + _vm._s(_vm.form.liveDate) + " "
                          ),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.form.liveStartTime) +
                                " " +
                                _vm._s(_vm.form.liveEndTime)
                            )
                          ])
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "video-box" }, [
                _vm.isBtnGroud([1, 4], _vm.form.liveStatus)
                  ? _c("div", { staticClass: "video-make" }, [
                      _c("h2", [
                        _vm._v(
                          _vm._s(
                            _vm._f("ftValue2label")(
                              _vm.form.liveStatus,
                              _vm.dict.liveStatus
                            )
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { attrs: { id: "wrap" } }, [
                  _c("video", {
                    attrs: {
                      id: "leb-video-container",
                      playsinline: "",
                      "webkit-playsinline": ""
                    }
                  })
                ])
              ]),
              _c(
                "div",
                { staticClass: "d2-mt", attrs: { flex: "main:right" } },
                [
                  _vm.isBtnGroud([1], _vm.form.liveStatus)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onStart }
                        },
                        [_vm._v("开始直播")]
                      )
                    : _vm._e(),
                  _vm.isBtnGroud([2, 4], _vm.form.liveStatus)
                    ? _c(
                        "el-button",
                        {
                          staticClass: "d2-mr",
                          attrs: {
                            icon: _vm.pause
                              ? "el-icon-video-play"
                              : "el-icon-video-pause",
                            type: "text"
                          },
                          on: { click: _vm.onPause }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.pause ? "恢复" : "暂停") + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.isBtnGroud([2, 4], _vm.form.liveStatus)
                    ? _c(
                        "el-button",
                        { attrs: { type: "danger" }, on: { click: _vm.onEnd } },
                        [_vm._v("结束")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }