import _defineProperty from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
export default (function (_ref) {
  var _ref2;

  var request = _ref.request;
  return _ref2 = {
    vote_getVoteSetting: function vote_getVoteSetting() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/getVoteSetting',
        data: data
      });
    },
    vote_saveVoteSetting: function vote_saveVoteSetting() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/saveVoteSetting',
        data: data
      });
    },
    vote_saveArticle: function vote_saveArticle() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/saveArticle',
        data: data
      });
    },
    vote_saveVote: function vote_saveVote() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/saveVote',
        data: data
      });
    },
    vote_votes: function vote_votes() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/votes',
        data: data
      });
    },
    vote_voteDetails: function vote_voteDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/voteDetails',
        data: data
      });
    },
    vote_userVotes: function vote_userVotes() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/userVotes',
        data: data
      });
    },
    vote_articles: function vote_articles() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/articles',
        data: data
      });
    },
    vote_showArticle: function vote_showArticle() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/showArticle',
        data: data
      });
    },
    vote_deleteArticle: function vote_deleteArticle() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/deleteArticle',
        data: data
      });
    },
    vote_articleDetails: function vote_articleDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/vote/articleDetails',
        data: data
      });
    }
  }, _defineProperty(_ref2, "vote_saveArticle", function vote_saveArticle() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request({
      url: 'admin/vote/saveArticle',
      data: data
    });
  }), _defineProperty(_ref2, "vote_deleteVote", function vote_deleteVote() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request({
      url: 'admin/vote/deleteVote',
      data: data
    });
  }), _ref2;
});