var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Title", [_vm._v("门店服务人次")]),
      _c(
        "div",
        { staticClass: "center" },
        _vm._l(_vm.topData, function(l, i) {
          return _c("div", { key: i, staticClass: "item" }, [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("../../assets/image/r-line.png"), alt: "" }
            }),
            _c("div", { staticClass: "label" }, [_vm._v(_vm._s(l.label))]),
            _c(
              "div",
              { staticClass: "num" },
              [_c("d2-count-up", { attrs: { end: l.number } })],
              1
            )
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "progress" },
        _vm._l(_vm.tabData, function(l, i) {
          return _c(
            "div",
            { key: i, staticClass: "item flex-center-between" },
            [
              _c("img", {
                staticClass: "img",
                attrs: { src: l.icon, alt: "" }
              }),
              _c("div", { staticClass: "label" }, [_vm._v(_vm._s(l.label))]),
              _c(
                "div",
                { staticClass: "flex-box-1" },
                [
                  _c("el-progress", {
                    class: _vm._f("showProgressColor")(l.key),
                    attrs: {
                      "show-text": false,
                      "stroke-width": 9,
                      percentage: _vm.setPercentage(l.number)
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "num" },
                [_c("d2-count-up", { attrs: { end: l.number } })],
                1
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }