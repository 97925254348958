/**
 * 动作相关
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    FOOD_LIST: function FOOD_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/food/foods',
        data: data
      });
    },
    ADD_FOOD: function ADD_FOOD() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/food/addFood',
        data: data
      });
    },
    DELETE_FOOD: function DELETE_FOOD() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/food/deleteFood',
        data: data
      });
    },
    FOOD_MENU_LIST: function FOOD_MENU_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/food/foodsMenu',
        data: data
      });
    }
  };
});