import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import comp from './comp/index';
import { cloneDeep } from 'lodash';
var initForm = {
  orderNo: '',
  userNo: '',
  consumerName: '',
  applyTimeRange: {
    start: '',
    end: ''
  },
  auditTimeRange: {
    start: '',
    end: ''
  }
};
export default {
  meta: {
    title: '取消订单管理',
    auth: true,
    authorityValue: 'mall-cancel-list'
  },
  name: 'cancel-order',
  components: _objectSpread({}, comp),
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      activeName: 'stayExamine',
      tab: [{
        label: '待审核',
        type: 'stayExamine',
        limt: 0,
        refundStatus: 1
      }, {
        label: '取消成功',
        type: 'stayReceiv',
        limt: 0,
        refundStatus: 2
      }, {
        label: '取消失败',
        type: 'stayExchange',
        limt: 0,
        refundStatus: 3
      }, {
        label: '全部',
        type: 'afterAll',
        limt: 0,
        refundStatus: 4
      }]
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$refs.baseTable[0].handleSearch(values);
    }
  }
};