export default [{
  title: '救助管理',
  iconSvg: '急救管理',
  authorityValue: "AED-management",
  children: [{
    path: '/first-aid-call-record-list',
    title: '救助呼叫记录',
    authorityValue: "AED-call-list"
  }, {
    path: '/aed-position',
    title: 'AED位置管理',
    authorityValue: "AED-location-manage"
  }, {
    path: '/first-aid-skills-certification-list',
    title: '救助技能认证',
    authorityValue: "AED-skill-auth"
  }, {
    path: '/public-welfare-introduction',
    title: '要炼公益介绍',
    authorityValue: "yl-public-good"
  }, {
    path: '/foundation—story-list',
    title: '基金会事迹管理',
    authorityValue: "foundation-deeds"
  }, {
    path: '/about-foundation',
    title: '基金会介绍',
    authorityValue: "foundation-intro"
  }, {
    title: '救助事件',
    authorityValue: "AED-event-manage",
    children: [{
      path: '/fist-aid-event-cate',
      title: '救助事件分类',
      authorityValue: "AED-event-type"
    }, {
      path: '/fist-aid-event-list',
      title: '救助事件管理',
      authorityValue: "AED-event-list"
    }]
  }, {
    title: '救助知识管理',
    path: '/fist-aid-knowledge-list',
    authorityValue: "AED-knowledge-manage" // children: [
    //   // {
    //   //   path: '/fist-aid-knowledge-cate',
    //   //   title: '救助知识分类'
    //   // },
    //   {
    //     path: '/fist-aid-knowledge-list',
    //     title: '救助知识'
    //   }
    // ]

  }]
}];