var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Title", [_vm._v("事件轨迹")]),
      _c("el-scrollbar", { class: { show: true, showHeight: _vm.show } }, [
        _c(
          "div",
          { staticClass: "centent" },
          [
            _c("div", { staticClass: "line" }),
            _vm._l(_vm.tabData, function(l, i) {
              return _c("div", { key: i, staticClass: "item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("../../assets/image/m-user.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "label-item" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("div", { staticClass: "nickname" }, [
                        _vm._v(_vm._s(l.name))
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDateTime")(l.createTime)))
                      ])
                    ]),
                    _c("div", { staticClass: "commonet" }, [
                      l.files && l.files.length == 0
                        ? _c(
                            "span",
                            { staticStyle: { "line-height": "1.5" } },
                            [_vm._v(_vm._s(l.text))]
                          )
                        : _c(
                            "div",
                            { staticClass: "el-img" },
                            _vm._l(l.files, function(m, f) {
                              return _c("el-image", {
                                key: f,
                                attrs: {
                                  fit: "cover",
                                  src: m,
                                  "preview-src-list": [m]
                                }
                              })
                            }),
                            1
                          )
                    ])
                  ])
                ])
              ])
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }