var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "退款申请单号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "nickname",
              label: "客户姓名",
              align: "center",
              width: "150px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { attrs: { flex: "main" } }, [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(_vm._s(row.nickname))
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "userNo", label: "客户账号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "applyTime", label: "申请时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "refundAmount", label: "退款金额", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "operatorName", label: "审核人", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNo",
              label: "订单编号",
              align: "center",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { attrs: { flex: "main" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.handleGoRoute(row.orderId)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.orderNo))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#606266" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.copyText(row.orderId)
                              }
                            }
                          },
                          [_vm._v("复制")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "delivered", label: "出库信息", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.delivered ? "出库" : "未出库"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countdownHour",
              label: "超时审核倒计时",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.countdownHour) +
                          ":" +
                          _vm._s(row.countdownMinute)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onDetail(row.refundId)
                          }
                        }
                      },
                      [_vm._v(" 服务单详情 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }