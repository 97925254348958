var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("签到奖励金设置")]),
      _c(
        "page-content",
        [
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: {
                pagination: false,
                data: _vm.dataSource,
                showHeader: false
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "xx", prop: "xxx", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(" 连续" + _vm._s(scope.$index + 1) + "天签到")
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "xx", prop: "xx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [_vm._v("现金：")]),
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "150px" },
                            attrs: {
                              placeholder: "",
                              type: "number",
                              min: "0"
                            },
                            model: {
                              value: row.amount,
                              callback: function($$v) {
                                _vm.$set(row, "amount", $$v)
                              },
                              expression: "row.amount"
                            }
                          },
                          [_c("template", { slot: "append" }, [_vm._v("元")])],
                          2
                        ),
                        _c("span", { staticClass: "ml-20" }, [
                          _vm._v("奖励浮动百分比：")
                        ]),
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "150px" },
                            attrs: {
                              placeholder: "",
                              type: "number",
                              min: "0",
                              max: "100"
                            },
                            model: {
                              value: row.floatRate,
                              callback: function($$v) {
                                _vm.$set(row, "floatRate", $$v)
                              },
                              expression: "row.floatRate"
                            }
                          },
                          [_c("template", { slot: "append" }, [_vm._v("%")])],
                          2
                        ),
                        _c("span", { staticClass: "ml-20 text-999" }, [
                          _vm._v("用户签到时可获取浮动范围内随机现金。")
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }