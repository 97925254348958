var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("el-scrollbar", { staticClass: "scrollbar" }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.load,
                expression: "load"
              }
            ],
            attrs: { "infinite-scroll-delay": "500" }
          },
          _vm._l(_vm.tabData, function(l, i) {
            return _c(
              "li",
              {
                key: i,
                on: {
                  click: function($event) {
                    return _vm.clickTab(l)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "bg-img",
                    class: { bgActive: _vm.active === l.newsId }
                  },
                  [
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "top" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(_vm._s(l.newsTitle))
                        ])
                      ]),
                      _c("div", { staticClass: "bottom" }, [
                        _vm._v(_vm._s(l.createTime))
                      ]),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: i !== 0,
                            expression: "i !== 0"
                          }
                        ],
                        staticClass: "line"
                      })
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }