export default [{
  title: '商城管理',
  iconSvg: '商城管理',
  authorityValue: "mall-management",
  children: [{
    title: '店铺管理',
    authorityValue: "store-manage",
    children: [{
      path: '/store-settled',
      title: '已入驻店铺管理',
      authorityValue: "store-settled-list"
    }, {
      path: '/shop-list',
      title: '店铺入驻意向',
      authorityValue: "store-settled-intention"
    }, {
      path: '/store-entry',
      title: '店铺入驻审核',
      authorityValue: "store-settled-audit"
    }, {
      path: '/store-review',
      title: '营业执照更新审核',
      authorityValue: "store-license-update-audit"
    }, {
      path: '/store-legal',
      title: '法人身份证更新审核',
      authorityValue: "store-legal-id-update-audit"
    }, {
      path: '/store-tax',
      title: '税务信息更新审核',
      authorityValue: "store-taxpayer-update-audit"
    }]
  }, {
    title: '商品管理',
    authorityValue: "goods-manage",
    children: [{
      path: '/goods-class',
      title: '商品分类',
      authorityValue: "goods-type"
    }, {
      path: '/sale-trade',
      title: '在售商品',
      authorityValue: "goods-on-shelf"
    }, {
      path: '/coms-trade',
      title: '待售商品',
      authorityValue: "goods-off-shelf"
    }, {
      path: '/goods-comment',
      title: '商品评价',
      authorityValue: "goods-evaluate"
    }]
  }, {
    title: '订单管理',
    authorityValue: "mall-order-manage",
    children: [{
      path: '/order-search',
      title: '订单查询与跟踪',
      authorityValue: "mall-order-list"
    }, {
      path: '/delay-ship',
      title: '延迟发货记录',
      authorityValue: "delayed-shipment-list"
    }]
  }, {
    title: '售后服务',
    authorityValue: "mall-after-sale-service",
    children: [{
      path: '/after-sale',
      title: '退换货服务单',
      authorityValue: "mall-refund-list"
    }, {
      path: '/cancel-order',
      title: '取消订单管理',
      authorityValue: "mall-cancel-list"
    }]
  }, {
    title: '数据统计',
    authorityValue: "mall-data-manage",
    children: [{
      path: '/statistics-realTime',
      title: '实时概况分析',
      authorityValue: "mall-real-time-sta"
    }, {
      path: '/statistics-transaction',
      title: '交易分析',
      authorityValue: "mall-trade-sta"
    }, {
      path: '/statistics-commodity',
      title: '商品明细',
      authorityValue: "mall-goods-sta"
    }, {
      path: '/statistics-service',
      title: '退换货服务分析',
      authorityValue: "mall-refund-sta"
    }, {
      path: '/statistics-evaluate',
      title: '评价分析数据',
      authorityValue: "goods-evaluate-sta"
    }]
  }, {
    title: '商城设置',
    authorityValue: "mall-setting-manage",
    children: [{
      path: '/set-banner',
      title: '商城首页轮播图',
      authorityValue: "mall-banner"
    }, {
      path: '/set-market',
      title: '营销页面',
      authorityValue: "marketing-goods-list"
    }, {
      path: '/set-a-shop',
      title: '优选商品设置',
      authorityValue: "goods-chosen-setting"
    }, {
      path: '/find-good-goods',
      title: '发现好物商品',
      authorityValue: "goods-recommend-setting"
    }, {
      path: '/set-attr',
      title: '商品销售属性',
      authorityValue: "goods-item-setting"
    }, {
      path: '/set-bond',
      title: '店铺保证金设置',
      authorityValue: "store-bond-setting"
    }]
  }]
}];