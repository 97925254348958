import { render, staticRenderFns } from "./index.page.vue?vue&type=template&id=6f54967e&"
import script from "./index.page.vue?vue&type=script&lang=js&"
export * from "./index.page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yaolian_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f54967e')) {
      api.createRecord('6f54967e', component.options)
    } else {
      api.reload('6f54967e', component.options)
    }
    module.hot.accept("./index.page.vue?vue&type=template&id=6f54967e&", function () {
      api.rerender('6f54967e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/first-aid-management/first-aid-skills-certification/list/index.page.vue"
export default component.exports