import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 设备列表 */
    equipments_equipments: createAction(request, '/admin/equipment/equipments'),

    /** 添加设备 */
    equipments_saveEquipment: createAction(request, '/admin/equipment/saveEquipment'),

    /** 删除设备 */
    equipments_deleteEquipment: createAction(request, '/admin/equipment/deleteEquipment'),

    /** 设备列表（20230308） */
    lease_listEquipment: createAction(request, '/admin/lease/list'),

    /** 保存设备（20230308） */
    lease_saveEquipment: createAction(request, '/admin/lease/save'),

    /** 删除器械（20230308） */
    lease_deleteEquipment: createAction(request, '/admin/lease/delete'),

    /** 锁定/解锁（20230308） */
    lease_lockEquipment: createAction(request, '/admin/lease/lock')
  };
});