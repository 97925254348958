import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      tableData: []
    };
  },
  filters: {
    fileInvoiceTitleType: function fileInvoiceTitleType(e) {
      var item = [{
        value: 1,
        label: '个人'
      }, {
        value: 2,
        label: '公司'
      }].find(function (v) {
        return v.value == e;
      });
      if (!item) return;
      return item.label;
    }
  },
  methods: {
    itemSellAttributesFn: function itemSellAttributesFn(row) {
      var itemSellAttributes = row.itemSellAttributes;
      var s = itemSellAttributes.map(function (v) {
        return "".concat(v.itemName, " : ").concat(v.itemValue);
      });
      return s.join('');
    }
  }
};