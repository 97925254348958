import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';

var userImg = require("@/assets/images/user.png");

export default {
  data: function data() {
    return {
      userImg: userImg
    };
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['logout'])), {}, {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },

    /**
     * @description 修改密码
     */
    changePass: function changePass() {
      this.goRoute('/change-pass');
    }
  })
};