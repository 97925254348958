var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("商城轮播图")]),
      _vm._l(_vm.forms, function(item, index) {
        return _c(
          "el-form",
          {
            key: item.$uid,
            ref: item.formRef,
            refInFor: true,
            attrs: { model: item, "label-width": "120px", rules: _vm.rules }
          },
          [
            _c(
              "el-card",
              { staticClass: "mb-20" },
              [
                _vm._v(" 序号：" + _vm._s(index + 1) + " "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _vm._v(" 建议上传图片尺寸690*280像素,大小不能超过300kb "),
                    _vm.forms.length > 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { float: "right" },
                            attrs: { type: "default" },
                            on: {
                              click: function($event) {
                                return _vm.onDel(index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "轮播图", prop: "bannerUrl", required: "" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-cell",
                        on: {
                          click: function($event) {
                            return _vm.onOpenUpload(index)
                          }
                        }
                      },
                      [
                        _c(
                          "el-image",
                          { attrs: { fit: "contain", src: item.bannerUrl } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px"
                                  },
                                  attrs: { src: _vm.uploadImg }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "轮播图排序", prop: "bannerSort" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "", type: "number", min: "1" },
                      on: {
                        input: function($event) {
                          return _vm.changeInt(index, item.bannerSort)
                        }
                      },
                      model: {
                        value: item.bannerSort,
                        callback: function($$v) {
                          _vm.$set(item, "bannerSort", $$v)
                        },
                        expression: "item.bannerSort"
                      }
                    }),
                    _c("span", { staticClass: "ml-20" }, [
                      _vm._v("数值越小，排在越前面")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "跳转页面内容" } },
                  [
                    _c("base-radio-group", {
                      attrs: { options: _vm.options },
                      model: {
                        value: item.towardDetails.towardType,
                        callback: function($$v) {
                          _vm.$set(item.towardDetails, "towardType", $$v)
                        },
                        expression: "item.towardDetails.towardType"
                      }
                    })
                  ],
                  1
                ),
                item.towardDetails.towardType == 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("base-editor", {
                          attrs: {
                            upToken: _vm.qnToken,
                            content: item.towardDetails.htmlContent
                          },
                          on: {
                            "update:content": function($event) {
                              return _vm.$set(
                                item.towardDetails,
                                "htmlContent",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                item.towardDetails.towardType == 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "mt-10",
                            attrs: { label: "App跳转链接" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: item.towardDetails.appUrl,
                                callback: function($$v) {
                                  _vm.$set(item.towardDetails, "appUrl", $$v)
                                },
                                expression: "item.towardDetails.appUrl"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.towardDetails.towardType == 3
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "mt-10", attrs: { label: "" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSelectGood(index)
                                  }
                                }
                              },
                              [_vm._v("请选择商品")]
                            ),
                            item.towardDetails.content
                              ? _c("span", { staticClass: "d2-ml-20" }, [
                                  _vm._v(
                                    "商品编号：" +
                                      _vm._s(item.towardDetails.content)
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("发布")]
          ),
          _c(
            "el-button",
            {
              staticClass: "mt-20",
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.onAdd }
            },
            [_vm._v("+新增轮播图")]
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { appendBody: false, token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e(),
      _c("select-goods", {
        attrs: { show: _vm.showSelectGoods },
        on: {
          "update:show": function($event) {
            _vm.showSelectGoods = $event
          },
          close: function($event) {
            _vm.showSelectGoods = false
          },
          confirm: _vm.handleSelectConfirm
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }