var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box flex-center" }, [
    _c("div", { staticClass: "right", on: { click: _vm.handleBilibili } }, [
      !_vm.bilibili
        ? _c("i", { staticClass: "icon el-icon-close-notification" })
        : _vm._e(),
      _vm.bilibili ? _c("i", { staticClass: "icon el-icon-bell" }) : _vm._e()
    ]),
    _c("div", { staticClass: "label" }, [_vm._v("要炼救助中心")]),
    _c("div", { staticClass: "time" }, [_c("HeaderTime")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }