var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "请选择动作", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "动作编号" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入动作编号" },
                model: {
                  value: _vm.form.actionNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "actionNo", $$v)
                  },
                  expression: "form.actionNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动作名称" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入动作名称" },
                model: {
                  value: _vm.form.actionName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "actionName", $$v)
                  },
                  expression: "form.actionName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动作类型" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.actionType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "actionType", $$v)
                    },
                    expression: "form.actionType"
                  }
                },
                [
                  _c("el-option", {
                    key: 0,
                    attrs: { label: "全部", value: 0 }
                  }),
                  _vm._l(_vm.dict.actionTypes, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.name, value: item.key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部位" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.part,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "part", $$v)
                    },
                    expression: "form.part"
                  }
                },
                _vm._l(_vm.dict.actionParts, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.name, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练类型" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "categoryId", $$v)
                    },
                    expression: "form.categoryId"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.dict.initCourseCates, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.name, value: item.key }
                    })
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.submitSelection } },
        [_vm._v("确定选择选中的动作")]
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { headStyle: _vm.headStyle, request: _vm.request },
          on: { "selection-change": _vm.onSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "动作编号", prop: "actionNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "动作名称", prop: "actionName" }
          }),
          _c("el-table-column", {
            attrs: { label: "单位时长（S）", prop: "actionSecond" }
          }),
          _c("el-table-column", {
            attrs: { label: "动作类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.actionTypeStr(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "部位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.actionPartStr(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "训练类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.actionCateStr(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "210", label: "创建时间", prop: "createTime" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }