var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("p", { staticClass: "top-tip" }, [
        _vm._v(
          _vm._s(_vm.$route.query.id ? "您正在修改文章" : "您正在创建新的文章")
        )
      ]),
      _c(
        "div",
        { staticClass: "edit-wrap" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "180px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "新闻标题" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.detail.articleTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "articleTitle", $$v)
                      },
                      expression: "detail.articleTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.detail.articleTypeId,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "articleTypeId", $$v)
                        },
                        expression: "detail.articleTypeId"
                      }
                    },
                    _vm._l(_vm.cates, function(item) {
                      return _c("el-option", {
                        key: item.articleTypeId,
                        attrs: {
                          label: item.articleTypeName,
                          value: item.articleTypeId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "新闻封面" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-cell",
                      on: { click: _vm.openUploadModal }
                    },
                    [
                      _c(
                        "el-image",
                        {
                          attrs: {
                            fit: "contain",
                            src: _vm.detail.articleCover
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: _vm.uploadImg }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("span", [_vm._v("建议上传图片尺寸190*132像素")])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "新闻排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { type: "number", min: "1" },
                    on: { input: _vm.changeInt },
                    model: {
                      value: _vm.detail.articleSort,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "articleSort", $$v)
                      },
                      expression: "detail.articleSort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "是否在APP展示" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": true,
                      "inactive-value": false,
                      "active-text": "展示",
                      "inactive-text": "暂不展示"
                    },
                    model: {
                      value: _vm.detail.articleShow,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "articleShow", $$v)
                      },
                      expression: "detail.articleShow"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新闻内容" } },
                [
                  _c("base-editor", {
                    attrs: {
                      upToken: _vm.qnToken,
                      content: _vm.detail.articleContent
                    },
                    on: {
                      "update:content": function($event) {
                        return _vm.$set(_vm.detail, "articleContent", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  ),
                  _vm.$route.query.id
                    ? _c("el-button", { on: { click: _vm.onReset } }, [
                        _vm._v("取消")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }