var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-descriptions",
        { attrs: { column: 1 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "教练实况", flex: "main:center" } },
            [
              _vm._v(" " + _vm._s(_vm.nowTime) + " "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.toFresh }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "d2-mb",
          attrs: { direction: "vertical", column: 7, border: "" }
        },
        [
          _c("el-descriptions-item", { attrs: { label: "认证教练类型" } }, [
            _vm._v(_vm._s(_vm.records.categoryName))
          ]),
          _c("el-descriptions-item", { attrs: { label: "已认证教练总数" } }, [
            _vm._v(_vm._s(_vm.records.coachCount))
          ]),
          _c("el-descriptions-item", { attrs: { label: "上线数" } }, [
            _vm._v(_vm._s(_vm.records.activeCount))
          ]),
          _c("el-descriptions-item", { attrs: { label: "下线数" } }, [
            _vm._v(_vm._s(_vm.records.inactiveCount))
          ])
        ],
        1
      ),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "教练" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  placeholder: "输入用户编号、昵称、真实姓名、手机号码搜索"
                },
                model: {
                  value: _vm.form.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "认证类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "categoryId", $$v)
                    },
                    expression: "form.categoryId"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.categoryId,
                    attrs: { label: item.categoryName, value: item.categoryId }
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.subcategoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "subcategoryId", $$v)
                    },
                    expression: "form.subcategoryId"
                  }
                },
                _vm._l(_vm.options1, function(item) {
                  return _c("el-option", {
                    key: item.subcategoryId,
                    attrs: {
                      label: item.subcategoryName,
                      value: item.subcategoryId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.options2 },
                model: {
                  value: _vm.form.coachStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "coachStatus", $$v)
                  },
                  expression: "form.coachStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别" } },
            [
              _c("base-select", {
                attrs: { options: _vm.options3 },
                model: {
                  value: _vm.form.userGender,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userGender", $$v)
                  },
                  expression: "form.userGender"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "年龄" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.ageRange.start,
                  max: _vm.form.ageRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.ageRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.ageRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "用户编号", prop: "userNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "真实姓名", prop: "realName" }
          }),
          _c("el-table-column", { attrs: { label: "昵称", prop: "nickname" } }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "接单状态", prop: "coachStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(row.coachStatus == 1 ? "上线" : "下线") + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "性别", prop: "gender" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.gender == 1 ? "男" : "女") + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { label: "年龄", prop: "age" } }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: {
                                  userId: scope.row.userId,
                                  type: "coach"
                                }
                              }
                            }
                          },
                          [_vm._v(" 详情 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }