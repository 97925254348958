import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CitySelect from '@/components/city-select';
import { cloneDeep } from 'lodash';
var title = '教练上线实况';
var initForm = {
  location: {},
  trainingOrderNo: '',
  realName: '',
  nickname: '',
  evaluateType: 0,
  replied: ''
};
export default {
  meta: {
    title: title,
    auth: true,
    authorityValue: 'coach-online-real-time'
  },
  name: 'coach-online-live',
  components: {
    CitySelect: CitySelect
  },
  data: function data() {
    return {
      headStyle: function headStyle() {
        return 'text-align:center';
      },
      title: title,
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'course_coachData',
        params: {},
        parser: function parser(res) {
          return _objectSpread(_objectSpread({}, res), {}, {
            records: res.list
          });
        }
      },
      options: [{
        label: '全部',
        value: ''
      }, {
        label: '健身训练',
        value: 1
      }, {
        label: '瑜伽',
        value: 2
      }, {
        label: '孕产妇训练',
        value: 3
      }, {
        label: '青少年体能',
        value: 4
      }, {
        label: '老年保健',
        value: 5
      }, {
        label: '术后（伤后）康复',
        value: 6
      }, {
        label: '冥想',
        value: 7
      }],
      nowTime: null
    };
  },
  mounted: function mounted() {
    var date = new Date().getTime();
    this.nowTime = YL.stampFormat(date, 'yyyy-MM-dd hh:mm:ss');
  },
  methods: {
    read: function read(row) {
      this.$router.push('/coach-online-live-detail?categoryId=' + row.categoryId);
    },
    handleSearch: function handleSearch() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData(reset);
    },
    toFresh: function toFresh() {
      this.handleSearch();
      var date = new Date().getTime();
      this.nowTime = YL.stampFormat(date, 'yyyy-MM-dd hh:mm:ss');
    }
  }
};