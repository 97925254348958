var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    attrs: {
      clearable: false,
      type: "monthrange",
      "range-separator": "至",
      "start-placeholder": "请选择开始月份",
      "end-placeholder": "请选择结束月份",
      "value-format": "yyyy-MM"
    },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }