var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.refreshView > 0
    ? _c(
        "div",
        [
          _c("p", { staticStyle: { color: "orange" } }, [
            _vm._v("说明：以下内容展示在要炼APP“我的->帮助与反馈”界面")
          ]),
          _c(
            "TableTitleBar",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.showAddDialog }
                },
                [_vm._v("新增帮助分类")]
              )
            ],
            1
          ),
          _vm._l(_vm.dataSource, function(item, index) {
            return _c(
              "el-card",
              { key: index, staticClass: "mt-20" },
              [
                _c(
                  "TableTitleBar",
                  { staticStyle: { margin: "0" } },
                  [
                    _c("span", { staticClass: "space" }, [
                      _vm._v("帮助分类名称：" + _vm._s(item.categoryName))
                    ]),
                    _c("span", { staticClass: "space" }, [
                      _vm._v("帮助分类排序：" + _vm._s(item.categorySort))
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.showAddDialog(item)
                          }
                        }
                      },
                      [_vm._v("修改分类")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onDelCatg(item)
                          }
                        }
                      },
                      [_vm._v("删除分类")]
                    )
                  ],
                  1
                ),
                _c(
                  "base-table",
                  { attrs: { pagination: false, data: item.helps || [] } },
                  [
                    _c("el-table-column", {
                      attrs: { label: "排序", prop: "helpSort" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "帮助标题", prop: "helpTitle" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "是否显示" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.helpShow,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "helpShow", $$v)
                                    },
                                    expression: "scope.row.helpShow"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", prop: "xxx" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text" } },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path: "/help-center-view-question",
                                            query: {
                                              helpId: scope.row.helpId,
                                              categoryType: _vm.categoryType
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v("查看")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  { attrs: { type: "text" } },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path: "/help-center-add-question",
                                            query: {
                                              helpId: scope.row.helpId,
                                              categoryType: _vm.categoryType
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v("修改")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onDel(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "TableTitleBar",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default" },
                        on: {
                          click: function($event) {
                            return _vm.addHelp(item)
                          }
                        }
                      },
                      [_vm._v("+添加帮助 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "base-dialog",
            {
              attrs: {
                labelWidth: "80px",
                title: "分类",
                visible: _vm.dialogVisible,
                rules: _vm.formRules,
                formData: _vm.dialogFormData,
                isEdit: _vm.isEdit,
                width: "500px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                "update:formData": function($event) {
                  _vm.dialogFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.dialogFormData = $event
                },
                confirm: _vm.callAdd
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "", maxlength: "10" },
                    model: {
                      value: _vm.dialogFormData.categoryName,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "categoryName", $$v)
                      },
                      expression: "dialogFormData.categoryName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { placeholder: "", type: "number", min: "1" },
                    on: { input: _vm.changeInt },
                    model: {
                      value: _vm.dialogFormData.categorySort,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "categorySort", $$v)
                      },
                      expression: "dialogFormData.categorySort"
                    }
                  }),
                  _c("span", { staticClass: "ml-20" }, [
                    _vm._v("数值越小，排序越前")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }