var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c("template", { slot: "header" }, [
            _vm._v(
              " 课程审核状态：" +
                _vm._s(_vm.courseAuditStr(_vm.detail.auditStatus)) +
                " "
            )
          ]),
          _vm.detail.auditStatus === 1
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.openAudit } },
                [_vm._v(" 审核 ")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.openRemark } }, [_vm._v(" 备注 ")])
        ],
        2
      ),
      _c(
        "el-card",
        { staticClass: "edit-wrap" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "180px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程大类及分类" } },
                [
                  !_vm.localItem
                    ? _c("course-cate", {
                        staticClass: "yl-read",
                        attrs: { disabled: true, form: _vm.detail }
                      })
                    : _c("div", [
                        _vm._v(
                          _vm._s(_vm.localItem.categoryName) +
                            "/" +
                            _vm._s(_vm.localItem.subcategoryFirstName) +
                            "/" +
                            _vm._s(_vm.localItem.subcategorySecondName) +
                            "/" +
                            _vm._s(_vm.localItem.subcategoryThreeName)
                        )
                      ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程名称" } },
                [_vm._v(" " + _vm._s(_vm.detail.courseName) + " ")]
              ),
              _c("el-form-item", { attrs: { label: "消耗能量" } }, [
                _vm.detail.consumeCalorieMin === _vm.detail.consumeCalorieMax
                  ? _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.detail.consumeCalorieMax) + "千卡 "
                      )
                    ])
                  : _c("div", [
                      _vm._v(
                        _vm._s(_vm.detail.consumeCalorieMin) +
                          "~" +
                          _vm._s(_vm.detail.consumeCalorieMax) +
                          "千卡"
                      )
                    ])
              ]),
              _c("el-form-item", { attrs: { label: "课程难度" } }, [
                _vm._v(" L" + _vm._s(_vm.detail.courseDifficultyLevel) + " ")
              ]),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "练习频次" } },
                [_vm._v(" " + _vm._s(_vm.detail.trainingFrequency) + " ")]
              ),
              _vm.detail.fitnessEquipmentTypes.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { required: "", label: "器械" } },
                    [
                      _c("el-row", [_vm._v("有器械")]),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            background: "#f9f9f9",
                            padding: "10px 10px"
                          }
                        },
                        [
                          _vm._v("器械："),
                          _vm._l(_vm.detail.fitnessEquipmentTypes, function(
                            item,
                            index
                          ) {
                            return _c("span", { key: index }, [
                              _vm._v(_vm._s(item + " "))
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "训练总能量" } },
                [
                  _vm._v(" " + _vm._s(_vm.detail.trainingCalorie) + "千卡 "),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("训练总能量用于计算用户训练消耗的能量")
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程内容类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "yl-read",
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.detail.courseType,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "courseType", $$v)
                        },
                        expression: "detail.courseType"
                      }
                    },
                    _vm._l(_vm.dict.courseTypes, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + "课程 ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.detail.courseType === 2
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { required: "", label: "课程视频" } },
                      [
                        _c("div", { staticClass: "flex-box" }, [
                          _vm.detail.videoUrl
                            ? _c("video", {
                                staticClass: "form-video",
                                attrs: {
                                  id: "course-video",
                                  controls: "",
                                  src: _vm.detail.videoUrl
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm.detail.courseType === 1
                ? [
                    _c("el-form-item", { attrs: { label: "课程内容" } }, [
                      _c(
                        "div",
                        _vm._l(_vm.detail.videos, function(item, index) {
                          return _c(
                            "el-card",
                            {
                              key: index,
                              staticClass: "action-card",
                              staticStyle: { width: "800px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix action-info",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [
                                    _vm._v("第" + _vm._s(index + 1) + "个动作")
                                  ]),
                                  _c("span", [
                                    _vm._v("动作编号：" + _vm._s(item.actionNo))
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "动作名称:" + _vm._s(item.videoTitle)
                                    )
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "单位动作时长:" +
                                        _vm._s(item.actionSecond) +
                                        "S"
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-form",
                                { attrs: { "label-width": "100px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "动作形式" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detail.videos[index]
                                              .playType === 1
                                              ? "组类"
                                              : "倒计时类"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  item.playType === 1
                                    ? [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "10px"
                                            },
                                            attrs: { label: "动作次数" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .actionCount
                                                ) +
                                                "次 "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "休息时长" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .relaxTime
                                                ) +
                                                "S "
                                            ),
                                            _c("p", { staticClass: "yl-tip" }, [
                                              _vm._v("0代表不休息")
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  item.playType === 2
                                    ? [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "10px"
                                            },
                                            attrs: { label: "倒计时长" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .countdownSecond
                                                ) +
                                                "S "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "休息时长" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .relaxTime
                                                ) +
                                                "S "
                                            ),
                                            _c("p", { staticClass: "yl-tip" }, [
                                              _vm._v("0代表不休息")
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ])
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程封面" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-cell",
                      on: {
                        click: function($event) {
                          return _vm.openImageView(_vm.detail.coverImageUrl)
                        }
                      }
                    },
                    [
                      _c(
                        "el-image",
                        {
                          attrs: {
                            fit: "contain",
                            src: _vm.detail.coverImageUrl
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: _vm.uploadImg }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程介绍" } },
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.detail.courseIntroduce) }
                  })
                ]
              ),
              _vm.detail.auditStatus === 2
                ? _c(
                    "el-form-item",
                    { attrs: { required: "", label: "课程排序" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            model: {
                              value: _vm.detail.courseSort,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "courseSort", $$v)
                              },
                              expression: "detail.courseSort"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.saveSort }
                            },
                            [_vm._v("保存")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm.detail.auditStatus > 1
            ? _c(
                "el-card",
                [
                  _c("template", { slot: "header" }, [_vm._v("记录")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.detail.auditLogList } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "时间", prop: "createTime" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "备注", prop: "logContent" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作人", prop: "operatorName" }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              on: { click: _vm.goBack }
            },
            [_vm._v("返回")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.auditVisible, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.auditVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.auditStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "auditStatus", $$v)
                    },
                    expression: "form.auditStatus"
                  }
                },
                [_vm._v("通过")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 3 },
                  model: {
                    value: _vm.form.auditStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "auditStatus", $$v)
                    },
                    expression: "form.auditStatus"
                  }
                },
                [_vm._v("不通过")]
              ),
              _vm.form.auditStatus == 3
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("el-input", {
                        attrs: {
                          max: "50",
                          placeholder: "输入审核不通过的原因",
                          type: "textarea",
                          maxlength: "50"
                        },
                        model: {
                          value: _vm.form.rejectReason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectReason", $$v)
                          },
                          expression: "form.rejectReason"
                        }
                      }),
                      _c("p", { staticStyle: { margin: "10px 0" } }, [
                        _vm._v(_vm._s(_vm.form.rejectReason.length) + "/50")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAudit } },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.auditVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "备注", visible: _vm.remarkVisible, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.remarkVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("el-input", {
                attrs: {
                  max: "50",
                  placeholder: "输入备注说明",
                  type: "textarea"
                },
                model: {
                  value: _vm.form2.logContent,
                  callback: function($$v) {
                    _vm.$set(_vm.form2, "logContent", $$v)
                  },
                  expression: "form2.logContent"
                }
              }),
              _c("p", { staticStyle: { margin: "10px 0" } }, [
                _vm._v(_vm._s(_vm.form2.logContent.length) + "/50")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveRemark } },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.remarkVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }