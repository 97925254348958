var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.$route.query.liveId ? "编辑直播" : "添加直播"))
      ]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "div",
            { staticStyle: { width: "500px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "直播类型", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.liveCategoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "liveCategoryId", $$v)
                        },
                        expression: "form.liveCategoryId"
                      }
                    },
                    _vm._l(_vm.live_cate_type, function(l) {
                      return _c(
                        "el-radio",
                        { key: l.value, attrs: { label: l.value } },
                        [_vm._v(_vm._s(l.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动直播名称", required: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.courseName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "courseName", $$v)
                      },
                      expression: "form.courseName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直播时间", required: "" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "datetime", placeholder: "选择日期" },
                    on: { change: _vm.datePicker },
                    model: {
                      value: _vm.form.liveDateStartTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "liveDateStartTime", $$v)
                      },
                      expression: "form.liveDateStartTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动直播时长", required: "" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.form.liveTimeLength,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "liveTimeLength", $$v)
                        },
                        expression: "form.liveTimeLength"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动直播封面", required: "" } },
                [
                  _c("image-upload", {
                    attrs: { url: _vm.form.coverImageUrl, srcList: _vm.imgSrc },
                    on: {
                      "update:url": function($event) {
                        return _vm.$set(_vm.form, "coverImageUrl", $event)
                      }
                    }
                  }),
                  _c("span", [
                    _vm._v("建议上传图片尺寸690*280像素,大小不能超过300kb")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "直播简介" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.briefIntro,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "briefIntro", $$v)
                  },
                  expression: "form.briefIntro"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "800px" },
              attrs: { label: "活动直播介绍", required: "" }
            },
            [
              _c("base-editor", {
                attrs: {
                  upToken: _vm.qnToken,
                  content: _vm.form.courseIntroduce
                },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "courseIntroduce", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }