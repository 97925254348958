var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("审核回放视频")]),
      _c(
        "el-card",
        {
          class: { rate: "rate" + _vm.rateLink },
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [_c("video", { attrs: { id: "leb-video-container" } })]
      ),
      _c(
        "el-button",
        {
          staticClass: "d2-mt",
          attrs: { type: "primary" },
          on: { click: _vm.rateClick }
        },
        [_vm._v("旋转")]
      ),
      _c(
        "el-button",
        {
          staticClass: "d2-mt",
          on: {
            click: function($event) {
              return _vm.goBack()
            }
          }
        },
        [_vm._v("返回")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }