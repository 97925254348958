var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("回放")]),
      _c(
        "el-card",
        {
          attrs: { shadow: "never", "body-style": { padding: "20px" } },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", { attrs: { flex: "main:justify" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.form.courseName))]),
                    _c("div", { attrs: { flex: "main:center" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { "margin-right": "100px", color: "red" }
                        },
                        [_vm._v("回放")]
                      ),
                      _c("span", [
                        _vm._v(" 直播时间：" + _vm._s(_vm.form.liveDate) + " "),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.liveStartTime) +
                              "-" +
                              _vm._s(_vm.form.liveEndTime)
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c("div", { class: _vm.rate ? "rate" + _vm.rateLink : "" }, [
                _c("video", { attrs: { id: "leb-video-container" } })
              ])
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "d2-mt",
              attrs: { type: "primary" },
              on: { click: _vm.rateClick }
            },
            [_vm._v("旋转")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }