var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Title", [_vm._v("运动数据")]),
      _c(
        "div",
        { staticClass: "opt flex-center-column" },
        _vm._l(_vm.tabData, function(l, i) {
          return _c("div", { key: i, staticClass: "item" }, [
            _c("img", { staticClass: "img", attrs: { src: l.icon, alt: "" } }),
            _c(
              "div",
              {
                staticClass: "num",
                class: { "reso-numm": i == 1 },
                style: { color: l.color }
              },
              [_c("d2-count-up", { attrs: { end: l.number } })],
              1
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }