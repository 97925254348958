//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import { auditStates } from '../../professional-qualification-audit/const';
var initForm = {
  recertificationNo: "",
  status: 0,
  nickname: "",
  createTimeRange: {
    start: "",
    end: ""
  }
};
export default {
  meta: {
    title: 'tab-all',
    auth: true
  },
  name: 'tab-all',
  components: {},
  data: function data() {
    return {
      auditStates: auditStates,
      result: [{
        label: '待审核',
        value: 1
      }, {
        label: '审核通过',
        value: 2
      }, {
        label: '审核不通过',
        value: 3
      }],
      status: [{
        label: '全部',
        value: 0
      }, {
        label: '待审核',
        value: 1
      }, {
        label: '审核通过',
        value: 2
      }, {
        label: '审核不通过',
        value: 3
      }],
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'coach_recertificationList',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    onDetail: function onDetail() {
      this.$router.push({
        path: '/qualification-certificate-audit-detail'
      });
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};