var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("营销页面")]),
      _vm._l(_vm.forms, function(item, index) {
        return _c(
          "el-form",
          {
            key: item.$uid,
            ref: item.formRef,
            refInFor: true,
            attrs: { model: item, "label-width": "120px", rules: _vm.rules }
          },
          [
            _c(
              "el-card",
              { staticClass: "mb-20" },
              [
                _vm._v(" 序号：" + _vm._s(index + 1) + " "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _vm._v(
                      " 上传图片将等比缩放至750像素，建议上传750像素宽度的图片 "
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { type: "default" },
                        on: {
                          click: function($event) {
                            return _vm.onDel(index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "营销图片", prop: "url", required: "" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-cell",
                        on: {
                          click: function($event) {
                            return _vm.onOpenUpload(index)
                          }
                        }
                      },
                      [
                        _c(
                          "el-image",
                          { attrs: { fit: "contain", src: item.url } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px"
                                  },
                                  attrs: { src: _vm.uploadImg }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "营销详情",
                      prop: "goodsContent",
                      required: ""
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.openTablist(index)
                              }
                            }
                          },
                          [_vm._v("查看详情")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("发布")]
          ),
          _c(
            "el-button",
            {
              staticClass: "mt-20",
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.onAdd }
            },
            [_vm._v("+新增营销图片")]
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { appendBody: false, token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e(),
      _c("select-goods", {
        attrs: { show: _vm.showSelectGoods },
        on: {
          "update:show": function($event) {
            _vm.showSelectGoods = $event
          },
          close: function($event) {
            _vm.showSelectGoods = false
          },
          confirm: _vm.handleSelectConfirm
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "营销图片列表", visible: _vm.layer.show },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.layer, "show", $event)
            }
          }
        },
        [
          _c("el-button", { on: { click: _vm.addItemData } }, [
            _vm._v("添加营销图片")
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.itemData } },
            [
              _c("el-table-column", {
                attrs: { property: "url", label: "营销图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticClass: "upload-img-cell",
                          attrs: { fit: "contain", src: row.url },
                          on: {
                            click: function($event) {
                              return _vm.openImageView(row.url)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "sort", label: "排序" }
              }),
              _c("el-table-column", {
                attrs: { property: "goodsId", label: "跳转商品" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.addItemDel($index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加营销图片", visible: _vm.layer2.show },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.layer2, "show", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.layer2.form,
                "label-width": "120px",
                rules: _vm.layer2.rules
              }
            },
            [
              _c("div", { staticClass: "d2-pb-5" }, [
                _vm._v("上传图片将等比缩放至750像素，建议上传750像素宽度的图片")
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "营销图片", prop: "url", required: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-cell",
                      on: { click: _vm.onOpenUpload2 }
                    },
                    [
                      _c(
                        "el-image",
                        { attrs: { fit: "contain", src: _vm.layer2.form.url } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: _vm.uploadImg }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营销图片排序", prop: "sort" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { placeholder: "", type: "number", min: "1" },
                    on: { input: _vm.changeInt2 },
                    model: {
                      value: _vm.layer2.form.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.layer2.form, "sort", $$v)
                      },
                      expression: "layer2.form.sort"
                    }
                  }),
                  _c("span", { staticClass: "ml-20" }, [
                    _vm._v("数值越小，排在越前面")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "跳转页面商品", prop: "goodsId" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSelectGood }
                    },
                    [_vm._v("请选择商品")]
                  ),
                  _vm.layer2.form.goodsId
                    ? _c("span", { staticClass: "d2-ml-20" }, [
                        _vm._v("商品编号：" + _vm._s(_vm.layer2.form.goodsId))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  ),
                  _c("el-button", { on: { click: _vm.onDelItem } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }