var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("base-select", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.storeId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeId", $$v)
                  },
                  expression: "form.storeId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.orderTimeRange.start,
                  max: _vm.form.orderTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品编码" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.goodsNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsNo", $$v)
                  },
                  expression: "form.goodsNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.settledStatus },
                model: {
                  value: _vm.form.settledStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "settledStatus", $$v)
                  },
                  expression: "form.settledStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出库日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.deliveryTimeRange.start,
                  max: _vm.form.deliveryTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.deliveryTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.deliveryTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.orderStatus },
                model: {
                  value: _vm.form.orderStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderStatus", $$v)
                  },
                  expression: "form.orderStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "10px" },
              attrs: { slot: "extra", type: "" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "查询合计" } }),
      _c(
        "el-card",
        { staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c("el-descriptions-item", { attrs: { label: "货款" } }, [
                _vm._v(_vm._s(_vm.extraData.goodsAmount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "佣金" } }, [
                _vm._v(_vm._s(_vm.extraData.commissionAmount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "返现" } }, [
                _vm._v(_vm._s(_vm.extraData.cashbackAmount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "笔数" } }, [
                _vm._v(_vm._s(_vm.extraData.orderCount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "笔数" } }, [
                _vm._v(_vm._s(_vm.extraData.commissionCount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "笔数" } }, [
                _vm._v(_vm._s(_vm.extraData.cashbackCount))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", prop: "orderNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "订单状态", prop: "orderStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.orderStatus,
                            _vm.tabStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "下单时间", prop: "orderTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "出库时间", prop: "deliveryTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "完成时间", prop: "completeTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "goodsName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("goodsItemList")(row.goodsItemList)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "商品编号", prop: "goodsNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("goodsNo")(row.goodsItemList)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "金额", prop: "trainingPrice", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v("货款 " + _vm._s(row.refundAmount))]),
                    _c("p", [_vm._v("佣金 " + _vm._s(row.commissionAmount))]),
                    _c("p", [_vm._v("返现 " + _vm._s(row.cashbackAmount))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentTime",
              label: "收支方向和结算状态",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _vm._v(
                        "支出 " +
                          _vm._s(row.goodsAmountSettled ? "已结算" : "未结算")
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "收入 " +
                          _vm._s(row.cashbackSettled ? "已结算" : "未结算")
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "收入 " +
                          _vm._s(row.commissionSettled ? "已结算" : "未结算")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "checkedTime", label: "结算时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s(row.goodsAmountSettlementTime))
                    ]),
                    _c("p", [
                      _vm._v(" " + _vm._s(row.commissionSettlementTime))
                    ]),
                    _c("p", [_vm._v(" " + _vm._s(row.cashbackSettlementTime))])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }