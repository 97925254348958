var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Title", [_vm._v("用户和交易")]),
      _c(
        "div",
        { staticClass: "tab flex-center-around" },
        [
          _vm._l(_vm.tabData, function(l, i) {
            return [
              _c("div", { key: i, staticClass: "flex-center-column" }, [
                _c(
                  "div",
                  { staticClass: "number" },
                  [_c("d2-count-up", { attrs: { end: l.number } })],
                  1
                ),
                _c("div", { staticClass: "label" }, [_vm._v(_vm._s(l.label))])
              ])
            ]
          })
        ],
        2
      ),
      _c("div", { ref: "leftEchart", staticClass: "leftEchart" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }