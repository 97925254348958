import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import util from '@/libs/util';
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
export default {
  props: {
    initForm: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      request: {
        api: 'ORDER_MALL_CANCEL_LIST',
        params: cloneDeep(_objectSpread(_objectSpread({}, this.initForm), {}, {
          refundStatus: 3
        }))
      },
      refundTypeOptions: [{
        label: '全部',
        value: ''
      }, {
        label: '退款',
        value: 1
      }, {
        label: '换货',
        value: 2
      }]
    };
  },
  computed: _objectSpread({}, mapState('d2admin', {
    menu: function menu(state) {
      return state.permission.routeKey;
    }
  })),
  methods: {
    isShow: function isShow(auto) {
      return this.isBtnGroud(this.menu, auto);
    },
    onDetail: function onDetail(e) {
      this.$router.push({
        path: '/cancel-order-detail',
        query: {
          refundId: e
        }
      });
    },
    copyText: function copyText(e) {
      util.copyThat(e);
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    handleGoRoute: function handleGoRoute(id) {
      if (this.isShow('mall-order-manage')) {
        this.$router.push({
          path: '/order-detail',
          query: {
            orderId: id
          }
        });
      } else {
        this.$message.error('您没有权限访问');
      }
    }
  }
};