var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入店铺名称" },
                model: {
                  value: _vm.form.storeName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeName", $$v)
                  },
                  expression: "form.storeName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "要炼SKU" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入要炼SKU" },
                model: {
                  value: _vm.form.adminSku,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "adminSku", $$v)
                  },
                  expression: "form.adminSku"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "要炼分类" } },
            [
              _c("el-cascader", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  options: _vm.typeOptions,
                  props: {
                    expandTrigger: "hover",
                    value: "goodsTypeId",
                    label: "typeName"
                  }
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.goodsTypeIdFirst,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsTypeIdFirst", $$v)
                  },
                  expression: "form.goodsTypeIdFirst"
                }
              }),
              _vm.typeOptions2.length > 0
                ? _c("el-cascader", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: {
                      options: _vm.typeOptions2,
                      props: {
                        expandTrigger: "hover",
                        value: "goodsTypeId",
                        label: "typeName"
                      }
                    },
                    on: { change: _vm.handleChange2 },
                    model: {
                      value: _vm.form.goodsTypeIdSecond,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "goodsTypeIdSecond", $$v)
                      },
                      expression: "form.goodsTypeIdSecond"
                    }
                  })
                : _vm._e(),
              _vm.typeOptions3.length > 0
                ? _c("el-cascader", {
                    attrs: {
                      options: _vm.typeOptions3,
                      props: {
                        expandTrigger: "hover",
                        value: "goodsTypeId",
                        label: "typeName"
                      }
                    },
                    model: {
                      value: _vm.form.goodsTypeId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "goodsTypeId", $$v)
                      },
                      expression: "form.goodsTypeId"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品编号" },
                model: {
                  value: _vm.form.goodsNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsNo", $$v)
                  },
                  expression: "form.goodsNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "要炼价格" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.priceRange.start,
                  max: _vm.form.priceRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.priceRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.priceRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品货号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品货号" },
                model: {
                  value: _vm.form.goodsModel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsModel", $$v)
                  },
                  expression: "form.goodsModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品库存" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.stockRange.start,
                  max: _vm.form.stockRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.stockRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.stockRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上架时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.shelfTimeRange.start,
                  max: _vm.form.shelfTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.shelfTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.shelfTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "品牌" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.brandId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "brandId", $$v)
                    },
                    expression: "form.brandId"
                  }
                },
                _vm._l(_vm.brandOptions, function(item) {
                  return _c("el-option", {
                    key: item.brandId,
                    attrs: { label: item.brandName, value: item.brandId }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "storeName" }
          }),
          _c("el-table-column", {
            attrs: { "min-width": "400", label: "商品信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("goods-card", { attrs: { row: row } })]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "总库存", prop: "goodsStock" }
          }),
          _c("el-table-column", {
            attrs: { label: "单价（元）", prop: "goodsPrice" }
          }),
          _c("el-table-column", {
            attrs: { label: "30天销量", prop: "soldCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "上架时间", prop: "onShelfTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "修改时间", prop: "lastUpdateTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute(
                              "/system-off-shelf?goodsId=" + row.goodsId
                            )
                          }
                        }
                      },
                      [_vm._v("系统下架")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handlePay(row)
                          }
                        }
                      },
                      [_vm._v("支付设置")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支付设置",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-radio",
            {
              attrs: { label: 1 },
              model: {
                value: _vm.dialogForm.balanceLimit,
                callback: function($$v) {
                  _vm.$set(_vm.dialogForm, "balanceLimit", $$v)
                },
                expression: "dialogForm.balanceLimit"
              }
            },
            [_vm._v("混合支付")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: 2 },
              model: {
                value: _vm.dialogForm.balanceLimit,
                callback: function($$v) {
                  _vm.$set(_vm.dialogForm, "balanceLimit", $$v)
                },
                expression: "dialogForm.balanceLimit"
              }
            },
            [_vm._v("可提现金额付款")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: 3 },
              model: {
                value: _vm.dialogForm.balanceLimit,
                callback: function($$v) {
                  _vm.$set(_vm.dialogForm, "balanceLimit", $$v)
                },
                expression: "dialogForm.balanceLimit"
              }
            },
            [_vm._v("不可提现金额付款")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }