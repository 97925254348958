var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-date-picker",
    _vm._b(
      {
        attrs: {
          type: "datetimerange",
          "range-separator": "至",
          "start-placeholder": "请选择开始时间",
          "end-placeholder": "请选择结束时间"
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      },
      "el-date-picker",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }