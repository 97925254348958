//
//
//
//
//
//
//
//
//
//
//
//
var uploadImg = require('@/assets/images/defaultImg.jpg');

export default {
  props: {
    url: {
      type: String
    },
    srcList: {
      type: Array
    },
    nickname: {
      type: String
    }
  },
  name: 'coach-info',
  data: function data() {
    return {
      uploadImg: uploadImg
    };
  }
};