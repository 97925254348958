var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-box" },
    [
      _c("div", { staticClass: "titleBox", staticStyle: { padding: "30px" } }, [
        _c("div", { staticClass: "block" }),
        _c(
          "div",
          [
            _c("el-switch", {
              attrs: { "active-text": "开启提醒", "inactive-text": "关闭提醒" },
              model: {
                value: _vm.switchAudio,
                callback: function($$v) {
                  _vm.switchAudio = $$v
                },
                expression: "switchAudio"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.propr,
                  expression: "propr"
                }
              ],
              ref: "initPropr",
              staticClass: "init-propr",
              attrs: { tabindex: "0" },
              on: { focusout: _vm.handleFocusOut }
            },
            [
              _c(
                "el-scrollbar",
                { staticStyle: { height: "250px" } },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        shadow: "never",
                        "body-style": { padding: "20px" }
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.emojiQQ, function(item) {
                          return _c(
                            "el-col",
                            {
                              key: item,
                              staticClass: "d2-pb",
                              attrs: { span: 4 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "grid-content",
                                  on: {
                                    click: function($event) {
                                      return _vm.clickEmoji(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-drawer",
            {
              attrs: { title: "常用语", visible: _vm.drawer },
              on: {
                "update:visible": function($event) {
                  _vm.drawer = $event
                }
              }
            },
            [
              _c(
                "el-row",
                { staticClass: "d2-p", attrs: { gutter: 0 } },
                _vm._l(_vm.drawerList, function(item, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "d2-p-0 d2-pb",
                          attrs: { underline: false },
                          on: {
                            click: function($event) {
                              return _vm.clickEmoji("" + item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "lemon-imui",
            _vm._b(
              {
                ref: "IMUI",
                attrs: {
                  user: _vm.user,
                  width: "80vw",
                  height: "80vh",
                  theme: "blue"
                },
                on: {
                  "pull-messages": _vm.handlePullMessages,
                  send: _vm.handleSend,
                  "change-contact": _vm.changeContact,
                  "message-click": _vm.messageClick
                }
              },
              "lemon-imui",
              _vm.initData,
              false
            )
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频播放",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("video", {
            attrs: {
              src: _vm.videoSrc,
              width: "100%",
              height: "300px",
              autoplay: "",
              controls: ""
            }
          })
        ]
      ),
      _c("base-loading", { ref: "baseLoading" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }