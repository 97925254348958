//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import CityCourse from '@/components/city-course';
var initForm = {
  createTimeRange: {}
};
export default {
  meta: {
    title: '救助呼叫记录',
    auth: true,
    authorityValue: 'AED-call-list'
  },
  name: 'first-aid-call-record-list',
  components: {
    CityCourse: CityCourse
  },
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'aed_rescueList',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};