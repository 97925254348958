var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("税务信息更新审核")]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "待审核", name: "1" } },
            [_c("TabWatingAudit")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "全部", name: "2" } },
            [_c("TabAll")],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }