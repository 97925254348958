import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";

/**
 * @description 创建一个请求
 */
export var createAction = function createAction(request, url, requestConfig) {
  return function (data) {
    return request(_objectSpread({
      url: url,
      data: data
    }, requestConfig));
  };
};