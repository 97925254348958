var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("新增资讯")]),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "180px" } },
        [
          _c(
            "el-form-item",
            { attrs: { required: "", label: "资讯标题" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.form.articleTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "articleTitle", $$v)
                  },
                  expression: "form.articleTitle"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "关联活动" } }, [
            _c("div", [_vm._v(_vm._s(_vm.voteTitle))])
          ]),
          _c("el-form-item", { attrs: { required: "", label: "资讯封面" } }, [
            _c(
              "div",
              {
                staticClass: "upload-img-cell",
                on: {
                  click: function($event) {
                    return _vm.openUploadModal("articleCover")
                  }
                }
              },
              [
                _c(
                  "el-image",
                  { attrs: { fit: "contain", src: _vm.form.articleCover } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error"
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: { src: _vm.uploadImg }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c("span", [_vm._v("建议上传图片尺寸180*125像素")])
          ]),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "资讯排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "120px" },
                attrs: { type: "number", clearable: "", min: "1" },
                on: { input: _vm.changeInt },
                model: {
                  value: _vm.form.articleSort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "articleSort", $$v)
                  },
                  expression: "form.articleSort"
                }
              }),
              _vm._v(" 数值越小，排序越前 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "APP是否显示" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.articleShow,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "articleShow", $$v)
                  },
                  expression: "form.articleShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "资讯内容" } },
            [
              _c("base-editor", {
                attrs: {
                  upToken: _vm.qnToken,
                  content: _vm.form.articleContent
                },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "articleContent", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.confirmSave()
                    }
                  }
                },
                [_vm._v("立即发布")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }