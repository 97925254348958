var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.$route.query.id ? "编辑课程" : "新建课程"))
      ]),
      _c("p", { staticClass: "top-tip" }, [
        _vm._v(
          _vm._s(_vm.$route.query.id ? "您正在修改课程" : "您正在创建新的课程")
        )
      ]),
      _c(
        "div",
        { staticClass: "edit-wrap" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "180px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程大类及分类" } },
                [
                  _c("course-cate", {
                    attrs: { form: _vm.detail },
                    on: { change: _vm.onCateTypeChange }
                  })
                ],
                1
              ),
              _vm.detail.categoryId == "10000002" ||
              _vm.detail.categoryId == "10000004" ||
              _vm.detail.categoryId == "10000003"
                ? _c(
                    "el-form-item",
                    { attrs: { required: "", label: "计划排序" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        model: {
                          value: _vm.detail.planSort,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "planSort", $$v)
                          },
                          expression: "detail.planSort"
                        }
                      }),
                      _c("p", { staticClass: "yl-tip" }, [
                        _vm._v(
                          "说明：计划排序是指在运动计划展示的排序，序号数值越小排序越前"
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      clearable: "",
                      maxlength: "30",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.detail.courseName,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "courseName", $$v)
                      },
                      expression: "detail.courseName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "教练性别" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.detail.coachGender,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "coachGender", $$v)
                        },
                        expression: "detail.coachGender"
                      }
                    },
                    [_vm._v("男")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.detail.coachGender,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "coachGender", $$v)
                        },
                        expression: "detail.coachGender"
                      }
                    },
                    [_vm._v("女")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "消耗能量" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.detail.consumeCalorieMin,
                      max: _vm.detail.consumeCalorieMax
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(_vm.detail, "consumeCalorieMin", $event)
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.detail, "consumeCalorieMax", $event)
                      }
                    }
                  }),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v(
                      "说明：单位千卡，“消耗能量”是用于在APP展示给用户查看，不作为训练能量计算"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程难度" } },
                _vm._l([1, 2, 3, 4, 5], function(item) {
                  return _c(
                    "el-radio",
                    {
                      key: item,
                      attrs: { label: item },
                      model: {
                        value: _vm.detail.courseDifficultyLevel,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "courseDifficultyLevel", $$v)
                        },
                        expression: "detail.courseDifficultyLevel"
                      }
                    },
                    [_vm._v(" L" + _vm._s(item) + " ")]
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "练习频次" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { clearable: "", maxlength: "6" },
                    model: {
                      value: _vm.detail.trainingFrequency,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "trainingFrequency", $$v)
                      },
                      expression: "detail.trainingFrequency"
                    }
                  })
                ],
                1
              ),
              _vm.detail.categoryId != "10000009"
                ? _c(
                    "el-form-item",
                    { attrs: { required: "", label: "器械" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.detail.fitnessEquipmentTypes,
                            callback: function($$v) {
                              _vm.$set(_vm.detail, "fitnessEquipmentTypes", $$v)
                            },
                            expression: "detail.fitnessEquipmentTypes"
                          }
                        },
                        _vm._l(_vm.dict.equipments, function(item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item }
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "600px" },
                          model: {
                            value: _vm.diyfitnessEquipmentTypes,
                            callback: function($$v) {
                              _vm.diyfitnessEquipmentTypes = $$v
                            },
                            expression: "diyfitnessEquipmentTypes"
                          }
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v("自定义器械")
                          ])
                        ],
                        2
                      ),
                      _c("p", { staticClass: "yl-tip" }, [
                        _vm._v("说明：以上器械不选/不填代表无器械")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "训练总能量" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.detail.trainingCalorie,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "trainingCalorie", $$v)
                        },
                        expression: "detail.trainingCalorie"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("千卡")])],
                    2
                  ),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("训练总能量用于计算用户训练消耗的能量")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程内容类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onCourseTypeChange },
                      model: {
                        value: _vm.detail.courseType,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "courseType", $$v)
                        },
                        expression: "detail.courseType"
                      }
                    },
                    _vm._l(_vm.dict.courseTypes, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + "课程 ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.detail.courseType === 2
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { required: "", label: "课程视频" } },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-box" },
                          [
                            _vm.detail.videoUrl
                              ? _c("video", {
                                  staticClass: "form-video",
                                  attrs: {
                                    id: "course-video",
                                    controls: "",
                                    src: _vm.detail.videoUrl
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.onOpenUpload("video")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.detail.videoUrl ? "更换" : "上传"
                                  ) + "视频"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("el-form-item", { attrs: { label: "课程预览视频" } }, [
                      _c(
                        "div",
                        { staticClass: "flex-box" },
                        [
                          _vm.detail.introduceVideoUrl
                            ? _c("video", {
                                staticClass: "form-video",
                                attrs: {
                                  id: "action-video",
                                  controls: "",
                                  src: _vm.detail.introduceVideoUrl
                                }
                              })
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onOpenUpload("videoCover")
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.detail.introduceVideoUrl ? "更换" : "上传"
                                ) + "视频"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { required: "", label: "课程阶段" } },
                      [
                        _c("section", { staticClass: "video-row video-head" }, [
                          _c("div", { staticClass: "video-col" }, [
                            _vm._v("阶段序号")
                          ]),
                          _c("div", { staticClass: "video-col" }, [
                            _vm._v("阶段名称")
                          ]),
                          _c("div", { staticClass: "video-col" }, [
                            _vm._v("动作描述")
                          ]),
                          _c("div", { staticClass: "video-col" }, [
                            _vm._v("阶段开始时间")
                          ]),
                          _c("div", { staticClass: "video-col" }, [
                            _vm._v("此段时长")
                          ])
                        ]),
                        _c(
                          "draggable",
                          {
                            model: {
                              value: _vm.detail.videos,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "videos", $$v)
                              },
                              expression: "detail.videos"
                            }
                          },
                          _vm._l(_vm.detail.videos, function(item, index) {
                            return _c(
                              "section",
                              { key: index, staticClass: "video-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "video-col" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#f99" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delVideo(index)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    ),
                                    _vm._v(" " + _vm._s(index + 1) + " ")
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "video-col" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.videoTitle,
                                        callback: function($$v) {
                                          _vm.$set(item, "videoTitle", $$v)
                                        },
                                        expression: "item.videoTitle"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "video-col" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: { type: "text", maxlength: "10" },
                                      model: {
                                        value: item.description,
                                        callback: function($$v) {
                                          _vm.$set(item, "description", $$v)
                                        },
                                        expression: "item.description"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "video-col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "range-input" },
                                    [
                                      _vm._v(" 第"),
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "120px",
                                          padding: "0 10px"
                                        },
                                        attrs: {
                                          placeholder: "秒",
                                          type: "number",
                                          min: "0"
                                        },
                                        model: {
                                          value: item.frameStart,
                                          callback: function($$v) {
                                            _vm.$set(item, "frameStart", $$v)
                                          },
                                          expression: "item.frameStart"
                                        }
                                      }),
                                      _vm._v("秒开始 ")
                                    ],
                                    1
                                  )
                                ]),
                                _c("div", { staticClass: "video-col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "range-input" },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "120px",
                                          padding: "0 10px"
                                        },
                                        attrs: {
                                          placeholder: "秒",
                                          type: "number",
                                          min: "0"
                                        },
                                        model: {
                                          value: item.frameSecond,
                                          callback: function($$v) {
                                            _vm.$set(item, "frameSecond", $$v)
                                          },
                                          expression: "item.frameSecond"
                                        }
                                      }),
                                      _vm._v("秒 ")
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.addVideoFrag }
                          },
                          [_vm._v("添加阶段")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.detail.courseType === 1
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { required: "", label: "课程内容" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              { on: { click: _vm.onOpenActionModal } },
                              [_vm._v(" 选择动作 ")]
                            ),
                            _c(
                              "draggable",
                              {
                                model: {
                                  value: _vm.detail.videos,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "videos", $$v)
                                  },
                                  expression: "detail.videos"
                                }
                              },
                              _vm._l(_vm.detail.videos, function(item, index) {
                                return _c(
                                  "el-card",
                                  {
                                    key: index,
                                    staticClass: "action-card",
                                    staticStyle: { width: "800px" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "clearfix action-info",
                                        attrs: { slot: "header" },
                                        slot: "header"
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "第" + _vm._s(index + 1) + "个动作"
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "动作编号：" + _vm._s(item.actionNo)
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "动作名称:" +
                                              _vm._s(item.videoTitle)
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "单位动作时长:" +
                                              _vm._s(item.actionSecond) +
                                              "S"
                                          )
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              float: "right",
                                              color: "#f99",
                                              padding: "3px 0"
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delAction(index)
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form",
                                      { attrs: { "label-width": "100px" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "动作形式" } },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: { label: 1 },
                                                model: {
                                                  value:
                                                    _vm.detail.videos[index]
                                                      .playType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.detail.videos[index],
                                                      "playType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "detail.videos[index].playType"
                                                }
                                              },
                                              [_vm._v("组类")]
                                            ),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: { label: 2 },
                                                model: {
                                                  value:
                                                    _vm.detail.videos[index]
                                                      .playType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.detail.videos[index],
                                                      "playType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "detail.videos[index].playType"
                                                }
                                              },
                                              [_vm._v("倒计时类")]
                                            )
                                          ],
                                          1
                                        ),
                                        item.playType === 1
                                          ? [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  },
                                                  attrs: { label: "动作次数" }
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      staticStyle: {
                                                        width: "200px"
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        min: "0"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.detail.videos[
                                                            index
                                                          ].actionCount,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.detail.videos[
                                                              index
                                                            ],
                                                            "actionCount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "detail.videos[index].actionCount"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("次")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "休息时长" }
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      staticStyle: {
                                                        width: "200px"
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        min: "0"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.detail.videos[
                                                            index
                                                          ].relaxTime,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.detail.videos[
                                                              index
                                                            ],
                                                            "relaxTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "detail.videos[index].relaxTime"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("S")]
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "yl-tip" },
                                                    [_vm._v("0代表不休息")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        item.playType === 2
                                          ? [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  },
                                                  attrs: { label: "倒计时长" }
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      staticStyle: {
                                                        width: "200px"
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        min: "0"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.detail.videos[
                                                            index
                                                          ].countdownSecond,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.detail.videos[
                                                              index
                                                            ],
                                                            "countdownSecond",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "detail.videos[index].countdownSecond"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("S")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "休息时长" }
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      staticStyle: {
                                                        width: "200px"
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        min: "0"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.detail.videos[
                                                            index
                                                          ].relaxTime,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.detail.videos[
                                                              index
                                                            ],
                                                            "relaxTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "detail.videos[index].relaxTime"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("S")]
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "yl-tip" },
                                                    [_vm._v("0代表不休息")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程封面" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-cell",
                      on: {
                        click: function($event) {
                          return _vm.onOpenUpload("cover")
                        }
                      }
                    },
                    [
                      _c(
                        "el-image",
                        {
                          attrs: {
                            fit: "contain",
                            src: _vm.detail.coverImageUrl
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: _vm.uploadImg }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("建议上传690*270尺寸图片")
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程简介" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.detail.briefIntro,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "briefIntro", $$v)
                      },
                      expression: "detail.briefIntro"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程介绍" } },
                [
                  _c("base-editor", {
                    attrs: {
                      upToken: _vm.qnToken,
                      content: _vm.detail.courseIntroduce
                    },
                    on: {
                      "update:content": function($event) {
                        return _vm.$set(_vm.detail, "courseIntroduce", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程标签" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.detail.courseLabelIds,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "courseLabelIds", $$v)
                        },
                        expression: "detail.courseLabelIds"
                      }
                    },
                    _vm._l(_vm.labels, function(item, index) {
                      return _c(
                        "el-checkbox",
                        { key: index, attrs: { label: item.labelId } },
                        [_vm._v(" " + _vm._s(item.labelName) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "number", min: "1" },
                    on: {
                      input: function($event) {
                        return _vm.changeInt(_vm.detail.courseSort)
                      }
                    },
                    model: {
                      value: _vm.detail.courseSort,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "courseSort", $$v)
                      },
                      expression: "detail.courseSort"
                    }
                  }),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v(
                      "说明:课程排序序号用于决定该课程在本课程大类下的显示排序，APP的数值越小，标签排序越前"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "在APP显示状态" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": true,
                      "inactive-value": false,
                      "active-text": "立即显示",
                      "inactive-text": "暂不显示"
                    },
                    model: {
                      value: _vm.detail.appShow,
                      callback: function($$v) {
                        _vm.$set(_vm.detail, "appShow", $$v)
                      },
                      expression: "detail.appShow"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  ),
                  _vm.$route.query.id
                    ? _c("el-button", { on: { click: _vm.onReset } }, [
                        _vm._v("取消")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload-cover",
            attrs: { token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e(),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload-videoCover",
            attrs: { type: "video", token: _vm.qnToken },
            on: { confirm: _vm.setVideoCover }
          })
        : _vm._e(),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload-video",
            attrs: { type: "video", token: _vm.qnToken },
            on: { confirm: _vm.setVideo }
          })
        : _vm._e(),
      _c("action-list", {
        ref: "action-list",
        on: { confirm: _vm.setActionList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }