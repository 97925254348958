var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c("span", [_vm._v("用户新增和活跃统计")]),
            _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "d2-mr",
                    on: { change: _vm.timeTypeChange },
                    model: {
                      value: _vm.form.timeType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "timeType", $$v)
                      },
                      expression: "form.timeType"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("最近七天")
                    ]),
                    _c("el-radio-button", { attrs: { label: 2 } }, [
                      _vm._v("最近30天")
                    ])
                  ],
                  1
                ),
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  on: { change: _vm.timeTypeChange2 },
                  model: {
                    value: _vm.datePickerTime,
                    callback: function($$v) {
                      _vm.datePickerTime = $$v
                    },
                    expression: "datePickerTime"
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [
              _vm._v(" 趋势图数据 "),
              _c("span", { staticClass: "d2-ml" }, [
                _vm._v(_vm._s(_vm._f("chartDataTimeMaxMin")(_vm.chartData)))
              ])
            ])
          ]),
          _c("div", {
            staticStyle: { height: "400px" },
            attrs: { id: "canvas" }
          }),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "类别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c("div", { attrs: { flex: "cross:center" } }, [
                          _c("div", {
                            staticClass: "line-leng d2-mr",
                            class: { green: $index == 1 }
                          }),
                          _c("span", [_vm._v(_vm._s(row.name))])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "UserTotal", label: "总数" }
              }),
              _c("el-table-column", {
                attrs: { prop: "UserAvg", label: "平均" }
              }),
              _c("el-table-column", {
                attrs: { prop: "UserMax", label: "最高" }
              }),
              _c("el-table-column", {
                attrs: { prop: "UserMin", label: "最低" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "d2-mt",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("详细数据")])
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.chartData2,
                border: "",
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "时间", prop: "date", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "新增用户", prop: "newUser", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "活跃用户",
                  prop: "activeUser",
                  align: "center"
                }
              })
            ],
            1
          ),
          _c("d2-pagination", {
            attrs: { total: _vm.total },
            on: { pagination: _vm.pagination }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }