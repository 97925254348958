export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 入驻意向列表
     */
    STORE_INTENTION_LIST: function STORE_INTENTION_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/intentionList',
        data: data
      });
    },

    /**
     * @description 入驻、营业信息更新、法人信息、税务信息 审核列表
     */
    STORE_AUDIT_LIST: function STORE_AUDIT_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/auditList',
        data: data
      });
    },

    /**
     * @description 申请详情
     */
    STORE_AUDIT_DETAILS: function STORE_AUDIT_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/auditDetails',
        data: data
      });
    },

    /**
     * @description 锁定店铺
     */
    STORE_LOCK_STORE: function STORE_LOCK_STORE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/lockStore',
        data: data
      });
    },

    /**
     * @description 审核
     */
    STORE_AUDIT: function STORE_AUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/audit',
        data: data
      });
    },

    /**
     * @description 审核备注
     */
    STORE_AUDIT_REMARK: function STORE_AUDIT_REMARK() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/auditRemark',
        data: data
      });
    },

    /**
     * @description 已入驻的店铺列表
     */
    STORE_LIST: function STORE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/storeList',
        data: data
      });
    },

    /**
     * @description 店铺详情
     */
    STORE_DETAILS: function STORE_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/storeDetails',
        data: data
      });
    },

    /**
     * @description 店铺信息 - 品牌列表
     */
    STORE_BRANDS: function STORE_BRANDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/brands',
        data: data
      });
    },

    /**
     * @description 店铺信息 - 删除品牌
     */
    STORE_DELETEBRAND: function STORE_DELETEBRAND() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/deleteBrand',
        data: data
      });
    },

    /**
     * @description 店铺信息 - 保存店铺品牌
     */
    STORE_SAVEBRAND: function STORE_SAVEBRAND() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/saveBrand',
        data: data
      });
    },

    /**
    * @description 店铺信息 - 启用和停用品牌
    */
    STORE_ACTIVATEBRAND: function STORE_ACTIVATEBRAND() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/activateBrand',
        data: data
      });
    },

    /**
     * @description 商品分类列表
     */
    GOODS_GOODS_TYPES: function GOODS_GOODS_TYPES() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsTypes',
        data: data
      });
    },

    /**
    * @description 商品分类列表(树形结构)
     */
    goodsTypeTrees: function goodsTypeTrees() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsTypeTrees',
        data: data
      });
    },

    /**
     * @description 保存商品分类
     */
    SAVE_GOODS_TYPE: function SAVE_GOODS_TYPE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/saveGoodsType',
        data: data
      });
    },

    /**
     * @description 商品分类详情
     */
    GOODS_TYPE_DETAILS: function GOODS_TYPE_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsTypeDetails',
        data: data
      });
    },

    /**
     * @description 删除商品分类
     */
    DELETE_GOODS_TYPE: function DELETE_GOODS_TYPE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/deleteGoodsType',
        data: data
      });
    },

    /**
    * @description 保存店铺的经营类目
    */
    saveBusinessCategories: function saveBusinessCategories() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/saveBusinessCategories',
        data: data
      });
    },

    /**
     * @description 商品管理 - 商品列表（在售、不在售）
     */
    GOODS_GOODS_LIST: function GOODS_GOODS_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsList',
        data: data
      });
    },

    /**
     * @description 商品管理 - 待售商品违规原因
     */
    violationReason: function violationReason() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/violationReason',
        data: data
      });
    },

    /**
     * @description 商品管理 - 商品详情
     */
    GOODS_GOODS_DETAILS: function GOODS_GOODS_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsDetails',
        data: data
      });
    },

    /**
     * @description 商品管理 - 商品下架
     */
    GOODS_GOODS_ONSHELF: function GOODS_GOODS_ONSHELF() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/onShelf',
        data: data
      });
    },

    /**
     * @description 商品管理 - 评价列表
     */
    GOODS_GOODS_EVALUETE_LIST: function GOODS_GOODS_EVALUETE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsEvaluateList',
        data: data
      });
    },

    /**
     * @description 商品管理 - 评价详情
     */
    GOODS_GOODS_EVALUETE_DETAILS: function GOODS_GOODS_EVALUETE_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/goodsEvaluateDetails',
        data: data
      });
    },

    /**
     * @description 商品管理 - 屏蔽评价
     */
    blockEvaluate: function blockEvaluate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/blockEvaluate',
        data: data
      });
    },

    /**
     * @description 订单列表
     */
    ORDER_MALL_ORDERS: function ORDER_MALL_ORDERS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/orders',
        data: data
      });
    },

    /**
     * @description 订单数量
     */
    ORDER_MALL_ORDER_COUNT: function ORDER_MALL_ORDER_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/orderCount',
        data: data
      });
    },

    /**
     * @description 订单详情
     */
    ORDER_MALL_ORDER_DETAILS: function ORDER_MALL_ORDER_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/orderDetails',
        data: data
      });
    },

    /**
     * @description 修改备注
     */
    ORDER_MALL_REMARK: function ORDER_MALL_REMARK() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/remark',
        data: data
      });
    },

    /**
     * @description 延迟出库记录
     */
    ORDER_MALL_OVERDUE_DELIVERY_LIST: function ORDER_MALL_OVERDUE_DELIVERY_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/overdueDeliveryList',
        data: data
      });
    },

    /**
     * @description 退换货服务单列表
     */
    ORDER_MALL_REFUNDS: function ORDER_MALL_REFUNDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/refunds',
        data: data
      });
    },

    /**
     * @description 退换货详情
     */
    ORDER_MALL_REFUND_DETAILS: function ORDER_MALL_REFUND_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/refundDetails',
        data: data
      });
    },

    /**
     * @description 退款订单 协商记录
     */
    ORDER_MALL_REFUND_CONSULTS: function ORDER_MALL_REFUND_CONSULTS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/refundConsults',
        data: data
      });
    },

    /**
     * @description 取消订单列表
     */
    ORDER_MALL_CANCEL_LIST: function ORDER_MALL_CANCEL_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/cancelList',
        data: data
      });
    },

    /**
     * @description 取消订单详情
     */
    ORDER_MALL_CANCEL_DETAILS: function ORDER_MALL_CANCEL_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/cancelDetails',
        data: data
      });
    },

    /**
     * @description 取消订单详情数量
     */
    ORDER_MALL_CANCEL_COUNT: function ORDER_MALL_CANCEL_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/cancelCount',
        data: data
      });
    },

    /**
     * @description 审核取消订单
     */
    ORDER_MALL_CANCEL_AUDIT: function ORDER_MALL_CANCEL_AUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/audit',
        data: data
      });
    },

    /**
     * @description 获取商城店铺保证金
     */
    STORE_DEPOSIT_AMOUNT: function STORE_DEPOSIT_AMOUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/depositAmount',
        data: data
      });
    },

    /**
     * @description 保存商城店铺保证金
     */
    STORE_SAVE_DEPOSIT_AMOUNT: function STORE_SAVE_DEPOSIT_AMOUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/store/saveDepositAmount',
        data: data
      });
    },

    /**
     * @description 销售属性列表
     */
    STORE_SELL_ATTRIBUTE: function STORE_SELL_ATTRIBUTE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/sellAttributes',
        data: data
      });
    },

    /**
     * @description 销售属性详情
     */
    STORE_SELL_ATTRIBUTE_DETAILS: function STORE_SELL_ATTRIBUTE_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/sellAttributeDetails',
        data: data
      });
    },

    /**
     * @description 删除销售属性
     */
    STORE_DELETE_SELL_ATTRIBUTE: function STORE_DELETE_SELL_ATTRIBUTE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/deleteSellAttribute',
        data: data
      });
    },

    /**
     * @description 保存销售属性
     */
    STORE_SAVE_SELL_ATTRIBUTE: function STORE_SAVE_SELL_ATTRIBUTE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/saveSellAttribute',
        data: data
      });
    },

    /**
     * @description 发现好物列表
     */
    STORE_GOODS_RECOMMEND_LIST: function STORE_GOODS_RECOMMEND_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/recommendList',
        data: data
      });
    },

    /**
     * @description 发现好物  添加/移除
     */
    STORE_GOODS_ADD_RECOMMEND: function STORE_GOODS_ADD_RECOMMEND() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/addRecommend',
        data: data
      });
    },

    /**
     * @description 保存发现好物的排序序号
     */
    STORE_GOODS_SAVE_RECOMMEND_SORT: function STORE_GOODS_SAVE_RECOMMEND_SORT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/saveRecommendSort',
        data: data
      });
    },

    /**
    * @description 导出订单
    */
    exportOrders: function exportOrders() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall-order/exportOrders',
        data: data
      });
    },

    /**
    * @description 商品管理 - 设置余额支付限制
    */
    STORE_GOODS_saveBalanceLimit: function STORE_GOODS_saveBalanceLimit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/goods/saveBalanceLimit',
        data: data
      });
    }
  };
});