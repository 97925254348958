//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TabWatingAudit from './TabWatingAudit';
import TabAll from './TabAll';
export default {
  meta: {
    title: '职业资质审核',
    auth: true,
    authorityValue: 'coach-license-audit'
  },
  name: 'professional-qualification-audit',
  components: {
    TabWatingAudit: TabWatingAudit,
    TabAll: TabAll
  },
  data: function data() {
    return {
      activeName: 'waiting-audit'
    };
  }
};