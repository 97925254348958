var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        title: "充值类型",
        visible: _vm.dialogVisible,
        rules: _vm.formRules,
        formData: _vm.dialogFormData,
        width: "600px",
        isEdit: _vm.isEdit
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "update:formData": function($event) {
          _vm.dialogFormData = $event
        },
        "update:form-data": function($event) {
          _vm.dialogFormData = $event
        },
        confirm: _vm.onSubmit
      }
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "mt-20",
          attrs: { label: "充值金额", prop: "chargeAmount" }
        },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "200px" },
              attrs: { placeholder: "", type: "number", min: "0" },
              model: {
                value: _vm.dialogFormData.chargeAmount,
                callback: function($$v) {
                  _vm.$set(_vm.dialogFormData, "chargeAmount", $$v)
                },
                expression: "dialogFormData.chargeAmount"
              }
            },
            [_c("template", { slot: "append" }, [_vm._v("元")])],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "赠送金额", prop: "rewardAmount" } },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "200px" },
              attrs: { placeholder: "", type: "number", min: "0" },
              model: {
                value: _vm.dialogFormData.rewardAmount,
                callback: function($$v) {
                  _vm.$set(_vm.dialogFormData, "rewardAmount", $$v)
                },
                expression: "dialogFormData.rewardAmount"
              }
            },
            [_c("template", { slot: "append" }, [_vm._v("元")])],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "限购次数", prop: "chargeLimit" } },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "200px" },
              attrs: { placeholder: "填写0则不限购", type: "number", min: "0" },
              model: {
                value: _vm.dialogFormData.chargeLimit,
                callback: function($$v) {
                  _vm.$set(_vm.dialogFormData, "chargeLimit", $$v)
                },
                expression: "dialogFormData.chargeLimit"
              }
            },
            [_c("template", { slot: "append" }, [_vm._v("次")])],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "库存", prop: "chargeStock" } },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "200px" },
              attrs: { placeholder: "", type: "number", min: "0" },
              model: {
                value: _vm.dialogFormData.chargeStock,
                callback: function($$v) {
                  _vm.$set(_vm.dialogFormData, "chargeStock", $$v)
                },
                expression: "dialogFormData.chargeStock"
              }
            },
            [_c("template", { slot: "append" }, [_vm._v("份")])],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "排序", prop: "chargeSort" } },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "", type: "number", min: "1" },
            on: { input: _vm.changeInt },
            model: {
              value: _vm.dialogFormData.chargeSort,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "chargeSort", $$v)
              },
              expression: "dialogFormData.chargeSort"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "销售状态" } },
        [
          _c("base-radio-group", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.dialogFormData.chargeStatus,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "chargeStatus", $$v)
              },
              expression: "dialogFormData.chargeStatus"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }