var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("角色管理")]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            [
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                    [_vm._v("添加角色")]
                  )
                ],
                1
              ),
              _c(
                "base-table",
                { ref: "baseTable", attrs: { request: _vm.request } },
                [
                  _c("el-table-column", {
                    attrs: { label: "角色名称", prop: "positionName" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "添加时间",
                      prop: "createTime",
                      width: "200"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", prop: "xxx" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              { attrs: { type: "text" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/auth-settings",
                                        query: {
                                          positionId: scope.row.positionId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v("权限设置")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onEdit(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onDel(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 删除 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-dialog",
            {
              attrs: {
                labelWidth: "150px",
                width: "500px",
                title: "角色",
                rules: _vm.rules,
                visible: _vm.dialogVisible,
                formData: _vm.dialogFormData,
                isEdit: _vm.isEdit
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                "update:formData": function($event) {
                  _vm.dialogFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.dialogFormData = $event
                },
                confirm: _vm.callAdminSavePosition
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "positionName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "", maxlength: "10" },
                    model: {
                      value: _vm.dialogFormData.positionName,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "positionName", $$v)
                      },
                      expression: "dialogFormData.positionName"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }