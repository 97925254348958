var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("消息中心")]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "10px" } } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unReadMsg > 0,
                  expression: "unReadMsg>0"
                }
              ],
              staticClass: "readAll",
              on: { click: _vm.readAllMsg }
            },
            [_vm._v("标记所有为已读")]
          ),
          _c(
            "el-skeleton",
            {
              staticStyle: { overflow: "auto" },
              attrs: { rows: 6, animated: "", loading: _vm.loading }
            },
            [
              _c(
                "ul",
                { staticClass: "ul-list" },
                _vm._l(_vm.count, function(l) {
                  return _c(
                    "li",
                    {
                      key: l.messageId,
                      staticClass: "li-list",
                      on: {
                        click: function($event) {
                          return _vm.openDetail(l)
                        }
                      }
                    },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            shadow: "never",
                            "body-style": { padding: "20px" }
                          }
                        },
                        [
                          _c("div", { staticClass: "top" }, [
                            !l.messageRead
                              ? _c("i", { staticClass: "dot" })
                              : _vm._e(),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(l.messageTitle))
                            ]),
                            _c("div", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatDateTime")(l.createTime))
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "bottom" }, [
                            _vm._v(_vm._s(l.messageContent))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }