import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
export var downloadFile = function downloadFile(file_name, file_path) {
  var ajax = new XMLHttpRequest();
  ajax.open("GET", file_path, true);
  ajax.setRequestHeader("Cache-Control", "no-cache");
  ajax.setRequestHeader('Access-Control-Allow-Origin', '*');
  ajax.responseType = 'blob';

  ajax.onload = function (e) {
    var res = e.target.response; // let blob = new Blob([res],{type: "application/pdf;charset=UTF-8"}) // 这里的res为后端返回的流数据

    var blob = new Blob([res]);
    var aLink = document.createElement("a");
    aLink.download = file_name; // 下载文件的名字

    aLink.href = URL.createObjectURL(blob);
    aLink.click();
  };

  ajax.send();
};