var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-button",
        {
          staticClass: "d2-mb",
          on: {
            click: function($event) {
              return _vm.goDetail()
            }
          }
        },
        [_vm._v("添加一级分类")]
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", { attrs: { label: "编号", prop: "typeNo" } }),
          _c("el-table-column", {
            attrs: { label: "分类名称", prop: "typeName" }
          }),
          _c("el-table-column", {
            attrs: { label: "级别", prop: "typeLevel" }
          }),
          _c("el-table-column", {
            attrs: { label: "是否显示", prop: "typeShow" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(row.typeShow ? "是" : "否"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { label: "排序", prop: "typeSort" } }),
          _c("el-table-column", {
            attrs: { label: "设置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.clickSee({
                              preTypeId: row.goodsTypeId,
                              name: row.typeName
                            })
                          }
                        }
                      },
                      [_vm._v("查看下级")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "d2-mr",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goDetail({
                              goodsTypeId: row.goodsTypeId
                            })
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.deleteStoreGoodsType({
                              goodsTypeId: row.goodsTypeId
                            })
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }