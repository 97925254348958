//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'w-loading',
  props: {
    text: String,
    mask: Boolean | String,
    click: Boolean | String
  },
  data: function data() {
    return {
      show: false
    };
  },
  methods: {
    preventTouchMove: function preventTouchMove() {
      console.log('stop user scroll it!');
      return;
    },
    Mclose: function Mclose() {
      if (this.click == 'false' || this.click == false) {
        this.show = false;
      }
    },
    open: function open() {
      var _this = this;

      this.$nextTick(function () {
        _this.show = true;
      });
    },
    close: function close() {
      this.show = false;
    }
  }
};