var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("新增资讯")]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "资讯标题", prop: "newsTitle" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "输入资讯标题" },
                    model: {
                      value: _vm.form.newsTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newsTitle", $$v)
                      },
                      expression: "form.newsTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "资讯排序", prop: "newsSort" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { placeholder: "输入资讯排序" },
                    model: {
                      value: _vm.form.newsSort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newsSort", $$v)
                      },
                      expression: "form.newsSort"
                    }
                  }),
                  _c("span", { staticClass: "text-999 d2-ml" }, [
                    _vm._v("说明：数值越小，排序越前")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "资讯作者", prop: "authorName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "输入资讯作者" },
                    model: {
                      value: _vm.form.authorName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "authorName", $$v)
                      },
                      expression: "form.authorName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "资讯内容", prop: "newsContent" } },
                [
                  _c("base-editor", {
                    attrs: {
                      upToken: _vm.qnToken,
                      content: _vm.form.newsContent
                    },
                    on: {
                      "update:content": function($event) {
                        return _vm.$set(_vm.form, "newsContent", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  ),
                  _c("el-button", { on: { click: _vm.goBack } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }