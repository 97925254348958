var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(item, index) {
      return _c(
        "el-checkbox",
        {
          key: item.value,
          attrs: { checked: _vm.value[index] },
          on: {
            change: function($event) {
              return _vm.handleChange($event, index)
            }
          }
        },
        [_vm._v(_vm._s(item.label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }