var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("下架商品")])
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tabData.goodsItems,
                border: "",
                stripe: "",
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "xxx", label: "商品信息", "min-width": "250px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_c("goods-card", { attrs: { row: row } })]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "总库存", prop: "goodsStock" }
              }),
              _c("el-table-column", {
                attrs: { label: "单价（元）", prop: "goodsPrice" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "d2-mt", attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("下架原因")])
          ]),
          _c("base-editor", {
            attrs: { upToken: _vm.qnToken, content: _vm.form.violationReason },
            on: {
              "update:content": function($event) {
                return _vm.$set(_vm.form, "violationReason", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onsubmit } },
            [_vm._v("确认提交")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }