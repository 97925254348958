//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    styleBg: {
      type: Object,
      default: function _default() {}
    }
  },
  name: 'DvFullScreenContainer',
  data: function data() {
    return {
      ref: 'full-screen-container',
      allWidth: 0,
      scale: 0,
      datavRoot: ''
    };
  },
  methods: {}
};