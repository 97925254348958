var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("钱包充值记录")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "充值日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "充值订单号" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.rechargeNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rechargeNo", $$v)
                  },
                  expression: "form.rechargeNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "充值用户" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "输入用户昵称/手机号码/用户ID查找" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "充值邀请人" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.recommenderName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "recommenderName", $$v)
                  },
                  expression: "form.recommenderName"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "TableTitleBar",
        { attrs: { title: "查询统计" } },
        [
          _c("span", { staticClass: "space" }, [
            _vm._v("用户充值总额：￥" + _vm._s(_vm.extraData.chargeAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("平台赠送总额：￥" + _vm._s(_vm.extraData.rewardAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("实际到账总额：￥" + _vm._s(_vm.extraData.finalAmount))
          ]),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download2 },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "充值订单号", prop: "rechargeNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "充值时间", prop: "orderTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDateTime")(scope.row.orderTime))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "充值用户", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户手机号", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "充值金额（元）", prop: "chargeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "赠送金额（元）", prop: "rewardAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "实际到账总额", prop: "finalAmount" }
          }),
          _c(
            "el-table-column",
            { attrs: { label: "收支方向", prop: "xxx" } },
            [[_vm._v(_vm._s("收入"))]],
            2
          ),
          _c("el-table-column", {
            attrs: { label: "支付渠道", prop: "payWay" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.payWay,
                            _vm.dict.payOptions
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "充值邀请人", prop: "recommenderName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("p", [_vm._v(_vm._s(scope.row.recommenderName))])]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }