var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("base-select", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.storeId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeId", $$v)
                  },
                  expression: "form.storeId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.billDateRange.start,
                  max: _vm.form.billDateRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.billDateRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.billDateRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "d2-mb",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                _c("div", { staticClass: "l-line-box" }, [
                  _c("p", [_vm._v("当前账单总数")]),
                  _c("p", [_vm._v(_vm._s(_vm.extraData.total) + "条")]),
                  _c("div", { staticClass: "l-line" })
                ])
              ]),
              _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                _c("div", { staticClass: "l-line-box" }, [
                  _c("p", [_vm._v("总收入金额")]),
                  _c("p", [_vm._v(_vm._s(_vm.extraData.incomeAmount) + "元")]),
                  _c("div", { staticClass: "l-line" })
                ])
              ]),
              _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                _c("div", { staticClass: "l-line-box" }, [
                  _c("p", [_vm._v("总支出金额")]),
                  _c("p", [_vm._v(_vm._s(_vm.extraData.expendAmount) + "元")]),
                  _c("div", { staticClass: "l-line" })
                ])
              ]),
              _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                _c("div", { staticClass: "l-line-box" }, [
                  _c("p", [_vm._v("总实际结算金额")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        (
                          _vm.extraData.incomeAmount -
                          _vm.extraData.expendAmount
                        ).toFixed(2)
                      ) + "元"
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "账单日期", prop: "date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("p", [_vm._v(_vm._s(scope.row.date))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "incomeAmount",
              align: "center",
              label: "收入（元）"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticStyle: { color: "#409eff" } }, [
                      _vm._v(_vm._s(scope.row.incomeAmount))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commissionAmount",
              align: "center",
              label: "支出（元）"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticStyle: { color: "#409eff" } }, [
                      _vm._v(_vm._s(scope.row.expendAmount))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsAmount",
              align: "center",
              label: "实际结算（元）"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (
                            scope.row.incomeAmount - scope.row.expendAmount
                          ).toFixed(2)
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "200",
                          trigger: "click"
                        }
                      },
                      [
                        _c(
                          "el-descriptions",
                          { attrs: { column: 1, border: "" } },
                          [
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "名称" } },
                              [_vm._v("金额（元）")]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "货款" } },
                              [_vm._v(_vm._s(row.goodsAmount))]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "佣金" } },
                              [_vm._v("-" + _vm._s(row.commissionAmount))]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "返现" } },
                              [_vm._v("-" + _vm._s(row.cashbackAmount))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference"
                          },
                          [_vm._v("费用汇总")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.download(row)
                          }
                        }
                      },
                      [_vm._v("下载明细")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }