var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "form-grid",
        {
          attrs: {
            options: _vm.options,
            column: 1,
            direction: "horizontal",
            labelStyle: "width: 240px"
          }
        },
        [
          _vm.isTypeCommentOrMsg
            ? _c(
                "el-descriptions-item",
                { attrs: { slot: "extra", label: "举报内容" }, slot: "extra" },
                [
                  _c("p", [_vm._v(_vm._s(_vm.record.postContent))]),
                  _vm.record.targetType == 1
                    ? _c(
                        "el-button",
                        { attrs: { type: "text" } },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  path: "community-message-detail",
                                  query: { postId: _vm.record.targetId }
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          )
                        ],
                        1
                      )
                    : _vm.record.targetType == 3
                    ? _c(
                        "el-button",
                        { attrs: { type: "text" } },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  path: "user-detail",
                                  query: { userId: _vm.record.targetId }
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.isStatusHandle
            ? _c(
                "el-descriptions-item",
                { attrs: { slot: "extra", label: "处理备注" }, slot: "extra" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.record.dealRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dealRemark", $$v)
                      },
                      expression: "record.dealRemark"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isStatusHandle
        ? _c("form-grid", {
            staticClass: "mt-20",
            attrs: {
              options: _vm.optionsHandle,
              column: 1,
              direction: "horizontal",
              labelStyle: "width: 240px"
            }
          })
        : _vm._e(),
      !_vm.isStatusHandle
        ? _c(
            "template",
            { slot: "footer" },
            [
              _vm.isTypeUser
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function($event) {
                          return _vm.showDialog(true)
                        }
                      }
                    },
                    [_vm._v("屏蔽和禁言")]
                  )
                : _vm._e(),
              _vm.isTypeCommentOrMsg
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function($event) {
                          return _vm.showDialog(false)
                        }
                      }
                    },
                    [_vm._v("屏蔽内容")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.onInvalid } },
                [_vm._v("无效举报")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "base-dialog",
        {
          attrs: {
            labelWidth: "120px",
            fullTitle: "屏蔽内容",
            visible: _vm.dialogVisible,
            rules: _vm.formRules,
            formData: _vm.dialogFormData,
            isEdit: true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            "update:formData": function($event) {
              _vm.dialogFormData = $event
            },
            "update:form-data": function($event) {
              _vm.dialogFormData = $event
            },
            confirm: _vm.onDialogConfirm
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "处理屏蔽内容" } },
            [
              _c("base-radio-group", {
                attrs: {
                  options: [
                    {
                      label: "屏蔽",
                      value: true
                    },
                    {
                      label: "不屏蔽",
                      value: false
                    }
                  ]
                },
                model: {
                  value: _vm.dialogFormData.blocked,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogFormData, "blocked", $$v)
                  },
                  expression: "dialogFormData.blocked"
                }
              })
            ],
            1
          ),
          _c("span", { staticStyle: { "margin-left": "50px" } }, [
            _vm._v("禁言期间，该用户将不能发布社区动态、不能发私信和点赞")
          ]),
          _c(
            "el-form-item",
            { staticClass: "mt-20", attrs: { label: "禁言时间" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: false },
                  model: {
                    value: _vm.dialogFormData.isSilent,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogFormData, "isSilent", $$v)
                    },
                    expression: "dialogFormData.isSilent"
                  }
                },
                [_vm._v("不禁言")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: true },
                  model: {
                    value: _vm.dialogFormData.isSilent,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogFormData, "isSilent", $$v)
                    },
                    expression: "dialogFormData.isSilent"
                  }
                },
                [
                  _vm._v("禁言 "),
                  _c(
                    "el-input",
                    {
                      staticStyle: {
                        width: "180px",
                        "vertical-align": "middle"
                      },
                      attrs: {
                        placeholder: "",
                        type: "number",
                        min: "1",
                        disabled: !_vm.dialogFormData.isSilent
                      },
                      on: { input: _vm.changeInt },
                      model: {
                        value: _vm.dialogFormData.muteUser,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogFormData, "muteUser", $$v)
                        },
                        expression: "dialogFormData.muteUser"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("天")])],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }