//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var title = '举报列表';
var initForm = {};
export default {
  meta: {
    title: title,
    auth: true,
    authorityValue: 'report-manage'
  },
  name: 'tip-off-list',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'report_reports',
        params: cloneDeep(initForm)
      },
      targetTypes: [{
        label: '全部',
        value: ''
      }, {
        label: '举报动态内容',
        value: 1
      }, {
        label: '举报评论内容',
        value: 2
      }, {
        label: '举报用户',
        value: 3
      }],
      dealResult: [{
        label: '全部',
        value: ''
      }, {
        label: '未处理',
        value: 1
      }, {
        label: '已处理',
        value: 2
      }],
      dealResultTable: [{
        label: '未处理',
        value: 1
      }, {
        label: '已处理',
        value: 2
      }, {
        label: '已处理',
        value: 3
      }],
      blockTypes: [{
        label: '全部',
        value: ''
      }, {
        label: '无效举报',
        value: 1
      }, {
        label: '屏蔽内容不禁言',
        value: 2
      }, {
        label: '屏蔽内容且禁言',
        value: 3
      }, {
        label: '不屏蔽内容不禁言',
        value: 4
      }, {
        label: '不屏蔽内容但禁言',
        value: 5
      }]
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};