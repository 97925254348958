var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("教练复审记录")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "申请编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入申请编号搜索" },
                model: {
                  value: _vm.form.auditNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "auditNo", $$v)
                  },
                  expression: "form.auditNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "教练" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  placeholder: "输入教练真实姓名、昵称、用户编号、手机号码搜索"
                },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.auditStatus },
                model: {
                  value: _vm.form.auditStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "auditStatus", $$v)
                  },
                  expression: "form.auditStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "教练连线时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.applyTimeRange.start,
                  max: _vm.form.applyTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.auditTimeRange.start,
                  max: _vm.form.auditTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "申请编号", prop: "auditNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "审核结果", prop: "auditStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm._f("auditStatus")(scope.row.auditStatus))
                      )
                    ]),
                    scope.row.auditStatus === 3
                      ? _c(
                          "p",
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _vm._l(scope.row.rejectReasons, function(e, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [_vm._v(_vm._s(e)), _c("br")]
                                  )
                                }),
                                _c("el-button", { attrs: { type: "text" } }, [
                                  _vm._v("查看原因")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "教练姓名", prop: "nickname" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: {
                                  userId: scope.row.userId,
                                  type: "coach"
                                }
                              }
                            }
                          },
                          [_vm._v(_vm._s(scope.row.realName))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "教练连线时间", prop: "applyTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDateTime")(scope.row.applyTime))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "平台审核时间", prop: "auditTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDateTime")(scope.row.auditTime))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "审核备注", prop: "auditRemark" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.playbackUrl
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.seeReplay(scope.row.playbackUrl)
                              }
                            }
                          },
                          [_vm._v(" 查看回放 ")]
                        )
                      : _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v(" 回放生成中 ")
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }