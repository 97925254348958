var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("退保审核")]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 4 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "退保编号" } }, [
                    _vm._v(_vm._s(_vm.record.depositNo))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                    _vm._v(_vm._s(_vm.record.depositTime))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                    _vm._v(_vm._s(_vm.record.companyName))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "店铺名称" } },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.goRoute(
                                "/store-detail?storeId=" + _vm.record.storeId
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.record.storeName))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-descriptions",
                {
                  staticClass: "d2-mt",
                  attrs: { title: _vm.title, column: 1 }
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "开户名称" } }, [
                    _vm._v(_vm._s(_vm.record.payeeAccountName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "开户账号" } }, [
                    _vm._v(_vm._s(_vm.record.payeeAccount))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "开户银行支行" } },
                    [_vm._v(_vm._s(_vm.record.payeeBankName))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "联行号" } }, [
                    _vm._v(_vm._s(_vm.record.payeeBankNo))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "d2-mt",
              attrs: { shadow: "never", "body-style": { padding: "20px" } }
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.form.depositStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "depositStatus", $$v)
                            },
                            expression: "form.depositStatus"
                          }
                        },
                        [_vm._v("审核通过")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.form.depositStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "depositStatus", $$v)
                            },
                            expression: "form.depositStatus"
                          }
                        },
                        [_vm._v("审核不通过")]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.depositStatus == 3,
                            expression: "form.depositStatus == 3"
                          }
                        ],
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入内容",
                          maxlength: "50"
                        },
                        model: {
                          value: _vm.form.rejectReason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectReason", $$v)
                          },
                          expression: "form.rejectReason"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.goBack()
                            }
                          }
                        },
                        [_vm._v("返回")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }