var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("代理商账号删除记录")]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            [
              _c(
                "base-table",
                { ref: "baseTable", attrs: { request: _vm.request } },
                [
                  _c("el-table-column", {
                    attrs: { label: "账号名称", prop: "adminAccount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", prop: "adminName" }
                  }),
                  _vm.listType == "ROLE_CITY"
                    ? _c("el-table-column", {
                        attrs: { label: "管理的城市", prop: "agents" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("HiddenText", {
                                    attrs: { text: _vm.getCityName(row) }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3632034424
                        )
                      })
                    : _vm._e(),
                  _vm.listType == "ROLE_PROVINCE"
                    ? _c("el-table-column", {
                        attrs: { label: "管理的省份", prop: "agents" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("HiddenText", {
                                    attrs: { text: _vm.getProvinceName(row) }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          660667077
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { label: "代理商ID", prop: "adminId", width: "200" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-dialog",
            {
              attrs: {
                width: "500px",
                title: "账号",
                rules: _vm.rules,
                visible: _vm.dialogVisible,
                formData: _vm.dialogFormData,
                isEdit: _vm.isEdit
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                "update:formData": function($event) {
                  _vm.dialogFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.dialogFormData = $event
                },
                confirm: _vm.callAdminSavePosition
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号名称", prop: "adminAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号名称" },
                    model: {
                      value: _vm.dialogFormData.adminAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "adminAccount", $$v)
                      },
                      expression: "dialogFormData.adminAccount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录密码", prop: "loginPassword" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入登录密码" },
                    model: {
                      value: _vm.dialogFormData.loginPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "loginPassword", $$v)
                      },
                      expression: "dialogFormData.loginPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "adminName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.dialogFormData.adminName,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "adminName", $$v)
                      },
                      expression: "dialogFormData.adminName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "管理的城市", prop: "agents" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.options,
                      "show-all-levels": false,
                      props: { multiple: true },
                      "collapse-tags": ""
                    },
                    model: {
                      value: _vm.dialogFormData.agents,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "agents", $$v)
                      },
                      expression: "dialogFormData.agents"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }