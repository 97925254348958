var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v("私教课直播画面")]),
      _vm.records.length == 0
        ? _c("el-empty", { attrs: { description: "暂无直播" } })
        : _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "div",
                { staticClass: "row-box" },
                [
                  _vm._l(_vm.records, function(item, index) {
                    return [
                      _c(
                        "el-card",
                        {
                          key: item.trainingOrderId,
                          staticClass: "d2-m",
                          attrs: {
                            shadow: "never",
                            "body-style": { padding: "20px" }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c("span", [_vm._v(_vm._s(item.coachName))])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("video", {
                            attrs: {
                              id: "leb-video-container-" + item.trainingOrderId
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "d2-m-10 d2-fr",
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.onEnd(index)
                                }
                              }
                            },
                            [_vm._v("中断直播")]
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }