export default [{
  title: '财务管理',
  iconSvg: '财务结算',
  authorityValue: "financial-management",
  children: [{
    title: '设置',
    authorityValue: "financial-setting",
    children: [{
      title: '邀请注册奖励设置',
      path: '/invitation-registration-reward-setting',
      authorityValue: "financial-setting-invite"
    }, {
      title: '签到奖励金设置',
      path: '/signin-reward-money-setting',
      authorityValue: "financial-setting-sign"
    }, {
      title: '钱包充值设置',
      path: '/wallet-charge-setting',
      authorityValue: "financial-setting-recharge"
    }, {
      title: '提现手续费设置',
      path: '/withdrawal-fee-setting',
      authorityValue: "financial-setting-transferfee"
    }]
  }, {
    title: '签到奖励',
    path: '/signin-reward',
    authorityValue: "sign-in-reward"
  }, {
    title: '邀请奖励',
    path: '/invitation-reward',
    authorityValue: "invitation-reward"
  }, {
    title: '邀请奖励终审',
    path: '/invitation-review',
    authorityValue: "invite-reward-audit"
  }, {
    title: '私教课分销返现',
    path: '/course-distribution-cashback',
    authorityValue: "training-cash-back"
  }, {
    title: '发票管理',
    authorityValue: "invoice-manage",
    children: [{
      title: '开票管理',
      path: '/invoice-list',
      authorityValue: "invoice-list"
    }, {
      title: '未开票订单',
      path: '/not-invoice-order',
      authorityValue: "not-voiced-list"
    }]
  }, {
    title: 'PLUS会员',
    authorityValue: "plus-manage",
    children: [{
      title: 'PLUS会员汇总',
      path: '/invoice-plus-member',
      authorityValue: "plus-sta"
    }, {
      title: 'PLUS订单对账',
      path: '/order-reconciliation',
      authorityValue: "plus-order-reconciliation"
    }, {
      title: 'PLUS退款对账',
      path: '/refund-order-reconciliation',
      authorityValue: "plus-refund-reconciliation"
    }, {
      title: 'PLUS门店收益',
      path: '/stores-earnings',
      authorityValue: "plus-store-income-list"
    }, {
      title: 'PLUS返现记录',
      path: '/plus-records',
      authorityValue: "admin-router-page"
    }]
  }, {
    title: 'APP用户钱包',
    authorityValue: "user-wallet-manage",
    children: [{
      path: '/app-user-wallet-list',
      title: '用户钱包余额',
      authorityValue: "user-wallet-list"
    }, {
      path: '/app-user-wallet-charge',
      title: '用户钱包充值',
      authorityValue: "user-recharges"
    }, {
      path: '/app-user-wallet-manage',
      title: '用户钱包管理',
      authorityValue: "user-wallet-manage"
    }, {
      path: '/app-user-withdrawal',
      title: '用户提现',
      authorityValue: "user-transfers"
    }, {
      path: '/app-user-withdrawal-notice',
      title: '提现失败通知',
      authorityValue: "user-transfers"
    }]
  }, {
    title: '商城结算',
    authorityValue: "mall-settlement-manage",
    children: [{
      title: '店铺钱包余额',
      path: '/store-wallet-list',
      authorityValue: "store-wallet-list"
    }, {
      title: '店铺提现',
      path: '/store-withdrawal-from-mall-list',
      authorityValue: "store-transfers"
    }, {
      title: '店铺账单查询',
      path: '/store-bill-query',
      authorityValue: "store-bill-list"
    }, {
      title: '店铺明细账单查询',
      path: '/store-detail-bill-query',
      authorityValue: "store-bill-logs"
    }]
  }, {
    title: '健身门店结算',
    authorityValue: "gym-settlement-manage",
    children: [{
      title: '门店钱包余额',
      path: '/shop-wallet-list',
      authorityValue: "gym-wallet-list"
    }, {
      title: '门店提现',
      path: '/shop-withdrawal-list',
      authorityValue: "gym-transfers"
    }, {
      title: '门店账单查询',
      path: '/shop-bill-query',
      authorityValue: "gym-bill-list"
    }, {
      title: '门店明细账单查询',
      path: '/shop-detail-bill-query',
      authorityValue: "gym-bill-logs"
    }]
  }, {
    title: '教练保证金',
    path: '/coach-guarantees-pay',
    authorityValue: "coach-bond-list"
  }, {
    title: '店铺保证金',
    path: '/store-deposit-list',
    authorityValue: "store-bond-list"
  }, {
    title: '门店保证金',
    path: '/shop-deposit-list',
    authorityValue: "gym-bond-list"
  }, {
    title: '平台收支',
    path: '/platform-revenue-expenditure',
    authorityValue: "platform-income-expenditure"
  }, {
    title: '购票明细记录',
    path: '/buyVote',
    authorityValue: "vote-management"
  }]
}];