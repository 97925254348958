import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 帮助中心 列表 */
    help_helps: createAction(request, '/admin/help/helps'),

    /** 帮助中心 保存分类 */
    help_saveHelpCategory: createAction(request, '/admin/help/saveHelpCategory'),

    /** 帮助中心 保存帮助 */
    help_saveHelp: createAction(request, '/admin/help/saveHelp'),

    /** 帮助中心 帮助详情 */
    help_helpDetails: createAction(request, '/admin/help/helpDetails'),

    /** 帮助中心 删除帮助 */
    help_deleteHelp: createAction(request, '/admin/help/deleteHelp'),

    /** 帮助中心 删除分类 */
    help_deleteHelpCategory: createAction(request, '/admin/help/deleteHelpCategory')
  };
});