import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// 设置文件

/**
 * 一些业务的数据字典
 */
var liveStatus = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '未开始'
}, {
  value: 2,
  label: '直播中'
}, {
  value: 3,
  label: '回放生成中'
}, {
  value: 4,
  label: '直播暂停'
}, {
  value: 5,
  label: '已取消'
}, {
  value: 6,
  label: '已生成回放'
}];
var initCourseCates = [{
  name: '健身训练',
  key: '10000001'
}, {
  name: '瑜伽',
  key: '10000002'
}, {
  name: '孕产',
  key: '10000003'
}, {
  name: '青少年中考',
  key: '10000004'
}, {
  name: '康复',
  key: '10000005'
}, {
  name: '老年保健',
  key: '10000006'
}, // { name: '跑步、骑行、登山', key: '10000007' },
{
  name: '冥想',
  key: '10000008'
}];
var initCourseType = [{
  name: '健身训练',
  key: '10000001'
}, {
  name: '瑜伽',
  key: '10000002'
}, {
  name: '孕产',
  key: '10000003'
}, {
  name: '青少年中考',
  key: '10000004'
}, {
  name: '康复',
  key: '10000005'
}, {
  name: '老年保健',
  key: '10000006'
}, // { name: '跑步、骑行、登山', key: '10000007' },
{
  name: '冥想',
  key: '10000008'
}];
var initCourseType2 = [{
  name: '健身训练',
  key: '10000001'
}, {
  name: '瑜伽',
  key: '10000002'
}, {
  name: '孕产',
  key: '10000003'
}, {
  name: '青少年中考',
  key: '10000004'
}, {
  name: '康复',
  key: '10000005'
}, {
  name: '老年保健',
  key: '10000006'
} // { name: '跑步、骑行、登山', key: '10000007' },
// { name: '冥想', key: '10000008' }
];
var actionTypes = [{
  name: '热身',
  key: 1
}, {
  name: '训练',
  key: 2
}, {
  name: '拉伸',
  key: 3
}, {
  name: '瑜伽',
  key: 4
}];
var expressStatus = [{
  name: '在途',
  key: 0
}, {
  name: '揽收',
  key: 1
}, {
  name: '疑难',
  key: 2
}, {
  name: '签收',
  key: 3
}, {
  name: '退签',
  key: 4
}, {
  name: '派件',
  key: 5
}, {
  name: '清关',
  key: 8
}, {
  name: '拒签',
  key: 14
}];
var actionParts = [{
  name: '综合',
  key: '综合'
}, {
  name: '肩部',
  key: '肩部'
}, {
  name: '颈部',
  key: '颈部'
}, {
  name: '胸部',
  key: '胸部'
}, {
  name: '背部',
  key: '背部'
}, {
  name: '手臂',
  key: '手臂'
}, {
  name: '上肢',
  key: '上肢'
}, {
  name: '腰部',
  key: '腰部'
}, {
  name: '腹部',
  key: '腹部'
}, {
  name: '臀部',
  key: '臀部'
}, {
  name: '腿部',
  key: '腿部'
}]; //订单状态

var orderStatus = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '待付款'
}, {
  value: 2,
  label: '待出库'
}, {
  value: 3,
  label: '已出库'
}, {
  value: 4,
  label: '已签收'
}, {
  value: 5,
  label: '已完成'
}, {
  value: 6,
  label: '已取消'
}, {
  value: 7,
  label: '已取消'
}, {
  value: 8,
  label: '已取消'
}];
var difficultyLevels = [{
  name: 'L1',
  key: 1
}, {
  name: 'L2',
  key: 2
}, {
  name: 'L3',
  key: 3
}];
var operationType = [{
  value: 1,
  label: '增加'
}, {
  value: 2,
  label: '扣减'
}, {
  value: 3,
  label: '冻结'
}, {
  value: 4,
  label: '解冻'
}];
var courseCates = {};
initCourseCates.map(function (item) {
  courseCates[item.key] = item.name;
});
var courseType = {};
initCourseType.map(function (item) {
  courseType[item.key] = item.name;
});
var courseType2 = {};
initCourseType2.map(function (item) {
  courseType2[item.key] = item.name;
});
var actionTypesMap = {};
actionTypes.map(function (item) {
  actionTypesMap[item.key] = item.name;
});
var difficultyLevelsMap = {};
difficultyLevels.map(function (item) {
  difficultyLevelsMap[item.key] = item.name;
});
var gender = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '男'
}, {
  value: 2,
  label: '女'
}];
var showOptions = [{
  value: null,
  label: '全部'
}, {
  value: true,
  label: '是'
}, {
  value: false,
  label: '否'
}];
var payOptions = [{
  value: '',
  label: '其他'
}, {
  value: 1,
  label: '微信'
}, {
  value: 2,
  label: '支付宝'
}];
var hasOptions = [{
  value: null,
  label: '全部'
}, {
  value: true,
  label: '有'
}, {
  value: false,
  label: '无'
}];
var courseDifficultyLevels = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: 'L1'
}, {
  value: 2,
  label: 'L2'
}, {
  value: 3,
  label: 'L3'
}, {
  value: 4,
  label: 'L4'
}, {
  value: 5,
  label: 'L5'
}];
var auditStatus = [{
  value: '',
  label: '全部'
}, {
  value: 2,
  label: '通过'
}, {
  value: 3,
  label: '不通过'
}];
var equipmentType = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '手环'
}, {
  value: 2,
  label: '跳绳'
}, {
  value: 3,
  label: '健腹轮'
}, {
  value: 4,
  label: '筋膜枪'
}, {
  value: 5,
  label: '震动机（甩脂机）'
}, {
  value: 6,
  label: '踏步机'
}, {
  value: 7,
  label: '俯卧撑板'
}, {
  value: 8,
  label: '体重秤'
}, {
  value: 9,
  label: '动感单车'
}, {
  value: 10,
  label: '健身车'
}, {
  value: 11,
  label: '椭圆机'
}, {
  value: 12,
  label: '登山机'
}, {
  value: 13,
  label: '划船器'
}, {
  value: 14,
  label: '跑步机'
}, {
  value: 15,
  label: '呼啦圈'
}, {
  value: 20,
  label: '力量站'
}];
var dict = {
  auditStatus: auditStatus,
  liveStatus: liveStatus,
  initCourseCates: initCourseCates,
  // 大类
  allCourseCates: [],
  // 大类下面的子类,后端返回
  courseCates: courseCates,
  // 大类
  initCourseType: initCourseType,
  initCourseType2: initCourseType2,
  actionParts: actionParts,
  actionTypesMap: actionTypesMap,
  gender: gender,
  showOptions: showOptions,
  courseDifficultyLevels: courseDifficultyLevels,
  hasOptions: hasOptions,
  actionTypes: actionTypes,
  expressStatus: expressStatus,
  //物流字典
  payOptions: payOptions,
  difficultyLevels: difficultyLevels,
  difficultyLevelsMap: difficultyLevelsMap,
  courseTypes: [{
    value: 2,
    label: '视频类'
  }, {
    value: 1,
    label: '动作类'
  }],
  operationType: operationType,
  // equipments: ['弹力带', '跑步机', '椭圆机', '哑铃', '杠铃', '史密斯架', 'TRX'],
  equipments: ['哑铃', '壶铃', '战绳', '健身球', '跳绳', '立式拳击器', '弹力带', '泡沫轴', '筋膜枪', '健腹轮', '甩脂机', '仰卧板', '踏步机', '俯卧撑架'],
  transferStatus: ['未知', '待充值', '成功', '失败'],
  orderStatus: orderStatus,
  equipmentType: equipmentType
};

function setCateData(res) {
  res.map(function (item) {
    item.value = item.id;
    item.label = item.name;
    item.subList && item.subList.length && (item.children = setCateData(item.subList));
  });
  return res;
}

export default {
  namespaced: true,
  state: _objectSpread({}, dict),
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    courseCatesSet: function courseCatesSet(state, res) {
      // store 赋值
      var courseCates = {};
      var initCourseCates = [];
      res.map(function (item) {
        initCourseCates.push({
          name: item.name,
          key: item.id
        });
      });
      res = setCateData(res);
      state.allCourseCates = res;
      state.initCourseCates = initCourseCates;
      initCourseCates.map(function (item) {
        courseCates[item.key] = item.name;
      });
      state.courseCates = courseCates;
    }
  }
};