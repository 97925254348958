var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v("投票活动详情")]),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "180px" } },
        [
          _c("el-form-item", { attrs: { label: "投票标题" } }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.form.voteTitle) } })
          ]),
          _c("el-form-item", { attrs: { label: "投票活动介绍" } }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.contentHtml(_vm.form.voteIntro))
              }
            })
          ]),
          _c("el-form-item", { attrs: { label: "活动投票封面" } }, [
            _c(
              "div",
              { staticClass: "upload-img-cell" },
              [
                _c("el-image", {
                  attrs: {
                    fit: "contain",
                    src: _vm.form.coverUrl,
                    "preview-src-list": [_vm.form.coverUrl]
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "投票选项" } },
            _vm._l(_vm.form.options, function(e, i) {
              return _c(
                "el-card",
                {
                  key: i,
                  staticStyle: {
                    border: "1px solid #c0c0c0",
                    width: "100%",
                    height: "100%",
                    padding: "20px"
                  }
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("选项" + _vm._s(i + 1) + "：")])
                  ]),
                  _c("el-row", [
                    _vm._v("选项排序："),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(e.optionIndex) }
                    })
                  ]),
                  _c("el-row", [
                    _vm._v("选项标题："),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(e.optionTitle) }
                    })
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("span", [_vm._v("选项图片：")]),
                      _c(
                        "div",
                        { staticClass: "upload-img-cell" },
                        [
                          _c(
                            "el-image",
                            {
                              attrs: {
                                fit: "contain",
                                src: e.optionImage,
                                "preview-src-list": [e.optionImage]
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error"
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px"
                                    },
                                    attrs: { src: _vm.uploadImg }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("span", [_vm._v("排名图片：")]),
                      _c(
                        "div",
                        { staticClass: "upload-img-cell" },
                        [
                          _c(
                            "el-image",
                            {
                              attrs: {
                                fit: "contain",
                                src: e.rankImage,
                                "preview-src-list": [e.rankImage]
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error"
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px"
                                    },
                                    attrs: { src: _vm.uploadImg }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-row", { attrs: { type: "flex" } }, [
                    _c("span", { staticStyle: { width: "80px" } }, [
                      _vm._v("图文介绍：")
                    ]),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(e.optionIntro) }
                    })
                  ])
                ],
                1
              )
            }),
            1
          ),
          _c("el-form-item", { attrs: { label: "每人每天可免费投多少票" } }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.form.freeVoteCount) }
            })
          ]),
          _c("el-form-item", { attrs: { label: "投票截止时间" } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.form.startTime) + " 至 " + _vm._s(_vm.form.endTime)
              )
            ])
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }