import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  data: function data() {
    return {
      prop1: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/databig', ['getCenterTab'])), {}, {
    tabData: function tabData() {
      return this.getCenterTab;
    }
  })
};