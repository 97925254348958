/**
 * 商城设置
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * @description 商城设置 - 获取设置
     */
    MALL_GET_SETTING: function MALL_GET_SETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/getSetting',
        data: data
      });
    },

    /**
     * @description 商城设置 - 保存设置
     */
    MALL_SAVE_SETTING: function MALL_SAVE_SETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/saveSetting',
        data: data
      });
    },

    /**
     * @description 轮播图列表
     */
    STORE_MALL_BANNER: function STORE_MALL_BANNER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/banners',
        data: data
      });
    },

    /**
     * @description 保存轮播图
     */
    STORE_MALL_SAVE_BANNER: function STORE_MALL_SAVE_BANNER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/saveBanner',
        data: data
      });
    },

    /**
     * @description 删除轮播图
     */
    STORE_MALL_DELETE_BANNER: function STORE_MALL_DELETE_BANNER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/deleteBanner',
        data: data
      });
    },

    /**
     * @description 获取商城优选设置
     */
    STORE_MALL_GET_CHOSEN_SETTING: function STORE_MALL_GET_CHOSEN_SETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/getChosenSetting',
        data: data
      });
    },

    /**
     * @description 保存商城优选设置
     */
    STORE_MALL_SAVE_CHOSEN_SETTING: function STORE_MALL_SAVE_CHOSEN_SETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/saveChosenSetting',
        data: data
      });
    },

    /**
    * @description 营销页面列表
    */
    STORE_MALL_marketing_Goods: function STORE_MALL_marketing_Goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/marketingGoods',
        data: data
      });
    },

    /**
    * @description 保存营销页面
    */
    STORE_MALL_SAVE_Marketing_Goods: function STORE_MALL_SAVE_Marketing_Goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/mall/saveMarketingGoods',
        data: data
      });
    }
  };
});