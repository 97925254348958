var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v("纠纷详情" + _vm._s(_vm.status == "finish" ? "-已处理" : ""))
      ]),
      _c(
        "el-card",
        [
          _c(
            "base-table",
            { attrs: { data: [_vm.record], pagination: false, border: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "订单号", prop: "orderNo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.orderType == 2
                          ? _c(
                              "el-button",
                              { attrs: { type: "text" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/order-detail",
                                        query: { orderId: scope.row.orderId }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.orderNo))]
                                )
                              ],
                              1
                            )
                          : _c(
                              "el-button",
                              { attrs: { type: "text" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/course-order-detail",
                                        query: {
                                          trainingOrderId: scope.row.orderId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.orderNo))]
                                )
                              ],
                              1
                            )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "申请时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "申请用户类型", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.userType,
                                  _vm.userTypes
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "申请人", prop: "nickname" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/user-detail",
                                    query: { userId: scope.row.userId }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.nickname))]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "手机号码", prop: "phoneNumber" }
              }),
              _c("el-table-column", {
                attrs: { label: "处理状态", prop: "dealStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.dealStatus,
                                  _vm.dealStatus
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-form",
            { staticClass: "mt-20" },
            [
              _c("el-form-item", { attrs: { label: "纠纷描述", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.record.disputeDescription) + " ")
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "凭证图片" } },
                [
                  [
                    _c("base-gallery", {
                      attrs: {
                        srcList: _vm.record.disputeFiles.map(function(item) {
                          return item.filepath
                        }),
                        imgWidth: "100px"
                      }
                    })
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "mt-20" },
        [
          _c(
            "el-form",
            { attrs: { form: _vm.form } },
            [
              _vm.isNotDeal
                ? _c(
                    "el-form-item",
                    { attrs: { label: "处理描述" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "50vw" },
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.form.dealDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dealDescription", $$v)
                          },
                          expression: "form.dealDescription"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isNotDeal
                ? _c(
                    "el-form-item",
                    { attrs: { label: "附件" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.openUploadModal()
                            }
                          }
                        },
                        [_vm._v("上传附件")]
                      ),
                      _c("el-upload", {
                        attrs: {
                          action: "",
                          multiple: "",
                          "file-list": _vm.uploadFileUrls
                        }
                      }),
                      _c("base-upload", {
                        ref: "upload",
                        attrs: { type: "other" },
                        on: { confirm: _vm.handleUpload }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.istDealed
                ? _c("el-form-item", { attrs: { label: "处理描述" } }, [
                    _vm._v(" " + _vm._s(_vm.record.dealDescription) + " ")
                  ])
                : _vm._e(),
              _vm.istDealed
                ? _c(
                    "el-form-item",
                    { attrs: { label: "附件" } },
                    _vm._l(this.record.dealFiles, function(item, index) {
                      return _c(
                        "p",
                        { key: index },
                        [
                          _vm._v(" " + _vm._s(item.filepath) + " "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.downloadFiles(
                                    item.filepath,
                                    item.filepath
                                  )
                                }
                              }
                            },
                            [_vm._v("下载附件")]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isNotDeal
        ? _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }