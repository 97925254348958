import "core-js/modules/es.array.join";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  meta: {
    title: '投票结果',
    auth: true,
    authorityValue: 'vote-management'
  },
  name: 'vote-result',
  data: function data() {
    var _this = this;

    return {
      extraData: {},
      request: {
        api: 'vote_userVotes',
        params: {
          voteId: this.$route.query.voteId
        },
        parser: function parser(res) {
          _this.extraData = res.voteCount;
          return {
            records: res.list,
            pageIndex: 1,
            pageSize: 10
          };
        }
      }
    };
  },
  filters: {
    optionIndexList: function optionIndexList(val) {
      return val.join(',');
    }
  },
  methods: {
    handleSearch: function handleSearch() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      console.log('values:', values);
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData(reset);
    }
  }
};