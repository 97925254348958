//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TabWatingAudit from './TabWatingAudit';
import TabAll from './TabAll';
export default {
  meta: {
    title: '资质证书审核',
    auth: true,
    authorityValue: 'coach-cert-audit'
  },
  name: 'qualification-certificate-audit',
  components: {
    TabWatingAudit: TabWatingAudit,
    TabAll: TabAll
  },
  data: function data() {
    return {
      activeName: '1'
    };
  }
};