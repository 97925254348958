var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("邀请奖励终审")]),
      _c(
        "page-content",
        [
          _c("base-radio-group", {
            attrs: { options: _vm.options, childrenType: "button" },
            on: {
              change: function($event) {
                return _vm.handleSearch(_vm.form, true)
              }
            },
            model: {
              value: _vm.auditStatusTabs,
              callback: function($$v) {
                _vm.auditStatusTabs = $$v
              },
              expression: "auditStatusTabs"
            }
          }),
          _c(
            "base-form",
            {
              staticClass: "mt-20",
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch,
                onReset: _vm.onReset
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "邀请人用户" } },
                [
                  _c("el-input", {
                    staticStyle: { wdith: "300px" },
                    attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "受邀人用户" } },
                [
                  _c("el-input", {
                    staticStyle: { wdith: "300px" },
                    attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                    model: {
                      value: _vm.form.newUserNickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newUserNickname", $$v)
                      },
                      expression: "form.newUserNickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邀请时间段" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.inviteTimeRange.start,
                      max: _vm.form.inviteTimeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.inviteTimeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.inviteTimeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "终审时间段" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.auditTimeRange.start,
                      max: _vm.form.auditTimeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.auditTimeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.auditTimeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm.auditStatusTabs !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c("base-select", {
                        attrs: { options: _vm.invoiceTypes },
                        model: {
                          value: _vm.form.auditStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "auditStatus", $$v)
                          },
                          expression: "form.auditStatus"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "TableTitleBar",
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.multipleSelection.length == 0 },
                  on: { click: _vm.allHandle }
                },
                [_vm._v("批量操作")]
              )
            ],
            1
          ),
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: { request: _vm.request },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { label: "邀请人用户昵称", prop: "nickname" }
              }),
              _c("el-table-column", {
                attrs: { label: "用户ID", prop: "userNo" }
              }),
              _c("el-table-column", {
                attrs: { label: "用户手机号码", prop: "phoneNumber" }
              }),
              _c("el-table-column", {
                attrs: { label: "邀请注册人昵称", prop: "newUserNickname" }
              }),
              _c("el-table-column", {
                attrs: { label: "活动名称", prop: "activityName" }
              }),
              _c("el-table-column", {
                attrs: { label: "邀请奖励金额", prop: "rewardAmount" }
              }),
              _c("el-table-column", {
                attrs: { label: "邀请时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "auditStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("ftValue2label")(
                              row.auditStatus,
                              _vm.invoiceTypes
                            )
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm.auditStatusTabs !== 1
                ? _c("el-table-column", {
                    attrs: {
                      label: "审核时间",
                      prop: "auditTime",
                      width: "200"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.auditStatus !== 1
                                ? _c("span", [_vm._v(_vm._s(row.auditTime))])
                                : _c("span", [_vm._v("-")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1204340299
                    )
                  })
                : _vm._e(),
              _vm.auditStatusTabs !== 1
                ? _c("el-table-column", {
                    attrs: {
                      label: "终审操作账号名称",
                      prop: "adminName",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.auditStatus == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleReview(row)
                                  }
                                }
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        row.auditStatus === 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRefuse(row)
                                  }
                                }
                              },
                              [_vm._v("查看拒绝理由")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "base-dialog",
            {
              attrs: {
                width: "500px",
                fullTitle: "审核",
                rules: _vm.rules,
                visible: _vm.dialogVisible,
                formData: _vm.dialogFormData,
                isEdit: _vm.isEdit
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                "update:formData": function($event) {
                  _vm.dialogFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.dialogFormData = $event
                },
                confirm: _vm.callAdminSaveDepartment
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否通过", prop: "auditStatus" } },
                [
                  _c("base-radio-group", {
                    attrs: { options: _vm.invoiceTypes2 },
                    model: {
                      value: _vm.dialogFormData.auditStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "auditStatus", $$v)
                      },
                      expression: "dialogFormData.auditStatus"
                    }
                  })
                ],
                1
              ),
              _vm.dialogFormData.auditStatus == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拒绝理由", prop: "rejectReason" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.dialogFormData.rejectReason,
                          callback: function($$v) {
                            _vm.$set(_vm.dialogFormData, "rejectReason", $$v)
                          },
                          expression: "dialogFormData.rejectReason"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }