import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import city from './city.json';
city.unshift({
  label: '全部省',
  value: 100000,
  children: [{
    label: '全部市',
    value: 100000
  }]
});
export default {
  data: function data() {
    return {
      show: false,
      show2: false,
      proData: city,
      activePro: 100000,
      activeCity: 100000,
      proLabel: '全部省',
      cityLabel: '全部市'
    };
  },
  computed: {
    cityDataComp: function cityDataComp() {
      var _this = this;

      var data = [];
      var item = this.proData.find(function (v) {
        return v.value == _this.activePro;
      });

      if (this.activePro == 100000) {
        data = item.children.map(function (v) {
          return v.children;
        })[0];
      } else {
        data = item.children;
      }

      return data;
    }
  },
  methods: {
    clickItem: function clickItem(l, type) {
      this[type] = l.label;

      if (type == 'proLabel') {
        if (this.activePro == l.value) return;
        this.activePro = l.value;
        this.show = false;
        this.activeCity = 100000;
        this.cityLabel = '全部市';
        this.$emit('change', {
          proCode: l.value == 100000 ? '' : l.value
        });
      } else {
        if (this.activeCity == l.value) return;
        this.activeCity = l.value;
        this.show2 = false;
        this.$emit('change', {
          proCode: this.activePro == 100000 ? '' : this.activePro,
          cityCode: l.value == 100000 ? '' : l.value
        });
      }
    }
  }
};