var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box flex-center", staticStyle: { flex: "1" } },
    [
      _c("div", { staticClass: "label" }, [_vm._v("要炼资讯平台")]),
      _c("div", { staticClass: "time" }, [_c("HeaderTime")], 1)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }