var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-alert", {
        staticClass: "d2-mb",
        attrs: {
          title:
            "订单为未完成状态时，存在订单取消及退款的可能性，应结金额仅供参考。请以最终实际结算金额及流水为准",
          type: "warning",
          closable: false
        }
      }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "门店" } },
            [
              _c("base-select", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.gymId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gymId", $$v)
                  },
                  expression: "form.gymId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "区域" } },
            [
              _c("CitySelect", {
                attrs: {
                  province: _vm.form.location.province,
                  city: _vm.form.location.city,
                  district: _vm.form.location.district
                },
                on: {
                  "update:province": function($event) {
                    return _vm.$set(_vm.form.location, "province", $event)
                  },
                  "update:city": function($event) {
                    return _vm.$set(_vm.form.location, "city", $event)
                  },
                  "update:district": function($event) {
                    return _vm.$set(_vm.form.location, "district", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.orderTimeRange.start,
                  max: _vm.form.orderTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.trainingOrderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "trainingOrderNo", $$v)
                  },
                  expression: "form.trainingOrderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类型" } },
            [
              _c("SourseSubCateSelect", {
                staticStyle: { width: "300px" },
                attrs: {
                  subcategoryId: _vm.form.subcategoryId,
                  categoryId: _vm.form.categoryId,
                  noShow: true
                },
                on: {
                  onLoadSubcate: _vm.onLoadSubcate,
                  "update:subcategoryId": function($event) {
                    return _vm.$set(_vm.form, "subcategoryId", $event)
                  },
                  "update:subcategory-id": function($event) {
                    return _vm.$set(_vm.form, "subcategoryId", $event)
                  },
                  "update:categoryId": function($event) {
                    return _vm.$set(_vm.form, "categoryId", $event)
                  },
                  "update:category-id": function($event) {
                    return _vm.$set(_vm.form, "categoryId", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.orderStatus },
                model: {
                  value: _vm.form.orderStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderStatus", $$v)
                  },
                  expression: "form.orderStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.settlementStatus },
                model: {
                  value: _vm.form.settlementStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "settlementStatus", $$v)
                  },
                  expression: "form.settlementStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.settleTimeRange.start,
                  max: _vm.form.settleTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.settleTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.settleTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "查询合计" } }, [
        _c("span", { staticClass: "space" }, [
          _vm._v("收入：￥" + _vm._s(_vm.extraData.gymIncomeAmount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("笔数： " + _vm._s(_vm.extraData.orderCount))
        ])
      ]),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", prop: "trainingOrderNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "订单状态", prop: "orderStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.orderStatus,
                            _vm.orderStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "下单时间", prop: "orderTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "课程类型", prop: "categoryName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      _vm._s(
                        row.categoryName + "（" + row.subcategoryName + "）"
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "门店名称", prop: "gymName" }
          }),
          _c("el-table-column", {
            attrs: { label: "门店收入(元)", prop: "gymIncomeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "结算状态", prop: "settlementStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.settlementStatus,
                            _vm.settlementStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "结算时间", prop: "completeTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return row.settlementStatus == 2
                      ? [
                          row.billStatus == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleFrozen(row)
                                    }
                                  }
                                },
                                [_vm._v("冻结")]
                              )
                            : _vm._e(),
                          row.billStatus == 3
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleFrozen(row)
                                    }
                                  }
                                },
                                [_vm._v("解冻")]
                              )
                            : _vm._e()
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "before-close": _vm.onChancel,
            "close-on-click-modal": false,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm.dialogVisible
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.dialogFormData,
                    rules: _vm.formRules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "理由", prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入理由" },
                        model: {
                          value: _vm.dialogFormData.reason,
                          callback: function($$v) {
                            _vm.$set(_vm.dialogFormData, "reason", $$v)
                          },
                          expression: "dialogFormData.reason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.onChancel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.callFeedback } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }