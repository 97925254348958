//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['orderDetail'],
  data: function data() {
    return {
      steps: [{
        id: 1,
        label: '提交订单',
        desc: '2017-07-19 15:43:23',
        stutas: 1
      }, {
        id: 2,
        label: '支付订单',
        desc: '未支付',
        stutas: 1
      }, {
        id: 3,
        label: '商品出库',
        desc: '',
        stutas: 1
      }, {
        id: 4,
        label: '确认收货',
        desc: '',
        stutas: 1
      }, {
        id: 5,
        label: '完成',
        desc: '',
        stutas: 1
      }]
    };
  },
  computed: {
    active: function active() {
      return this.orderDetail.orderStatus;
    }
  },
  watch: {
    orderDetail: {
      handler: function handler(val) {
        for (var index = 0; index < val.orderStatus; index++) {
          this.steps[index].stutas = 0;
        }
      },
      immediate: true
    }
  },
  methods: {
    desc: function desc(index) {
      var desc = '';
      var i = this.orderDetail.orderStatus;

      if (index == 0) {
        desc = this.orderDetail.orderTime;
      }

      if (index == 1) {
        desc = index < i ? '已支付' : '未支付';
      }

      if (index == 2) {
        desc = index < i ? '已出库' : '待出库';
      }

      if (index == 3) {
        desc = index < i ? '已收货' : '待收货';
      }

      if (index == 4) {
        desc = index < i ? '已完成' : '待评价';
      }

      return desc;
    }
  }
};