var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("查看门店（管理后台）")]),
      _c(
        "div",
        [
          _c("span", [_vm._v("场地编号：" + _vm._s(_vm.record.gymNo))]),
          _c(
            "el-button",
            { staticClass: "d2-pl", attrs: { type: "text" } },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/uncertified-store-add",
                      query: { gymId: _vm.gymId }
                    }
                  }
                },
                [_vm._v("编辑门店")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("h4", [_vm._v("门店设置")]),
      _c("form-grid", {
        attrs: { options: _vm.options4, column: 1, direction: "horizontal" }
      }),
      _c("h4", [_vm._v("门店与平台的联系信息")]),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _vm._l(_vm.record.contactList, function(l, i) {
            return [
              _c("el-descriptions-item", { key: i, attrs: { label: "姓名" } }, [
                _vm._v(_vm._s(l.name))
              ]),
              _c("el-descriptions-item", { key: i, attrs: { label: "职务" } }, [
                _vm._v(_vm._s(l.position))
              ]),
              _c("el-descriptions-item", { key: i, attrs: { label: "电话" } }, [
                _vm._v(_vm._s(l.phone))
              ])
            ]
          })
        ],
        2
      ),
      _c("h4", [_vm._v("附件信息")]),
      _vm._l(_vm.record.extraFiles, function(item, index) {
        return _c(
          "el-row",
          { key: index, attrs: { type: "flex" } },
          [
            _c("el-col", [_vm._v("附件")]),
            _c("el-col", [_vm._v(_vm._s(item.filename))]),
            _c(
              "el-col",
              [
                item.filename
                  ? _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.downloadFile(
                              item.filepath,
                              item.filepath
                            )
                          }
                        }
                      },
                      [_vm._v("下载附件")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          on: {
            click: function($event) {
              return _vm.goBack()
            }
          }
        },
        [_vm._v("返回")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }