var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("救助事件管理")]),
      _c(
        "page-content",
        [
          _c(
            "TableTitleBar",
            { staticStyle: { "margin-top": "0" }, attrs: { title: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                [_vm._v("新增救助事件")]
              )
            ],
            1
          ),
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "救助事件标题" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "输入救助事件标题关键搜索" },
                    model: {
                      value: _vm.form.eventTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "eventTitle", $$v)
                      },
                      expression: "form.eventTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "救助事件分类" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.typeList },
                    model: {
                      value: _vm.form.typeId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "typeId", $$v)
                      },
                      expression: "form.typeId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "浏览人数" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.browseRange.start,
                      max: _vm.form.browseRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(_vm.form.browseRange, "start", $event)
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.browseRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "排序", prop: "eventSort" }
              }),
              _c("el-table-column", {
                attrs: { label: "救助事件标题", prop: "eventTitle" }
              }),
              _c("el-table-column", {
                attrs: { label: "所属分类", prop: "typeName" }
              }),
              _c("el-table-column", {
                attrs: { label: "救助事件浏览人数", prop: "eventBrowse" }
              }),
              _c("el-table-column", {
                attrs: { label: "APP是否显示", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: scope.row.eventShow,
                            callback: function($$v) {
                              _vm.$set(scope.row, "eventShow", $$v)
                            },
                            expression: "scope.row.eventShow"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/fist-aid-event-detail",
                                    query: { eventId: scope.row.eventId }
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/fist-aid-event-add",
                                    query: { eventId: scope.row.eventId }
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onDel(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }