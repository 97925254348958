var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-descriptions", { attrs: { column: 1, title: "店铺的联系信息" } }),
      _c(
        "div",
        { attrs: { flex: "main" } },
        _vm._l(_vm.data.contactList, function(l, i) {
          return _c(
            "el-descriptions",
            { key: i, attrs: { column: 1 } },
            [
              _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                _vm._v(" " + _vm._s(l.name) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "职务" } }, [
                _vm._v(" " + _vm._s(l.position) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "电话" } }, [
                _vm._v(" " + _vm._s(l.phone) + " ")
              ])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }