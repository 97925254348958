var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("举报管理")]),
      _c(
        "page-content",
        [
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "举报人" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "举报人昵称/用户ID/手机号码" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "举报对象" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "举报对象昵称/用户ID/手机号码" },
                    model: {
                      value: _vm.form.targetUserNickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "targetUserNickname", $$v)
                      },
                      expression: "form.targetUserNickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "举报类型" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.targetTypes },
                    model: {
                      value: _vm.form.targetType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "targetType", $$v)
                      },
                      expression: "form.targetType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "举报状态" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.dealResult },
                    model: {
                      value: _vm.form.dealResult,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dealResult", $$v)
                      },
                      expression: "form.dealResult"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理结果" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.blockTypes },
                    model: {
                      value: _vm.form.blockType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "blockType", $$v)
                      },
                      expression: "form.blockType"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "编号", prop: "reportNo", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "举报类型", prop: "targetType" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.targetType,
                                  _vm.targetTypes
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "举报原因", prop: "reportReason" }
              }),
              _c("el-table-column", {
                attrs: { label: "举报人", prop: "nickname" }
              }),
              _c("el-table-column", {
                attrs: { label: "举报时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "举报对象", prop: "targetUserNickname" }
              }),
              _c("el-table-column", {
                attrs: { label: "举报状态", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.dealResult,
                                  _vm.dealResultTable
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "处理结果", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.blockType,
                                  _vm.blockTypes
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/tip-off-detail",
                                    query: {
                                      reportId: scope.row.reportId,
                                      targetType: scope.row.targetType
                                    }
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }