export default [{
  title: '课程管理',
  iconSvg: '课程管理',
  authorityValue: 'course-management',
  children: [{
    path: '/course-labels',
    title: '课程标签管理',
    authorityValue: 'course-label-manage'
  }, {
    path: '/action-list',
    title: '官方动作管理',
    authorityValue: 'official-action-manage'
  }, {
    path: '/course-list',
    title: '官方课程管理',
    authorityValue: 'official-course-manage'
  }, {
    path: '/course-newer-zone',
    title: '新课专区管理',
    authorityValue: 'new-course-manage'
  }, {
    path: '/course-guest-zone',
    title: '游客课程展示',
    authorityValue: 'visitor-course-manage'
  }, {
    title: '用户自制课程',
    authorityValue: 'diy-course-manage',
    children: [{
      path: '/course-diy-list',
      title: '自制课程查询',
      authorityValue: 'diy-course-list'
    }, {
      path: '/course-diy-audit',
      title: '自制课程审核',
      authorityValue: 'diy-course-audit'
    }, {
      path: '/course-diy-skill',
      title: '课程制作技巧',
      authorityValue: 'diy-course-guide'
    }]
  }, {
    path: '/course-diy-banner',
    title: '创作中心轮播图',
    authorityValue: 'diy-banner'
  }]
}];