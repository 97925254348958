var teachingTypes = [{
  label: '全部',
  value: ''
}, {
  label: '线下私教课',
  value: 1
}, {
  label: '直播私教课',
  value: 2
}];
export { teachingTypes };