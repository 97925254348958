// import { find, assign } from 'lodash'
// const users = [
//   { username: 'admin', password: 'admin', uuid: 'admin-uuid', name: 'Admin' },
//   { username: 'editor', password: 'editor', uuid: 'editor-uuid', name: 'Editor' },
//   { username: 'user1', password: 'user1', uuid: 'user1-uuid', name: 'User1' }
// ]
export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    USER_LOGIN: function USER_LOGIN() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // // 模拟数据
      // mock
      //   .onAny('/login')
      //   .reply(config => {
      //     const user = find(users, tools.parse(config.data))
      //     return user
      //       ? tools.responseSuccess(assign({}, user, { token: faker.random.uuid() }))
      //       : tools.responseError({}, '账号或密码不正确')
      //   })
      // 接口请求
      return request({
        url: 'admin/login/account',
        data: data
      });
    },

    /**
     * @description 找回密码
     */
    user_findPassword: function user_findPassword() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/admin/findPassword',
        data: data
      });
    },

    /**
     * @description 找回密码 验证码
     */
    user_findPasswordVerifyCode: function user_findPasswordVerifyCode() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/admin/findPasswordVerifyCode',
        data: data
      });
    },

    /**
     * @description 修改密码
     */
    user_updatePassword: function user_updatePassword() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/admin/updatePassword',
        data: data
      });
    },

    /**
     *用户列表
     *
     */
    USER_LIST: function USER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/user/users',
        data: data
      });
    },

    /**
     *用户详情
     *
     */
    USER_USERDETAILS: function USER_USERDETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/user/userDetails',
        data: data
      });
    },

    /**
     * 课程、直播统计
     *
     */
    user_courseData: function user_courseData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'admin/user/courseData',
        data: data
      });
    },

    /**
     * 用户详情 - 充值统计
     */
    user_rechargeData: function user_rechargeData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/rechargeData',
        data: data
      });
    },

    /**
     * 用户详情 - 私教课订单统计
     */
    user_trainingOrderData: function user_trainingOrderData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/trainingOrderData',
        data: data
      });
    },

    /**
     * 用户新增和活跃统计
     */
    user_userTrendsData: function user_userTrendsData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/userTrendsData',
        data: data
      });
    },

    /**
     * 用户详情- 钱包数据
     */
    user_wallet: function user_wallet() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/wallet',
        data: data
      });
    },

    /**
     * 用户详情 - 商品订单统计
     */
    user_mallOrderData: function user_mallOrderData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/mallOrderData',
        data: data
      });
    },

    /**
     * 用户详情 - PLUS会员订单统计
     */
    user_plusData: function user_plusData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/plusData',
        data: data
      });
    },

    /**
     * 未认证教练
     * */
    COACH_UNCERT: function COACH_UNCERT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // // 模拟数据
      // mock
      //   .onAny('/login')
      //   .reply(config => {
      //     const user = find(users, tools.parse(config.data))
      //     return user
      //       ? tools.responseSuccess(assign({}, user, { token: faker.random.uuid() }))
      //       : tools.responseError({}, '账号或密码不正确')
      //   })
      // 接口请求
      return request({
        url: 'admin/coach/nonCertifiedCoachs',
        data: data
      });
    },

    /**
     * @description 获取IM签名
     */
    imUserSign: function imUserSign() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 接口请求
      return request({
        url: 'admin/index/imUserSign',
        data: data
      });
    },

    /**
     * 用户留存统计 - 列表
    */
    user_userRetainedList: function user_userRetainedList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/userRetainedList',
        data: data
      });
    },

    /**
     * 用户留存统计 - 图表数据
    */
    user_userRetainedData: function user_userRetainedData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/userRetainedData',
        data: data
      });
    },

    /**
     * 教练 交易数据
    */
    user_coachAnalysis: function user_coachAnalysis() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/coachAnalysis',
        data: data
      });
    },

    /**
     * 教练 评价数据
    */
    user_evaluateAnalysis: function user_evaluateAnalysis() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/evaluateAnalysis',
        data: data
      });
    },

    /**
     * 推送APP消息
    */
    user_pushMessage: function user_pushMessage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/pushMessage',
        data: data
      });
    },
    coachShowSetting: function coachShowSetting() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/coachShowSetting',
        data: data
      });
    },
    saveCoachShowSetting: function saveCoachShowSetting() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/saveCoachShowSetting',
        data: data
      });
    }
  };
});