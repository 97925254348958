var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c("el-card", { attrs: { shadow: "never" } }, [
        _c(
          "div",
          {
            staticClass: "titleBox",
            staticStyle: { "margin-top": "6px", "margin-bottom": "39px" }
          },
          [
            _c("div", { staticClass: "block" }),
            _c("div", { staticClass: "text" }, [_vm._v("待办事项")])
          ]
        ),
        _c(
          "div",
          { staticClass: "waitBox" },
          [
            _c(
              "el-row",
              { staticStyle: { margin: "0 40px" }, attrs: { gutter: 20 } },
              [
                _c(
                  "div",
                  { staticClass: "card d2-pt" },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("教练")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("coach-license-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push(
                                            "/professional-qualification-audit"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("教练职业资质待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.coachAuthAudits))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("coach-cert-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "qualification-certificate-audit"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("资质证书待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.certAuthAudits))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("coach-live-audit-wait")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "coach-waiting-audit-list"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("教练直播认证待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.coachLiveAuthAudits)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("课程")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("diy-course-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("course-diy-audit")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("用户自制课程待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.diyAudits))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("live-unaudited-list")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("course-live-wait")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("教练开播待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.trainingLiveAudits)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("门店")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("gym-settled-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "store-entry-audit"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("门店入驻待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.gymApplyAudits))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("gym-license-update-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "shopp-store-review"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("营业执照更新待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.gymLicenseUpdateAudit)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("gym-legal-id-update-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "shopp-store-legal"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("法人身份信息更新待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.gymLegalPersonUpdateAudit
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("gym-taxpayer-update-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("shopp-store-tax")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("税务信息更新待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.gymTaxpayerUpdateAudit
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("举报")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("report-manage")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("tip-off-list")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("举报待处理")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.reports))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("提现")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("store-transfers")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "store-withdrawal-from-mall-list"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("商城店铺提现待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.gymTransferAudits)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("store-transfers")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "store-withdrawal-from-mall-list"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("商城店铺提现待打款")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.storeTransferTransfers
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("gym-transfers")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "shop-withdrawal-list"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("门店提现待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.storeTransferAudits)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("gym-transfers")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute(
                                            "shop-withdrawal-list"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("门店提现待打款")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.gymTransferTransfers)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("纠纷")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("dispute-management")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("dispute-list")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("纠纷待处理")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.disputes))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("商城")]),
                        _c(
                          "div",
                          { staticClass: "ul" },
                          [
                            _vm.isShow("store-settled-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("store-entry")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("店铺入驻待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(_vm.todo.storeApplyAudits)
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("store-license-update-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("store-review")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("营业执照更新待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.storeLicenseUpdateAudit
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("store-legal-id-update-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("store-legal")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("法人身份信息更新待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.storeLegalPersonUpdateAudit
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("store-taxpayer-update-audit")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("store-tax")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("税务信息更新待审核")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.storeTaxpayerUpdateAudit
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isShow("invoice-manage")
                              ? _c("el-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "li",
                                      on: {
                                        click: function($event) {
                                          return _vm.goRoute("invoice-list")
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "dot" }),
                                      _c("div", { staticClass: "msg" }, [
                                        _vm._v("待开发票")
                                      ]),
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(_vm._s(_vm.todo.invoices))
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "titleBox",
            staticStyle: { "margin-top": "40px", "margin-bottom": "33px" }
          },
          [
            _c("div", { staticClass: "block" }),
            _c("div", { staticClass: "text" }, [_vm._v("今日实况")]),
            _c("div", { staticClass: "updateTime" }, [
              _vm._v(
                "更新时间：" +
                  _vm._s(_vm.YLdateFormat(_vm.realTime.nowTime, "hh:mm:ss"))
              )
            ]),
            _c("el-image", {
              staticClass: "refresh",
              staticStyle: { width: "18px", height: "20px" },
              attrs: { src: _vm.updateImg },
              on: { click: _vm.getRealTimeData }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "todayBox" },
          [
            _vm.realTimeMap.length > 0
              ? _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", gutter: 20 }
                  },
                  _vm._l(_vm.realTimeMap, function(item) {
                    return _c(
                      "el-col",
                      { key: item.value, staticStyle: {}, attrs: { span: 4 } },
                      [
                        _c("div", { staticClass: "tb_box" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.realTime[item.value]))
                          ]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", justify: "center" }
                  },
                  [_vm._v("暂无访问权限")]
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "todayBox", staticStyle: { "margin-top": "31px" } },
          [
            _vm.realTimeMap2.length > 0
              ? _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", gutter: 20 }
                  },
                  _vm._l(_vm.realTimeMap2, function(item) {
                    return _c(
                      "el-col",
                      { key: item.value, staticStyle: {}, attrs: { span: 4 } },
                      [
                        _c("div", { staticClass: "tb_box" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.realTime[item.value]))
                          ]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", justify: "center" }
                  },
                  [_vm._v("暂无访问权限")]
                )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "titleBox",
            staticStyle: { "margin-top": "40px", "margin-bottom": "33px" }
          },
          [
            _c("div", { staticClass: "block" }),
            _c("div", { staticClass: "text" }, [_vm._v("用户概况")])
          ]
        ),
        _c(
          "div",
          { staticClass: "userBox" },
          [
            _c(
              "el-row",
              { attrs: { type: "flex", gutter: 46 } },
              [
                _c("el-col", [
                  _c("div", { staticClass: "u_one" }, [
                    _c("div", { staticClass: "u_left" }, [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.user.total))
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("累计用户")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "u_right" },
                      [
                        _c("el-image", {
                          staticClass: "imgBox",
                          attrs: { src: _vm.imgOne }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("el-col", [
                  _c("div", { staticClass: "u_one" }, [
                    _c("div", { staticClass: "u_left" }, [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.user.todayRegister))
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("今日新增")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "u_right" },
                      [
                        _c("el-image", {
                          staticClass: "imgBox",
                          attrs: { src: _vm.imgTwo }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("el-col", [
                  _c("div", { staticClass: "u_one" }, [
                    _c("div", { staticClass: "u_left" }, [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.user.todayActive))
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("今日活跃")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "u_right" },
                      [
                        _c("el-image", {
                          staticClass: "imgBox",
                          attrs: { src: _vm.imgThree }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "echartBox" }, [
          _c("div", {
            staticStyle: { height: "587px", width: "100%" },
            attrs: { id: "main-chart" }
          })
        ]),
        _c(
          "div",
          {
            staticClass: "titleBox flexBet",
            staticStyle: { "margin-top": "40px", "margin-bottom": "33px" }
          },
          [
            _c("div", { staticClass: "block" }),
            _c("div", { staticClass: "text", staticStyle: { flex: "1" } }, [
              _vm._v("app使用统计")
            ]),
            _c(
              "div",
              { staticClass: "dateBox" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.realTimeAppType,
                      callback: function($$v) {
                        _vm.realTimeAppType = $$v
                      },
                      expression: "realTimeAppType"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "date" } }, [
                      _vm._v("日")
                    ]),
                    _c("el-radio-button", { attrs: { label: "week" } }, [
                      _vm._v("周")
                    ]),
                    _c("el-radio-button", { attrs: { label: "month" } }, [
                      _vm._v("月")
                    ]),
                    _c("el-radio-button", { attrs: { label: "year" } }, [
                      _vm._v("年")
                    ])
                  ],
                  1
                ),
                _c("span", { staticStyle: { margin: "0 10px" } }),
                _c("el-date-picker", {
                  attrs: {
                    type: _vm.realTimeAppType,
                    format:
                      _vm.realTimeAppType == "week" ? "yyyy 第 WW 周" : "",
                    placeholder: "选择时间",
                    clearable: false,
                    "default-time": "00:00:00",
                    "value-format": "yyyy-MM-dd HH:mm:ss"
                  },
                  model: {
                    value: _vm.realTimeAppDate,
                    callback: function($$v) {
                      _vm.realTimeAppDate = $$v
                    },
                    expression: "realTimeAppDate"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 10px" } }),
                _c("el-button", { on: { click: _vm.getAppData } }, [
                  _vm._v("查询")
                ])
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "todayBox", staticStyle: { "margin-top": "31px" } },
          [
            _vm.realTimeMap.length > 0
              ? _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", gutter: 20 }
                  },
                  _vm._l(_vm.realTimeMap3, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, staticStyle: {}, attrs: { span: 4 } },
                      [
                        _c("div", { staticClass: "tb_box" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.realTimeApp[item.key]))
                          ]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", justify: "center" }
                  },
                  [_vm._v("暂无访问权限")]
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "todayBox", staticStyle: { "margin-top": "31px" } },
          [
            _vm.realTimeMap.length > 0
              ? _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", gutter: 20 }
                  },
                  _vm._l(_vm.realTimeMap4, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, staticStyle: {}, attrs: { span: 4 } },
                      [
                        _c("div", { staticClass: "tb_box" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.realTimeApp[item.key]))
                          ]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", justify: "center" }
                  },
                  [_vm._v("暂无访问权限")]
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "todayBox", staticStyle: { "margin-top": "31px" } },
          [
            _vm.realTimeMap.length > 0
              ? _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", gutter: 20 }
                  },
                  _vm._l(_vm.realTimeMap5, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, staticStyle: {}, attrs: { span: 4 } },
                      [
                        _c("div", { staticClass: "tb_box" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.realTimeApp[item.key]))
                          ]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", justify: "center" }
                  },
                  [_vm._v("暂无访问权限")]
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "todayBox", staticStyle: { "margin-top": "31px" } },
          [
            _vm.realTimeMap.length > 0
              ? _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", gutter: 20 }
                  },
                  _vm._l(_vm.realTimeMap6, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, staticStyle: {}, attrs: { span: 4 } },
                      [
                        _c("div", { staticClass: "tb_box" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.realTimeApp[item.key]))
                          ]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", align: "middle", justify: "center" }
                  },
                  [_vm._v("暂无访问权限")]
                )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }