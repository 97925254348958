//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PaymentRecord from './PaymentRecord.vue';
import SurrenderPendingApproval from './SurrenderPendingApproval.vue';
import ReturnedRecords from './ReturnedRecords.vue';
import RefuseReturn from './RefuseReturn.vue';
export default {
  meta: {
    title: '教练保证金',
    auth: true,
    authorityValue: 'coach-bond-list'
  },
  name: 'coach-guarantees-pay',
  components: {
    PaymentRecord: PaymentRecord,
    SurrenderPendingApproval: SurrenderPendingApproval,
    ReturnedRecords: ReturnedRecords,
    RefuseReturn: RefuseReturn
  },
  data: function data() {
    return {
      activeName: 'PaymentRecord'
    };
  },
  methods: {},
  mounted: function mounted() {}
};