//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import HiddenText from '@/components/global/base-table/HiddenText';
var initForm = {
  createTimeRange: {}
};
export default {
  meta: {
    title: '纠纷信息',
    auth: true,
    authorityValue: 'dispute-management'
  },
  name: 'dispute-list',
  components: {
    HiddenText: HiddenText
  },
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'dispute_disputes',
        params: cloneDeep(initForm)
      },
      dealStatus: [{
        label: '全部',
        value: ''
      }, {
        label: '待处理',
        value: 1
      }, {
        label: '已完成',
        value: 2
      }],
      userTypes: [{
        label: '用户',
        value: 1
      }, {
        label: '教练',
        value: 2
      }]
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};