/**
 * 直播课
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    LIVE_LIST: function LIVE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/lives',
        data: data
      });
    },
    LIVE_APPSHOW: function LIVE_APPSHOW() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/appShow',
        data: data
      });
    },
    LIVE_SAVELIVE: function LIVE_SAVELIVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/saveLive',
        data: data
      });
    },
    LIVE_DEL: function LIVE_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/delete',
        data: data
      });
    },
    LIVE_CANCEL: function LIVE_CANCEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/cancel',
        data: data
      });
    },
    LIVE_CATE: function LIVE_CATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/liveCategories',
        data: data
      });
    },
    LIVE_SAVEACTIVELIVE: function LIVE_SAVEACTIVELIVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/saveActivityLive',
        data: data
      });
    },
    LIVE_CATE_DEL: function LIVE_CATE_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/deleteLiveCategory',
        data: data
      });
    },
    LIVE_CATE_EDIT: function LIVE_CATE_EDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/saveLiveCategory',
        data: data
      });
    },
    LIVE_LIVE_DETAILS: function LIVE_LIVE_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/liveDetails',
        data: data
      });
    },
    LIVE_LIVE_UPDATE: function LIVE_LIVE_UPDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/live/updateStatus',
        data: data
      });
    }
  };
});