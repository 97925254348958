import Vue from 'vue';
import Clipboard from 'clipboard'; // 文本复制

function copySuccess(text) {
  Vue.prototype.$message({
    type: 'success',
    message: "".concat(text, " \u590D\u5236\u5230\u526A\u5207\u677F"),
    duration: 1500
  });
}

function copyFail() {
  Vue.prototype.$message({
    message: '该浏览器不支持自动复制',
    type: 'warning'
  });
}

export default function copyText(_text, e) {
  var clipboard = new Clipboard(e.target, {
    text: function text() {
      return _text;
    }
  });
  clipboard.on('success', function (e) {
    console.log('e:', e);
    copySuccess(e.text); // 释放内存

    clipboard.destroy();
  });
  clipboard.on('error', function () {
    // 不支持复制
    copyFail(); // 释放内存

    clipboard.destroy();
  }); // 解决第一次点击不生效的问题，如果没有，第一次点击会不生效

  clipboard.onClick(e);
}