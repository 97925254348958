//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  depositTimeRange: {
    start: '',
    end: ''
  },
  storeName: '',
  storeNo: '',
  companyName: '',
  type: 6
};
export default {
  data: function data() {
    var _this = this;

    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'finance_storeDepositList',
        params: cloneDeep(initForm),
        parser: function parser(res) {
          _this.extraData = res.extraData;
          return res;
        }
      },
      extraData: {}
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};