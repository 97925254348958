/**
 * 私教设置
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    COURSE_SAVE: function COURSE_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/saveCourse'
      });
    },
    COURSE_DETAIL: function COURSE_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/courseDetails'
      });
    },
    COURSE_BANNER_LIST: function COURSE_BANNER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/getDiyBanner'
      });
    },
    COURSE_BANNER_SAVE: function COURSE_BANNER_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/saveDiyBanner'
      });
    },
    COURSE_BANNER_DEL: function COURSE_BANNER_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/deleteDiyBanner'
      });
    },
    COURSE_LABELS: function COURSE_LABELS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/labels'
      });
    },
    COURSE_LABEL_SAVE: function COURSE_LABEL_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/saveLabel'
      });
    },
    COURSE_LABEL_DEL: function COURSE_LABEL_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        data: data,
        url: '/admin/course/deleteLabel'
      });
    },
    COURSE_TOGGLESHOW: function COURSE_TOGGLESHOW() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/showCourse',
        data: data
      });
    },
    COURSE_DEL: function COURSE_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/deleteCourse',
        data: data
      });
    },
    COURSE_CATE: function COURSE_CATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/categories',
        data: data
      });
    },
    COURSE_LIST: function COURSE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/courses',
        data: data
      });
    },
    COACH_SUBCATE: function COACH_SUBCATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/subcategories',
        data: data
      });
    },
    COACH_DELCATE: function COACH_DELCATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/deleteSubcategory',
        data: data
      });
    },
    COACH_SAVECATE: function COACH_SAVECATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/saveSubcategory',
        data: data
      });
    },
    COACH_PRICE: function COACH_PRICE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/price',
        data: data
      });
    },
    COACH_SETPRICE: function COACH_SETPRICE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/savePrice',
        data: data
      });
    },
    COURSE_NEWER: function COURSE_NEWER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/newUserCourses',
        data: data
      });
    },
    COURSE_NEWER_ADD: function COURSE_NEWER_ADD() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/saveNewUserCourse',
        data: data
      });
    },
    COURSE_NEWER_DEL: function COURSE_NEWER_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/deleteNewUserCourse',
        data: data
      });
    },
    COURSE_NEWER_SORT: function COURSE_NEWER_SORT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/saveSort',
        data: data
      });
    },
    COURSE_DIY_LIST: function COURSE_DIY_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/diyCourses',
        data: data
      });
    },
    COURSE_DIY_SKILL: function COURSE_DIY_SKILL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/getDiyGuide',
        data: data
      });
    },
    COURSE_DIY_SKILL_SAVE: function COURSE_DIY_SKILL_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/saveDiyGuide',
        data: data
      });
    },
    // /admin/course/diyAuditList
    COURSE_DIY_AUDIT: function COURSE_DIY_AUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/diyAuditList',
        data: data
      });
    },
    // /admin/course/diyAuditDetails
    COURSE_DIY_AUDIT_DETAIL: function COURSE_DIY_AUDIT_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/diyAuditDetails',
        data: data
      });
    },
    COURSE_DIY_AUDIT_SUBMIT: function COURSE_DIY_AUDIT_SUBMIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/auditDiy',
        data: data
      });
    },
    COURSE_DIY_AUDIT_REMARK: function COURSE_DIY_AUDIT_REMARK() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/diyRemark',
        data: data
      });
    },
    /// admin/course/updateSort
    COURSE_DIY_AUDIT_SORT: function COURSE_DIY_AUDIT_SORT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/course/updateSort',
        data: data
      });
    },
    course_gymData: function course_gymData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/gymData',
        data: data
      });
    },
    course_coachData: function course_coachData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/coachData',
        data: data
      });
    },
    course_gymCoaches: function course_gymCoaches() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/gymCoaches',
        data: data
      });
    },
    course_gymDataById: function course_gymDataById() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/gymDataById',
        data: data
      });
    },
    course_categoryCoach: function course_categoryCoach() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/categoryCoach',
        data: data
      });
    },
    // 教练分析
    course_coachAnalysis: function course_coachAnalysis() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/coachAnalysis',
        data: data
      });
    },
    // 实时数据
    course_realTimeData: function course_realTimeData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/realTimeData',
        data: data
      });
    },
    // 今日趋势
    course_todayTrends: function course_todayTrends() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/todayTrends',
        data: data
      });
    },
    // 交易趋势
    course_tradeTrends: function course_tradeTrends() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/tradeTrends',
        data: data
      });
    },
    // 交易概况
    course_trade: function course_trade() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/trade',
        data: data
      });
    },
    // 私教课统计 - 私教课类型分析
    course_categoryAnalysis: function course_categoryAnalysis() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/categoryAnalysis',
        data: data
      });
    },
    // 私教课直播监控 - 中断直播
    course_stopLive: function course_stopLive() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/stopLive',
        data: data
      });
    }
  };
});