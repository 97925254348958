//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  auditStatus: 1,
  companyName: '',
  auditNo: '',
  gymName: '',
  gymNo: '',
  createTimeRange: {
    start: '',
    end: ''
  },
  type: 3
};
export default {
  meta: {
    title: 'tab-all',
    auth: true
  },
  name: 'tab-all',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'gym_auditList',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    onDetail: function onDetail(e) {
      this.$router.push({
        path: '/store-legal-details-shopp',
        query: {
          auditId: e
        }
      });
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};