import "core-js/modules/es.array.find";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { invoiceTitleType } from '../options';
export default {
  props: ['orderDetail'],
  data: function data() {
    return {
      tableData: []
    };
  },
  filters: {
    fileInvoiceTitleType: function fileInvoiceTitleType(e) {
      var item = invoiceTitleType.find(function (v) {
        return v.value == e;
      });
      if (!item) return;
      return item.label;
    }
  },
  methods: {
    itemSellAttributesFn: function itemSellAttributesFn(row) {
      var itemSellAttributes = row.itemSellAttributes;
      var s = itemSellAttributes.map(function (v) {
        return "".concat(v.itemValue);
      });
      return s.join('');
    }
  }
};