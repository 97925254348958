/**
 * 文章设置
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    ARTICLE_LIST: function ARTICLE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/articles',
        data: data
      });
    },
    ARTICLE_DEL: function ARTICLE_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/deleteArticle',
        data: data
      });
    },
    ARTICLE_SAVE: function ARTICLE_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/saveArticle',
        data: data
      });
    },
    ARTICLE_DETAIL: function ARTICLE_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/articleDetails',
        data: data
      });
    },
    ARTICLE_TOGGLESHOW: function ARTICLE_TOGGLESHOW() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/showArticle',
        data: data
      });
    },
    ARTICLE_CATE: function ARTICLE_CATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/types',
        data: data
      });
    },
    ARTICLE_CATE_DEL: function ARTICLE_CATE_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/deleteType',
        data: data
      });
    },
    ARTICLE_CATE_EDIT: function ARTICLE_CATE_EDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/article/saveType',
        data: data
      });
    },
    ARTICLE_TEXT_LIST: function ARTICLE_TEXT_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/textList',
        data: data
      });
    },
    ARTICLE_SAVE_TEXT: function ARTICLE_SAVE_TEXT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/saveText',
        data: data
      });
    },
    ARTICLE_DELETE_TEXT: function ARTICLE_DELETE_TEXT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/deleteText',
        data: data
      });
    },
    ARTICLE_IMAGE_LIST: function ARTICLE_IMAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/imageList',
        data: data
      });
    },
    ARTICLE_SAVE_IMAGE: function ARTICLE_SAVE_IMAGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/saveImage',
        data: data
      });
    },
    ARTICLE_DELETE_IMAGE: function ARTICLE_DELETE_IMAGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/setting/deleteImage',
        data: data
      });
    }
  };
});