var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("健身用户")]),
      _c(
        "page-content",
        [
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户", prop: "keyword" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                    model: {
                      value: _vm.form.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "keyword", $$v)
                      },
                      expression: "form.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册日期" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.registerRange.start,
                      max: _vm.form.registerRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(_vm.form.registerRange, "start", $event)
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.registerRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最后登录日期" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.lastLoginRange.start,
                      max: _vm.form.lastLoginRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.lastLoginRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.lastLoginRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册渠道" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.registerPlatformTypes },
                    model: {
                      value: _vm.form.registerPlatformType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "registerPlatformType", $$v)
                      },
                      expression: "form.registerPlatformType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否为好友邀请注册：" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.options },
                    model: {
                      value: _vm.form.inviteType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "inviteType", $$v)
                      },
                      expression: "form.inviteType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邀请注册人：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                    model: {
                      value: _vm.form.inviterKeyword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "inviterKeyword", $$v)
                      },
                      expression: "form.inviterKeyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性别" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.sexs },
                    model: {
                      value: _vm.form.userGender,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "userGender", $$v)
                      },
                      expression: "form.userGender"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "年龄" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.ageRange.start,
                      max: _vm.form.ageRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(_vm.form.ageRange, "start", $event)
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.ageRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在城市" } },
                [
                  _c("CityCourse", {
                    attrs: { city: _vm.form.cityCode, cityOnly: "" },
                    on: {
                      "update:city": function($event) {
                        return _vm.$set(_vm.form, "cityCode", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否为教练：" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.getBooleanOptions() },
                    model: {
                      value: _vm.form.isCoach,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "isCoach", $$v)
                      },
                      expression: "form.isCoach"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否为认证教练：" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.getBooleanOptions() },
                    model: {
                      value: _vm.form.isAuthCoach,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "isAuthCoach", $$v)
                      },
                      expression: "form.isAuthCoach"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否有救助技能：" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.getBooleanOptions() },
                    model: {
                      value: _vm.form.isAid,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "isAid", $$v)
                      },
                      expression: "form.isAid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品成交额" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.goodsAmountRange.start,
                      max: _vm.form.goodsAmountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.goodsAmountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(
                          _vm.form.goodsAmountRange,
                          "end",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品成交量" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.goodsCountRange.start,
                      max: _vm.form.goodsCountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.goodsCountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.goodsCountRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "私教课成交量" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.trainingCountRange.start,
                      max: _vm.form.trainingCountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.trainingCountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(
                          _vm.form.trainingCountRange,
                          "end",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "私教课成交总额" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.trainingAmountRange.start,
                      max: _vm.form.trainingAmountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.trainingAmountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(
                          _vm.form.trainingAmountRange,
                          "end",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值总额" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.rechargeAmountRange.start,
                      max: _vm.form.rechargeAmountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.rechargeAmountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(
                          _vm.form.rechargeAmountRange,
                          "end",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值次数" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.rechargeCountRange.start,
                      max: _vm.form.rechargeCountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.rechargeCountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(
                          _vm.form.rechargeCountRange,
                          "end",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "PLUS会员申购总额" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.plusAmountRange.start,
                      max: _vm.form.plusAmountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.plusAmountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.plusAmountRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "PLUS会员购买次数" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.plusCountRange.start,
                      max: _vm.form.plusCountRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.plusCountRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.plusCountRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { slot: "extra", label: "" }, slot: "extra" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "default" }, on: { click: _vm.download } },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleCommand }
                },
                [
                  _c("el-button", { attrs: { type: "" } }, [
                    _vm._v(" 推送消息"),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right"
                    })
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "1" } }, [
                        _vm._v("选中用户")
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "2" } }, [
                        _vm._v("全部用户")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: { request: _vm.request },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { label: "用户ID", prop: "userNo" }
              }),
              _c("el-table-column", {
                attrs: { label: "用户昵称", prop: "nickname" }
              }),
              _c("el-table-column", {
                attrs: { label: "手机号码", prop: "phoneNumber" }
              }),
              _c("el-table-column", {
                attrs: { label: "性别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.userGender,
                                  _vm.sexs
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", { attrs: { label: "年龄", prop: "age" } }),
              _c("el-table-column", {
                attrs: { label: "生日", prop: "dateOfBirth" }
              }),
              _c("el-table-column", {
                attrs: { label: "所在城市" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.getCityName(scope.row)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否为教练" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.isCoach,
                                  _vm.getBooleanOptions()
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否为认证教练" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.isAuthCoach,
                                  _vm.getBooleanOptions()
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否有救助技能" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.isAuthCoach == 1 &&
                                  scope.row.isCoach == 1
                                  ? "是"
                                  : "否"
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "商品成交总额", prop: "goodsOrderAmount" }
              }),
              _c("el-table-column", {
                attrs: { label: "商品成交单量", prop: "goodsOrderCount" }
              }),
              _c("el-table-column", {
                attrs: { label: "私教课成交总额", prop: "trainingOrderAmount" }
              }),
              _c("el-table-column", {
                attrs: { label: "私教课成交单量", prop: "trainingOrderCount" }
              }),
              _c("el-table-column", {
                attrs: { label: "充值总额", prop: "rechargeAmount" }
              }),
              _c("el-table-column", {
                attrs: { label: "充值次数", prop: "rechargeCount" }
              }),
              _c("el-table-column", {
                attrs: { label: "PLUS会员申购总额", prop: "plusOrderAmount" }
              }),
              _c("el-table-column", {
                attrs: { label: "PLUS会员购买次数", prop: "plusOrderCount" }
              }),
              _c("el-table-column", {
                attrs: { label: "邀请注册人昵称", prop: "inviterName" }
              }),
              _c("el-table-column", {
                attrs: { label: "注册渠道", prop: "registerPlatformType" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.registerPlatformType,
                                  _vm.registerPlatformTypes
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "注册时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最后登录时间",
                  prop: "lastLoginTime",
                  width: "200"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/user-detail",
                                    query: { userId: scope.row.userId }
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "APP推送",
            visible: _vm.dialogFormVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.cancel
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推送标题", prop: "messageTitle" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "14", "show-word-limit": "" },
                    model: {
                      value: _vm.appForm.messageTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "messageTitle", $$v)
                      },
                      expression: "appForm.messageTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推送内容", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", "show-word-limit": "" },
                    model: {
                      value: _vm.appForm.content,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "content", $$v)
                      },
                      expression: "appForm.content"
                    }
                  }),
                  _c("span", [
                    _vm._v("APP推送标题不能超过14个字，内容不能超过20个字。")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }