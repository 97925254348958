var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-track" },
    _vm._l(_vm.data, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "event-track-item" },
        [
          _c(
            "div",
            { staticClass: "avatar-box" },
            [
              _c("el-avatar", {
                attrs: { shape: "circle", size: "middle", src: item.avatar }
              }),
              _c("span", { staticStyle: { width: "200px" } }, [
                _vm._v(_vm._s(item.name))
              ]),
              _c("span", [_vm._v(_vm._s(item.createTime))])
            ],
            1
          ),
          _c("div", { staticClass: "mt-20" }, [_vm._v(_vm._s(item.text))]),
          _c("base-gallery", {
            staticClass: "mt-10",
            attrs: { srcList: item.files }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }