var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("span", { staticClass: "d2-mr-10" }, [_vm._v("导入Excel")]),
              _vm.pageType !== "aed"
                ? _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.download } },
                    [_vm._v("下载模板")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("UploadExcel", { attrs: { "on-success": _vm.upload } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }