import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV); // 自动读取.page.vue结尾的文件，写进路由


var files = require.context('../views', true, /\.page\.vue$/);

var generators = files.keys().map(function (key) {
  return {
    path: files(key).default.path || files(key).default.name,
    meta: _objectSpread({}, files(key).default.meta),
    name: files(key).default.name,
    component: _import(key.replace('./', ''))
  };
});
/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 首页
  {
    path: 'index',
    name: 'index',
    iconSvg: '私教课交易',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }, // 文件导入页面
  {
    path: 'import',
    name: 'import',
    meta: {
      title: '导入',
      auth: true
    },
    component: _import('import')
  }, // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  } // ...generators
  ]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, // 忘记密码
{
  path: '/forgetPwd',
  name: 'forgetPwd',
  component: _import('system/forgetPwd')
}, {
  path: '/chat',
  name: 'chat',
  component: _import('chat/index')
}, {
  path: '/news-screen',
  name: 'news-screen',
  meta: {
    title: '资讯大屏',
    auth: true
  },
  component: _import('big-screen/news-screen')
}, {
  path: '/aed-screen',
  name: 'aed-screen',
  meta: {
    title: '救助大屏',
    auth: true
  },
  component: _import('big-screen/aed-screen')
}, {
  path: '/data-screen',
  name: 'data-screen',
  meta: {
    title: '数据大屏',
    auth: true
  },
  component: _import('big-screen/data-screen')
}, {
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 导出默认框架内显示的路由

export var routesInLayout = frameIn; // 导出默认框架外显示的路由

export var routesOutLayout = frameOut; // 导出默认框架动态的路由

export var generatorsRoutes = generators;