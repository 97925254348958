import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _toConsumableArray from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/**
 * 创建级联选择器可以初始化显示的数据
 * @param {*} treeData
 * @param {*} departmentId
 */
function createCascaderValue(treeData, departmentId) {
  var targetList = [];

  var convert = function convert(list, selectList) {
    return list === null || list === void 0 ? void 0 : list.map(function (item) {
      var _selectList = [].concat(_toConsumableArray(selectList), [item.value]); // eslint-disable-next-line eqeqeq


      if (item.value == departmentId) {
        targetList = _toConsumableArray(_selectList);
      }

      return _objectSpread(_objectSpread({}, item), {}, {
        label: item.label,
        value: item.value,
        selectList: _selectList,
        children: convert(item === null || item === void 0 ? void 0 : item.children, _selectList)
      });
    });
  };

  convert(treeData, []);
  return targetList;
}

export { createCascaderValue };