var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-card",
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("span", [
                _vm._v("上级分类：" + _vm._s(_vm.$route.query.name))
              ]),
              _c(
                "el-button",
                {
                  staticClass: "d2-mb",
                  staticStyle: { "margin-left": "10px" },
                  on: {
                    click: function($event) {
                      return _vm.goDetail(
                        Object.assign({}, _vm.$route.query, {
                          typeLevel: 1,
                          upName: _vm.$route.query.name
                        })
                      )
                    }
                  }
                },
                [_vm._v(" 添加子分类 ")]
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "编号", prop: "typeNo" }
              }),
              _c("el-table-column", {
                attrs: { label: "分类名称", prop: "typeName" }
              }),
              _c("el-table-column", {
                attrs: { label: "级别", prop: "typeLevel" }
              }),
              _c("el-table-column", {
                attrs: { label: "是否显示", prop: "typeShow" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [_vm._v(_vm._s(row.typeShow ? "是" : "否"))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "排序", prop: "typeSort" }
              }),
              _vm.form.typeLevel !== 3
                ? _c("el-table-column", {
                    attrs: { label: "设置", prop: "xxx" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickSee({
                                        preTypeId: row.goodsTypeId,
                                        name: row.typeName
                                      })
                                    }
                                  }
                                },
                                [_vm._v("查看下级")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3346629860
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          {
                            staticClass: "d2-mr",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.goDetail({
                                  goodsTypeId: row.goodsTypeId,
                                  upName: _vm.$route.query.name
                                })
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.deleteStoreGoodsType({
                                  goodsTypeId: row.goodsTypeId
                                })
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }