//
//
//
//
//
//
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    clearable: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      default: ''
    }
  },
  methods: {
    handleChange: function handleChange(e) {
      this.$emit('change', e);
    }
  }
};