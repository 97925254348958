var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        { attrs: { column: 1, title: "营业执照" } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "营业执照照片" } },
            [
              _c("el-image", {
                staticStyle: { height: "100px" },
                attrs: {
                  "preview-src-list": [_vm.data.businessLicenseImage],
                  src: _vm.data.businessLicenseImage,
                  fit: "cover"
                }
              })
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
            _vm._v(" " + _vm._s(_vm.data.companyName) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "统一社会信用代码" } }, [
            _vm._v(" " + _vm._s(_vm.data.unionCreditCode) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "营业执照所在地" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("businessLicenseProvince")(_vm.data)) + " "
            )
          ]),
          _c("el-descriptions-item", { attrs: { label: "住所" } }, [
            _vm._v(" " + _vm._s(_vm.data.businessLicenseAddress) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "成立日期" } }, [
            _vm._v(" " + _vm._s(_vm.data.setupDate) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "营业期限" } }, [
            _vm._v(" " + _vm._s(_vm._f("businessDateFrom")(_vm.data)) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "注册资本" } }, [
            _vm._v(" " + _vm._s(_vm.data.registeredCapital) + "万元 ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "经营范围" } }, [
            _vm._v(" " + _vm._s(_vm.data.businessScope) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-descriptions",
        { attrs: { column: 1, title: "法人信息" } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "法人身份证件" } },
            [
              _c("el-image", {
                staticClass: "d2-mr",
                staticStyle: { width: "240px", height: "151px" },
                attrs: {
                  "preview-src-list": [_vm.data.legalPersonIdCardFace],
                  src: _vm.data.legalPersonIdCardFace,
                  fit: "cover"
                }
              }),
              _c("el-image", {
                staticStyle: { width: "240px", height: "151px" },
                attrs: {
                  "preview-src-list": [_vm.data.legalPersonIdCardEmblem],
                  src: _vm.data.legalPersonIdCardEmblem,
                  fit: "cover"
                }
              })
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "法人姓名" } }, [
            _vm._v(" " + _vm._s(_vm.data.legalPersonName) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "法人证件号" } }, [
            _vm._v(" " + _vm._s(_vm.data.legalPersonIdCardNumber) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "法人证件有效期" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("legalPersonIdCardTimeFrom")(_vm.data)) + " "
            )
          ])
        ],
        1
      ),
      _c(
        "el-descriptions",
        { attrs: { column: 1, title: "税务信息" } },
        [
          _c("el-descriptions-item", { attrs: { label: "纳税人类型" } }, [
            _vm._v(
              " " +
                _vm._s(
                  ["一般纳税人", "小规模纳税人"][_vm.data.taxpayerType - 1]
                ) +
                " "
            )
          ]),
          _vm.data.taxpayerType == 1
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "一般纳税人资格证" } },
                [
                  _c("el-image", {
                    staticStyle: { height: "100px" },
                    attrs: {
                      "preview-src-list": [_vm.data.taxpayerCertImage],
                      src: _vm.data.taxpayerCertImage,
                      fit: "cover"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("el-descriptions-item", { attrs: { label: "合同编号" } }, [
            _vm._v(" " + _vm._s(_vm.data.contractNo) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "开户行" } }, [
            _vm._v(" " + _vm._s(_vm.data.bankName) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "账号" } }, [
            _vm._v(" " + _vm._s(_vm.data.bankAccount) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "支行名称" } }, [
            _vm._v(" " + _vm._s(_vm.data.bankBranchName) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-descriptions",
        { attrs: { column: 1, title: "店铺设置" } },
        [
          _c("el-descriptions-item", { attrs: { label: "店铺名称" } }, [
            _vm._v(" " + _vm._s(_vm.data.storeName) + " ")
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "店铺logo" } },
            [
              _c("el-image", {
                staticStyle: { height: "100px" },
                attrs: {
                  "preview-src-list": [_vm.data.storeLogo],
                  src: _vm.data.storeLogo,
                  fit: "cover"
                }
              })
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "店铺简介" } }, [
            _vm._v(" " + _vm._s(_vm.data.storeIntro) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }