var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-descriptions",
        { attrs: { column: 1 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "门店教练实况", flex: "main:center" } },
            [
              _vm._v(" " + _vm._s(_vm.nowTime) + " "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.toFresh }
                },
                [_vm._v("刷新")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "d2-mb",
          attrs: { direction: "vertical", column: 7, border: "" }
        },
        [
          _c("el-descriptions-item", { attrs: { label: "门店编号" } }, [
            _vm._v(_vm._s(_vm.records.gymNo))
          ]),
          _c("el-descriptions-item", { attrs: { label: "门店名称" } }, [
            _vm._v(_vm._s(_vm.records.gymName))
          ]),
          _vm.records.cityCode
            ? _c("el-descriptions-item", { attrs: { label: "所在城市" } }, [
                _vm._v(_vm._s(_vm._f("ftGetCityByCode")(_vm.records.cityCode)))
              ])
            : _vm._e(),
          _c(
            "el-descriptions-item",
            { attrs: { label: "可提供场地类型" } },
            _vm._l(_vm.records.bookSettingList, function(e, i) {
              return _c("p", { key: i }, [_vm._v(_vm._s(e.categoryName))])
            }),
            0
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前教练数量" } },
            _vm._l(_vm.records.bookSettingList, function(e, i) {
              return _c("p", { key: i }, [_vm._v(_vm._s(e.coachCount))])
            }),
            0
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前可预约状态的教练数" } },
            _vm._l(_vm.records.bookSettingList, function(e, i) {
              return _c("p", { key: i }, [_vm._v(_vm._s(e.activeCount))])
            }),
            0
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前不可预约状态的教练" } },
            _vm._l(_vm.records.bookSettingList, function(e, i) {
              return _c("p", { key: i }, [_vm._v(_vm._s(e.inactiveCount))])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "用户编号", prop: "userId" }
          }),
          _c("el-table-column", {
            attrs: { label: "真实姓名", prop: "realName" }
          }),
          _c("el-table-column", {
            attrs: { label: "在本门店的接单状态", prop: "bookedFull" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(_vm._s(row.bookedFull ? "不可预约" : "可预约"))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "性别", prop: "gender" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.gender == 1 ? "男" : "女"))]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { label: "年龄", prop: "age" } }),
          _c("el-table-column", {
            attrs: { label: "所在城市", prop: "cityCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(_vm._s(_vm._f("ftGetCityByCode")(row.cityCode)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "可交流语言", prop: "languages" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.languages.join("、")))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: {
                                  userId: scope.row.userId,
                                  type: "coach"
                                }
                              }
                            }
                          },
                          [_vm._v(" 详情 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }