var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.$route.query.helpId ? "编辑问题" : "添加问题"))
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "帮助分类", prop: "helpCategoryId" } },
            [
              _c("base-select", {
                attrs: { options: _vm.helpCategorys },
                model: {
                  value: _vm.form.helpCategoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "helpCategoryId", $$v)
                  },
                  expression: "form.helpCategoryId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "帮助标题", prop: "helpTitle" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.helpTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "helpTitle", $$v)
                  },
                  expression: "form.helpTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "显示设置" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.helpShow,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "helpShow", $$v)
                  },
                  expression: "form.helpShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "帮助排序", prop: "helpSort" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { placeholder: "", type: "number", min: "1" },
                on: { input: _vm.changeInt },
                model: {
                  value: _vm.form.helpSort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "helpSort", $$v)
                  },
                  expression: "form.helpSort"
                }
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v(" 数值越小，排列越前")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "帮助正文", prop: "helpContent" } },
            [
              _c("base-editor", {
                attrs: { upToken: _vm.qnToken, content: _vm.form.helpContent },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "helpContent", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }