import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Title from '../title';
import { mapGetters } from 'vuex';
export default {
  components: {
    Title: Title
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/aedbig', ['getLeftOnUser'])), {}, {
    user: function user() {
      return this.getLeftOnUser;
    }
  }),
  filters: {
    address: function address(e) {
      if (!e) return;
      return Object.keys(e).map(function (v) {
        return isNaN(Number(e[v])) ? e[v] : '';
      }).join('');
    }
  }
};