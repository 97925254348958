var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.actives, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item + "千卡", name: item } },
            [
              _c("span", [_vm._v(_vm._s(item + "千卡食谱"))]),
              _vm._l(_vm.list, function(item_, index_) {
                return _c(
                  "el-card",
                  { key: index_, attrs: { header: "食谱" + (index_ + 1) } },
                  [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      _vm._l(item_, function(item__, index__) {
                        return _c(
                          "el-col",
                          {
                            key: index__,
                            staticStyle: { "text-align": "center" },
                            attrs: { span: 3 }
                          },
                          [
                            _c("span", [_vm._v(_vm._s("day" + (index__ + 1)))]),
                            _c("el-divider"),
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: item__.breakfast.components,
                                  border: "",
                                  "row-class-name": _vm.tableRowClassName,
                                  "header-cell-class-name": "table_header"
                                },
                                on: { "row-click": _vm.onRowClick }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "foodName",
                                    align: "center",
                                    label: "食物"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "divBox",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toChangeFood(
                                                      row,
                                                      index__,
                                                      row.row_index,
                                                      "breakfast"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.foodName || "-")
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "weight",
                                    align: "center",
                                    label: "重量"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "divBox",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toChangeWeight(
                                                      row,
                                                      index__,
                                                      row.row_index,
                                                      "breakfast"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.weight || "-")
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      _vm._l(_vm.list[0], function(e, i) {
                        return _c(
                          "el-col",
                          { key: i, staticClass: "colBox", attrs: { span: 3 } },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(e.breakfast.foodEnergy) + "kcal")
                            ])
                          ]
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "lunchBox",
                        attrs: { type: "flex", justify: "center" }
                      },
                      _vm._l(item_, function(item__, index__) {
                        return _c(
                          "el-col",
                          {
                            key: index__,
                            staticStyle: { "text-align": "center" },
                            attrs: { span: 3 }
                          },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: item__.lunch.components,
                                  border: "",
                                  "row-class-name": _vm.tableRowClassName
                                },
                                on: { "row-click": _vm.onRowClick }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "foodName",
                                    align: "center",
                                    label: "食物"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "divBox",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toChangeFood(
                                                      row,
                                                      index__,
                                                      row.row_index,
                                                      "lunch"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.foodName || "-")
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "weight",
                                    align: "center",
                                    label: "重量"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "divBox",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toChangeWeight(
                                                      row,
                                                      index__,
                                                      row.row_index,
                                                      "lunch"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.weight || "-")
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      _vm._l(_vm.list[0], function(e, i) {
                        return _c(
                          "el-col",
                          { key: i, staticClass: "colBox", attrs: { span: 3 } },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(e.lunch.foodEnergy) + "kcal")
                            ])
                          ]
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "lunchBox",
                        attrs: { type: "flex", justify: "center" }
                      },
                      _vm._l(item_, function(item__, index__) {
                        return _c(
                          "el-col",
                          {
                            key: index__,
                            staticStyle: { "text-align": "center" },
                            attrs: { span: 3 }
                          },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: item__.dinner.components,
                                  border: "",
                                  "row-class-name": _vm.tableRowClassName
                                },
                                on: { "row-click": _vm.onRowClick }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "foodName",
                                    align: "center",
                                    label: "食物"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "divBox",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toChangeFood(
                                                      row,
                                                      index__,
                                                      row.row_index,
                                                      "dinner"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.foodName || "-")
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "weight",
                                    align: "center",
                                    label: "重量"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "divBox",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toChangeWeight(
                                                      row,
                                                      index__,
                                                      row.row_index,
                                                      "dinner"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.weight || "-")
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      _vm._l(_vm.list[0], function(e, i) {
                        return _c(
                          "el-col",
                          { key: i, staticClass: "colBox", attrs: { span: 3 } },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(e.dinner.foodEnergy) + "kcal")
                            ])
                          ]
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      _vm._l(_vm.list[0], function(e, i) {
                        return _c(
                          "el-col",
                          {
                            key: i,
                            staticClass: "colBoxTotal",
                            attrs: { span: 3 }
                          },
                          [_c("span", [_vm._v(_vm._s(e.weekCalorie) + "kcal")])]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改食物",
            visible: _vm.dialogFormVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "食物：", "label-width": "100" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { filterable: "", placeholder: "请选择食物" },
                      model: {
                        value: _vm.form.foodName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "foodName", $$v)
                        },
                        expression: "form.foodName"
                      }
                    },
                    _vm._l(_vm.foodList, function(e, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: e.foodName, value: e.foodName }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.toConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改食物重量",
            visible: _vm.dialogFormVisible2,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible2 = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "重量：", "label-width": "100" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.form.weight,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "weight", $$v)
                      },
                      expression: "form.weight"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible2 = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.toConfirm2 } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }