var commonSub1 = [{
  id: '100002301',
  name: '肩部',
  subList: []
}, {
  id: '100002302',
  name: '颈部',
  subList: []
}, {
  id: '100002303',
  name: '胸部',
  subList: []
}, {
  id: '100002304',
  name: '背部',
  subList: []
}, {
  id: '100002305',
  name: '手臂',
  subList: []
}, {
  id: '100002306',
  name: '腰部',
  subList: []
}, {
  id: '100002307',
  name: '腹部',
  subList: []
}, {
  id: '100002308',
  name: '髋部',
  subList: []
}, {
  id: '100002309',
  name: '臀部',
  subList: []
}, {
  id: '100002310',
  name: '核心',
  subList: []
}, {
  id: '100002311',
  name: '脊柱',
  subList: []
}, {
  id: '100002312',
  name: '腿部',
  subList: []
}, {
  id: '100002313',
  name: '综合',
  subList: []
}];
var commonSub = [{
  id: '100002207',
  name: '伸展柔韧',
  subList: commonSub1
}, {
  id: '100002208',
  name: '力量提升',
  subList: commonSub1
}, {
  id: '100002209',
  name: '燃脂塑形',
  subList: commonSub1
}, {
  id: '100002210',
  name: '体态调整',
  subList: []
}, {
  id: '100002211',
  name: '理疗保养',
  subList: commonSub1
}, {
  id: '100002212',
  name: '平衡专注',
  subList: commonSub1
}, {
  id: '100002213',
  name: '舒缓减压',
  subList: commonSub1
}, {
  id: '100002214',
  name: '串联流动',
  subList: commonSub1
}, {
  id: '100002215',
  name: '倒立系列',
  subList: commonSub1
}, {
  id: '100002216',
  name: '后弯系列',
  subList: commonSub1
}, {
  id: '100002217',
  name: '手支撑系列',
  subList: commonSub1
}, {
  id: '100002218',
  name: '技能提升',
  subList: commonSub1
}];
var temp = {
  id: '10000002',
  name: '瑜伽',
  subLables: ['系列', '类型', '部位'],
  subList: [{
    id: '100002101',
    name: '零基础',
    subList: [{
      id: '100002201',
      name: '站姿体式',
      subList: commonSub1
    }, {
      id: '100002202',
      name: '坐姿体式',
      subList: commonSub1
    }, {
      id: '100002203',
      name: '仰卧体式',
      subList: commonSub1
    }, {
      id: '100002204',
      name: '俯卧体式',
      subList: commonSub1
    }, {
      id: '100002205',
      name: '支撑体式',
      subList: commonSub1
    }, {
      id: '100002206',
      name: '常用呼吸法',
      subList: commonSub1
    }]
  }, {
    id: '100002102',
    name: '初级',
    subList: commonSub
  }, {
    id: '100002103',
    name: '中级',
    subList: commonSub
  }, {
    id: '100002104',
    name: '高级',
    subList: commonSub
  }, {
    id: '100002105',
    name: '孕产',
    subList: [{
      id: '100002219',
      name: '备孕',
      subList: commonSub1
    }, {
      id: '100002220',
      name: '孕期',
      subList: commonSub1
    }, {
      id: '100002221',
      name: '产后',
      subList: commonSub1
    }]
  }]
};
export default temp;