var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v(" 交易分析 ")]),
      _c("div", { staticClass: "card d2-mb" }, [
        _c("div", { attrs: { flex: "main:justify" } }, [
          _c("div", { staticStyle: { "font-size": "20px" } }, [
            _vm._v(" 交易概况 "),
            _c("i", {
              staticClass: "el-icon-timer d2-ml",
              staticStyle: { "font-size": "12px" }
            }),
            _c("span", { staticStyle: { "font-size": "12px" } }, [
              _vm._v(
                "更新时间: " + _vm._s(_vm._f("formatDateTime")(new Date()))
              )
            ])
          ]),
          _c(
            "div",
            { attrs: { flex: "main:justify" } },
            [
              _c("span", { attrs: { for: "" } }, [_vm._v("店铺名称：")]),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { clearable: "" },
                  on: { change: _vm.handleSelect },
                  model: {
                    value: _vm.form.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "storeId", $$v)
                    },
                    expression: "form.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.storeName, value: item.storeId }
                  })
                }),
                1
              ),
              _c("range-date", {
                attrs: { "picker-options": _vm.pickerOptions, clearable: true },
                on: { change: _vm.rangeDateChange }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "0" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h3", { staticClass: "d2-m-0" }, [
              _vm._v(" 数据概况"),
              _c(
                "span",
                { staticStyle: { "font-size": "12px", "margin-left": "20px" } },
                [_vm._v("选择自定义时间，指标按天累加，暂不去重")]
              )
            ])
          ]),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "row-box" },
              [
                _vm._l(_vm.newsList, function(l, i) {
                  return [
                    _c("div", { key: i, staticClass: "item" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !l.hide,
                              expression: "!l.hide"
                            }
                          ],
                          staticClass: "tit"
                        },
                        [_vm._v(_vm._s(l.tit))]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !l.hide,
                              expression: "!l.hide"
                            }
                          ],
                          staticClass: "centent"
                        },
                        [_vm._v(_vm._s(l.count))]
                      )
                    ])
                  ]
                })
              ],
              2
            ),
            _c("div", { staticClass: "card-line-box" }, [
              _c("div", { staticClass: "card-line" }),
              _c("div", { staticClass: "card-line card-line2" }),
              _c("div", { staticClass: "card-line-centent" }),
              _c("div", { staticClass: "item item-line item-line1" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("下单转化率")]),
                _c("div", { staticClass: "centent" }, [
                  _vm._v(_vm._s(_vm.data.orderCoverRate) + "%")
                ])
              ]),
              _c("div", { staticClass: "item item-line item-line2" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("成交转化率")]),
                _c("div", { staticClass: "centent" }, [
                  _vm._v(_vm._s(_vm.data.tradeCoverRate) + "%")
                ])
              ]),
              _c("div", { staticClass: "item item-line item-line3" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("下单成交转化率")]),
                _c("div", { staticClass: "centent" }, [
                  _vm._v(_vm._s(_vm.data.orderTradeCoverRate) + "%")
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "card d2-mb" },
        [
          _c("h3", [_vm._v("交易趋势")]),
          _c(
            "el-checkbox-group",
            {
              attrs: { min: 0, max: 4 },
              model: {
                value: _vm.checkedCities,
                callback: function($$v) {
                  _vm.checkedCities = $$v
                },
                expression: "checkedCities"
              }
            },
            [
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "浏览行为" } },
                    [
                      _c("el-checkbox", { attrs: { label: "orders" } }, [
                        _vm._v("下单单量")
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "orderConsumers" } },
                        [_vm._v("下单客户数")]
                      ),
                      _c("el-checkbox", { attrs: { label: "orderAmount" } }, [
                        _vm._v("下单金额")
                      ]),
                      _c("el-checkbox", { attrs: { label: "orderGoods" } }, [
                        _vm._v("下单商品件数")
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "orderCoverRate" } },
                        [_vm._v("下单转化率")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "成交转化" } },
                    [
                      _c("el-checkbox", { attrs: { label: "trades" } }, [
                        _vm._v("成交单量")
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "tradeConsumers" } },
                        [_vm._v("成交客户数")]
                      ),
                      _c("el-checkbox", { attrs: { label: "tradeAmount" } }, [
                        _vm._v("成交金额")
                      ]),
                      _c("el-checkbox", { attrs: { label: "tradeGoods" } }, [
                        _vm._v("成交商品件数")
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "avgTradeAmount" } },
                        [_vm._v("客单价")]
                      ),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "tradeCoverRate" } },
                        [_vm._v("成交转化率")]
                      ),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "orderTradeCoverRate" } },
                        [_vm._v("下单成交转化率")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "d2-mt d2-mb" }, [
            _vm._v(" 已选："),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.checkedCitiesLen) + " / 4")
            ]),
            _vm._v("项 ")
          ]),
          _c("div", { ref: "chart1", staticStyle: { height: "400px" } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }