var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.goRoute("course-edit")
            }
          }
        },
        [_vm._v("添加课程")]
      ),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程编号" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入课程编号" },
                model: {
                  value: _vm.form.courseNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courseNo", $$v)
                  },
                  expression: "form.courseNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程名称" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入课程名称" },
                model: {
                  value: _vm.form.courseName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courseName", $$v)
                  },
                  expression: "form.courseName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类型" } },
            [
              _c("course-cate", {
                attrs: { form: _vm.form },
                on: { change: _vm.onCateTypeChange }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "教练性别" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.coachGender,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "coachGender", $$v)
                    },
                    expression: "form.coachGender"
                  }
                },
                _vm._l(_vm.dict.gender, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否在app显示" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.appShow,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "appShow", $$v)
                    },
                    expression: "form.appShow"
                  }
                },
                _vm._l(_vm.dict.showOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "器械" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.hasEquipment,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "hasEquipment", $$v)
                    },
                    expression: "form.hasEquipment"
                  }
                },
                _vm._l(_vm.dict.hasOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程难度" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.courseDifficultyLevel,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "courseDifficultyLevel", $$v)
                    },
                    expression: "form.courseDifficultyLevel"
                  }
                },
                _vm._l(_vm.dict.courseDifficultyLevels, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "浏览人数" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.browsePeopleRange.start,
                  max: _vm.form.browsePeopleRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.browsePeopleRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.browsePeopleRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "浏览量" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.browseCountRange.start,
                  max: _vm.form.browseCountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.browseCountRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.browseCountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "评论数" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.commentCountRange.start,
                  max: _vm.form.commentCountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.commentCountRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.commentCountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收藏数" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.favoriteCountRange.start,
                  max: _vm.form.favoriteCountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(
                      _vm.form.favoriteCountRange,
                      "start",
                      $event
                    )
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.favoriteCountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序方式" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.orderType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "orderType", $$v)
                    },
                    expression: "form.orderType"
                  }
                },
                _vm._l(_vm.orderTypeDict, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { headStyle: _vm.headStyle, request: _vm.request }
        },
        [
          _c("el-table-column", {
            attrs: { label: "课程编号", width: "130", prop: "courseNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.courseNo || scope.row.courseId) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "课程名称", prop: "courseName" }
          }),
          _c("el-table-column", {
            attrs: { label: "课程大类", prop: "categoryName" }
          }),
          _c("el-table-column", {
            attrs: { label: "一级分类", prop: "subcategoryFirstName" }
          }),
          _c("el-table-column", {
            attrs: { label: "二级分类", prop: "subcategorySecondName" }
          }),
          _c("el-table-column", {
            attrs: { label: "三级分类", prop: "subcategoryThreeName" }
          }),
          _c("el-table-column", {
            attrs: { label: "教练性别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.genderStr(scope.row.coachGender)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "课程难度" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" L" + _vm._s(scope.row.courseDifficultyLevel) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "器械" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.hasEquipment ? "有" : "无") + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "浏览人数", prop: "browsePeople" }
          }),
          _c("el-table-column", {
            attrs: { label: "浏览量", prop: "browseCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "评论人数", prop: "commentPeople" }
          }),
          _c("el-table-column", {
            attrs: { label: "评论条数", prop: "commentCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "收藏数", prop: "favoriteCount" }
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "是否APP展示" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-value": true,
                        "inactive-value": false,
                        "active-text": "是",
                        "inactive-text": "否"
                      },
                      on: {
                        change: function($event) {
                          return _vm.onToggleShow(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.appShow,
                        callback: function($$v) {
                          _vm.$set(scope.row, "appShow", $$v)
                        },
                        expression: "scope.row.appShow"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "170", label: "添加时间", prop: "createTime" }
          }),
          _c("el-table-column", {
            attrs: { width: "210", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.read(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#F56C6C" },
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }