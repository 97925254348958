var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "审批编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入审批编号" },
                model: {
                  value: _vm.form.auditNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "auditNo", $$v)
                  },
                  expression: "form.auditNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.storeName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeName", $$v)
                  },
                  expression: "form.storeName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺编号" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.storeNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeNo", $$v)
                  },
                  expression: "form.storeNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "companyName", $$v)
                  },
                  expression: "form.companyName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "审批编号", prop: "auditNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "审核状态", prop: "auditStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          ["", "待审核", "已通过", "未通过"][row.auditStatus]
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "storeName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.goRoute(
                              "/store-detail?storeId=" + row.storeId
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.storeName) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "公司名称", prop: "companyName" }
          }),
          _c("el-table-column", {
            attrs: { label: "申请时间", prop: "createTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTime")(scope.row.createTime)) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onDetail(scope.row.auditId)
                          }
                        }
                      },
                      [_vm._v(" 审核 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }