import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
export default {
  data: function data() {
    return {
      page: 1,
      limit: 10,
      active: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/databig', ['getNewLeft'])), {}, {
    tabData: function tabData() {
      return this.getNewLeft || [];
    }
  }),
  watch: {
    getNewLeft: {
      handler: function handler(v) {
        if (!isEmpty(v) && !this.active) {
          this.clickTab(v[0]);
        }
      },
      deep: true
    }
  },
  methods: {
    load: function load() {
      this.$emit('load', {
        page: this.page++,
        limit: this.limit
      });
    },
    clickTab: function clickTab(l) {
      this.active = l.newsId;
      this.$emit('change', {
        newsId: l.newsId
      });
    }
  }
};