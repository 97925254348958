import { render, staticRenderFns } from "./TabCoach.vue?vue&type=template&id=6d4e2ac3&scoped=true&"
import script from "./TabCoach.vue?vue&type=script&lang=js&"
export * from "./TabCoach.vue?vue&type=script&lang=js&"
import style0 from "./TabCoach.vue?vue&type=style&index=0&id=6d4e2ac3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4e2ac3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yaolian_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d4e2ac3')) {
      api.createRecord('6d4e2ac3', component.options)
    } else {
      api.reload('6d4e2ac3', component.options)
    }
    module.hot.accept("./TabCoach.vue?vue&type=template&id=6d4e2ac3&scoped=true&", function () {
      api.rerender('6d4e2ac3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user-management/user/detail/TabCoach.vue"
export default component.exports