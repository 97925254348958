var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        labelWidth: "150px",
        title: "AED位置",
        visible: _vm.dialogVisible,
        rules: _vm.formRules,
        formData: _vm.dialogFormData,
        isEdit: _vm.isEdit,
        width: "900px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "update:formData": function($event) {
          _vm.dialogFormData = $event
        },
        "update:form-data": function($event) {
          _vm.dialogFormData = $event
        },
        confirm: _vm.onSubmit
      }
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "mt-20",
          attrs: { label: "AED位置名称", prop: "aedName" }
        },
        [
          _c("el-input", {
            staticStyle: { width: "300px" },
            attrs: { placeholder: "" },
            model: {
              value: _vm.dialogFormData.aedName,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "aedName", $$v)
              },
              expression: "dialogFormData.aedName"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "详细地址", prop: "location" } },
        [
          _c("CitySelect", {
            ref: "citySelect",
            attrs: {
              province: _vm.dialogFormData.location.proCode,
              city: _vm.dialogFormData.location.cityCode,
              district: _vm.dialogFormData.location.addCode,
              provinceName: _vm.dialogFormData.location.province,
              cityName: _vm.dialogFormData.location.city,
              districtName: _vm.dialogFormData.location.district,
              useLocation: "",
              longitude: _vm.dialogFormData.location.longitude,
              latitude: _vm.dialogFormData.location.latitude
            },
            on: {
              "update:province": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "proCode", $event)
              },
              "update:city": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "cityCode", $event)
              },
              "update:district": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "addCode", $event)
              },
              "update:provinceName": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "province", $event)
              },
              "update:province-name": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "province", $event)
              },
              "update:cityName": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "city", $event)
              },
              "update:city-name": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "city", $event)
              },
              "update:districtName": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "district", $event)
              },
              "update:district-name": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "district", $event)
              },
              "update:longitude": function($event) {
                return _vm.$set(
                  _vm.dialogFormData.location,
                  "longitude",
                  $event
                )
              },
              "update:latitude": function($event) {
                return _vm.$set(_vm.dialogFormData.location, "latitude", $event)
              }
            }
          }),
          _c("el-input", {
            staticClass: "mt-20",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请输入详细地址" },
            model: {
              value: _vm.dialogFormData.location.details,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData.location, "details", $$v)
              },
              expression: "dialogFormData.location.details"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }