var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Title", [
        _c("span", { staticClass: "on" }, [_vm._v("呼救")]),
        _vm._v("人员基本信息")
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "top" }, [
          _vm._m(0),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("姓名:")]),
              _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.user.name))
              ])
            ]),
            _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("性别:")]),
              _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(["未知", "男", "女"][_vm.user.userGender]))
              ])
            ]),
            _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("年龄:")]),
              _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.user.age))])
            ]),
            _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("电话:")]),
              _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.user.phoneNumber))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "center" }, [
          _c("div", { staticClass: "label" }, [_vm._v("所在位置:")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm._f("address")(_vm.user.location)))
          ])
        ]),
        _vm._m(1)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../assets/image/on-user.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../assets/image/o-line.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }