import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 反馈列表 */
    feedback_feedbacks: createAction(request, '/admin/feedback/feedbacks'),

    /** 回复反馈 */
    feedback_reply: createAction(request, '/admin/feedback/reply')
  };
});