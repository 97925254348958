//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'event-track',
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  }
};