//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    width: {
      type: String,
      default: '55%'
    },
    visible: {
      default: false
    },
    title: {
      type: String
    },
    fullTitle: {
      type: String
    },
    formData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    buttons: {
      type: Array,
      default: function _default() {
        return ['cancel', 'confirm'];
      }
    }
  },
  data: function data() {
    return {
      form: {}
    };
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('update:visible', false);
    },
    onChancel: function onChancel() {
      this.$emit('cancel');
      this.closeDialog();
    },
    onConfirm: function onConfirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$emit('confirm', _this.form);
        } else {
          console.log('error confirm!!');
          return false;
        }
      });
    }
  },
  watch: {
    // visible: {
    //   immediate: true,
    //   handler () {
    //     if (this.visible) {
    //       this.$nextTick(() => {
    //         this.$refs.form.resetFields()
    //       })
    //     }
    //   }
    // },
    formData: {
      deep: true,
      handler: function handler() {// console.log('this.formData:', this.formData)
      }
    } // form: {
    //   deep: true,
    //   handler () {
    //     this.$emit('update:formData', this.form)
    //   }
    // }

  },
  computed: {
    titleLabel: function titleLabel() {
      if (this.fullTitle) {
        return this.fullTitle;
      }

      return "".concat(this.isEdit ? "\u7F16\u8F91".concat(this.title) : "\u65B0\u589E".concat(this.title));
    }
  }
};