export default [{
  title: '员工与权限',
  iconSvg: '员工权限',
  authorityValue: "staff-authority-management",
  children: [{
    path: '/role-list',
    title: '角色管理',
    authorityValue: "roles-manage"
  }, {
    path: '/staff-list',
    title: '员工管理',
    authorityValue: "staff-manage"
  }]
}];