//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  name: '',
  nickname: '',
  companyName: '',
  createTimeRange: {
    start: '',
    end: ''
  }
};
export default {
  meta: {
    title: '店铺入驻意向',
    auth: true,
    authorityValue: 'store-settled-intention'
  },
  name: 'shop-list',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'STORE_INTENTION_LIST',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};