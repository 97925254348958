var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.edit()
            }
          }
        },
        [_vm._v("新增APP首页课程标签")]
      ),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { headStyle: _vm.headStyle, request: _vm.request }
        },
        [
          _c("el-table-column", {
            attrs: { label: "显示排序", prop: "labelSort" }
          }),
          _c("el-table-column", {
            attrs: { label: "标签名称", prop: "labelName" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户信息需满足以下匹配条件" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(_vm.filterStr(scope.row)) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "210",
              label: "是否对未登录和未完善信息的用户展示标签"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.anyShow ? "是" : "否") + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "210", label: "创建时间", prop: "createTime" }
          }),
          _c("el-table-column", {
            attrs: { width: "220", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#F56C6C" },
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "APP首页课程标签",
            visible: _vm.editVisible,
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                "label-width": "100px;",
                model: _vm.nowRow,
                rules: _vm.formRules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "labelName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.nowRow.labelName,
                      callback: function($$v) {
                        _vm.$set(_vm.nowRow, "labelName", $$v)
                      },
                      expression: "nowRow.labelName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签匹配属性" } },
                [
                  _c("p", [_c("br"), _vm._v("性别")]),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.nowRow.genderMale,
                        callback: function($$v) {
                          _vm.$set(_vm.nowRow, "genderMale", $$v)
                        },
                        expression: "nowRow.genderMale"
                      }
                    },
                    [_vm._v("男")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.nowRow.genderFemale,
                        callback: function($$v) {
                          _vm.$set(_vm.nowRow, "genderFemale", $$v)
                        },
                        expression: "nowRow.genderFemale"
                      }
                    },
                    [_vm._v("女")]
                  ),
                  _c("p", [_vm._v("运动偏好")]),
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.nowRow.categoryIds,
                        callback: function($$v) {
                          _vm.$set(_vm.nowRow, "categoryIds", $$v)
                        },
                        expression: "nowRow.categoryIds"
                      }
                    },
                    _vm._l(_vm.dict.initCourseType2, function(item, index) {
                      return _c(
                        "el-checkbox",
                        { key: index, attrs: { label: item.key } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否对未登录的用户展示标签" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.nowRow.anyShow,
                      callback: function($$v) {
                        _vm.$set(_vm.nowRow, "anyShow", $$v)
                      },
                      expression: "nowRow.anyShow"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "number", min: "0" },
                    model: {
                      value: _vm.nowRow.labelSort,
                      callback: function($$v) {
                        _vm.$set(_vm.nowRow, "labelSort", $$v)
                      },
                      expression: "nowRow.labelSort"
                    }
                  }),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("数值越小，标签排序越前")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }