var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c("el-form-item", { attrs: { label: "所属分类" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("ftValue2label")(
                    _vm.form.helpCategoryId,
                    _vm.helpCategorys
                  )
                ) +
                " "
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "问题标题", prop: "helpTitle" } },
            [_vm._v(" " + _vm._s(_vm.form.helpTitle) + " ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "问题排序", prop: "helpSort" } },
            [_vm._v(" " + _vm._s(_vm.form.helpSort) + " ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回答内容", prop: "helpContent" } },
            [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.fitHtmlImgWidth(_vm.form.helpContent))
                }
              })
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }