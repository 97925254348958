var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-content",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.storeName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeName", $$v)
                  },
                  expression: "form.storeName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.storeNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "storeNo", $$v)
                  },
                  expression: "form.storeNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.companyName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "companyName", $$v)
                  },
                  expression: "form.companyName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.depositTimeRange.start,
                  max: _vm.form.depositTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.depositTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.depositTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "退保编号", prop: "depositNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "退保申请时间", prop: "depositTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatDateTime")(scope.row.depositTime)
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "金额(元）", prop: "depositAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "公司名称", prop: "companyName" }
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "storeName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.goRoute(
                              "/store-detail?storeId=" + scope.row.storeId
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.storeName))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "审核时间", prop: "auditTime", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDateTime")(scope.row.auditTime))
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }