var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("服务单详情")]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "header" }, [
          _c("span", { staticClass: "d2-mr-10" }, [_vm._v("服务单状态：")]),
          _vm.tabData.refundType === 1
            ? _c(
                "span",
                { staticStyle: { "font-size": "18px", color: "#70b685" } },
                [_vm._v(_vm._s(_vm._f("status")(_vm.tabData.refundStatus)))]
              )
            : _vm._e(),
          _vm.tabData.refundType === 2
            ? _c(
                "span",
                { staticStyle: { "font-size": "18px", color: "#70b685" } },
                [_vm._v(_vm._s(_vm._f("status2")(_vm.tabData.refundStatus)))]
              )
            : _vm._e(),
          _c("span", { staticStyle: { color: "#d9001b" } }, [
            _vm._v(_vm._s(_vm._f("day")(_vm.tabData.applyTime)))
          ])
        ]),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-descriptions",
              {
                staticClass: "d2-mb-15",
                attrs: {
                  column: 1,
                  border: "",
                  labelStyle: { "text-align": "right", width: "200px" }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "body-tit" }, [
                          _vm._v("服务单信息")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c("el-descriptions-item", { attrs: { label: "服务单号" } }, [
                  _vm._v(" " + _vm._s(_vm.tabData.refundNo) + " ")
                ]),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "退货商品" } },
                  _vm._l(_vm.tabData.goodsItemList, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("el-image", {
                          key: _vm.i,
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "10px"
                          },
                          attrs: {
                            src: item.goodsPrimaryImage,
                            fit: "cover",
                            "preview-src-list": [item.goodsPrimaryImage]
                          }
                        }),
                        _vm._l(item.itemSellAttributes, function(
                          _item,
                          _index
                        ) {
                          return _c("span", { key: _index }, [
                            _vm._v(" " + _vm._s(_item.itemValue))
                          ])
                        }),
                        _c("span", { staticStyle: { margin: "0 10px" } }, [
                          _vm._v(_vm._s(item.goodsName))
                        ]),
                        _c("span", { staticStyle: { margin: "0 10px" } }, [
                          _vm._v("￥" + _vm._s(item.goodsPrice))
                        ]),
                        _c("span", [_vm._v("x" + _vm._s(item.goodsCount))])
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c("el-descriptions-item", { attrs: { label: "申请数量" } }, [
                  _vm._v(" " + _vm._s(_vm.tabData.goodsCount) + " ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户期望" } }, [
                  _vm._v(
                    " " +
                      _vm._s(["", "退货", "换货"][_vm.tabData.refundType]) +
                      " "
                  ),
                  _vm.tabData.refundType == 2
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.tabData.newItemSellAttributes, function(
                            item,
                            index
                          ) {
                            return _c(
                              "span",
                              { key: index, staticStyle: { color: "red" } },
                              [_vm._v(_vm._s(item.itemValue))]
                            )
                          }),
                          _c(
                            "span",
                            { staticStyle: { color: "red", margin: "0 20px" } },
                            [_vm._v("SKU:" + _vm._s(_vm.tabData.newGoodsSku))]
                          ),
                          _vm.tabData.refundAddress.contactName
                            ? _c("div", [
                                _vm._v(" 换货收货地址: "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      margin: "0 20px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.refundAddress(
                                          _vm.tabData.refundAddress
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ]),
                _c("el-descriptions-item", { attrs: { label: "申请原因" } }, [
                  _vm._v(" " + _vm._s(_vm.tabData.refundReason) + " ")
                ]),
                _vm.tabData.refundType === 1
                  ? _c(
                      "el-descriptions-item",
                      { attrs: { label: "申请退款金额" } },
                      [_vm._v(" " + _vm._s(_vm.tabData.refundAmount) + " ")]
                    )
                  : _vm._e(),
                _vm.tabData.refundType === 1
                  ? _c(
                      "el-descriptions-item",
                      { attrs: { label: "退款描述" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.tabData.refundDescription) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.tabData.refundType === 2
                  ? _c(
                      "el-descriptions-item",
                      { attrs: { label: "换货描述" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.tabData.refundDescription) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "客户图片" } },
                  _vm._l(_vm.tabData.descFiles, function(l, i) {
                    return _c("el-image", {
                      key: i,
                      staticStyle: {
                        width: "100px",
                        height: "100px",
                        "margin-right": "10px"
                      },
                      attrs: {
                        src: l.filepath,
                        fit: "cover",
                        "preview-src-list": [l.filepath]
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-descriptions",
              {
                staticClass: "d2-mb-15",
                attrs: {
                  column: 1,
                  border: "",
                  labelStyle: { "text-align": "right", width: "200px" }
                }
              },
              [
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "订单编号" } },
                  [
                    _vm._v(" " + _vm._s(_vm.tabData.orderNo) + " "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleNav("order-detail", {
                              orderId: _vm.tabData.orderId
                            })
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ],
                  1
                ),
                _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                  _vm._v(" " + _vm._s(_vm.tabData.applyTime) + " ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "发票信息" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("invoiceType")(_vm.tabData.invoiceType)) +
                      " "
                  )
                ]),
                _c("el-descriptions-item", { attrs: { label: "下单账号" } }, [
                  _vm._v(" " + _vm._s(_vm.tabData.nickname) + " ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.tabData.consigneeInfo.consigneeName) + " "
                  )
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                  _vm._v(" " + _vm._s(_vm.tabData.consigneeInfo.consigneePhone))
                ]),
                _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tabData.consigneeInfo.consigneeProvince +
                          _vm.tabData.consigneeInfo.consigneeCity +
                          _vm.tabData.consigneeInfo.consigneeDistrict +
                          _vm.tabData.consigneeInfo.consigneeDetails
                      ) +
                      " "
                  )
                ])
              ],
              1
            ),
            _c(
              "el-descriptions",
              {
                staticClass: "d2-mb-15",
                attrs: {
                  column: 1,
                  border: "",
                  labelStyle: { display: "none" }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "body-tit",
                            staticStyle: { "font-weight": "800" }
                          },
                          [_vm._v("协商记录")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              _vm._l(_vm.tabData.refundConsults, function(l, i) {
                return _c("el-descriptions-item", { key: i }, [
                  _c("div", { staticClass: "item-list" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("i", { staticClass: "el-icon-user" })
                    ]),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "top" }, [
                        _c("b", [_vm._v(_vm._s(l.nickname))]),
                        _c("span", [
                          _vm._v(
                            "(" +
                              _vm._s(_vm._f("formatDateTime")(l.createTime)) +
                              ")"
                          )
                        ])
                      ]),
                      l.logType == 1
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 2
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 2
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v("申请原因:" + _vm._s(l.logContent.reason))
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 3
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 4
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 4
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v("审核备注:" + _vm._s(l.logContent.reason))
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 4
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(
                                "退回地址:" +
                                  _vm._s(
                                    l.logContent.refundAddress.contactName +
                                      " " +
                                      l.logContent.refundAddress.phoneNumber +
                                      " "
                                  ) +
                                  _vm._s(
                                    _vm.location(l.logContent.refundAddress)
                                  )
                              )
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 5
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 5
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(
                                "物流公司:" +
                                  _vm._s(
                                    _vm._f("expressCompany")(
                                      l.logContent.backExpressCompany
                                    )
                                  )
                              )
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 5
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(
                                "物流单号:" +
                                  _vm._s(l.logContent.backExpressNumber)
                              )
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 6
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 6
                        ? _c("div", { staticClass: "bottom" }, [
                            l.userType === 1
                              ? _c("span", [
                                  _vm._v(
                                    "状态:" +
                                      _vm._s(
                                        l.logContent.checkedStatus == 3
                                          ? "拒绝退款"
                                          : l.logContent.checkedStatus == 2
                                          ? "同意退款"
                                          : ""
                                      )
                                  )
                                ])
                              : _vm._e(),
                            l.userType === 2
                              ? _c("span", [
                                  _vm._v(
                                    "状态:" +
                                      _vm._s(
                                        l.logContent.checkedStatus == 3
                                          ? "拒绝换货"
                                          : l.logContent.checkedStatus == 2
                                          ? "同意换货"
                                          : ""
                                      )
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      l.logType == 6
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(_vm._s(l.logContent.checkedDescription))
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 7
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 7
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(
                                "物流公司:" +
                                  _vm._s(
                                    _vm._f("expressCompany")(
                                      l.logContent.newExpressCompany
                                    )
                                  )
                              )
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 7
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(
                                "物流单号:" +
                                  _vm._s(l.logContent.newExpressNumber)
                              )
                            ])
                          ])
                        : _vm._e(),
                      l.logType == 8
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 9
                        ? _c("div", { staticClass: "center" }, [
                            _c("span", [_vm._v(_vm._s(l.logContent.text))])
                          ])
                        : _vm._e(),
                      l.logType == 9
                        ? _c("div", { staticClass: "bottom" }, [
                            _c("span", [
                              _vm._v(
                                "用户收货地址:" +
                                  _vm._s(
                                    l.logContent.consignee.consigneeName +
                                      " " +
                                      l.logContent.consignee.consigneePhone +
                                      " "
                                  ) +
                                  _vm._s(
                                    _vm.returnLoction(l.logContent.consignee)
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }