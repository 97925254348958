var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              _vm.record.carefullyChosen
                ? _c(
                    "el-button",
                    {
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.cancelHighQualityGoods([_vm.record])
                        }
                      }
                    },
                    [_vm._v(" 取消精品 ")]
                  )
                : _c(
                    "el-button",
                    {
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setHighQualityGoods([_vm.record])
                        }
                      }
                    },
                    [_vm._v(" 设为精品 ")]
                  ),
              _vm.record.blockType == 1
                ? _c(
                    "el-button",
                    {
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showSheildDialog(_vm.record)
                        }
                      }
                    },
                    [_vm._v(" 屏蔽 ")]
                  )
                : _c(
                    "el-button",
                    {
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showCancelSheildDialog(_vm.record)
                        }
                      }
                    },
                    [_vm._v(" 取消屏蔽 ")]
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "page-content",
        [
          _c("div", [
            _c("p", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.record.postTitle))
            ]),
            _c("p", { staticClass: "content" }, [
              _vm._v(" " + _vm._s(_vm.record.postContent) + " ")
            ]),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              _vm._l(_vm.record.postFiles, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.mediaType === 2
                      ? _c("video", {
                          staticStyle: { width: "500px" },
                          attrs: { src: item.fileUrl, controls: "" }
                        })
                      : _c("base-gallery", {
                          staticClass: "mt-20",
                          attrs: { srcList: [item.fileUrl], imgWidth: "100px" }
                        })
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("TableTitleBar", { attrs: { title: "记录" } }),
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: { pagination: false, data: _vm.record.logs }
            },
            [
              _c("el-table-column", {
                attrs: { label: "操作时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "事件", prop: "logTitle" }
              }),
              _c("el-table-column", {
                attrs: { label: "其他", prop: "logRemark" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作人", prop: "operatorName" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("ShieldDialog", {
        attrs: { row: _vm.row, visible: _vm.shieldDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.shieldDialogVisible = $event
          },
          onSuccess: function($event) {
            return _vm.callPostPostDetails()
          }
        }
      }),
      _c("CandelShieldDialog", {
        attrs: { row: _vm.row, visible: _vm.candelShieldDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.candelShieldDialogVisible = $event
          },
          onSuccess: function($event) {
            return _vm.callPostPostDetails()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }