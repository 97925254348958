var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              _vm.data.auditStatus == 1
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubimt } },
                    [_vm._v("确定")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-card",
        { staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c("el-descriptions-item", { attrs: { label: "入驻类型" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      ["", "第三方店铺入驻", "自营店铺入驻"][_vm.data.storeType]
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1, title: "营业执照" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "营业执照照片" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "auto", height: "100px" },
                    attrs: {
                      "preview-src-list": [_vm.data.businessLicenseImage],
                      src: _vm.data.businessLicenseImage,
                      fit: "cover"
                    }
                  })
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                _vm._v(" " + _vm._s(_vm.data.companyName) + " ")
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "统一社会信用代码" } },
                [_vm._v(" " + _vm._s(_vm.data.unionCreditCode) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "营业执照所在地" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("businessLicenseProvince")(_vm.data)) +
                      " "
                  )
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "住所" } }, [
                _vm._v(" " + _vm._s(_vm.data.businessLicenseAddress) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "成立日期" } }, [
                _vm._v(" " + _vm._s(_vm.data.setupDate) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "营业期限" } }, [
                _vm._v(" " + _vm._s(_vm._f("businessDateFrom")(_vm.data)) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "注册资本" } }, [
                _vm._v(" " + _vm._s(_vm.data.registeredCapital) + " "),
                _c("span", [_vm._v("万元")])
              ]),
              _c("el-descriptions-item", { attrs: { label: "经营范围" } }, [
                _vm._v(" " + _vm._s(_vm.data.businessScope) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1, title: "法人信息" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "法人身份证件" } },
                [
                  _c("el-image", {
                    staticClass: "d2-mr",
                    staticStyle: { width: "240px", height: "151px" },
                    attrs: {
                      src: _vm.data.legalPersonIdCardFace,
                      fit: "cover",
                      "preview-src-list": [_vm.data.legalPersonIdCardFace]
                    }
                  }),
                  _c("el-image", {
                    staticStyle: { width: "240px", height: "151px" },
                    attrs: {
                      src: _vm.data.legalPersonIdCardEmblem,
                      fit: "cover",
                      "preview-src-list": [_vm.data.legalPersonIdCardEmblem]
                    }
                  })
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "法人姓名" } }, [
                _vm._v(" " + _vm._s(_vm.data.legalPersonName) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "法人证件号" } }, [
                _vm._v(" " + _vm._s(_vm.data.legalPersonIdCardNumber) + " ")
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "法人证件有效期" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("legalPersonIdCardTimeFrom")(_vm.data)) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1, title: "税务信息" } },
            [
              _c("el-descriptions-item", { attrs: { label: "纳税人类型" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      ["", "一般纳税人", "小规模纳税人"][_vm.data.taxpayerType]
                    ) +
                    " "
                )
              ]),
              _vm.data.taxpayerType == 1
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "一般纳税人资格证" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "auto", height: "100px" },
                        attrs: {
                          src: _vm.data.taxpayerCertImage,
                          fit: "cover",
                          "preview-src-list": [_vm.data.taxpayerCertImage]
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-descriptions-item", { attrs: { label: "经营类目" } }, [
                _vm._v(
                  " " + _vm._s(_vm._f("businessCategories")(_vm.data)) + " "
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "合同编号" } }, [
                _vm._v(" " + _vm._s(_vm.data.contractNo) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "开户行" } }, [
                _vm._v(" " + _vm._s(_vm.data.bankName) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "账号" } }, [
                _vm._v(" " + _vm._s(_vm.data.bankAccount) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "支行名称" } }, [
                _vm._v(" " + _vm._s(_vm.data.bankBranchName) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1, title: "店铺命名" } },
            [
              _c("el-descriptions-item", { attrs: { label: "店铺名称" } }, [
                _vm._v(" " + _vm._s(_vm.data.storeName) + " ")
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "店铺logo" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "auto", height: "100px" },
                    attrs: {
                      src: _vm.data.storeLogo,
                      fit: "cover",
                      "preview-src-list": [_vm.data.storeLogo]
                    }
                  })
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "店铺简介" } }, [
                _vm._v(" " + _vm._s(_vm.data.storeIntro) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.data.auditStatus == 1
        ? _c(
            "el-card",
            { staticClass: "d2-mb", attrs: { shadow: "never" } },
            [
              _c("el-descriptions", {
                attrs: { column: 1, title: "店铺企业账号信息" }
              }),
              _vm.data.auditStatus == 1
                ? _c(
                    "div",
                    { attrs: { flex: "main" } },
                    _vm._l(_vm.data.contactList, function(l) {
                      return _c(
                        "el-descriptions",
                        { key: l.phone, attrs: { column: 1 } },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "手机号码" } },
                            [_vm._v(" " + _vm._s(l.phone) + " ")]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "用户名" } },
                            [_vm._v(" " + _vm._s(l.name) + " ")]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.data.auditStatus == 1
        ? _c(
            "el-card",
            { staticClass: "d2-mb", attrs: { shadow: "never" } },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1, title: "店铺入驻审核" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "审核结果" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ulData.auditStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.ulData, "auditStatus", $$v)
                            },
                            expression: "ulData.auditStatus"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("审核通过")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("审核不通过")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.ulData.auditStatus == 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "不通过原因" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 2 },
                            model: {
                              value: _vm.ulData.rejectReason,
                              callback: function($$v) {
                                _vm.$set(_vm.ulData, "rejectReason", $$v)
                              },
                              expression: "ulData.rejectReason"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.data.auditStatus > 1
        ? _c(
            "el-card",
            { staticStyle: { "margin-top": "20px", "font-size": "small" } },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("记录")
              ]),
              _vm._l(_vm.data.logs, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.logType === 2
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("备注：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    item.logType === 1
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("审核：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }