var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("救助呼叫详情")]),
      _c("TableTitleBar", [
        _c("span", { staticClass: "space" }, [
          _vm._v("救助呼叫发出时间：" + _vm._s(_vm.record.createTime))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("救助呼叫结束时间："),
          _vm.record.rescueStatus === 2
            ? _c("span", [_vm._v(_vm._s(_vm.record.finishTime))])
            : _vm._e()
        ])
      ]),
      _c("TableTitleBar", { attrs: { title: "呼救者" } }),
      _c(
        "base-table",
        { attrs: { pagination: false, data: [_vm.record] } },
        [
          _c("el-table-column", {
            attrs: { label: "呼叫用户定位城市", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(_vm.getCity(scope.row)))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "呼救发出时的地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.getLocationString(scope.row)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "呼叫用户昵称", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "呼叫用户手机号码", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "呼叫用户编号", prop: "userNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.userNo) + " "),
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: { userId: scope.row.userId }
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "救援人员信息" } }),
      _c(
        "base-table",
        { attrs: { pagination: false, data: _vm.record.rescueUserList || [] } },
        [
          _c("el-table-column", {
            attrs: {
              label: "确认提供帮助时间",
              prop: "checkedTime",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "救援人员真实姓名", prop: "realName" }
          }),
          _c("el-table-column", {
            attrs: { label: "救援人员真实性别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.userGender,
                              _vm.sexs
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "用户编号", prop: "userNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.userNo) + " "),
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: { userId: scope.row.userId }
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "事件轨迹" } }),
      _c("EventTrack", { attrs: { data: _vm.messageList } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }