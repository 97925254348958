//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TabAddCatg from './TabAddCatg.vue';
var title = '帮助中心';
export default {
  meta: {
    title: title,
    auth: true,
    authorityValue: 'app-help'
  },
  name: 'help-center-list',
  components: {
    TabAddCatg: TabAddCatg
  },
  data: function data() {
    return {
      title: title,
      activeName: '1'
    };
  },
  methods: {}
};