import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 账号列表 */
    admin_staffList: createAction(request, '/admin/admin/staffList'),

    /** 保存员工 */
    admin_delStaff: createAction(request, '/admin/admin/deleteStaff'),

    /** 删除员工 */
    admin_saveStaff: createAction(request, '/admin/admin/saveStaff'),

    /** 员工账号详情 */
    admin_staffDetails: createAction(request, '/admin/admin/staffDetails'),

    /** 冻结/启用 账号 */
    admin_lock: createAction(request, '/admin/admin/lock'),

    /** 部门列表（tree 结构） */
    admin_departments: createAction(request, '/admin/admin/departments'),

    /** 删除部门 */
    admin_deleteDepartment: createAction(request, '/admin/admin/deleteDepartment'),

    /** 保存部门 */
    admin_saveDepartment: createAction(request, '/admin/admin/saveDepartment'),

    /** 角色列表 */
    admin_positions: createAction(request, '/admin/admin/positions'),

    /** 添加角色 */
    admin_savePosition: createAction(request, '/admin/admin/savePosition'),

    /** 删除角色 */
    admin_deletePosition: createAction(request, '/admin/admin/deletePosition'),

    /** 权限列表 */
    admin_authorities: createAction(request, '/admin/admin/authorities'),

    /** 保存角色的权限 */
    admin_saveAuthorities: createAction(request, '/admin/admin/saveAuthorities'),

    /** 根据角色id获取权限 */
    admin_positionDetails: createAction(request, '/admin/admin/positionDetails'),

    /** 代理商列表 */
    admin_agents: createAction(request, '/admin/admin/agents'),

    /** 保存代理商 */
    admin_saveAgent: createAction(request, '/admin/admin/saveAgent'),

    /** 代理商删记录 */
    agentDeleteList: createAction(request, '/admin/admin/agentDeleteList')
  };
});