import "core-js/modules/es.array.splice";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var uploadImg = require('@/assets/images/upload.png');

import { cloneDeep } from 'lodash';
var initData = {
  articleId: '',
  articleTypeId: '',
  articleTitle: '',
  articleContent: '',
  articleCover: '',
  articleSort: 1,
  articleShow: true,
  time: '',
  voteList: [{
    title: '',
    image: '',
    desc: ''
  }]
};
var voteObj = {
  title: '',
  image: '',
  desc: ''
};
export default {
  meta: {
    title: '发布投票活动',
    auth: true,
    authorityValue: 'vote-management'
  },
  name: 'vote-publish',
  data: function data() {
    return {
      uploadImg: uploadImg,
      form: cloneDeep(initData),
      voteObj: voteObj,
      initData: initData
    };
  },
  mounted: function mounted() {
    this.getQnToken();
  },
  methods: {
    setCover: function setCover(url) {
      this.form.articleCover = url;
    },
    openUploadModal: function openUploadModal() {
      console.log('上传');
      this.$refs.upload.open();
    },
    toAddVote: function toAddVote() {
      var obj = cloneDeep(this.voteObj);
      this.form.voteList.push(obj);
    },
    toDelVote: function toDelVote(i) {
      this.form.voteList.splice(i, 1);
    }
  }
};