var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box-time flex-center-between" }, [
    _c("span", [_vm._v(_vm._s(_vm.currentTime))]),
    _c("img", {
      staticClass: "img",
      attrs: { src: require("../../assets/image/off.png"), alt: "" },
      on: {
        click: function($event) {
          return _vm.$router.replace("/")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }