export default [{
  title: '用户管理',
  iconSvg: '用户管理',
  authorityValue: 'user-management',
  children: [{
    path: '/fitness-user',
    title: '健身用户',
    authorityValue: 'normal-user'
  }, {
    title: '教练管理',
    authorityValue: 'admin-router-page',
    children: [{
      path: '/certified-coach',
      title: '已认证教练',
      authorityValue: 'authed-coach'
    }, {
      path: '/uncertified-coach',
      title: '未认证教练',
      authorityValue: 'unauth-coach-list'
    }]
  }, {
    title: '教练认证审核',
    authorityValue: 'coach-auth-audit',
    children: [{
      path: '/professional-qualification-audit',
      title: '职业资质审核',
      authorityValue: 'coach-license-audit'
    }, {
      path: '/coach-offline-certification',
      title: '线下门店审核',
      authorityValue: 'coach-offline-audit-view'
    } // {
    //   path: '/offline-store-audit',
    //   title: '线下门店审核'
    // }
    ]
  }, {
    title: '教练直播年审',
    authorityValue: 'coach-yearly-review',
    children: [{
      path: '/coach-waiting-audit-list',
      title: '教练等待审核',
      authorityValue: 'coach-live-audit-wait'
    }, {
      path: '/live-audit-record',
      title: '直播审核记录',
      authorityValue: 'coach-live-audit-list'
    }]
  }, {
    path: '/qualification-certificate-audit',
    title: '资质证书审核',
    authorityValue: 'coach-cert-audit'
  }, {
    path: '/new-active-stat',
    title: '新增与活跃统计',
    authorityValue: 'user-new-active-sta'
  }, {
    path: '/users-retention-stat',
    title: '用户留存统计',
    authorityValue: 'user-retention-sta'
  }]
}];