export default [{
  title: '内容管理',
  iconSvg: '内容管理',
  authorityValue: 'content-management',
  children: [{
    title: '文章管理',
    authorityValue: 'article-manage',
    children: [{
      path: '/article-cate',
      title: '文章分类',
      authorityValue: 'article-type'
    }, {
      path: '/article-list',
      title: '文章列表',
      authorityValue: 'article-list'
    }, {
      path: '/article-edit',
      title: '新增文章',
      authorityValue: 'article-add'
    }]
  }, {
    title: '社区动态',
    authorityValue: 'post-manage',
    children: [{
      path: '/community-message',
      title: '动态管理',
      authorityValue: 'post-list'
    }, {
      path: '/shield-message',
      title: '被屏蔽的动态',
      authorityValue: 'post-blocks'
    }]
  }, {
    path: '/tip-off-list',
    title: '举报管理',
    authorityValue: 'report-manage'
  }, // {
  //   title: '举报管理',
  //   authorityValue: 'report-manage',
  //   children: [{ path: '/tip-off-list', title: '举报列表', authorityValue: 'report-manage' }]
  // },
  {
    path: '/notice-message-list',
    title: 'APP公告',
    authorityValue: 'app-notice'
  }, // {
  //   title: '公告消息',
  //   authorityValue: 'app-notice',
  //   children: [{ path: '/notice-message-list', title: '公告消息', authorityValue: 'app-notice' }]
  // },
  {
    path: '/help-center-list',
    title: 'APP帮助中心',
    authorityValue: 'app-help'
  }, // {
  //   title: '帮助内容',
  //   authorityValue: 'app-help',
  //   children: [{ path: '/help-center-list', title: '帮助中心', authorityValue: 'app-help' }]
  // },
  {
    path: '/feedback-list',
    title: '意见反馈',
    authorityValue: 'feedback-manage'
  }, {
    path: '/large-news',
    title: '大屏资讯',
    authorityValue: 'large-news'
  }, {
    title: '分享管理',
    authorityValue: 'share-content-manage',
    children: [{
      path: '/share-picture',
      title: '分享图片',
      authorityValue: 'share-content-manage'
    }, {
      path: '/share-Copywriting',
      title: '分享文案',
      authorityValue: 'share-content-manage'
    }]
  } // {
  //   title: '计划食谱',
  //   authorityValue: 'admin-router-page',
  //   children: [
  //     { path: '/food-list', title: '食物管理', authorityValue: 'admin-router-page' },
  //     { path: '/food-menu', title: '食谱管理', authorityValue: 'admin-router-page' }
  //   ]
  // }
  ]
}];