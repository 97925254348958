export default [{
  title: 'PLUS和邀好友',
  iconSvg: 'PLUS会员',
  authorityValue: "plus-management",
  children: [{
    path: '/plus-member-list',
    title: 'PLUS会员',
    authorityValue: "plus-list"
  }, {
    path: '/plus-member-usage-record',
    title: '会员使用记录',
    authorityValue: "plus-used-list"
  }, {
    path: '/plus-member-settings',
    title: 'PLUS会员设置',
    authorityValue: "plus-setting"
  }, {
    path: '/plus-member-earning-settings',
    title: 'PLUS门店收益和返现设置',
    authorityValue: "plus-store-income-setting"
  }, {
    path: '/invite-friends-activity',
    title: '拉新活动',
    authorityValue: "invite-activity-list"
  }, {
    path: '/invite-friends-make-money',
    title: '邀好友赚钱攻略',
    authorityValue: "invitation-setting-guide"
  }, {
    path: '/invite-friends-rules',
    title: '邀好友活动规则',
    authorityValue: "invitation-setting-rule"
  }]
}];