var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("购票明细记录")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入用户昵称搜索" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "购买时间" } },
            [
              _c("range-date", {
                attrs: { min: _vm.form.fromTime, max: _vm.form.toTime },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form, "fromTime", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form, "toTime", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "用户昵称", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "购买时间", prop: "orderTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "付款金额（元）", prop: "payAmount" }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }