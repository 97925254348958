var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coach-info" },
    [
      _c(
        "el-image",
        {
          staticStyle: { width: "100px", height: "100px" },
          attrs: { src: _vm.url, "preview-src-list": [_vm.url] }
        },
        [
          _c(
            "div",
            {
              staticClass: "image-slot",
              staticStyle: { "margin-top": "20px" },
              attrs: { slot: "error" },
              slot: "error"
            },
            [
              _c("el-image", {
                staticStyle: { width: "80px", height: "80px" },
                attrs: { src: _vm.uploadImg }
              })
            ],
            1
          )
        ]
      ),
      _c("p", { staticClass: "nickname" }, [_vm._v(_vm._s(_vm.nickname))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }