var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("用户详情")]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "普通用户身份详情", name: "user" } },
            [_c("TabNormalUser", { attrs: { userId: _vm.userId } })],
            1
          ),
          _vm.isCoach
            ? _c(
                "el-tab-pane",
                { attrs: { label: "教练身份详情", name: "coach" } },
                [_c("TabCoach", { attrs: { userId: _vm.userId } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }