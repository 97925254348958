var refundStatus = [{
  label: '待审核',
  value: 1
}, {
  label: '待收货',
  value: 2
}, {
  label: '已拒绝',
  value: 3
}, {
  label: '待收货',
  value: 4
}, // { label: '商品寄回', value: 4 },
{
  label: '已完成',
  value: 5
}, // { label: '商品收货', value: 5 },
{
  label: '商家寄出新货',
  value: 6
}, {
  label: '卖家收到新货',
  value: 7
}, {
  label: '已完成',
  value: 8
}, // { label: '商家收到寄回商品，拒绝退换', value: 9 },
{
  label: '拒绝',
  value: 9
}, {
  label: '用户撤销退换',
  value: 10
}, {
  label: '超时未寄回货物，自动关闭',
  value: 11
}, {
  label: '待审核即将超时',
  value: 101
}, {
  label: '待收货即将超时',
  value: 102
}, {
  label: '待换新货即将超时',
  value: 103
}];
var exchangeStatus = [{
  label: '待审核',
  value: 1
}, // { label: '已同意', value: 2 },
{
  label: '待收货',
  value: 2
}, {
  label: '已拒绝',
  value: 3
}, {
  label: '待收货',
  value: 4
}, // { label: '商品寄回', value: 4 },
{
  label: '待换新货',
  value: 5
}, // { label: '商品收货', value: 5 },
// { label: '商家寄出新货', value: 6 },
{
  label: '待客户收货',
  value: 6
}, {
  label: '卖家收到新货',
  value: 7
}, {
  label: '已完成',
  value: 8
}, // { label: '商家收到寄回商品，拒绝退换', value: 9 },
{
  label: '拒绝',
  value: 9
}, {
  label: '用户撤销退换',
  value: 10
}, {
  label: '超时未寄回货物，自动关闭',
  value: 11
}, {
  label: '待审核即将超时',
  value: 101
}, {
  label: '待收货即将超时',
  value: 102
}, {
  label: '待换新货即将超时',
  value: 103
}];
export { refundStatus, exchangeStatus };