var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "经营类目" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "d2-mb",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.open()
                    }
                  }
                },
                [_vm._v("新增类目")]
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.data.businessCategories,
                    border: "",
                    "header-cell-style": { background: "#F6F6F6" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "一级类目",
                      prop: "goodsTypeName",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scoped) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定删除吗？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delType(scoped)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "附件信息" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.upload.open()
                    }
                  }
                },
                [_vm._v("上传附件")]
              ),
              _vm._l(_vm.data.businessCategoriesFiles, function(item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "20px" } },
                    [
                      _vm._v(_vm._s(item.filepath) + " "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.downloadFiles(
                                item.filepath,
                                item.filepath
                              )
                            }
                          }
                        },
                        [_vm._v("下载附件")]
                      )
                    ],
                    1
                  )
                ])
              }),
              _vm.qnToken
                ? _c("base-upload", {
                    ref: "upload",
                    attrs: { token: _vm.qnToken, type: "other" },
                    on: { confirm: _vm.setCover }
                  })
                : _vm._e()
            ],
            2
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveBusinessCategories }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "类目设置", visible: _vm.centerDialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.centerDialogVisible = $event
            }
          }
        },
        [
          _vm.centerDialogVisible
            ? _c(
                "el-form",
                {
                  ref: "formEl",
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "businessCategories", "label-width": "0" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.businessCategories,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "businessCategories", $$v)
                            },
                            expression: "form.businessCategories"
                          }
                        },
                        _vm._l(_vm.option, function(item) {
                          return _c("el-option", {
                            key: item.goodsTypeId,
                            attrs: {
                              label: item.typeName,
                              value: item.goodsTypeId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmOk } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }