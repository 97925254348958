var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-excel" },
    [
      _c("div", { staticClass: "uploadBox" }, [
        _c(
          "div",
          { staticClass: "btn-upload" },
          [
            _c(
              "el-button",
              {
                attrs: { loading: _vm.loading, size: "mini", type: "primary" },
                on: { click: _vm.handleUpload }
              },
              [_vm._v(" 点击上传 ")]
            )
          ],
          1
        ),
        _c("input", {
          ref: "excel-upload-input",
          staticClass: "excel-upload-input",
          attrs: { type: "file", accept: ".xlsx, .xls" },
          on: { change: _vm.handleClick }
        }),
        _c(
          "div",
          {
            staticClass: "drop",
            on: {
              drop: _vm.handleDrop,
              dragover: _vm.handleDragover,
              dragenter: _vm.handleDragover
            }
          },
          [
            _c("i", { staticClass: "el-icon-upload" }),
            _c("span", [_vm._v("将文件拖到此处")])
          ]
        )
      ]),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "20px" },
          on: {
            click: function($event) {
              return _vm.goBack()
            }
          }
        },
        [_vm._v("返回")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }