var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("投票结果")]),
      _c("h5", [_vm._v("投票总数：" + _vm._s(_vm.extraData.voteCount))]),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { request: _vm.request, pagination: false }
        },
        [
          _c("el-table-column", {
            attrs: { label: "投票选项", prop: "optionTitle" }
          }),
          _c("el-table-column", {
            attrs: { label: "获得票数", prop: "voteCount" }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }