var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("发票管理")]),
      _c(
        "page-content",
        [
          _c("base-radio-group", {
            attrs: { options: _vm.options, childrenType: "button" },
            on: {
              change: function($event) {
                return _vm.handleSearch(_vm.form)
              }
            },
            model: {
              value: _vm.form.auditStatus,
              callback: function($$v) {
                _vm.$set(_vm.form, "auditStatus", $$v)
              },
              expression: "form.auditStatus"
            }
          }),
          _c(
            "base-form",
            {
              staticClass: "mt-20",
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入订单号" },
                    model: {
                      value: _vm.form.orderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "orderNo", $$v)
                      },
                      expression: "form.orderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请来源" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.applyTypes },
                    model: {
                      value: _vm.form.applyType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "applyType", $$v)
                      },
                      expression: "form.applyType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请发票类型" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.invoiceTypes },
                    model: {
                      value: _vm.form.invoiceType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "invoiceType", $$v)
                      },
                      expression: "form.invoiceType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请日期" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.createTimeRange.start,
                      max: _vm.form.createTimeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.createTimeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.createTimeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "抬头类型" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.titleTypes },
                    model: {
                      value: _vm.form.invoiceTitleType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "invoiceTitleType", $$v)
                      },
                      expression: "form.invoiceTitleType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.storeName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "storeName", $$v)
                      },
                      expression: "form.storeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户" } },
                [
                  _c("el-input", {
                    staticStyle: { wdith: "300px" },
                    attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "extra" },
                  on: { click: _vm.download },
                  slot: "extra"
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _vm.form.auditStatus == 1
            ? _c("TableTitleBar", { attrs: { title: "查询统计" } }, [
                _c("span", { staticClass: "space" }, [
                  _vm._v("待开票总额：" + _vm._s(_vm.extraData.orderAmount))
                ]),
                _c("span", { staticClass: "space" }, [
                  _vm._v("待开票笔数：" + _vm._s(_vm.extraData.invoiceCount))
                ])
              ])
            : _vm._e(),
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: { request: _vm.request },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单编号", prop: "orderNo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "order-detail",
                                    query: { orderId: scope.row.orderId }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.orderNo))]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "申请时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "申请来源", prop: "applyType" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(
                            row.applyType == 1
                              ? "下单申请"
                              : row.applyType == 2
                              ? "申请开票"
                              : ""
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "申请发票类型", prop: "invoiceType" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(
                            row.invoiceType == 1
                              ? "普通发票"
                              : row.invoiceType == 2
                              ? "增值税专用发票"
                              : ""
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "抬头名称", prop: "invoiceTitle" }
              }),
              _c("el-table-column", {
                attrs: { label: "发票状态", prop: "auditStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(
                            row.auditStatus == 1
                              ? "待开票"
                              : row.auditStatus == 2
                              ? "已开票"
                              : row.auditStatus == 3
                              ? "驳回"
                              : ""
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/invoice-audit",
                                    query: { invoiceId: row.invoiceId }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.auditStatus == 1
                                        ? "审核"
                                        : row.auditStatus == 2
                                        ? "查看详情"
                                        : row.auditStatus == 3
                                        ? "查看详情"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }