var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("span", [_vm._v("违规原因")])]),
      _c(
        "div",
        { staticStyle: { width: "1000px" } },
        [
          _c(
            "el-card",
            { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
            [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "left" }, [_vm._v("违规原因：")]),
                _c("div", {
                  staticClass: "html_box",
                  domProps: { innerHTML: _vm._s(_vm.cardData.violationReason) }
                })
              ])
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }