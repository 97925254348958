import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 这里的定义有点难理解，后端接口返回的结构也是比较难以理解
 * */
import { catesAll } from '@/const';
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      dictCatesAll: catesAll,
      cates: [],
      cate1str: '',
      cate2str: '',
      cate3str: '',
      cate1: [],
      // 一级分类
      cate2: [],
      // 二级分类
      cate3: [],
      // 三级分类
      value: ['', '', '', '']
    };
  },
  watch: {
    form: function form(newVal) {
      console.warn('form change', newVal);
      var val = [];

      if (newVal.categoryId) {
        val.push(newVal.categoryId);
        this.value0Change(newVal.categoryId, false);
      }

      if (newVal.subcategoryFirst) {
        val.push(newVal.subcategoryFirst);
        this.value1Change(newVal.subcategoryFirst, false);
      }

      if (newVal.subcategorySecond) {
        val.push(newVal.subcategorySecond);
        this.value2Change(newVal.subcategorySecond, false);
      }

      if (newVal.subcategoryThree) {
        val.push(newVal.subcategoryThree);
      }

      console.warn('form after change', val);
      this.value = val;
    }
  },
  methods: {
    value0Change: function value0Change(res) {
      var submit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      console.warn('valueChange', res, submit);
      var cate0 = {};
      var cate1 = [];
      var cate2 = [];
      var cate3 = [];
      var cate1str = '';
      var cate2str = '';
      var cate3str = '';
      console.log('cate0', cate0);
      this.dictCatesAll.map(function (item) {
        if (res === item.id) {
          cate0 = item;
        }
      }); // if (res === '10000001') { // 健身训练没有分类限定

      cate1 = cate0.subList;
      cate1str = cate0.subLables[0];
      cate2str = cate0.subLables[1];
      cate3str = cate0.subLables[2];
      this.cate1 = cate1;
      this.cate2 = cate2;
      this.cate3 = cate3;
      this.cate1str = cate1str;
      this.cate2str = cate2str;
      this.cate3str = cate3str;
      this.value[1] = '';
      this.value[2] = '';
      this.value[3] = '';
      this.onValuechange(this.value, submit); // if (cate1.length < 1) {
      //   this.onValuechange(this.value, submit)
      // }
    },
    value1Change: function value1Change(res) {
      var submit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      console.warn('value1Change', res);
      var cate0 = {};
      var cate2 = [];
      var cate3 = [];
      console.log('cate0', cate0);
      this.cate1.map(function (item) {
        if (res === item.id) {
          cate0 = item;
        }
      }); // if (res === '10000001') { // 健身训练没有分类限定

      cate2 = cate0.subList;
      this.cate2 = cate2;
      this.cate3 = cate3;
      this.value[2] = '';
      this.value[3] = '';
      this.onValuechange(this.value, submit); // if (cate2.length < 1) {
      //   this.onValuechange(this.value, submit)
      // }
    },
    value2Change: function value2Change(res) {
      var submit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      console.warn('value1Change', res);
      var cate0 = {};
      var cate3 = [];
      this.cate2.map(function (item) {
        if (res === item.id) {
          cate0 = item;
        }
      }); // if (res === '10000001') { // 健身训练没有分类限定

      cate3 = cate0.subList;
      this.cate3 = cate3;
      this.value[3] = '';
      this.onValuechange(this.value, submit); // if (cate3.length < 1) {
      //   this.onValuechange(this.value, submit)
      // }
    },
    value3Change: function value3Change() {
      this.onValuechange(this.value);
    },
    onValuechange: function onValuechange(res) {
      var submit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.value = res;
      submit && this.$emit('change', {
        categoryId: res[0] || '',
        subcategoryFirst: res[1] || '',
        subcategorySecond: res[2] || '',
        subcategoryThree: res[3] || ''
      });
    }
  }
};