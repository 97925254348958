var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v("订单详情")]),
      _c("div", { staticClass: "top" }, [
        _vm.showSteps
          ? _c(
              "div",
              { staticClass: "steps" },
              [_c("steps", { attrs: { orderDetail: _vm.tableData } })],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "orderNum" },
          [_c("orderNum", { attrs: { orderDetail: _vm.tableData } })],
          1
        )
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "div",
            { staticClass: "center" },
            [_c("orderUsp", { attrs: { orderDetail: _vm.tableData } })],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom" },
            [_c("orderTaber", { attrs: { orderDetail: _vm.tableData } })],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }