export default [{
  title: '投票管理',
  iconSvg: '活动直播管理',
  authorityValue: "vote-management",
  children: [{
    path: '/vote-setting',
    title: '设置投票费用',
    authorityValue: "vote-management"
  }, {
    path: '/vote-publish',
    title: '发布投票活动',
    authorityValue: "vote-management"
  }, {
    path: '/vote-list',
    title: '投票活动记录',
    authorityValue: "vote-management"
  }]
}];