var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v(" 私教课交易分析 ")]),
      _c("div", { staticClass: "card d2-mb" }, [
        _c(
          "div",
          { attrs: { flex: "main:right" } },
          [
            _c("range-date", {
              attrs: { "picker-options": _vm.pickerOptions, clearable: true },
              on: { change: _vm.rangeDateChange }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "card d2-mb card-box", attrs: { flex: "main" } },
        [
          _c(
            "div",
            { staticClass: "body d2-mb" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _vm._l(_vm.newsList, function(l, i) {
                    return [
                      _c("el-col", { key: i, attrs: { span: 8, offset: 0 } }, [
                        _c("div", { staticClass: "item" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !l.hide,
                                  expression: "!l.hide"
                                }
                              ],
                              staticClass: "tit"
                            },
                            [_vm._v(_vm._s(l.tit))]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !l.hide,
                                  expression: "!l.hide"
                                }
                              ],
                              staticClass: "centent"
                            },
                            [_vm._v(_vm._s(l.count))]
                          )
                        ])
                      ])
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("div", { staticClass: "card-line" }, [
            _c("div", { staticClass: "card-line-item" }, [
              _c("div", [_vm._v("成交转化率")]),
              _c("div", [_vm._v(_vm._s(_vm.data.tradeCoverRate) + "%")])
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "card d2-mb" },
        [
          _c("h3", [_vm._v("交易趋势")]),
          _c(
            "el-checkbox-group",
            {
              attrs: { min: 0, max: 4 },
              model: {
                value: _vm.checkedCities,
                callback: function($$v) {
                  _vm.checkedCities = $$v
                },
                expression: "checkedCities"
              }
            },
            [
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "成交转化" } },
                    [
                      _c("el-checkbox", { attrs: { label: "trades" } }, [
                        _vm._v("成交单量")
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "tradeConsumers" } },
                        [_vm._v("成交客户数")]
                      ),
                      _c("el-checkbox", { attrs: { label: "tradeAmount" } }, [
                        _vm._v("成交金额")
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "tradeCoverRate" } },
                        [_vm._v("成交转化率")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "d2-mt d2-mb" }, [
            _vm._v("已选："),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.checkedCitiesLen) + " / 4")
            ]),
            _vm._v("项")
          ]),
          _c("div", { ref: "chart1", staticStyle: { height: "400px" } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }