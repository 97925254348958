//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  courseNo: '',
  courseName: '',
  appShow: null
};
export default {
  data: function data() {
    return {
      activeName: 'COURSE_LIST',
      form: cloneDeep(initForm),
      initForm: cloneDeep(initForm),
      headStyle: function headStyle() {
        return 'text-align:center';
      },
      request: {
        api: 'COURSE_LIST',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    onSelect: function onSelect(item) {
      this.$emit('onSelect', item);
    },
    handleSearch: function handleSearch() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initForm;
      var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      console.log('values:', values);
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData(reset);
    },
    onTabClick: function onTabClick() {
      this.$set(this.request, 'api', this.activeName);
      this.form = cloneDeep(initForm);
      this.handleSearch();
    }
  }
};