var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "180px" } },
        [
          _c("el-form-item", { attrs: { label: "动作编号" } }, [
            _vm._v(" " + _vm._s(_vm.res.actionNo) + " ")
          ]),
          _c("el-form-item", { attrs: { label: "动作名称" } }, [
            _vm._v(" " + _vm._s(_vm.res.actionName) + " ")
          ]),
          _c("el-form-item", { attrs: { required: "", label: "动作视频" } }, [
            _c("div", { staticClass: "flex-box" }, [
              _vm.res.videoUrl
                ? _c("video", {
                    staticClass: "form-video",
                    attrs: {
                      id: "action-video",
                      controls: "",
                      src: _vm.res.videoUrl
                    }
                  })
                : _vm._e()
            ])
          ]),
          _c("el-form-item", { attrs: { label: "动作音频" } }, [
            _c("div", { staticClass: "flex-box" }, [
              _vm.res.titleAudioUrl
                ? _c("audio", {
                    staticClass: "form-audio",
                    attrs: { controls: "", src: _vm.res.titleAudioUrl }
                  })
                : _vm._e()
            ])
          ]),
          _c("el-form-item", { attrs: { label: "单位动作时长" } }, [
            _vm._v(" " + _vm._s(_vm.res.actionSecond) + "S ")
          ]),
          _c("el-form-item", { attrs: { label: "动作类型" } }, [
            _vm._v(
              " " + _vm._s(_vm.dict.actionTypesMap[_vm.res.actionType]) + " "
            )
          ]),
          _c("el-form-item", { attrs: { label: "动作难度" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.dict.difficultyLevelsMap[_vm.res.difficultyLevel]) +
                " "
            )
          ]),
          _c("el-form-item", { attrs: { label: "训练类型" } }, [
            _vm._v(" " + _vm._s(_vm.res.categoryIdsStr.join(",")) + " ")
          ]),
          _c("el-form-item", { attrs: { label: "部位" } }, [
            _vm._v(" " + _vm._s(_vm.res.bodyParts.join(",")) + " ")
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "显示排序" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    border: "",
                    data: _vm.res.actionSorts,
                    "header-cell-style": { background: "#F6F6F6" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "APP选项" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dict.courseCates[scope.row.categoryId]
                                ) +
                                "->" +
                                _vm._s(scope.row.bodyPart) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "排序序号(数值越小，排序越前。)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.sortValue) + " ")
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { required: "", label: "动作介绍" } }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.res.actionExplain) }
            })
          ])
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [_c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")])],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }