import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _typeof from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/typeof";
export default {
  // 上传类型封装
  beforeUploadCommon: function beforeUploadCommon(file, type) {
    var fileName = file.name;
    var pos = fileName.lastIndexOf('.');
    var lastName = fileName.substring(pos, fileName.length); // 获取文件后缀

    var typeObj = {
      audio: function audio() {
        if (!/\.(m4a|aac|mp3|wav)$/.test(lastName.toLowerCase())) {
          Message.error('音频文件类型只支持m4a、aac、mp3、wav格式');
          return false;
        } else return true;
      },
      video: function video() {
        if (!/\.(mp4|3gp|m3u8)$/.test(lastName.toLowerCase())) {
          Message.error('音频文件类型只支持mp4、3gp、m3u8格式');
          return false;
        } else return true;
      },
      pic: function pic() {
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(lastName.toLowerCase())) {
          Message.error('图片文件类型只支持png、jpeg、jpg格式');
          return false;
        } // const kSize = file.size / 1024 < 600
        // if (!kSize) {
        //   Message.error('图片大小不能超过600K')
        //   return false
        // }


        return true;
      }
    };

    try {
      return typeObj[type]();
    } catch (e) {
      console.log('错误');
      Message.error('上传错误，请联系管理员');
    }
  },
  // 获取文件后缀类型
  catchType: function catchType(sourceName) {
    var typestr = sourceName.toLowerCase().substring(sourceName.lastIndexOf('.'));
    return typestr;
  },

  /**
   * @description 判断数据是否为空
   * @param {String} any
   */
  isEmpty: function isEmpty(v) {
    switch (_typeof(v)) {
      case 'undefined':
        return true;

      case 'string':
        if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) return true;
        break;

      case 'boolean':
        if (!v) return true;
        break;

      case 'number':
        if (v === 0 || isNaN(v)) return true;
        break;

      case 'object':
        if (v === null || v.length === 0) return true;

        for (var i in v) {
          return false;
        }

        return true;
    }

    return false;
  }
};