var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.goRoute("action-edit")
            }
          }
        },
        [_vm._v("添加动作")]
      ),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "动作编号" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入动作编号" },
                model: {
                  value: _vm.form.actionNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "actionNo", $$v)
                  },
                  expression: "form.actionNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动作名称" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入动作名称" },
                model: {
                  value: _vm.form.actionName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "actionName", $$v)
                  },
                  expression: "form.actionName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动作类型" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.actionType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "actionType", $$v)
                    },
                    expression: "form.actionType"
                  }
                },
                [
                  _c("el-option", {
                    key: 0,
                    attrs: { label: "全部", value: 0 }
                  }),
                  _vm._l(_vm.dict.actionTypes, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.name, value: item.key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部位" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.part,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "part", $$v)
                    },
                    expression: "form.part"
                  }
                },
                _vm._l(_vm.dict.actionParts, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.name, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练类型" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "categoryId", $$v)
                    },
                    expression: "form.categoryId"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.dict.initCourseCates, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.name, value: item.key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "浏览人数" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.browsePeopleRange.start,
                  max: _vm.form.browsePeopleRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.browsePeopleRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.browsePeopleRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "浏览量" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.browseCountRange.start,
                  max: _vm.form.browseCountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.browseCountRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.browseCountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收藏数" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.favoriteCountRange.start,
                  max: _vm.form.favoriteCountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(
                      _vm.form.favoriteCountRange,
                      "start",
                      $event
                    )
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.favoriteCountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { headStyle: _vm.headStyle, request: _vm.request }
        },
        [
          _c("el-table-column", {
            attrs: { label: "动作编号", prop: "actionNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "动作名称", prop: "actionName" }
          }),
          _c("el-table-column", {
            attrs: { label: "动作类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.actionTypeStr(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "部位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.actionPartStr(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "动作难度", prop: "difficultyLevel" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      _vm._s(_vm.dict.difficultyLevelsMap[row.difficultyLevel])
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "训练类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.actionCateStr(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "浏览人数", prop: "browsePeople" }
          }),
          _c("el-table-column", {
            attrs: { label: "浏览量", prop: "browseCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "收藏数", prop: "favoriteCount" }
          }),
          _c("el-table-column", {
            attrs: {
              width: "150",
              label: "用户自制课程使用量",
              prop: "useCount"
            }
          }),
          _c("el-table-column", {
            attrs: { width: "110", label: "APP是否显示" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      on: {
                        change: function($event) {
                          return _vm.onToggleShow(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.appShow,
                        callback: function($$v) {
                          _vm.$set(scope.row, "appShow", $$v)
                        },
                        expression: "scope.row.appShow"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "210", label: "创建时间", prop: "createTime" }
          }),
          _c("el-table-column", {
            attrs: { width: "220", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute(
                              "action-detail?id=" + scope.row.actionId
                            )
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#F56C6C" },
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }