import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import axios from 'axios';
import Adapter from 'axios-mock-adapter';
import { get } from 'lodash'; // import util from '@/libs/util'

import { errorLog, errorCreate } from './tools';
import router from '@/router';
/**
 * @description 创建请求实例
 */

function createService() {
  // 创建一个 axios 实例
  var service = axios.create(); // 请求拦截

  service.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    // 发送失败
    console.log(error);
    return Promise.reject(error);
  }); // 响应拦截

  service.interceptors.response.use(function (response) {
    // dataAxios 是 axios 返回数据中的 data
    var dataAxios = response.data; // 这个状态码是和后端约定的

    var code = dataAxios.statusCode || 0; // 根据 code 进行判断

    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      if (code === 301 || code === 302) {
        console.warn('req code', code);
        YL.cookies.set('redirect', window.app.$route.fullPath);
        window.store.dispatch('d2admin/account/logout', {
          vm: window.app,
          confirm: false
        });
        var msgDom = document.querySelector('.el-message--error');

        if (!msgDom) {
          errorCreate("".concat(dataAxios.statusMsg));
        }

        return;
      } // 有 code 代表这是一个后端接口 可以进行进一步的判断


      switch (code) {
        case 200:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios.data;

        default:
          // 不是正确的 code
          errorCreate("".concat(dataAxios.statusMsg));
          break;
      }
    }
  }, function (error) {
    var status = get(error, 'response.status');

    switch (status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        error.message = '未授权，请登录';
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = 'API接口发生错误,code：500';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }

    var msgDom = document.querySelector('.el-message--error');

    if (!msgDom) {
      errorLog(error);
    }

    return Promise.reject(error);
  });
  return service;
}
/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */


function createRequestFunction(service) {
  return function (config) {
    var token = YL.cookies.get(YL.cookiesName.token);
    var configDefault = {
      headers: {
        Authorization: token,
        token: token,
        platform: 'admin',
        'Content-Type': get(config, 'headers.Content-Type', 'application/json')
      },
      method: 'post',
      timeout: 5000 * 4,
      baseURL: process.env.VUE_APP_API,
      data: {}
    };
    var t = -new Date().getTimezoneOffset() / 60 == 8 ? 'Asia/Shanghai' : '';
    var n = +new Date();
    configDefault.headers.timestamp = "".concat(n, "_").concat(t);
    return service(Object.assign(configDefault, config));
  };
} // 用于真实网络请求的实例和请求方法


export var service = createService();
export var request = createRequestFunction(service); // 用于模拟网络请求的实例和请求方法

export var serviceForMock = createService();
export var requestForMock = createRequestFunction(serviceForMock); // 网络请求数据模拟工具

export var mock = new Adapter(serviceForMock);