var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("base-editor", {
        attrs: { upToken: _vm.qnToken, content: _vm.form.guideContent },
        on: {
          "update:content": function($event) {
            return _vm.$set(_vm.form, "guideContent", $event)
          }
        }
      }),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.onSave }
        },
        [_vm._v("保存")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }