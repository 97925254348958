import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  meta: {
    title: '审核记录详情',
    auth: true,
    authorityValue: 'live-audited-record'
  },
  name: 'course-live-list-details',
  data: function data() {
    return {
      rate: false,
      rateLink: 0,
      player: null,
      playbackUrl: ''
    };
  },
  methods: {
    rateClick: function rateClick() {
      this.rateLink = this.rateLink === 3 ? 0 : this.rateLink + 1;
      this.rate = this.rateLink !== 0;
    },
    dataBind: function dataBind() {
      this.player = TCPlayer('leb-video-container', {
        autoplay: true,
        height: '560px'
      });
      this.player.src(this.playbackUrl);
    },
    destroyPlayer: function destroyPlayer() {
      this.player && this.player.dispose();
    },
    urlConversion: function urlConversion(path) {
      var reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
      path = path.replace(reg, 'https://$2$3$4$5$6');
      return path;
    }
  },
  mounted: function mounted() {
    this.playbackUrl = this.urlConversion(this.$route.query.playbackUrl);
    console.log(this.playbackUrl);
    this.dataBind();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroyPlayer();
  }
};