var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "tabs" },
        _vm._l(_vm.dict.initCourseType, function(item) {
          return _c(
            "el-button",
            {
              key: item.key,
              attrs: {
                type: item.key === _vm.nowCateId ? "primary" : "default"
              },
              on: {
                click: function($event) {
                  return _vm.onChangeCate(item.key)
                }
              }
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        1
      ),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "label-width": "150" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择课程到新课专区" } },
            [
              _c(
                "el-tag",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.openCourseModal }
                },
                [_vm._v("添加课程")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { float: "right", "margin-bottom": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.onSaveSort }
        },
        [_vm._v("保存排序")]
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { headStyle: _vm.headStyle, request: _vm.request }
        },
        [
          _c("el-table-column", {
            attrs: { label: "课程编号", width: "130", prop: "courseNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.courseNo) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "课程名称", prop: "courseName" }
          }),
          _c("el-table-column", {
            attrs: { label: "课程大类", prop: "categoryName" }
          }),
          _c("el-table-column", {
            attrs: { label: "一级分类", prop: "subcategoryFirstName" }
          }),
          _c("el-table-column", {
            attrs: { label: "二级分类", prop: "subcategorySecondName" }
          }),
          _c("el-table-column", {
            attrs: { label: "三级分类", prop: "subcategoryThreeName" }
          }),
          _c("el-table-column", {
            attrs: { label: "教练性别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.genderStr(scope.row.coachGender)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "课程难度" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" L" + _vm._s(scope.row.courseDifficultyLevel) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "排序" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { type: "number" },
                      model: {
                        value: scope.row.newUserSort,
                        callback: function($$v) {
                          _vm.$set(scope.row, "newUserSort", $$v)
                        },
                        expression: "scope.row.newUserSort"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onDel(scope.row)
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "请选择课程", visible: _vm.courseVisible },
          on: {
            "update:visible": function($event) {
              _vm.courseVisible = $event
            }
          }
        },
        [_c("course-choose", { on: { onSelect: _vm.onAddCourse } })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }