var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d2-pb" }, [
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _vm._m(0),
        _c(
          "el-descriptions",
          { attrs: { direction: "vertical", column: 5, border: "" } },
          [
            _c("el-descriptions-item", { attrs: { label: "买家" } }, [
              _vm._v(_vm._s(_vm.data.nickname))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收货人" } }, [
              _vm._v(_vm._s(_vm.data.invoicePayeeName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
              _vm._v(_vm._s(_vm.data.invoicePayeeAddress))
            ]),
            _c("el-descriptions-item", { attrs: { label: "手机号码" } }, [
              _vm._v(_vm._s(_vm.data.invoicePayeePhone))
            ]),
            _c("el-descriptions-item", { attrs: { label: "买家留言" } }, [
              _vm._v(_vm._s(_vm.data.buyerRemark))
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _vm._m(1),
        _c(
          "el-descriptions",
          { attrs: { direction: "vertical", column: 11, border: "" } },
          [
            _c("el-descriptions-item", { attrs: { label: "发票类型" } }, [
              _vm._v(
                _vm._s(
                  _vm._f("fileInvoiceTitleType")(_vm.data.invoiceTitleType)
                )
              )
            ]),
            _c("el-descriptions-item", { attrs: { label: "发票抬头" } }, [
              _vm._v(_vm._s(_vm.data.invoiceTitle))
            ]),
            _c("el-descriptions-item", { attrs: { label: "纳税人识别号" } }, [
              _vm._v(_vm._s(_vm.data.taxpayerIdNumber))
            ]),
            _c("el-descriptions-item", { attrs: { label: "地址" } }, [
              _vm._v(_vm._s(_vm.data.taxpayerAddress))
            ]),
            _c("el-descriptions-item", { attrs: { label: "电话" } }, [
              _vm._v(_vm._s(_vm.data.taxpayerPhone))
            ]),
            _c("el-descriptions-item", { attrs: { label: "开户银行" } }, [
              _vm._v(_vm._s(_vm.data.invoiceBankName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "银行账号" } }, [
              _vm._v(_vm._s(_vm.data.invoiceBankAccount))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收票人姓名" } }, [
              _vm._v(_vm._s(_vm.data.invoicePayeeName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收票人手机" } }, [
              _vm._v(_vm._s(_vm.data.invoicePayeePhone))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收票人地址" } }, [
              _vm._v(_vm._s(_vm.data.invoicePayeeAddress))
            ]),
            _c("el-descriptions-item", { attrs: { label: "发票内容" } }, [
              _vm._v(_vm._s(_vm.data.invoiceContent))
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.data.goodsItemList,
              border: "",
              "header-cell-style": { background: "#F6F6F6" }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "goodsSku",
                label: "商品编号",
                width: "180",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "goodsBarcode",
                label: "商品名称",
                width: "180",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsPrice", label: "商品价格", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "itemSellAttributes",
                label: "属性",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm._v(" " + _vm._s(_vm.itemSellAttributesFn(row)) + " ")
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsCount", label: "商品数量", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsStock", label: "库存", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "subtotalAmount", label: "小计", align: "center" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("i", {
        staticClass: "el-icon-user-solid",
        staticStyle: { "margin-right": "8px" }
      }),
      _vm._v("收货人信息")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("i", {
        staticClass: "el-icon-s-order",
        staticStyle: { "margin-right": "8px" }
      }),
      _vm._v("发票信息")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }