import "core-js/modules/es.array.join";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  auditNo: '',
  auditStatus: '',
  coachName: '',
  applyTimeRange: {
    start: '',
    end: ''
  },
  auditTimeRange: {
    start: '',
    end: ''
  }
};
export default {
  meta: {
    title: '私教直播审核记录',
    auth: true,
    authorityValue: "live-audited-record"
  },
  name: 'course-live-list',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'PTRAIN_COACH_EVALUATE_AUDIT_LIVE_LIST',
        params: cloneDeep(initForm)
      }
    };
  },
  filters: {
    rejectReasons: function rejectReasons(val) {
      if (val && val.length > 0) {
        return val.join('，');
      } else {
        return '暂无';
      }
    }
  },
  methods: {
    seeReplay: function seeReplay(index) {
      if (!index.playbackUrl) return this.$message.error('暂无回放');
      this.goRoute('/course-live-list-details?playbackUrl=' + index.playbackUrl);
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};