var temp = {
  id: '10000008',
  name: '冥想',
  subLables: ['分类'],
  subList: [{
    id: '100008101',
    name: '入门介绍',
    subList: []
  }, {
    id: '100008102',
    name: '一周入门练习',
    subList: []
  }, {
    id: '100008103',
    name: '正念入门',
    subList: []
  }, {
    id: '100008104',
    name: '慢节奏放松',
    subList: []
  }, {
    id: '100008105',
    name: '情绪',
    subList: []
  }, {
    id: '100008106',
    name: '压力',
    subList: []
  }, {
    id: '100008107',
    name: '专注',
    subList: []
  }, {
    id: '100008108',
    name: '幸福',
    subList: []
  }, {
    id: '100008109',
    name: '睡眠',
    subList: []
  }, {
    id: '100008110',
    name: '3分钟冥想',
    subList: []
  }, {
    id: '100008111',
    name: '自信之音',
    subList: []
  }]
};
export default temp;