var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("省代理商账号管理")]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            [
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onDelAll } },
                    [_vm._v("删除记录")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                    [_vm._v("新增账号")]
                  )
                ],
                1
              ),
              _c(
                "base-table",
                { ref: "baseTable", attrs: { request: _vm.request } },
                [
                  _c("el-table-column", {
                    attrs: { label: "账号名称", prop: "adminAccount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", prop: "adminName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "管理的省份", prop: "agents" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("HiddenText", {
                              attrs: { text: _vm.getCityName(row) }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "代理商ID", prop: "adminId", width: "200" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onFrozen(row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(row.locked ? "解冻" : "冻结") +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onEdit(row)
                                  }
                                }
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onDel(row)
                                  }
                                }
                              },
                              [_vm._v(" 删除 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-dialog",
            {
              attrs: {
                width: "500px",
                title: "账号",
                rules: _vm.rules,
                visible: _vm.dialogVisible,
                formData: _vm.dialogFormData,
                isEdit: _vm.isEdit
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                "update:formData": function($event) {
                  _vm.dialogFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.dialogFormData = $event
                },
                confirm: _vm.callAdminSavePosition
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号名称", prop: "adminAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号名称" },
                    model: {
                      value: _vm.dialogFormData.adminAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "adminAccount", $$v)
                      },
                      expression: "dialogFormData.adminAccount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录密码", prop: "loginPassword" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入登录密码" },
                    model: {
                      value: _vm.dialogFormData.loginPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "loginPassword", $$v)
                      },
                      expression: "dialogFormData.loginPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "adminName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.dialogFormData.adminName,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "adminName", $$v)
                      },
                      expression: "dialogFormData.adminName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "管理省份", prop: "agents" } },
                [
                  _c("base-select", {
                    attrs: {
                      options: _vm.provinceList,
                      multiple: "",
                      "collapse-tags": ""
                    },
                    model: {
                      value: _vm.dialogFormData.agents,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "agents", $$v)
                      },
                      expression: "dialogFormData.agents"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }