var commonSub1 = [{
  id: '100009309',
  name: '综合',
  subList: []
}];
var commonSub = [{
  id: '100009203',
  name: '减脂',
  subList: commonSub1
}];
var temp = {
  id: '10000009',
  name: '器械课程',
  subLables: ['类别', '类型', '部位'],
  subList: [{
    id: '100009101',
    name: '力量站',
    subList: [{
      id: '100009205',
      name: '塑形',
      subList: [{
        id: '100009301',
        name: '背部',
        subList: []
      }, {
        id: '100009307',
        name: '肩部',
        subList: []
      }, {
        id: '100009304',
        name: '手臂',
        subList: []
      }, {
        id: '100009308',
        name: '腿部',
        subList: []
      }, {
        id: '100009305',
        name: '臀部',
        subList: []
      }]
    }]
  }, {
    id: '100009102',
    name: '动感单车',
    subList: [{
      id: '100009201',
      name: '热身',
      subList: commonSub1
    }, {
      id: '100009203',
      name: '减脂',
      subList: []
    }, {
      id: '100009202',
      name: '拉伸',
      subList: []
    }]
  }, {
    id: '100009103',
    name: '健腹轮',
    subList: [{
      id: '100009202',
      name: '拉伸',
      subList: [{
        id: '100009302',
        name: '肩颈',
        subList: []
      }]
    }, {
      id: '100009204',
      name: '增肌塑形',
      subList: [{
        id: '100009303',
        name: '胸背',
        subList: []
      }]
    }, {
      id: '100009203',
      name: '减脂',
      subList: [{
        id: '100009306',
        name: '臀腿',
        subList: []
      }]
    }]
  }, {
    id: '100009104',
    name: '划船机',
    subList: commonSub
  }, {
    id: '100009105',
    name: '筋膜枪',
    subList: commonSub
  }, {
    id: '100009106',
    name: '踏步机',
    subList: commonSub
  }, {
    id: '100009107',
    name: '震动机',
    subList: commonSub
  }, {
    id: '100009108',
    name: '登山机',
    subList: commonSub
  }, {
    id: '100009109',
    name: '跑步机',
    subList: commonSub
  }, {
    id: '100009110',
    name: '健身车',
    subList: [{
      id: '100009204',
      name: '增肌塑形',
      subList: [{
        id: '100009305',
        name: '臀腿',
        subList: []
      }]
    }, {
      id: '100009203',
      name: '减脂',
      subList: commonSub1
    }, {
      id: '100009206',
      name: '康复',
      subList: []
    }]
  }, {
    id: '100009111',
    name: '椭圆机',
    subList: commonSub
  }, {
    id: '100009112',
    name: '拉力器',
    subList: commonSub
  }]
};
export default temp;