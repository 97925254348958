module.exports = {
  gymFacilities: ['付费停车', '吸烟区', '哑铃凳', '腿屈伸'],
  location: {
    province: '120000',
    city: '120000',
    district: '120000',
    details: '我是详细地址'
  },
  contactList: [{
    name: '我是1',
    position: '开发',
    phone: '13400003303'
  }],
  businessDays: [],
  gymImages: ['https://media.yaolianfit.com/upload/KMBfnmw3fR'],
  businessStatus: 1,
  businessAnytime: true,
  gymName: '我是测试门店',
  gymArea: '100',
  contactPhone: '1340000000',
  extraFiles: [{
    filename: '文件1',
    filepath: 'https://media.yaolianfit.com/upload/ydIo21kIde'
  }]
};