var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card d2-mb" }, [
    _c(
      "div",
      { staticClass: "card content" },
      _vm._l(_vm.tabData, function(l, i) {
        return _c(
          "div",
          { key: i, staticClass: "item" },
          [
            _c("div", { staticClass: "label" }, [
              _c("div", { staticClass: "msg" }, [_vm._v(_vm._s(l.label))]),
              l.show
                ? _c("i", { staticClass: "icon el-icon-question" })
                : _vm._e()
            ]),
            _c("div", { staticClass: "num" }, [_vm._v(_vm._s(l.num))]),
            _vm._l(l.children, function(k, n) {
              return _c("div", { key: n, staticClass: "li" }, [
                _c("div", { staticClass: "left" }, [_vm._v(_vm._s(k.label))]),
                _c("div", { staticClass: "right" }, [_vm._v(_vm._s(k.num))])
              ])
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }