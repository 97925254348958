import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { refundStatus } from './options';
export default {
  filters: {
    statusFil: function statusFil(e) {
      var item = refundStatus.find(function (v) {
        return v.value == e;
      });
      if (!item) return;
      return item.label;
    }
  },
  methods: {
    statusFn: function statusFn(e) {
      var item = orderStatus.find(function (v) {
        return v.value == e;
      });
      if (!item) return;
      return item.label;
    },
    xlsxExport: function xlsxExport() {
      var _this = this;

      this.$confirm('确定下载列表文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = _this.tabData.map(function (v) {
          return _objectSpread(_objectSpread({}, v), {}, {
            orderStatus: _this.statusFn(v.orderStatus)
          });
        });

        var columns = [{
          label: '订单编号',
          prop: 'orderNo'
        }, {
          label: '下单时间',
          prop: 'orderTime'
        }, {
          label: '付款时间',
          prop: 'payTime'
        }, {
          label: '下单账号',
          prop: 'nickname'
        }, {
          label: '商家备注',
          prop: 'storeRemark'
        }, {
          label: '订单状态',
          prop: 'orderStatus'
        }];

        _this.$export.excel({
          title: process.env.VUE_APP_TITLE + new Date().getTime(),
          columns: columns,
          data: data
        });
      }).catch(function () {});
    },
    // 格式转换
    formatJson: function formatJson(filterVal, jsonData) {
      var _this2 = this;

      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j == 'orderStatus') {
            v[j] = _this2.statusFn(v[j]);
          }

          return v[j];
        });
      });
    }
  }
};