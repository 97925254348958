import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import { orderStatus, overdueReason } from './options';
export default {
  data: function data() {
    return {
      payWay: [{
        value: 1,
        label: '可提现金额'
      }, {
        value: 2,
        label: '不可提现金额'
      }, {
        value: 3,
        label: '混合支付'
      }]
    };
  },
  filters: {
    statusFil: function statusFil(e) {
      var item = orderStatus.find(function (v) {
        return v.value == e;
      });
      if (!item) return;
      return item.label;
    }
  },
  methods: {
    statusFn: function statusFn(e) {
      var item = orderStatus.find(function (v) {
        return v.value == e;
      });
      if (!item) return;
      return item.label;
    },
    xlsxExport: function xlsxExport(columns, data) {
      var _this = this;

      var title = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date().getTime();
      this.$confirm('确定下载列表文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.xlsxColumns = [];

        _this.$export.excel({
          title: _this.VUE_APP_TITLE + title,
          columns: columns,
          data: data
        });
      });
    },
    // 格式转换
    formatJson: function formatJson(filterVal, jsonData) {
      var _this2 = this;

      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j == 'orderStatus') {
            v[j] = _this2.statusFn(v[j]);
          }

          return v[j];
        });
      });
    }
  }
};