import _defineProperty from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _mutations;

import { LIVE_STAGE } from '@/live/constants/room';
import * as mutationTypes from '@/live/constants/mutation-types';
import { sdkAppId, inviteUserSig } from '@/config';
export default {
  namespaced: true,
  state: {
    // 直播间阶段
    liveStage: LIVE_STAGE.NOT_STARTED,
    // 房间号信息
    roomId: 10086,
    // 直播间昵称
    roomName: '',
    // 应用信息
    appInfo: {
      sdkAppId: sdkAppId,
      userSig: inviteUserSig,
      shareUserSig: inviteUserSig
    },
    // 用户信息
    userInfo: {
      userId: 'user_11',
      userName: '测试',
      userAvatar: ''
    },
    // 是否在屏幕分享中
    isScreenSharing: false,
    // 使用的摄像头设备
    activeCamera: {},
    // 使用的麦克风设备
    activeMicrophone: {},
    // 使用的扬声器设备
    activeSpeaker: {},
    // 本地流是否设置镜像
    isSetMirror: true,
    // 视频参数
    videoProfile: {
      width: 500,
      height: 300,
      frameRate: 15,
      bitrate: 2000
    },
    // 是否开启美颜
    isOpenBeauty: false,
    // 美颜参数
    beautyParam: {
      beautyValue: 50,
      brightnessValue: 50,
      ruddyValue: 50
    },
    // 屏幕分享参数
    screenProfile: {
      width: 1920,
      height: 1080,
      frameRate: 5,
      bitrate: 1600
    },
    // 是否开启录制
    isRecordLive: true,
    // 音频采集状态
    isAudioMuted: false,
    // 视频采集状态
    isVideoMuted: false,
    // 音量大小
    audioLevel: 0,
    // 上行网络质量等级
    uplinkQualityLevel: 0,
    // 观看人员列表
    memberList: []
  },
  mutations: (_mutations = {}, _defineProperty(_mutations, mutationTypes.SET_ROOM_ID, function (state, value) {
    state.roomId = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_ROOM_NAME, function (state, value) {
    state.roomName = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_USER_INFO, function (state, data) {
    state.userInfo = data;
  }), _defineProperty(_mutations, mutationTypes.SET_APP_INFO, function (state, data) {
    state.appInfo = data;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_LIVE_STAGE, function (state, value) {
    state.liveStage = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_IS_SCREEN_SHARING, function (state, value) {
    state.isScreenSharing = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_ACTIVE_CAMERA, function (state, device) {
    state.activeCamera = device;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_ACTIVE_MICROPHONE, function (state, device) {
    state.activeMicrophone = device;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_ACTIVE_SPEAKER, function (state, device) {
    state.activeSpeaker = device;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_SET_MIRROR, function (state, value) {
    state.isSetMirror = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_OPEN_BEAUTY, function (state, value) {
    state.isOpenBeauty = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_VIDEO_PROFILE, function (state, data) {
    state.videoProfile = data;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_BEAUTY_PARAM, function (state, data) {
    state.beautyParam = data;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_RECORD_LIVE, function (state, value) {
    state.isRecordLive = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_VIDEO_STATE, function (state, value) {
    state.isVideoMuted = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_AUDIO_STATE, function (state, value) {
    state.isAudioMuted = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_AUDIO_LEVEL, function (state, value) {
    state.audioLevel = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_UPLINK_NETWORK_LEVEL, function (state, value) {
    state.uplinkQualityLevel = value;
  }), _defineProperty(_mutations, mutationTypes.UPDATE_MEMBER_LIST, function (state, value) {
    state.memberList = value;
  }), _mutations),
  getters: {
    // 当前使用的摄像头设备Id
    activeCameraId: function activeCameraId(state) {
      return state.activeCamera.deviceId;
    },
    // 当前使用的摄像头设备Id
    activeMicrophoneId: function activeMicrophoneId(state) {
      return state.activeMicrophone.deviceId;
    },
    // 当前使用的扬声器设备Id
    activeSpeakerId: function activeSpeakerId(state) {
      return state.activeSpeaker.deviceId;
    }
  },
  actions: {}
};