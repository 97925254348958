var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            placement: "top",
            "open-delay": 500,
            effect: "dark",
            disabled: !_vm.showTooltip
          },
          model: {
            value: _vm.showTooltip,
            callback: function($$v) {
              _vm.showTooltip = $$v
            },
            expression: "showTooltip"
          }
        },
        [
          _c("span", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _c(
            "div",
            {
              staticClass: "my-node",
              on: {
                mouseenter: function($event) {
                  return _vm.showTips($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }