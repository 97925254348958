var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.eventId ? "编辑" : "新增") + "救助事件")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "救助事件标题", prop: "eventTitle" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.eventTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "eventTitle", $$v)
                  },
                  expression: "form.eventTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属事件分类", prop: "typeId" } },
            [
              _c("base-select", {
                attrs: { options: _vm.typeList },
                model: {
                  value: _vm.form.typeId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "typeId", $$v)
                  },
                  expression: "form.typeId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "救助事件封面", prop: "eventCover" } },
            [
              _c("image-upload", {
                attrs: { srcList: _vm.form.eventCover },
                on: {
                  "update:srcList": function($event) {
                    return _vm.$set(_vm.form, "eventCover", $event)
                  },
                  "update:src-list": function($event) {
                    return _vm.$set(_vm.form, "eventCover", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "救助事件排序", prop: "eventSort" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "", type: "number", min: "1" },
                on: {
                  input: function($event) {
                    return _vm.changeInt(_vm.form.eventSort)
                  }
                },
                model: {
                  value: _vm.form.eventSort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "eventSort", $$v)
                  },
                  expression: "form.eventSort"
                }
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v("说明：数值越小，排列越前")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否在APP展示", prop: "eventShow" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.eventShow,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "eventShow", $$v)
                  },
                  expression: "form.eventShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "救助事件内容", prop: "eventContent" } },
            [
              _c("base-editor", {
                attrs: { content: _vm.form.eventContent },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "eventContent", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }