var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top-box" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("NormalUser", {
            attrs: {
              url: _vm.url,
              introduction: _vm.introduction,
              nickname: _vm.nickname,
              userId: _vm.userId
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("form-grid", {
            staticStyle: { "margin-left": "10px" },
            attrs: { options: _vm.userInfos }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _c(
          "TableTitleBar",
          { attrs: { title: "用户当前资产" } },
          [
            _c(
              "el-button",
              { attrs: { type: "text" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        path: "/app-user-wallet-detail",
                        query: _vm.$route.query
                      }
                    }
                  },
                  [_vm._v(" 查看钱包明细 ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "base-table",
          { attrs: { pagination: false, data: [_vm.walletData], border: "" } },
          [
            _c("el-table-column", {
              attrs: { label: "可用PLUS会员数", prop: "orderCount" }
            }),
            _c("el-table-column", {
              attrs: { label: "PLUS会员权益到期日", prop: "endDate" }
            }),
            _c("el-table-column", {
              attrs: { label: "钱包可用余额(元）", prop: "balanceAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "可提现金额(元）", prop: "baseAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "可用不可提现金额", prop: "rewardAmount" }
            })
          ],
          1
        ),
        _c(
          "TableTitleBar",
          { attrs: { title: "商品交易统计" } },
          [
            _c(
              "el-button",
              { attrs: { type: "text" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { path: "/order-search?nickname=" + _vm.nickname }
                    }
                  },
                  [_vm._v("查看商品订单")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "base-table",
          {
            attrs: { pagination: false, data: [_vm.mallOrderData], border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "累计成交金额（元）", prop: "orderAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计成交单量", prop: "orderCount" }
            }),
            _c("el-table-column", {
              attrs: { label: "客单价（元）", prop: "avgAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计退款金额（元）", prop: "refundAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计退款订单数", prop: "refundCount" }
            }),
            _c("el-table-column", {
              attrs: { label: "最近下单时间", prop: "orderTime", width: "200" }
            })
          ],
          1
        ),
        _c(
          "TableTitleBar",
          { attrs: { title: "私教课交易统计" } },
          [
            _c(
              "el-button",
              { attrs: { type: "text" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        path: "/course-order-list?groupNickname=" + _vm.nickname
                      }
                    }
                  },
                  [_vm._v("查看私教课订单")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "base-table",
          {
            attrs: {
              pagination: false,
              data: [_vm.trainingOrderData],
              border: ""
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "累计交易成功金额（元）", prop: "orderAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计交易成功订单数", prop: "orderCount" }
            }),
            _c("el-table-column", {
              attrs: { label: "客单价（元）", prop: "avgAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计退款金额（元）", prop: "refundAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计退款订单数", prop: "refundCount" }
            }),
            _c("el-table-column", {
              attrs: {
                label: "最近支付下单时间",
                prop: "lastOrderTime",
                width: "200"
              }
            })
          ],
          1
        ),
        _c(
          "TableTitleBar",
          { attrs: { title: "PLUS会员交易统计" } },
          [
            _c(
              "el-button",
              { attrs: { type: "text" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { path: "/plus-member-list?nickname=" + _vm.nickname }
                    }
                  },
                  [_vm._v("查看购买记录")]
                )
              ],
              1
            ),
            _c(
              "el-button",
              { attrs: { type: "text" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        path:
                          "/plus-member-usage-record?nickname=" + _vm.nickname
                      }
                    }
                  },
                  [_vm._v("查看使用记录")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "base-table",
          { attrs: { pagination: false, data: [_vm.plusData], border: "" } },
          [
            _c("el-table-column", {
              attrs: { label: "累计申购总额（元）", prop: "orderAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计购买次数", prop: "orderCount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计退款金额（元）", prop: "refundAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计退款会员数", prop: "refundCount" }
            }),
            _c("el-table-column", {
              attrs: { label: "最近购买时间", prop: "lastTime", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(row.lastTime)))
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "TableTitleBar",
          { attrs: { title: "钱包充值统计" } },
          [
            _c(
              "el-button",
              { attrs: { type: "text" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        path: "/app-user-wallet-charge?nickname=" + _vm.nickname
                      }
                    }
                  },
                  [_vm._v("查看充值记录")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "base-table",
          {
            attrs: { pagination: false, data: [_vm.rechargeData], border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "累计充值总额（元）", prop: "rechargeAmount" }
            }),
            _c("el-table-column", {
              attrs: { label: "累计充值次数", prop: "rechargeCount" }
            }),
            _c("el-table-column", {
              attrs: {
                label: "最近充值时间",
                prop: "lastRechargeTime",
                width: "200"
              }
            })
          ],
          1
        ),
        _c("TableTitleBar", { attrs: { title: "课程、直播课统计" } }),
        _c(
          "base-table",
          { attrs: { pagination: false, data: [_vm.coursedata], border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                label: "课程跟练总时长（分钟）",
                prop: "courseTrainingLong"
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "最近一次跟练时间",
                prop: "courseLastTime",
                width: "200"
              }
            }),
            _c("el-table-column", {
              attrs: { label: "直播课观看总时长", prop: "liveTrainingLong" }
            }),
            _c("el-table-column", {
              attrs: {
                label: " 最近一次观看时间",
                prop: "liveLastTime",
                width: "200"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }