/** 审核状态 */
export var auditStates = [{
  label: '全部',
  value: ''
}, {
  label: '待审核',
  value: 2
}, {
  label: '已通过',
  value: 3
}, {
  label: '未通过',
  value: 4
}];