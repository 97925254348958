export var orderStatus = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '待付款'
}, {
  value: 2,
  label: '待出库'
}, {
  value: 3,
  label: '已出库'
}, {
  value: 4,
  label: '已签收'
}, {
  value: 5,
  label: '已完成'
}, {
  value: 6,
  label: '已取消'
}, {
  value: 6,
  label: '已取消'
}, {
  value: 7,
  label: '已取消'
}, {
  value: 8,
  label: '已取消'
}];
export var orderPlatform = [{
  value: 'APP',
  label: 'app'
}, {
  value: 'H5',
  label: 'h5'
}, {
  value: 'mini',
  label: '微信小程序'
}];
export var invoiceTitleType = [{
  value: 1,
  label: '个人'
}, {
  value: 2,
  label: '公司'
}];