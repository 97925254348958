var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v("退换货服务单")]),
      _c(
        "el-card",
        {
          staticClass: "d2-mb",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-tooltip",
                { attrs: { content: "距离审核超时不足24h", placement: "top" } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 4,
                        offset: 0,
                        flex: "main:center dir:top cross:center"
                      }
                    },
                    [
                      _c("p", { staticClass: "d2-mb" }, [
                        _vm._v(_vm._s(_vm.numObj.auditOverSoon))
                      ]),
                      _c("p", [_vm._v("待审核即将超时")])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "退换服务单，从填写运单号距离收货超时不足5天",
                    placement: "top"
                  }
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 4,
                        offset: 0,
                        flex: "main:center dir:top cross:center"
                      }
                    },
                    [
                      _c("p", { staticClass: "d2-mb" }, [
                        _vm._v(_vm._s(_vm.numObj.checkOverSoon))
                      ]),
                      _c("p", [_vm._v("待收货即将超时")])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "退货服务单，从收货距离处理超时不足1.5天",
                    placement: "top"
                  }
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 4,
                        offset: 0,
                        flex: "main:center dir:top cross:center"
                      }
                    },
                    [
                      _c("p", { staticClass: "d2-mb" }, [
                        _vm._v(_vm._s(_vm.numObj.deliveryOverSoon))
                      ]),
                      _c("p", [_vm._v("待换新货即将超时")])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "card", lazy: "" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.tab, function(l) {
          return _c(
            "el-tab-pane",
            { key: l.id, attrs: { label: l.label, name: l.type } },
            [
              l.type == _vm.activeName
                ? _c(_vm.activeName, { tag: "component" })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }