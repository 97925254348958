var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("救助知识管理")]),
      _c(
        "page-content",
        [
          _c(
            "TableTitleBar",
            { staticStyle: { "margin-top": "0" }, attrs: { title: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                [_vm._v("新增救助知识")]
              )
            ],
            1
          ),
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "救助知识标题" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "输入救助知识标题关键搜索" },
                    model: {
                      value: _vm.form.knowledgeTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "knowledgeTitle", $$v)
                      },
                      expression: "form.knowledgeTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "救助知识分类" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.typeList },
                    model: {
                      value: _vm.form.typeId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "typeId", $$v)
                      },
                      expression: "form.typeId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "浏览人数" } },
                [
                  _c("range-input", {
                    attrs: {
                      min: _vm.form.knowledgeRange.start,
                      max: _vm.form.knowledgeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.knowledgeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.knowledgeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "排序", prop: "knowledgeSort" }
              }),
              _c("el-table-column", {
                attrs: { label: "救助知识标题", prop: "knowledgeTitle" }
              }),
              _c("el-table-column", {
                attrs: { label: "所属分类", prop: "typeId" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(_vm._f("type")(row.typeId)))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "救助知识浏览人数", prop: "knowledgeBrowse" }
              }),
              _c("el-table-column", {
                attrs: { label: "APP是否显示", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.knowledgeShow ? "是" : "否"))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/fist-aid-knowledge-detail",
                                    query: {
                                      knowledgeId: scope.row.knowledgeId
                                    }
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/fist-aid-knowledge-add",
                                    query: {
                                      knowledgeId: scope.row.knowledgeId
                                    }
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onDel(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }