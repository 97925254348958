var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderNum" },
    [
      _c(
        "div",
        { staticClass: "number" },
        [
          _c("label", [_vm._v("订单号：")]),
          _c("span", [_vm._v(_vm._s(_vm.orderDetail.orderNo))]),
          _c(
            "el-button",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { type: "text" },
              on: { click: _vm.copyThat }
            },
            [_vm._v("复制")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "order-stutas" }, [
        _c("label", [_vm._v("订单状态：")]),
        _c("span", [
          _vm._v(_vm._s(_vm._f("statusFil")(_vm.orderDetail.orderStatus)))
        ])
      ]),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer }, on: { confirm: _vm.confirm } },
        [
          _c("el-input", {
            attrs: { type: "textarea", resize: "none", rows: 5 },
            model: {
              value: _vm.layer.form.storeRemark,
              callback: function($$v) {
                _vm.$set(_vm.layer.form, "storeRemark", $$v)
              },
              expression: "layer.form.storeRemark"
            }
          }),
          _c(
            "div",
            { staticClass: "d2-mt", attrs: { flex: "main" } },
            [
              _c("span", [_vm._v("选择等级：")]),
              _c("el-rate", {
                model: {
                  value: _vm.layer.form.storeRemarkLevel,
                  callback: function($$v) {
                    _vm.$set(_vm.layer.form, "storeRemarkLevel", $$v)
                  },
                  expression: "layer.form.storeRemarkLevel"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }