//
//
//
//
//
//
//
//
export default {
  name: 'page2'
};