import { render, staticRenderFns } from "./inde.page.vue?vue&type=template&id=652236ea&"
import script from "./inde.page.vue?vue&type=script&lang=js&"
export * from "./inde.page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yaolian_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('652236ea')) {
      api.createRecord('652236ea', component.options)
    } else {
      api.reload('652236ea', component.options)
    }
    module.hot.accept("./inde.page.vue?vue&type=template&id=652236ea&", function () {
      api.rerender('652236ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/first-aid-management/knowledge/list/inde.page.vue"
export default component.exports