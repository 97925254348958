//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  companyName: '',
  accountName: '',
  auditNo: '',
  createTimeRange: {
    start: '',
    end: ''
  },
  auditStatus: '',
  type: 1
};
export default {
  meta: {
    title: 'tab-all',
    auth: true
  },
  name: 'tab-all',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'gym_auditList',
        params: cloneDeep(initForm)
      },
      auditStatus: [{
        label: '全部',
        value: ''
      }, {
        label: '待审核',
        value: 1
      }, {
        label: '审核通过',
        value: 2
      }, {
        label: '审核不通过',
        value: 3
      }]
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};