import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/databig', ['getNewRight'])), {}, {
    tabData: function tabData() {
      var oldContent = this.getNewRight.newsContent;
      if (!oldContent) return {};
      var content = oldContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (pre, cur) {
        var newImg = "<img src=\"".concat(cur, "\" alt=\"\" style=\"width:100%;\" />");
        return newImg;
      });
      return _objectSpread(_objectSpread({}, this.getNewRight), {}, {
        newsContent: content
      }) || {};
    }
  })
};