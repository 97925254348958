//
//
//
//
//
//
//
//
//
//
//
//
//
//
var uploadImg = require('@/assets/images/defaultImg.jpg');

export default {
  props: {
    url: {
      type: String
    },
    srcList: {
      type: Array
    },
    introduction: {
      type: String
    },
    nickname: {
      type: String
    },
    userId: {
      type: String
    }
  },
  name: '',
  data: function data() {
    return {
      uploadImg: uploadImg
    };
  }
};