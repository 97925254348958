//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TabAll from './TabAll';
import TabWatingAudit from './TabWatingAudit';
export default {
  meta: {
    title: '门店入驻审核',
    auth: true,
    authorityValue: 'gym-settled-audit'
  },
  name: 'store-entry-audit',
  components: {
    TabWatingAudit: TabWatingAudit,
    TabAll: TabAll
  },
  data: function data() {
    return {
      activeName: '1'
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};