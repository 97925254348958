import { render, staticRenderFns } from "./inError.vue?vue&type=template&id=10671d66&scoped=true&"
import script from "./inError.vue?vue&type=script&lang=js&"
export * from "./inError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10671d66",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yaolian_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10671d66')) {
      api.createRecord('10671d66', component.options)
    } else {
      api.reload('10671d66', component.options)
    }
    module.hot.accept("./inError.vue?vue&type=template&id=10671d66&scoped=true&", function () {
      api.rerender('10671d66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shopCentre/orderAdmin/orderSearch/comp/inError.vue"
export default component.exports