import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 拉新活动列表 */
    invitation_activities: createAction(request, '/admin/invitation/activities'),

    /** 保存活动 */
    invitation_saveActivity: createAction(request, '/admin/invitation/saveActivity'),

    /** 删除活动 */
    invitation_deleteActivity: createAction(request, '/admin/invitation/deleteActivity'),

    /** 邀请奖励终审列表 */
    invitation_rewardAuditList: createAction(request, '/admin/invitation/rewardAuditList'),

    /** 审核奖励 */
    invitation_auditReward: createAction(request, '/admin/invitation/auditReward')
  };
});