var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("cascader-select", {
    ref: "cascaderSelect",
    attrs: { options: _vm.options, value: _vm.value, maxLevel: _vm.maxLevel },
    on: { change: _vm.handleChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }