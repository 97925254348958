var isEnd = true;
var temp = {
  id: '10000003',
  name: '孕产',
  subLables: ['时期', '系列', '针对项'],
  subList: [{
    id: '100003101',
    name: '孕期',
    subList: [{
      id: '100003201',
      name: '核心稳定性训练',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100003202',
      name: '孕期腰痛训练',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100003203',
      name: '孕期臀部疼痛训练',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100003204',
      name: '孕期耻骨疼痛训练',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100003205',
      name: '孕期颈肩部疼痛训练',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100003206',
      name: '孕期足部疼痛训练',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100003207',
      name: '孕期下肢水肿',
      isEnd: isEnd,
      subList: []
    }]
  }, {
    id: '100003102',
    name: '产后',
    subList: [{
      id: '100003208',
      name: '月子期',
      subList: [{
        id: '100003301',
        name: '顺产',
        subList: []
      }, {
        id: '100003302',
        name: '剖腹产',
        subList: []
      }]
    }, {
      id: '100003209',
      name: '恢复期',
      subList: [{
        id: '100003303',
        name: '腹直肌分离',
        subList: []
      }, {
        id: '100003304',
        name: '妈妈臀修复',
        subList: []
      }, {
        id: '100003305',
        name: '假胯宽调整',
        subList: []
      }, {
        id: '100003306',
        name: '盆底肌修复',
        subList: []
      }, {
        id: '100003307',
        name: '产后腹部塑形',
        subList: []
      }]
    }]
  }]
};
export default temp;