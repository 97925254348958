var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("el-scrollbar", { staticClass: "scrollbar" }, [
        _c("div", { staticClass: "centent" }, [
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.tabData.newsTitle))
          ]),
          _c("div", { staticClass: "center" }, [
            _c("span", [_vm._v("发布：" + _vm._s(_vm.tabData.authorName))]),
            _c("span", [_vm._v("发布时间：" + _vm._s(_vm.tabData.createTime))])
          ]),
          _c("div", {
            staticClass: "item",
            staticStyle: { "word-break": "break-all" },
            domProps: { innerHTML: _vm._s(_vm.tabData.newsContent) }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }