var commonSub0 = [{
  id: '100001301',
  name: '核心',
  subList: []
}, {
  id: '100001302',
  name: '肩颈',
  subList: []
}, {
  id: '100001303',
  name: '胸背',
  subList: []
}, {
  id: '100001304',
  name: '手臂',
  subList: []
}, {
  id: '100001305',
  name: '臀腿',
  subList: []
}, {
  id: '100001306',
  name: '上肢',
  subList: []
}, {
  id: '100001307',
  name: '下肢',
  subList: []
}, {
  id: '100001308',
  name: '综合',
  subList: []
}];
var commonSub1 = [{
  id: '100001201',
  name: '热身',
  subList: commonSub0
}, {
  id: '100001202',
  name: '拉伸',
  subList: commonSub0
}, {
  id: '100001203',
  name: '减脂',
  subList: commonSub0
}, {
  id: '100001204',
  name: '增肌塑形',
  subList: commonSub0
}]; // 健身训练类型没有级别的限制

var temp = {
  id: '10000001',
  name: '健身训练',
  subLables: ['系列', '类型', '部位'],
  subList: [{
    id: '100001101',
    name: '小器械',
    subList: commonSub1
  }, {
    id: '100001102',
    name: '健身房',
    subList: commonSub1
  }, {
    id: '100001103',
    name: 'HIIT / Tabata',
    subList: commonSub1
  }, {
    id: '100001104',
    name: '徒手训练',
    subList: commonSub1
  }]
};
export default temp;