var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticStyle: { height: "10px" } }),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "180px" } },
        [
          _c(
            "el-form-item",
            { attrs: { required: "", label: "动作名称" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.res.actionName,
                  callback: function($$v) {
                    _vm.$set(_vm.res, "actionName", $$v)
                  },
                  expression: "res.actionName"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { required: "", label: "动作视频" } }, [
            _c(
              "div",
              { staticClass: "flex-box" },
              [
                _vm.res.videoUrl
                  ? _c("video", {
                      staticClass: "form-video",
                      attrs: {
                        id: "action-video",
                        controls: "",
                        src: _vm.res.videoUrl
                      }
                    })
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onOpenUpload("video")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.res.videoUrl ? "更换" : "上传") + "视频")]
                )
              ],
              1
            )
          ]),
          _c("el-form-item", { attrs: { label: "动作音频", required: "" } }, [
            _c(
              "div",
              { staticClass: "flex-box" },
              [
                _vm.res.titleAudioUrl
                  ? _c("audio", {
                      staticClass: "form-audio",
                      attrs: { controls: "", src: _vm.res.titleAudioUrl }
                    })
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onOpenUpload("audio")
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.res.titleAudioUrl ? "更换" : "上传") + "音频"
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "单位动作时长" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "number", min: "1" },
                  on: { input: _vm.handleChange },
                  model: {
                    value: _vm.res.actionSecond,
                    callback: function($$v) {
                      _vm.$set(_vm.res, "actionSecond", $$v)
                    },
                    expression: "res.actionSecond"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("S")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "动作类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.res.actionType,
                    callback: function($$v) {
                      _vm.$set(_vm.res, "actionType", $$v)
                    },
                    expression: "res.actionType"
                  }
                },
                _vm._l(_vm.dict.actionTypes, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.key } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "动作难度" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.res.difficultyLevel,
                    callback: function($$v) {
                      _vm.$set(_vm.res, "difficultyLevel", $$v)
                    },
                    expression: "res.difficultyLevel"
                  }
                },
                _vm._l(_vm.dict.difficultyLevels, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.key } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "训练类型" } },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.onCatePartChange },
                  model: {
                    value: _vm.res.categoryIds,
                    callback: function($$v) {
                      _vm.$set(_vm.res, "categoryIds", $$v)
                    },
                    expression: "res.categoryIds"
                  }
                },
                _vm._l(_vm.dict.initCourseType, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.key } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "部位" } },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.onCatePartChange },
                  model: {
                    value: _vm.res.bodyParts,
                    callback: function($$v) {
                      _vm.$set(_vm.res, "bodyParts", $$v)
                    },
                    expression: "res.bodyParts"
                  }
                },
                _vm._l(_vm.dict.actionParts, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.key } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "显示排序" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    border: "",
                    data: _vm.res.actionSorts,
                    "header-cell-style": { background: "#F6F6F6" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "APP选项" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dict.courseCates[scope.row.categoryId]
                                ) +
                                "->" +
                                _vm._s(scope.row.bodyPart) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "排序序号(数值越小，排序越前。)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { type: "number", min: "1" },
                              model: {
                                value: scope.row.sortValue,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "sortValue", $$v)
                                },
                                expression: "scope.row.sortValue"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "动作介绍" } },
            [
              _c("base-editor", {
                attrs: { upToken: _vm.qnToken, content: _vm.res.actionExplain },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.res, "actionExplain", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload-video",
            attrs: { type: "video", token: _vm.qnToken },
            on: { confirm: _vm.setVideo }
          })
        : _vm._e(),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload-audio",
            attrs: { type: "audio", token: _vm.qnToken },
            on: { confirm: _vm.setAudio }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }