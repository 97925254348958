import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 店铺详情 */
    store_storeDetails: createAction(request, '/admin/store/storeDetails'),

    /** 入驻意向列表 */
    store_intentionList: createAction(request, '/admin/gym/intentionList')
  };
});