var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "门店" } },
            [
              _c("base-select", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.gymId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gymId", $$v)
                  },
                  expression: "form.gymId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "日期" } },
            [
              _c("range-month", {
                attrs: {
                  min: _vm.form.billDateRange.start,
                  max: _vm.form.billDateRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.billDateRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.billDateRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "区域" } },
            [
              _c("CitySelect", {
                attrs: {
                  province: _vm.form.provinceCode,
                  city: _vm.form.cityCode,
                  district: _vm.form.districtCode
                },
                on: {
                  "update:province": function($event) {
                    return _vm.$set(_vm.form, "provinceCode", $event)
                  },
                  "update:city": function($event) {
                    return _vm.$set(_vm.form, "cityCode", $event)
                  },
                  "update:district": function($event) {
                    return _vm.$set(_vm.form, "districtCode", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "账单日期", prop: "date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.date)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "门店收入(元）", prop: "billAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "平台收入(元）", prop: "platformAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.download(row)
                          }
                        }
                      },
                      [_vm._v("下载明细")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }