import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 新增门店 */
    gym_saveGym: createAction(request, '/admin/gym/saveGym'),

    /** 保存轮播图 */
    gym_saveBanner: createAction(request, '/admin/gym/saveBanner'),

    /** 删除轮播图 */
    gym_deleteBanner: createAction(request, '/admin/gym/deleteBanner'),

    /** 获取轮播图 */
    gym_banners: createAction(request, '/admin/gym/banners'),

    /** 获取门店信息 */
    gym_information: createAction(request, '/admin/gym/information'),

    /** 门店列表 */
    gym_gyms: createAction(request, '/admin/gym/gyms'),

    /** 门店入驻、营业信息更新、法人信息、税务信息 审核列表 */
    gym_auditList: createAction(request, '/admin/gym/auditList'),

    /** 门店申请详情 */
    gym_auditDetails: createAction(request, '/admin/gym/auditDetails'),

    /** 锁定门店 */
    gym_lockGym: createAction(request, '/admin/gym/lockGym'),

    /** 门店详情 */
    gym_gymDetails: createAction(request, '/admin/gym/gymDetails'),

    /** 门店审核 */
    gym_audit: createAction(request, '/admin/gym/audit'),

    /** 门店审核备注 */
    gym_auditRemark: createAction(request, '/admin/gym/auditRemark'),

    /** 获取门店保证金 */
    gym_depositAmount: createAction(request, '/admin/gym/depositAmount'),

    /** 保存门店保证金 */
    gym_saveDepositAmount: createAction(request, '/admin/gym/saveDepositAmount'),

    /** 未认证的门店列表 */
    gym_noAuthGyms: createAction(request, '/admin/gym/noAuthGyms'),

    /** 开启关闭门店急救技能认证 */
    gym_setAedAuditGym: createAction(request, '/admin/gym/setAedAuditGym'),

    /** 开启关闭官方推荐门店 */
    gym_setRecommendGym: createAction(request, '/admin/gym/setRecommendGym'),

    /** 门店服务 */
    gym_gymServiceSetting: createAction(request, '/admin/gym/gymServiceSetting'),

    /** 删除门店 */
    gym_deleteGym: createAction(request, '/admin/gym/deleteGym')
  };
});