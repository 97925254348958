import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 私教课分销返现 */
    finance_trainingOrderCashbackRecords: createAction(request, '/admin/finance/trainingOrderCashbackRecords'),

    /** 用户提现列表 */
    finance_userTransferList: createAction(request, '/admin/finance/userTransferList'),

    /** 用户充值列表 */
    finance_userRechargeList: createAction(request, '/admin/finance/userRechargeList'),

    /** 用户钱包余额 */
    finance_userBalanceList: createAction(request, '/admin/finance/userBalanceList'),

    /** 用户帐单明细 */
    finance_userBills: createAction(request, '/admin/finance/userBills'),

    /** 店铺钱包余额 */
    finance_storeBalanceList: createAction(request, '/admin/finance/storeBalanceList'),

    /** 店铺账单明细 */
    finance_billList: createAction(request, '/admin/finance/billList'),

    /** 店铺提现列表 */
    finance_storeTransferList: createAction(request, '/admin/finance/storeTransferList'),

    /** 店铺提现详情 */
    finance_storeTransferDetails: createAction(request, '/admin/finance/storeTransferDetails'),

    /** 审核店铺提现 */
    finance_auditStoreTransfer: createAction(request, '/admin/finance/auditStoreTransfer'),

    /** 店铺帐单查询 */
    finance_storeBills: createAction(request, '/admin/finance/storeBills'),

    /** 门店钱包列表 */
    finance_gymBalanceList: createAction(request, '/admin/finance/gymBalanceList'),

    /** 门店体现列表 */
    finance_gymTransferList: createAction(request, '/admin/finance/gymTransferList'),

    /** 门店账单查询 */
    finance_gymBills: createAction(request, '/admin/finance/gymBills'),

    /** 门店账单明细列表 - 私教订单 */
    finance_trainingOrderBills: createAction(request, '/admin/finance/trainingOrderBills'),
    finance_trainingOrderBills1: createAction(request, '/admin/finance/trainingOrderBills1'),

    /** 门店账单明细列表 - PLUS消费 */
    finance_plusConsumes: createAction(request, '/admin/finance/plusConsumes'),

    /** 增加/扣减 门店余额 */
    finance_changeGymBalance: createAction(request, '/admin/finance/changeGymBalance'),

    /** 增加/扣减 门店余额 记录*/
    finance_changeGymBalanceRecords: createAction(request, '/admin/finance/changeGymBalanceRecords'),

    /** 冻结/解冻 门店账单 */
    finance_lockGymBill: createAction(request, '/admin/finance/lockGymBill'),

    /** 审核门店提现 */
    finance_auditGymTransfer: createAction(request, '/admin/finance/auditGymTransfer'),

    /** 门店提现详情 */
    finance_gymTransferDetails: createAction(request, '/admin/finance/gymTransferDetails'),

    /** 教练保证金列表 */
    finance_coachDepositList: createAction(request, '/admin/finance/coachDepositList'),

    /** 门店保证金列表 */
    finance_gymDepositList: createAction(request, '/admin/finance/gymDepositList'),

    /** 店铺保证金列表 */
    finance_storeDepositList: createAction(request, '/admin/finance/storeDepositList'),

    /** 获取每日签到奖励设置 */
    finance_getDailySignReward: createAction(request, '/admin/finance/getDailySignReward'),

    /** 设置每日签到奖励 */
    finance_setDailySignReward: createAction(request, '/admin/finance/setDailySignReward'),

    /** 获取邀请奖励金额 */
    finance_getInvitationReward: createAction(request, '/admin/finance/getInvitationReward'),

    /** 获取邀请奖励金额 */
    finance_setInvitationReward: createAction(request, '/admin/finance/setInvitationReward'),

    /** 获取提现手续费比例 */
    finance_getTransferFeeRate: createAction(request, '/admin/finance/getTransferFeeRate'),

    /** 设置提现手续费比例 */
    finance_setTransferFeeRate: createAction(request, '/admin/finance/setTransferFeeRate'),

    /** 获取充值金额设置列表 */
    finance_charges: createAction(request, '/admin/charge/charges'),

    /** 保存充值金额 */
    finance_saveCharge: createAction(request, '/admin/charge/saveCharge'),

    /** 上架/下架 充值金额 */
    finance_onShelf: createAction(request, '/admin/charge/onShelf'),

    /** 删除充值金额 */
    finance_deleteCharge: createAction(request, '/admin/charge/deleteCharge'),

    /** 获取每日签到奖励记录列表 */
    finance_dailySignRewardRecords: createAction(request, '/admin/finance/dailySignRewardRecords'),

    /** 获取邀请奖励奖励记录列表 */
    finance_invitationRewardRecords: createAction(request, '/admin/finance/invitationRewardRecords'),

    /** 审核教练保证金 */
    finance_auditCoachDeposit: createAction(request, '/admin/finance/auditCoachDeposit'),

    /** 审核店铺保证金 */
    finance_auditStoreDeposit: createAction(request, '/admin/finance/auditStoreDeposit'),

    /** 审核门店保证金 */
    finance_auditGymDeposit: createAction(request, '/admin/finance/auditGymDeposit'),

    /** 门店保证金详情 */
    finance_gymDepositDetails: createAction(request, '/admin/finance/gymDepositDetails'),

    /** 店铺保证金详情 */
    finance_storeDepositDetails: createAction(request, '/admin/finance/storeDepositDetails'),

    /** 发票列表 */
    finance_invoiceList: createAction(request, '/admin/invoice/invoiceList'),

    /** 发票详情 */
    finance_invoiceDetails: createAction(request, '/admin/invoice/invoiceDetails'),

    /** 发票审核 */
    finance_updateInvoice: createAction(request, '/admin/invoice/updateInvoice'),

    /** 未开票订单 */
    finance_orders: createAction(request, '/admin/invoice/notInvoiceList'),

    /** 确认店铺提现已打款 */
    finance_checkStoreTransfer: createAction(request, '/admin/finance/checkStoreTransfer'),

    /** 确认门店提现已打款 */
    finance_checkGymTransfer: createAction(request, '/admin/finance/checkGymTransfer'),

    /** 平台收支 */
    finance_platformBills: createAction(request, '/admin/finance/platformBills'),

    /** 用户购买记录 */
    vote_buyVoteList: createAction(request, '/admin/vote/buyVoteList'),

    /** 增加/扣减/冻结/解冻 用户余额 记录 */
    finance_changeUserBalanceRecords: createAction(request, '/admin/finance/changeUserBalanceRecords'),

    /** 批量 增加/扣减/冻结/解冻 用户余额 */
    finance_changeUserBalanceBatch: createAction(request, '/admin/finance/changeUserBalanceBatch'),

    /** 增加/扣减/冻结/解冻 用户余额 */
    finance_changeUserBalance: createAction(request, '/admin/finance/changeUserBalance'),

    /** 获取 提现失败通知设置 */
    finance_getTransferNotice: createAction(request, '/admin/finance/getTransferNotice'),

    /** 保存 提现失败通知设置 */
    finance_setTransferNotice: createAction(request, '/admin/finance/setTransferNotice')
  };
});