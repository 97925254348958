var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "TableTitleBar",
        { staticClass: "d2-mt-0", attrs: { title: "" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.goRoute("/add-large-news")
                }
              }
            },
            [_vm._v("新增官方资讯")]
          )
        ],
        1
      ),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "资讯标题" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入资讯标题" },
                model: {
                  value: _vm.form.newsTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "newsTitle", $$v)
                  },
                  expression: "form.newsTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "资讯作者" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入资讯作者" },
                model: {
                  value: _vm.form.authorName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "authorName", $$v)
                  },
                  expression: "form.authorName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", { attrs: { label: "排序", prop: "newsSort" } }),
          _c("el-table-column", {
            attrs: { label: "资讯标题", prop: "newsTitle" }
          }),
          _c("el-table-column", {
            attrs: { label: "资讯作者", prop: "authorName" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("/news-screen")
                          }
                        }
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/add-large-news",
                                query: { newsId: scope.row.newsId }
                              }
                            }
                          },
                          [_vm._v(" 修改 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onDel(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }