var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("钱包充值设置")]),
      _c(
        "TableTitleBar",
        { staticStyle: { "margin-top": "0" }, attrs: { title: "" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
            [_vm._v("添加充值类型")]
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "充值金额（元）", prop: "chargeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "赠送金额（元）", prop: "rewardAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "库存（份）", prop: "chargeStock" }
          }),
          _c("el-table-column", {
            attrs: { label: "是否限购", prop: "chargeLimit" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    !row.chargeLimit || row.chargeLimit <= 0
                      ? _c("span", [_vm._v("不限购")])
                      : _c("span", [
                          _vm._v(
                            "每个用户限购" + _vm._s(row.chargeLimit) + "次"
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "排序", prop: "chargeSort" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "chargeStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.chargeStatus,
                              _vm.result
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.chargeStatus == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onAdd(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    scope.row.chargeStatus == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onSelf(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 上架 ")]
                        )
                      : _vm._e(),
                    scope.row.chargeStatus == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onSelf(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 下架 ")]
                        )
                      : _vm._e(),
                    scope.row.chargeStatus == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onDel(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("AddWalletChargeDialog", {
        attrs: {
          visible: _vm.addWalletChargeDialogVisible,
          isEdit: _vm.isEdit,
          formData: _vm.addWalletChargeDialogForm
        },
        on: {
          "update:visible": function($event) {
            _vm.addWalletChargeDialogVisible = $event
          },
          onSuccess: _vm.handleSearch
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }