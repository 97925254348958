//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import { auditStates } from './const';
import CityCourse from '@/components/city-course';
var initForm = {
  applyTimeRange: {},
  auditTimeRange: {}
};
export default {
  meta: {
    title: 'tab-all',
    auth: true
  },
  name: 'tab-all',
  components: {
    CityCourse: CityCourse
  },
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'coach_certAuditList',
        params: cloneDeep(initForm)
      },
      sexs: [{
        label: '全部',
        value: ''
      }, {
        label: '男',
        value: 1
      }, {
        label: '女',
        value: 2
      }],
      citys: [],
      auditStates: auditStates
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};