var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("PLUS会员汇总")]),
      _c(
        "base-table",
        { attrs: { pagination: false, data: _vm.record } },
        [
          _c("el-table-column", {
            attrs: { label: "会员类型", prop: "plusMemberType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("plusMemberType")(row.plusMemberType)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "申购总额（元）", prop: "orderAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "申购会员数量", prop: "orderCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "退款总额（元）", prop: "refundAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "退款会员数", prop: "refundCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "实际扫码使用次数", prop: "consumeCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "门店收益", prop: "gymIncomeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "平台实际收入", prop: "platformIncomeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.plusMemberType !== 9
                      ? _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/plus-member-list",
                                    query: {
                                      plusMemberType: scope.row.plusMemberType
                                    }
                                  }
                                }
                              },
                              [_vm._v("查看明细")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }