import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 申请开通团队服务列表 */
    team_applyList: createAction(request, '/admin/team/applyList'),

    /** 审核开通团队 */
    team_audit: createAction(request, '/admin/team/audit')
  };
});