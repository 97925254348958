var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("发布投票活动")]),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "180px" } },
        [
          _c("el-form-item", { attrs: { required: "", label: "投票标题" } }, [
            _vm._v(" " + _vm._s(111) + " ")
          ]),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票活动介绍" } },
            [_vm._v(" " + _vm._s("活动介绍内容") + " ")]
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "活动投票封面" } },
            [
              _c(
                "div",
                { staticClass: "upload-img-cell" },
                [
                  _c(
                    "el-image",
                    { attrs: { fit: "contain", src: _vm.form.articleCover } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: { src: _vm.uploadImg }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票选项" } },
            _vm._l(_vm.form.voteList, function(e, i) {
              return _c(
                "el-card",
                {
                  key: i,
                  staticStyle: {
                    border: "1px solid #c0c0c0",
                    width: "400px",
                    height: "100%",
                    padding: "20px"
                  }
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("选项" + _vm._s(i + 1) + "：")])
                  ]),
                  _c("el-row", [_vm._v("选项标题：" + _vm._s(111))]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { margin: "20px 0" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("span", [_vm._v("选项图片：")]),
                      _c(
                        "div",
                        { staticClass: "upload-img-cell" },
                        [
                          _c(
                            "el-image",
                            { attrs: { fit: "contain", src: e.image } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error"
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px"
                                    },
                                    attrs: { src: _vm.uploadImg }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-row", { attrs: { type: "flex" } }, [
                    _c("span", { staticStyle: { width: "80px" } }, [
                      _vm._v("图文介绍：")
                    ]),
                    _vm._v(_vm._s("图文介绍"))
                  ])
                ],
                1
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "每人每天可免费投多少票" } },
            [_vm._v(" " + _vm._s(11) + "票 ")]
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票截止时间" } },
            [_vm._v(_vm._s("2022-07-19 10:10:10"))]
          ),
          _c("el-form-item", { attrs: { required: "", label: "关联资讯" } }, [
            _vm._v(_vm._s("skdka"))
          ])
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }