var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("门店PLUS收益设置")]),
      _c(
        "el-form",
        { ref: "form", staticStyle: { "font-size": "small" } },
        [
          _c("span", [_vm._v("用户使用PLUS会员消费，用户每次消费，门店收入")]),
          _c("el-input", {
            staticStyle: { width: "100px", margin: "0 10px" },
            attrs: { placeholder: "", type: "number" },
            model: {
              value: _vm.money,
              callback: function($$v) {
                _vm.money = $$v
              },
              expression: "money"
            }
          }),
          _vm._v(" 元/次 ")
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "20px", "font-size": "small" }
        },
        [
          _c("span", [
            _vm._v("用户使用PLUS会员消费，用户每次消费，邀请人返现比例")
          ]),
          _c("el-input", {
            staticStyle: { width: "100px", margin: "0 10px" },
            attrs: { placeholder: "", type: "number" },
            model: {
              value: _vm.inviterRewardRate,
              callback: function($$v) {
                _vm.inviterRewardRate = $$v
              },
              expression: "inviterRewardRate"
            }
          }),
          _vm._v(" % ")
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveData } },
            [_vm._v("确 定")]
          ),
          _c("span", { staticClass: "tips" }, [
            _vm._v("确定后立即生效，此后门店均按最新规则获得收益")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }