var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("PLUS扫码使用记录")]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "header-cell-style": { background: "#F6F6F6" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "使用记录编号",
              prop: "plusConsumeNo",
              width: "200",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "PLUS会员名称",
              prop: "plusMemberType",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.plusMemberType,
                              _vm.plusMemberType
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "PLUS会员使用时间",
              prop: "plusConsumeTime",
              width: "200",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "PLUS会员使用用户",
              prop: "nickname",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: { userId: scope.row.userId }
                              }
                            }
                          },
                          [_vm._v(_vm._s(scope.row.nickname))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "使用门店",
              prop: "gymName",
              width: "200",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "用户消耗金额（元）",
              prop: "dayAmount",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "门店收益（元）",
              prop: "gymIncomeAmount",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "xxx", align: "center" }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }