import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  orderNo: '',
  nickname: '',
  goodsName: '',
  level: '',
  replied: '',
  evaluateTimeRange: {
    start: '',
    end: ''
  } // hasFile: true,
  // hasContent: true

};
export default {
  meta: {
    title: '商品评价',
    auth: true,
    authorityValue: 'goods-evaluate'
  },
  name: 'goods-comment',
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'GOODS_GOODS_EVALUETE_LIST',
        params: cloneDeep(initForm)
      }
    };
  },
  filters: {
    itemSell: function itemSell(e) {
      try {
        return e.map(function (v) {
          return v.itemValue;
        }).join('/');
      } catch (err) {}
    }
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    handleNav: function handleNav(NAME, row) {
      this.$router.push({
        name: NAME,
        query: row ? row : ''
      });
    }
  }
};