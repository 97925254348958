var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("PLUS会员")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "购买日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.orderTimeRange.start,
                  max: _vm.form.orderTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "交易订单号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入交易订单号" },
                model: {
                  value: _vm.form.plusOrderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "plusOrderNo", $$v)
                  },
                  expression: "form.plusOrderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "购买用户" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "输入用户昵称/手机号码/用户ID查找" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "会员类型" } },
            [
              _c("base-select", {
                attrs: { options: _vm.memberTypes },
                on: {
                  change: function($event) {
                    return _vm.handleSelect()
                  }
                },
                model: {
                  value: _vm.form.plusMemberType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "plusMemberType", $$v)
                  },
                  expression: "form.plusMemberType"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.orderStatus },
                model: {
                  value: _vm.form.orderStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderStatus", $$v)
                  },
                  expression: "form.orderStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "失效原因" } },
            [
              _c("base-select", {
                attrs: { options: _vm.invalidTypes },
                model: {
                  value: _vm.form.invalidType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "invalidType", $$v)
                  },
                  expression: "form.invalidType"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "查询统计" } }, [
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "申购总额 ￥" + _vm._s(_vm.record ? _vm.record.totalAmount : "")
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("会员数量 " + _vm._s(_vm.total))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "退款总额 ￥" + _vm._s(_vm.record ? _vm.record.refundAmount : "")
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "退款会员数 " + _vm._s(_vm.record ? _vm.record.refundCount : "")
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "已消耗总额 ￥" + _vm._s(_vm.record ? _vm.record.consumeAmount : "")
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "剩余未消耗可退总额 ￥" +
              _vm._s(_vm.record ? _vm.record.canRefundAmount : "")
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "实际扫码使用次数 " +
              _vm._s(_vm.record ? _vm.record.consumeCount : "")
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "门店收益 " +
              _vm._s(_vm.record ? _vm.record.gymIncomeAmount : "") +
              "元"
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "返现金额 " +
              _vm._s(_vm.record ? _vm.record.inviterRewardAmount : "") +
              "元"
          )
        ])
      ]),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "交易订单号", prop: "plusOrderNo", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { label: "购买时间", prop: "orderTime", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "购买用户", prop: "nickname" },
            on: {
              change: function($event) {
                return _vm.handleSelect()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user-detail",
                                query: { userId: scope.row.userId }
                              }
                            }
                          },
                          [_vm._v(_vm._s(scope.row.nickname))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "用户手机号", prop: "phoneNumber", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { label: "会员状态", prop: "priceAmount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.orderStatus,
                              _vm.orderStatus
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "申购金额（元）", prop: "priceAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "会员有效期", prop: "xxx", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.validStartDate)) +
                          "至"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.validEndDate))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "赠送时间", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(scope.row.rewardStartDate)
                        ) + "至"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.rewardEndDate))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "单日费用（元）", prop: "dayAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "已消耗金额", prop: "consumeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "剩余未消耗可退金额", prop: "refundAmount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.orderStatus == 1
                      ? _c("span", [
                          _vm._v(_vm._s(row.priceAmount - row.consumeAmount))
                        ])
                      : _vm._e(),
                    row.orderStatus == 2
                      ? _c("span", [_vm._v(_vm._s(0))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "实际扫码使用次数", prop: "consumeCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "门店收益", prop: "gymIncomeAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "返现金额", prop: "inviterRewardAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "/plus-member-usage-personalRecord?nickname=" +
                                  row.nickname
                              }
                            }
                          },
                          [_vm._v("查看扫码使用记录")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }