var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticStyle: {
      position: "fixed",
      "z-index": "-1",
      width: "100%",
      height: "100%",
      "background-color": "transparent"
    },
    attrs: { id: "container" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }