import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  meta: {
    title: '审核回放视频',
    auth: true,
    authorityValue: 'coach-live-audit-list'
  },
  name: 'live-audit-record-details',
  data: function data() {
    return {
      player: null,
      rate: true,
      rateLink: 0
    };
  },
  methods: {
    rateClick: function rateClick() {
      this.rate = !this.rate;
      this.rateLink = this.rateLink === 3 ? 0 : this.rateLink + 1;
    },
    dataBind: function dataBind() {
      this.player = TCPlayer('leb-video-container', {
        autoplay: true,
        height: '560px'
      });
      this.player.src(this.urlConversion(this.$route.query.playbackUrl));
    },
    urlConversion: function urlConversion(path) {
      var reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
      path = path.replace(reg, 'https://$2$3$4$5$6');
      return path;
    },
    destroyPlayer: function destroyPlayer() {
      this.player && this.player.dispose();
    }
  },
  mounted: function mounted() {
    this.dataBind();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroyPlayer();
  }
};