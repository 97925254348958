var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        title: "分类",
        visible: _vm.dialogVisible,
        rules: _vm.formRules,
        width: "600px",
        formData: _vm.dialogFormData
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "update:formData": function($event) {
          _vm.dialogFormData = $event
        },
        "update:form-data": function($event) {
          _vm.dialogFormData = $event
        },
        confirm: _vm.onSubmit
      }
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "mt-20",
          attrs: { label: "分类名称", prop: "typeName" }
        },
        [
          _c("el-input", {
            staticStyle: { width: "300px" },
            attrs: { placeholder: "" },
            model: {
              value: _vm.dialogFormData.typeName,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "typeName", $$v)
              },
              expression: "dialogFormData.typeName"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "mt-20",
          attrs: { label: "分类排序", prop: "typeSort" }
        },
        [
          _c("el-input", {
            staticStyle: { width: "100px" },
            attrs: { placeholder: "", type: "number" },
            model: {
              value: _vm.dialogFormData.typeSort,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "typeSort", $$v)
              },
              expression: "dialogFormData.typeSort"
            }
          }),
          _vm._v(" 数值越小，排在越前面 ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }