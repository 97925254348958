var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.pageTitle))]),
      _c("TableTitleBar", { attrs: { title: _vm.pageTitle, card: "" } }),
      _c(
        "base-table",
        {
          staticClass: "mt-20",
          attrs: { pagination: false, data: [_vm.record] }
        },
        [
          _c("el-table-column", {
            attrs: { label: "申请单编号", prop: "auditNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户昵称", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户编号", prop: "userNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "申请时间", prop: "applyTime", width: "200" }
          })
        ],
        1
      ),
      _vm.record.auditStatus > 1
        ? _c(
            "el-card",
            { staticClass: "mt-20" },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "审核信息", column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "申请人真实姓名" } },
                    [_vm._v(_vm._s(_vm.record.realName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "申请人性别" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(_vm.record.gender, _vm.sexs)
                        )
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "审核事项" } },
                    [
                      _c(
                        "base-table",
                        {
                          staticStyle: { width: "70vw" },
                          attrs: {
                            pagination: false,
                            data: _vm.record.auditProjects
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "序号", prop: "name" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "项目", prop: "value" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "评定", prop: "result" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "审核资料" } },
                    _vm._l(_vm.record.auditFiles, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _vm._v(" " + _vm._s(item.filename) + " "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.downloadFiles(
                                    item.filename,
                                    item.filepath
                                  )
                                }
                              }
                            },
                            [_vm._v("下载资料")]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.record.auditStatus == 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "审核未通过原因" } },
                        [_vm._v(_vm._s(_vm.record.rejectReason))]
                      )
                    : _vm._e(),
                  _vm.record.auditStatus == 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "向平台反馈" } },
                        [_vm._v(_vm._s(_vm.record.auditFeedback))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.record.auditStatus > 1
        ? _c(
            "el-card",
            {
              staticClass: "d2-mt",
              staticStyle: { "font-size": "small" },
              attrs: { shadow: "never", "body-style": { padding: "20px" } }
            },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("记录")
              ]),
              _vm._l(_vm.record.logs, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.logType === 2
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("备注：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _c("el-col", [
                              _vm._v("门店编号：" + _vm._s(item.gymId))
                            ]),
                            _c("el-col", [
                              _vm._v("门店名称：" + _vm._s(item.gymName))
                            ]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    item.logType === 1
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("审核：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _c("el-col", [
                              _vm._v("门店编号：" + _vm._s(item.gymNo))
                            ]),
                            _c("el-col", [
                              _vm._v("门店名称：" + _vm._s(item.gymName))
                            ]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c("RemakDialog", {
        attrs: { visible: _vm.remakDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.remakDialogVisible = $event
          },
          onSuccess: function($event) {
            return _vm.handleSearch(_vm.form)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }