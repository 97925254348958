var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    _vm._b(
      {
        ref: "form",
        staticClass: "d2-mb",
        attrs: { model: _vm.form, inline: "" }
      },
      "el-form",
      _vm.$attrs,
      false
    ),
    [
      _vm._t("default"),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "default" }, on: { click: _vm.reset } },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._t("extra")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }