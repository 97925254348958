var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c("span", [_vm._v("用户留存统计")]),
            _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "d2-mr",
                    on: { change: _vm.timeTypeChange },
                    model: {
                      value: _vm.form.timeType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "timeType", $$v)
                      },
                      expression: "form.timeType"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("最近七天")
                    ]),
                    _c("el-radio-button", { attrs: { label: 2 } }, [
                      _vm._v("最近30天")
                    ])
                  ],
                  1
                ),
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  on: { change: _vm.timeTypeChange2 },
                  model: {
                    value: _vm.datePickerTime,
                    callback: function($$v) {
                      _vm.datePickerTime = $$v
                    },
                    expression: "datePickerTime"
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [
              _vm._v("趋势图数据 "),
              _c("span", { staticClass: "d2-ml" }, [
                _vm._v(_vm._s(_vm._f("chartDataTimeMaxMin")(_vm.chartData)))
              ])
            ])
          ]),
          _c("div", {
            staticStyle: { height: "400px" },
            attrs: { id: "canvas" }
          })
        ]
      ),
      _c(
        "el-card",
        {
          staticClass: "d2-mt",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "注册时间", prop: "date" }
              }),
              _c("el-table-column", {
                attrs: { label: "新增用户", prop: "newUser" }
              }),
              _c("el-table-column", {
                attrs: { label: "1天后", prop: "day1" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day1) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "2天后", prop: "day2" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day2) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "3天后", prop: "day3" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day3) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "4天后", prop: "day4" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day4) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "5天后", prop: "day5" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day5) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "6天后", prop: "day6" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day6) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "7天后", prop: "day7" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day7) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "14天后", prop: "day14" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day14) + "% ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "30天后", prop: "day30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.day30) + "% ")]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }