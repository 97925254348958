import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var title = '教练评价';
var initForm = {
  trainingOrderNo: '',
  nickname: '',
  evaluateType: 0,
  replied: '',
  hasFile: false,
  hasText: false,
  evaluateTimeRange: {
    start: '',
    end: ''
  }
};
export default {
  meta: {
    title: title,
    auth: true,
    authorityValue: 'coach-evaluate-manage'
  },
  name: 'personal-trainer-coach',
  components: {},
  data: function data() {
    return {
      title: title,
      initForm: initForm,
      form: cloneDeep(initForm),
      nowRow: null,
      headStyle: function headStyle() {
        return 'text-align:left';
      },
      request: {
        api: 'PTRAIN_COACH_EVALUATES',
        params: _objectSpread(_objectSpread({}, cloneDeep(initForm)), {}, {
          realName: this.$route.query.realName ? this.$route.query.realName : ''
        })
      }
    };
  },
  methods: {
    read: function read(row) {
      this.$router.push('/personal-trainer-evaluate?id=' + row.trainingOrderId);
    },
    handleSearch: function handleSearch() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (this.$route.query.realName) {
        this.$set(values, 'realName', this.$route.query.realName);
      }

      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData(reset);
    }
  },
  mounted: function mounted() {
    this.handleSearch();
  }
};