var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v("直播课详情")]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px", "label-position": "left" } },
            [
              _c("el-form-item", { attrs: { label: "推流地址" } }, [
                _c("span", [
                  _vm._v("请复制一个适合的推流地址到第三方直播设备平台")
                ])
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "RTMP地址" } },
                [
                  _c("span", { staticClass: "d2-mr" }, [
                    _vm._v(_vm._s(_vm.form.pushUrls.rtmp))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.copyText(_vm.form.pushUrls.rtmp, $event)
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "OBS推流地址" } },
                [
                  _c("span", { staticClass: "d2-mr" }, [
                    _vm._v(_vm._s(_vm.form.pushUrls.obsAddress))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.copyText(
                            _vm.form.pushUrls.obsAddress,
                            $event
                          )
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "OBS推流名称" } },
                [
                  _c("span", { staticClass: "d2-mr" }, [
                    _vm._v(_vm._s(_vm.form.pushUrls.obsName))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.copyText(_vm.form.pushUrls.obsName, $event)
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "WebRTC地址" } },
                [
                  _c("span", { staticClass: "d2-mr" }, [
                    _vm._v(_vm._s(_vm.form.pushUrls.webrtc))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.copyText(_vm.form.pushUrls.webrtc, $event)
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SRT地址" } },
                [
                  _c("span", { staticClass: "d2-mr" }, [
                    _vm._v(_vm._s(_vm.form.pushUrls.srt))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.copyText(_vm.pushUrls.srt, $event)
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "d2-mt",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("直播课信息")
          ]),
          _c(
            "el-form",
            { attrs: { "label-width": "120px", "label-position": "left" } },
            [
              _c("el-form-item", { attrs: { label: "直播类型" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.getCateStr(_vm.form.liveCategoryId)))
                ])
              ]),
              _c("el-form-item", { attrs: { label: "直播课名称" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(_vm._s(_vm.form.courseName))
                ])
              ]),
              _c("el-form-item", { attrs: { label: "直播时间" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(
                    _vm._s(_vm.form.liveDate) +
                      " " +
                      _vm._s(_vm.form.liveStartTime)
                  )
                ])
              ]),
              _c("el-form-item", { attrs: { label: "直播课时长" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(_vm._s(_vm.form.liveTimeLength))
                ])
              ]),
              _c("el-form-item", { attrs: { label: "消耗能量" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(
                    _vm._s(_vm.form.consumeCalorieMin) +
                      " - " +
                      _vm._s(_vm.form.consumeCalorieMax) +
                      " 千卡"
                  )
                ])
              ]),
              _c("el-form-item", { attrs: { label: "直播课难度" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v("L" + _vm._s(_vm.form.courseDifficultyLevel))
                ])
              ]),
              _c("el-form-item", { attrs: { label: "直播教练" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(
                    _vm._s(_vm.form.coachNo) +
                      " " +
                      _vm._s(_vm.form.realName) +
                      " " +
                      _vm._s(_vm.form.nickname)
                  )
                ])
              ]),
              _c("el-form-item", { attrs: { label: "练习频次" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(_vm._s(_vm.form.trainingFrequency))
                ])
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "器械" } },
                [
                  _c("span", { staticClass: "d2-mr" }, [
                    _vm._v(_vm._s(_vm.isEquipmentTypes ? "有器械" : "无器械"))
                  ]),
                  _vm.isEquipmentTypes
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { width: "500px" },
                          attrs: {
                            shadow: "never",
                            "body-style": { padding: "0px 10px" }
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._v(" 器械: "),
                              _vm._l(_vm.form.fitnessEquipmentTypes, function(
                                item,
                                index
                              ) {
                                return _c("div", { key: index }, [
                                  _vm._v(_vm._s(item))
                                ])
                              })
                            ],
                            2
                          ),
                          _c("div", [_vm._v("商品:")])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "训练总能量" } }, [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v(_vm._s(_vm.form.trainingCalorie))
                ])
              ]),
              _c("el-form-item", { attrs: { label: "直播课封面" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upload-img-cell",
                    on: {
                      click: function($event) {
                        return _vm.openImageView(_vm.form.coverImageUrl)
                      }
                    }
                  },
                  [
                    _c(
                      "el-image",
                      {
                        attrs: { fit: "contain", src: _vm.form.coverImageUrl }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "50px", height: "50px" },
                              attrs: { src: _vm.uploadImg }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("el-form-item", { attrs: { label: "直播课介绍" } }, [
                _c("span", {
                  staticClass: "d2-mr",
                  domProps: { innerHTML: _vm._s(_vm.form.courseIntroduce) }
                })
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }