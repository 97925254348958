/**
 * 大屏资讯
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * @description 保存
     */
    NEWS_SAVE_NEWS: function NEWS_SAVE_NEWS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/news/saveNews',
        data: data
      });
    },

    /**
     * @description 列表
     */
    NEWS_NEWS_LIST: function NEWS_NEWS_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/news/newsList',
        data: data
      });
    },

    /**
     * @description 详情
     */
    NEWS_NEWS_DETAILS: function NEWS_NEWS_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/news/newsDetails',
        data: data
      });
    },

    /**
     * @description 删除
     */
    NEWS_DELETE_NEWS: function NEWS_DELETE_NEWS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/news/deleteNews',
        data: data
      });
    }
  };
});