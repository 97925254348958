//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TableTitleBar from '@/components/global/base-table/TableTitleBar.vue';
import { cloneDeep } from 'lodash';
var initForm = {
  gymName: '',
  gymNo: '',
  companyName: '',
  depositTimeRange: {
    start: '',
    end: ''
  },
  type: 1,
  refunded: ''
};
export default {
  components: {
    TableTitleBar: TableTitleBar
  },
  data: function data() {
    var _this = this;

    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'finance_gymDepositList',
        params: cloneDeep(initForm),
        parser: function parser(res) {
          _this.extraData = res.extraData;
          return res;
        }
      },
      extraData: {}
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    onDetail: function onDetail(row) {
      this.$router.push({
        name: 'shop-payment-confirmed-detail',
        query: {
          depositId: row.depositId
        }
      });
    }
  }
};