var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c("template", { slot: "header" }, [_vm._v("等待审核直播间")]),
      _vm.records && _vm.records.length < 1
        ? _c("el-empty", { attrs: { description: "暂无待审核直播间" } })
        : _c(
            "div",
            { staticClass: "top-box" },
            _vm._l(_vm.records, function(l) {
              return _c(
                "div",
                { key: l.auditId, staticClass: "item" },
                [
                  _c("CoachInfo", {
                    attrs: {
                      url: l.userAvatar,
                      introduction: l.categoryName,
                      nickname: l.realName
                    }
                  }),
                  _c("p", { staticClass: "top-tip d2-mt-10" }, [
                    _vm._v(
                      _vm._s(l.categoryName) + "-" + _vm._s(l.subcategoryName)
                    )
                  ]),
                  _c("p", { staticClass: "top-tip" }, [
                    _vm._v("连线时间:" + _vm._s(l.applyTime))
                  ]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.goRoute(
                            "/course-live-wait-details?auditId=" + l.auditId
                          )
                        }
                      }
                    },
                    [_vm._v("审核")]
                  )
                ],
                1
              )
            }),
            0
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }