//
//
//
//
import AddBanner from '@/components/add-banner';
export default {
  meta: {
    title: '关于基金会',
    auth: true,
    authorityValue: 'foundation-intro'
  },
  components: {
    AddBanner: AddBanner
  },
  name: 'about-foundation',
  data: function data() {
    return {
      apis: {
        list: 'aed_getFoundationBanner',
        add: 'aed_saveFoundationBanner',
        del: 'aed_deleteFoundationBanner'
      }
    };
  }
};