var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "staff-edit" },
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.$route.query.adminId ? "编辑员工" : "添加员工"))
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "登录账号", prop: "adminAccount" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.adminAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "adminAccount", $$v)
                  },
                  expression: "form.adminAccount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录密码", prop: "loginPassword" } },
            [
              _vm.isEditPassword || !_vm.$route.query.adminId
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.loginPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "loginPassword", $$v)
                      },
                      expression: "form.loginPassword"
                    }
                  })
                : _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.fakePassword,
                      callback: function($$v) {
                        _vm.fakePassword = $$v
                      },
                      expression: "fakePassword"
                    }
                  }),
              _vm.$route.query.adminId
                ? _c(
                    "el-button",
                    {
                      staticClass: "ml-20",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          _vm.isEditPassword = true
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "员工姓名", prop: "adminName" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.adminName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "adminName", $$v)
                  },
                  expression: "form.adminName"
                }
              })
            ],
            1
          ),
          !_vm.$route.query.adminId
            ? _c(
                "el-form-item",
                { attrs: { label: "所属部门", prop: "departmentId" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      options: _vm.depTree,
                      props: { expandTrigger: "hover" }
                    },
                    model: {
                      value: _vm.form.departmentId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "departmentId", $$v)
                      },
                      expression: "form.departmentId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "员工手机号", prop: "phoneNumber" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入", maxlength: "11" },
                model: {
                  value: _vm.form.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phoneNumber", $$v)
                  },
                  expression: "form.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "搭配角色", prop: "positionId" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.positions },
                on: { change: _vm.changeRadio },
                model: {
                  value: _vm.form.positionId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "positionId", $$v)
                  },
                  expression: "form.positionId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权限" } },
            [
              _c("AuthSetting", {
                ref: "authSetting",
                staticStyle: { "min-height": "300px" },
                model: {
                  value: _vm.form.positionAuthorities,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "positionAuthorities", $$v)
                  },
                  expression: "form.positionAuthorities"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }