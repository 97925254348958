//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import { auditStates } from '../../professional-qualification-audit/const';
var initForm = {
  recertificationNo: "",
  status: 1,
  nickname: "",
  createTimeRange: {
    start: "",
    end: ""
  }
};
export default {
  meta: {
    title: 'tab-waiting-audit',
    auth: true
  },
  name: 'tab-waiting-audit',
  components: {},
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'coach_recertificationList',
        params: cloneDeep(initForm)
      },
      auditStates: auditStates,
      result: [{
        label: '未知',
        value: ''
      }, {
        label: '待审核',
        value: 1
      }, {
        label: '审核通过',
        value: 2
      }, {
        label: '审核不通过',
        value: 3
      }]
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};