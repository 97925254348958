//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
var initForm = {
  orderNo: '',
  createTimeRange: {
    start: '',
    end: ''
  }
};
import { cloneDeep } from 'lodash';
export default {
  meta: {
    title: '延迟发货记录',
    auth: true,
    authorityValue: 'delayed-shipment-list'
  },
  name: 'delay-ship',
  components: {
    ElImageViewer: ElImageViewer
  },
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'ORDER_MALL_OVERDUE_DELIVERY_LIST',
        params: cloneDeep(initForm)
      },
      showViewer: false
    };
  },
  methods: {
    onClose: function onClose(e) {
      this.showViewer = false;
    },
    onDetail: function onDetail(e) {
      this.$router.push({
        path: '/order-detail',
        query: {
          orderId: e
        }
      });
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    toOpenImageView: function toOpenImageView(obj) {
      this.openImageView({
        multiple: true,
        // 开启多图预览模式
        nowImgIndex: 1,
        // 多图预览，默认展示第二张图片
        imgList: obj,
        // 需要预览的多图数组
        mainBackground: 'rgba(0, 0, 0, .5)' // 整体背景颜色

      });
    }
  }
};