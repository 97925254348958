var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v(" 实时概况 ")]),
      _c(
        "el-card",
        {
          staticClass: "d2-mb",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c("div", { attrs: { flex: "main:justify" } }, [
            _c("div", { staticStyle: { "font-size": "20px" } }, [
              _c("b", [_vm._v("实时概况")]),
              _c("i", {
                staticClass: "el-icon-timer d2-ml",
                staticStyle: { "font-size": "12px" }
              }),
              _c("span", { staticStyle: { "font-size": "12px" } }, [
                _vm._v(
                  "更新时间: " + _vm._s(_vm._f("formatDateTime")(new Date()))
                )
              ])
            ]),
            _c(
              "div",
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("店铺名称：")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { clearable: "" },
                    on: { change: _vm.handleSelect },
                    model: {
                      value: _vm.form.storeId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "storeId", $$v)
                      },
                      expression: "form.storeId"
                    }
                  },
                  _vm._l(_vm.storeList, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.storeName, value: item.storeId }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("CenterHome", { ref: "CenterHome", staticClass: "d2-mb" }),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("b", [_vm._v("今日走势")])
          ]),
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.checkedCities,
                callback: function($$v) {
                  _vm.checkedCities = $$v
                },
                expression: "checkedCities"
              }
            },
            [
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "浏览行为" } },
                    [
                      _c("el-checkbox", { attrs: { label: "visitors" } }, [
                        _vm._v("访客数")
                      ]),
                      _c("el-checkbox", { attrs: { label: "browses" } }, [
                        _vm._v("浏览量")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "成交转化" } },
                    [
                      _c(
                        "el-checkbox",
                        { attrs: { label: "orderConsumers" } },
                        [_vm._v("成交客户数")]
                      ),
                      _c("el-checkbox", { attrs: { label: "orders" } }, [
                        _vm._v("成交单量")
                      ]),
                      _c("el-checkbox", { attrs: { label: "orderGoods" } }, [
                        _vm._v("成交商品件数")
                      ]),
                      _c("el-checkbox", { attrs: { label: "orderAmount" } }, [
                        _vm._v("成交金额")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { ref: "chart1", staticStyle: { height: "400px" } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }