var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box flex-center-between" }, [
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "label" }, [_vm._v("用户总数")]),
      _c(
        "div",
        { staticClass: "number" },
        [_c("d2-count-up", { attrs: { end: _vm.tabData.userCount } })],
        1
      ),
      _c("div", { staticClass: "js-object-log" })
    ]),
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "label" }, [_vm._v("今日新增用户")]),
      _c(
        "div",
        { staticClass: "number" },
        [_c("d2-count-up", { attrs: { end: _vm.tabData.userCountToday } })],
        1
      )
    ]),
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "label" }, [_vm._v("教练数")]),
      _c(
        "div",
        { staticClass: "number" },
        [_c("d2-count-up", { attrs: { end: _vm.tabData.coachCount } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }