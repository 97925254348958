var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("店铺信息（管理后台）")]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "店铺基础信息", name: "1" } },
            [_c("TabBasics", { attrs: { data: _vm.data } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "经营类目", name: "2" } },
            [
              _c("TabBrand", {
                attrs: { data: _vm.data },
                on: { updata: _vm.dataBind }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "店铺品牌", name: "3" } },
            [_c("TabCategory", { attrs: { data: _vm.data } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "店铺联系信息", name: "4" } },
            [_c("TabContact", { attrs: { data: _vm.data } })],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }