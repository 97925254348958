var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-button",
        {
          staticClass: "d2-mb",
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.edit()
            }
          }
        },
        [_vm._v("添加活动直播")]
      ),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动直播状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dict.liveStatus },
                model: {
                  value: _vm.form.liveStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "liveStatus", $$v)
                  },
                  expression: "form.liveStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.liveDateRange.start,
                  max: _vm.form.liveDateRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.liveDateRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.liveDateRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动直播类型" } },
            [
              _c("base-select", {
                attrs: { options: _vm.live_cate_type },
                model: {
                  value: _vm.form.liveCategoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "liveCategoryId", $$v)
                  },
                  expression: "form.liveCategoryId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否APP展示" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.appShow,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "appShow", $$v)
                    },
                    expression: "form.appShow"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _c("el-option", { attrs: { label: "是", value: true } }),
                  _c("el-option", { attrs: { label: "否", value: false } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动直播编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入直播编号搜索" },
                model: {
                  value: _vm.form.liveNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "liveNo", $$v)
                  },
                  expression: "form.liveNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动直播名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入直播名称搜索" },
                model: {
                  value: _vm.form.courseName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courseName", $$v)
                  },
                  expression: "form.courseName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "活动直播编号", prop: "liveNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("ftValue2label")(
                          scope.row.liveStatus,
                          _vm.dict.liveStatus
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "170", label: "直播时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.timeStartEnd(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "活动直播名称", prop: "courseName" }
          }),
          _c("el-table-column", {
            attrs: { label: "活动直播分类" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(_vm.getCateStr(scope.row)) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否APP展示", prop: "appShow" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      on: {
                        change: function($event) {
                          return _vm.onToggleShow(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.appShow,
                        callback: function($$v) {
                          _vm.$set(scope.row, "appShow", $$v)
                        },
                        expression: "scope.row.appShow"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", prop: "createTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.isBtnGroud([1, 2, 4], scope.row.liveStatus)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.goRoute(
                                  "/cate-live-list-console?liveId=" +
                                    scope.row.liveId
                                )
                              }
                            }
                          },
                          [_vm._v("直播控制台")]
                        )
                      : _vm._e(),
                    _vm.isBtnGroud([3, 6], scope.row.liveStatus)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v("数据")]
                        )
                      : _vm._e(),
                    _vm.isBtnGroud([6], scope.row.liveStatus)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.read(scope.row)
                              }
                            }
                          },
                          [_vm._v("回放")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.details(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    !_vm.isBtnGroud([5], scope.row.liveStatus)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.isBtnGroud([1], scope.row.liveStatus)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.cancel(scope.row)
                              }
                            }
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e(),
                    _vm.isBtnGroud([5], scope.row.liveStatus)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.del(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }