export default [// {
//   path: '/dispute-list',
//   title: '纠纷信息',
//   authorityValue: "dispute-management"
// },
{
  title: '纠纷信息',
  iconSvg: '纠纷信息',
  authorityValue: "dispute-management",
  children: [{
    path: '/dispute-list',
    title: '纠纷信息',
    authorityValue: "dispute-management"
  }]
}];