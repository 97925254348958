var commonSub = function commonSub(subList) {
  return [{
    id: '100004201',
    name: '男子',
    subList: subList
  }, {
    id: '100004202',
    name: '女子',
    subList: subList
  }];
};

var temp = {
  name: '青少年中考',
  id: '10000004',
  subLables: ['城市', '性别', '项目'],
  subList: [{
    id: '100004101',
    name: '广州',
    subList: commonSub([// {
    //   id: '100004301',
    //   name: '1000米',
    //   subList: []
    // },
    // {
    //   id: '100004302',
    //   name: '800米',
    //   subList: []
    // },
    {
      id: "100004301",
      name: "1000米",
      subList: []
    }, {
      id: "100004302",
      name: "800米",
      subList: []
    }, {
      id: "100004303",
      name: "立定跳远",
      subList: []
    }, {
      id: "100004304",
      name: "三级蛙跳",
      subList: []
    }, {
      id: "100004305",
      name: "一分钟跳绳",
      subList: []
    }, {
      id: "100004306",
      name: "铅球",
      subList: []
    }, {
      id: "100004307",
      name: "实心球",
      subList: []
    }, {
      id: "100004308",
      name: "足球",
      subList: []
    }, {
      id: "100004309",
      name: "篮球",
      subList: []
    }, {
      id: "100004310",
      name: "排球",
      subList: []
    }])
  }, {
    id: '100004102',
    name: '深圳',
    subList: commonSub([{
      id: "100004301",
      name: "1000米",
      subList: []
    }, {
      id: "100004302",
      name: "800米",
      subList: []
    }, {
      id: "100004303",
      name: "立定跳远",
      subList: []
    }, {
      id: "100004304",
      name: "三级蛙跳",
      subList: []
    }, {
      id: "100004305",
      name: "一分钟跳绳",
      subList: []
    }, {
      id: "100004306",
      name: "铅球",
      subList: []
    }, {
      id: "100004307",
      name: "实心球",
      subList: []
    }, {
      id: "100004308",
      name: "足球",
      subList: []
    }, {
      id: "100004309",
      name: "篮球",
      subList: []
    }, {
      id: "100004310",
      name: "排球",
      subList: []
    } // {
    //   id: '100004303',
    //   name: '立定跳远',
    //   subList: []
    // },
    // {
    //   id: '100004304',
    //   name: '三级跳远',
    //   subList: []
    // },
    // {
    //   id: '100004305',
    //   name: '一分钟跳绳',
    //   subList: []
    // }
    ])
  }, {
    id: '100004103',
    name: '北京',
    subList: commonSub([{
      id: "100004301",
      name: "1000米",
      subList: []
    }, {
      id: "100004302",
      name: "800米",
      subList: []
    }, {
      id: "100004303",
      name: "立定跳远",
      subList: []
    }, {
      id: "100004304",
      name: "三级蛙跳",
      subList: []
    }, {
      id: "100004305",
      name: "一分钟跳绳",
      subList: []
    }, {
      id: "100004306",
      name: "铅球",
      subList: []
    }, {
      id: "100004307",
      name: "实心球",
      subList: []
    }, {
      id: "100004308",
      name: "足球",
      subList: []
    }, {
      id: "100004309",
      name: "篮球",
      subList: []
    }, {
      id: "100004310",
      name: "排球",
      subList: []
    } // {
    //   id: '100004306',
    //   name: '铅球',
    //   subList: []
    // },
    // {
    //   id: '100004307',
    //   name: '实心球',
    //   subList: []
    // }
    ])
  }, {
    id: '100004104',
    name: '杭州',
    subList: commonSub([{
      id: "100004301",
      name: "1000米",
      subList: []
    }, {
      id: "100004302",
      name: "800米",
      subList: []
    }, {
      id: "100004303",
      name: "立定跳远",
      subList: []
    }, {
      id: "100004304",
      name: "三级蛙跳",
      subList: []
    }, {
      id: "100004305",
      name: "一分钟跳绳",
      subList: []
    }, {
      id: "100004306",
      name: "铅球",
      subList: []
    }, {
      id: "100004307",
      name: "实心球",
      subList: []
    }, {
      id: "100004308",
      name: "足球",
      subList: []
    }, {
      id: "100004309",
      name: "篮球",
      subList: []
    }, {
      id: "100004310",
      name: "排球",
      subList: []
    } // {
    //   id: '100004308',
    //   name: '足球',
    //   subList: []
    // },
    // {
    //   id: '100004309',
    //   name: '篮球',
    //   subList: []
    // },
    // {
    //   id: '100004310',
    //   name: '排球',
    //   subList: []
    // }
    ])
  }]
};
export default temp;