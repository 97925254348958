import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _toConsumableArray from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { washTree } from './washTree';
/**
 * 创建 role 级联选择器可以 初始化显示的列表
 * @param {*} roleTreeData
 * @return {*}
 */

function createRoleInitList(roleTreeData) {
  roleTreeData = washTree(roleTreeData, 'authorityName', 'authorityValue');
  var targetList = [];

  var convert = function convert(list, selectList) {
    return list === null || list === void 0 ? void 0 : list.map(function (item) {
      var _item$children;

      var _selectList = [].concat(_toConsumableArray(selectList), [item.value]);

      if (item.active && (!(item === null || item === void 0 ? void 0 : item.children) || (item === null || item === void 0 ? void 0 : (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) === 0)) {
        targetList.push(_selectList);
      }

      return _objectSpread(_objectSpread({}, item), {}, {
        label: item.label,
        value: item.value,
        selectList: _selectList,
        children: convert(item === null || item === void 0 ? void 0 : item.children, _selectList)
      });
    });
  };

  convert(roleTreeData, []);
  return targetList;
}

export { createRoleInitList };