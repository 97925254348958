var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { attrs: { flex: "main:right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitSort }
                    },
                    [_vm._v("保存排序")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v("发现好物商品")]),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "el-button",
            {
              staticClass: "d2-mb",
              attrs: { type: "primary" },
              on: { click: _vm.layerClickOpen }
            },
            [_vm._v("添加商品到发现好物专区")]
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "店铺名称", prop: "storeName", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品信息",
                  prop: "phone",
                  width: "500px",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_c("goods-card", { attrs: { row: row } })]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "总库存", prop: "goodsStock", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "售价(元)",
                  prop: "goodsPrice",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "30天销量", prop: "soldCount", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "上架时间",
                  prop: "onShelfTime",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatDateTime")(scope.row.onShelfTime)
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品排序",
                  prop: "recommendSort",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            precision: 0,
                            min: 1,
                            step: 1,
                            controls: false
                          },
                          model: {
                            value: row.recommendSort,
                            callback: function($$v) {
                              _vm.$set(row, "recommendSort", $$v)
                            },
                            expression: "row.recommendSort"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.delClick(row)
                              }
                            }
                          },
                          [_vm._v("移除商品")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "d2-layer",
        {
          attrs: { visible: _vm.layer.show, layer: _vm.layer },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.layer, "show", $event)
            },
            confirm: _vm.confirm
          }
        },
        [
          _vm.layer.loading
            ? _c("el-skeleton", { attrs: { rows: 6, animated: "" } })
            : _c("el-scrollbar", { staticStyle: { height: "50vh" } }, [
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.load,
                        expression: "load"
                      }
                    ],
                    staticClass: "d2-p-0",
                    staticStyle: { overflow: "auto" },
                    attrs: { "infinite-scroll-disabled": !_vm.layer.loading }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.layer.form.goodsId,
                          callback: function($$v) {
                            _vm.$set(_vm.layer.form, "goodsId", $$v)
                          },
                          expression: "layer.form.goodsId"
                        }
                      },
                      _vm._l(_vm.layer.records, function(l) {
                        return _c(
                          "li",
                          { key: l.goodsId },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "d2-mb",
                                attrs: {
                                  shadow: "never",
                                  "body-style": { padding: "20px" }
                                }
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: l.goodsId, flex: "main" } },
                                  [_c("goods-card", { attrs: { row: l } })],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }