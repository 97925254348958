var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "page-content",
        [
          _c("base-radio-group", {
            attrs: { options: _vm.blockTypes, childrenType: "button" },
            on: {
              change: function($event) {
                return _vm.handleSearch(_vm.form)
              }
            },
            model: {
              value: _vm.tabForm.blockType,
              callback: function($$v) {
                _vm.$set(_vm.tabForm, "blockType", $$v)
              },
              expression: "tabForm.blockType"
            }
          }),
          _c(
            "base-form",
            {
              staticClass: "mt-20",
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发布日期" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.createTimeRange.start,
                      max: _vm.form.createTimeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.createTimeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.createTimeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布人" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "发布人昵称/用户编号/手机号码" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "被屏蔽原因" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.blockReasons },
                    model: {
                      value: _vm.form.blockReason,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "blockReason", $$v)
                      },
                      expression: "form.blockReason"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "屏蔽日期" } },
                [
                  _c("range-date", {
                    attrs: {
                      min: _vm.form.blockTimeRange.start,
                      max: _vm.form.blockTimeRange.end
                    },
                    on: {
                      "update:min": function($event) {
                        return _vm.$set(
                          _vm.form.blockTimeRange,
                          "start",
                          $event
                        )
                      },
                      "update:max": function($event) {
                        return _vm.$set(_vm.form.blockTimeRange, "end", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "TableTitleBar",
            { attrs: { title: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.multipleSelection.length == 0,
                    type: "default"
                  },
                  on: {
                    click: function($event) {
                      return _vm.onShowDialog(_vm.multipleSelection, true)
                    }
                  }
                },
                [_vm._v("取消屏蔽")]
              )
            ],
            1
          ),
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: { request: _vm.request },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { label: "编号", prop: "postId", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "动态标题", prop: "postTitle" }
              }),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ftValue2label")(
                                  scope.row.blockType,
                                  _vm.blockTypes
                                )
                              )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "屏蔽原因", prop: "blockReason", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "所在位置", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.location.province) +
                              _vm._s(scope.row.location.city) +
                              _vm._s(scope.row.location.district)
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否公开" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(scope.row.openVisit ? "是" : "否")
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "发布人昵称", prop: "nickname", width: "150" }
              }),
              _c("el-table-column", {
                attrs: { label: "发布时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "屏蔽时间", prop: "blockTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/community-message-detail",
                                    query: {
                                      postId: scope.row.postId,
                                      extraType: scope.row.extraType
                                    }
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onShowDialog(scope.row, false)
                              }
                            }
                          },
                          [_vm._v("取消屏蔽")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "base-dialog",
            {
              attrs: {
                labelWidth: "80px",
                fullTitle: "取消屏蔽",
                visible: _vm.dialogVisible,
                rules: _vm.formRules,
                formData: _vm.dialogFormData,
                isEdit: true
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                "update:formData": function($event) {
                  _vm.dialogFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.dialogFormData = $event
                },
                confirm: _vm.callPostBlock
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "", type: "textarea", rows: 4 },
                    model: {
                      value: _vm.dialogFormData.logRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogFormData, "logRemark", $$v)
                      },
                      expression: "dialogFormData.logRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }