var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("订单详情")]),
      _c(
        "div",
        { staticClass: "top-bar" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(" 订单号：" + _vm._s(_vm.record.trainingOrderNo))
          ]),
          _c(
            "el-button",
            {
              staticClass: "ml-20",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.textCopy(_vm.record.trainingOrderNo, $event)
                }
              }
            },
            [_vm._v("复制")]
          ),
          _c("span", { staticClass: "title ml-20" }, [
            _vm._v("当前订单状态：")
          ]),
          _c("el-tag", { staticClass: "title", attrs: { type: "warning" } }, [
            _vm._v(
              _vm._s(
                _vm._f("ftValue2label")(_vm.record.orderStatus, _vm.orderStatus)
              )
            )
          ]),
          _c(
            "div",
            { staticClass: "btn-bar" },
            [
              _vm.showCancelOrderBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.cancelOrder }
                    },
                    [_vm._v("取消订单")]
                  )
                : _vm._e(),
              _vm.showRemarkOrderBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.remarkOrder }
                    },
                    [_vm._v("备注订单")]
                  )
                : _vm._e(),
              _vm.showFinishkOrderBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.finishOrder }
                    },
                    [_vm._v("完成订单")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "user-info-box" },
        [
          _c(
            "el-card",
            { staticClass: "card" },
            [
              _c("el-descriptions", {
                attrs: { title: "学员信息", column: 1 }
              }),
              _vm._l(_vm.record.userList, function(item, index) {
                return _c(
                  "el-descriptions",
                  { key: index, staticClass: "mb-10", attrs: { column: 1 } },
                  [
                    _c(
                      "el-descriptions-item",
                      { key: index, attrs: { label: "学员" } },
                      [_vm._v(_vm._s(item.nickname))]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                      _vm._v(_vm._s(item.phoneNumber))
                    ])
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "el-card",
            { staticClass: "card" },
            [
              _c("el-descriptions", {
                attrs: { title: "教练信息", column: 1 }
              }),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    title: "",
                    column: 1,
                    colon: false,
                    "content-class-name": "my-content"
                  }
                },
                [
                  _vm._v("> "),
                  _vm.isNeedCoachConfirm
                    ? _c(
                        "el-descriptions-item",
                        [
                          _c(
                            "el-tag",
                            {
                              staticStyle: { "margin-left": "-10px" },
                              attrs: { type: "danger" }
                            },
                            [_vm._v("待选新教练")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isNeedCoachConfirm
                    ? _c("el-descriptions-item", [
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "-10px" } },
                          [_vm._v(" 原教练")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "真实姓名:" } },
                    [_vm._v(_vm._s(_vm.record.realName))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "手机号:" } }, [
                    _vm._v(_vm._s(_vm.record.phoneNumber))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "card" },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "课程信息", column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "课程" } }, [
                    _vm._v(
                      _vm._s(_vm.record.categoryName) +
                        "（" +
                        _vm._s(_vm.record.subcategoryName) +
                        "）"
                    )
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "上课方式" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("ftValue2label")(
                          _vm.record.teachingType,
                          _vm.teachingTypes
                        )
                      )
                    )
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "训练时间" } }, [
                    _vm._v(
                      _vm._s(_vm.record.trainingTimeStart) +
                        " ~ " +
                        _vm._s(_vm.record.trainingTimeEnd)
                    )
                  ]),
                  _vm.record.teachingType === 1
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "上课门店" } },
                        [_vm._v(_vm._s(_vm.record.gymName))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cost-info" },
        [
          _c("el-card", [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("费用信息")
            ]),
            _c("div", [
              _vm._v("课程金额：￥" + _vm._s(_vm.record.trainingPrice))
            ]),
            _vm.showCost
              ? _c("div", { staticClass: "mt-10" }, [
                  _vm.record.teachingType === 1
                    ? _c("span", [
                        _vm._v(
                          "门店获得佣金" +
                            _vm._s(_vm.record.gymIncomeAmount) +
                            "元（课程金额的" +
                            _vm._s(_vm.record.gymIncomeRate) +
                            "%），"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    " 教练获得佣金" +
                      _vm._s(_vm.record.coachIncomeAmount) +
                      "元（课程金额的" +
                      _vm._s(_vm.record.coachIncomeRate) +
                      "%）， 教练获得接单奖励金额" +
                      _vm._s(_vm.record.coachRewardAmount) +
                      "元（未到结算日），"
                  ),
                  _vm.record.teachingType === 1
                    ? _c("span", [
                        _vm._v(
                          "平台收入" +
                            _vm._s(
                              _vm.record.trainingPrice -
                                (
                                  _vm.record.gymIncomeAmount +
                                  _vm.record.coachIncomeAmount
                                ).toFixed(2)
                            ) +
                            "元"
                        )
                      ])
                    : _vm._e(),
                  _vm.record.teachingType === 2
                    ? _c("span", [
                        _vm._v(
                          "平台收入" +
                            _vm._s(
                              (
                                _vm.record.trainingPrice -
                                _vm.record.coachIncomeAmount
                              ).toFixed(2)
                            ) +
                            "元"
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "操作信息" } }),
      _c(
        "base-table",
        { attrs: { pagination: false, data: _vm.record.logList, border: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "操作者", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户角色", prop: "userType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.userType,
                              _vm.userTypes
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作时间", prop: "createTime", width: "200" }
          }),
          _c("el-table-column", { attrs: { label: "事件", prop: "logTitle" } }),
          _c("el-table-column", { attrs: { label: "其他", prop: "logRemark" } })
        ],
        1
      ),
      _c("CancelOrderDialog", {
        attrs: {
          visible: _vm.cancelDialogVisible,
          trainingOrderId: _vm.record.trainingOrderId
        },
        on: {
          "update:visible": function($event) {
            _vm.cancelDialogVisible = $event
          },
          onSuccess: _vm.callTrainingOrderDetails
        }
      }),
      _c("ConfirmOrderDialog", {
        attrs: {
          visible: _vm.confirmDialogVisible,
          trainingOrderId: _vm.record.trainingOrderId
        },
        on: {
          "update:visible": function($event) {
            _vm.confirmDialogVisible = $event
          },
          onSuccess: _vm.callTrainingOrderDetails
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }