import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 订单列表 */
    training_orders: createAction(request, '/admin/training/orders'),

    /** 订单详情 */
    training_orderDetails: createAction(request, '/admin/training/orderDetails'),

    /** 取消订单 */
    training_cancel: createAction(request, '/admin/training/cancel'),

    /** 备注订单 */
    training_remark: createAction(request, '/admin/training/remark'),

    /** 完成订单 */
    training_finish: createAction(request, '/admin/training/finish'),

    /** 直播中的私教课 */
    training_livingList: createAction(request, '/admin/training/livingList')
  };
});