var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "edit-wrap" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "180px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程大类及分类" } },
                [
                  _c("course-cate", {
                    staticClass: "yl-read",
                    attrs: { disabled: true, form: _vm.detail }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程名称" } },
                [_vm._v(" " + _vm._s(_vm.detail.courseName) + " ")]
              ),
              !_vm.isDiy
                ? _c("el-form-item", { attrs: { label: "教练性别" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.detail.coachGender === 1 ? "男" : "女") +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("el-form-item", { attrs: { label: "消耗能量" } }, [
                _vm.detail.consumeCalorieMin === _vm.detail.consumeCalorieMax
                  ? _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.detail.consumeCalorieMax) + "千卡 "
                      )
                    ])
                  : _c("div", [
                      _vm._v(
                        _vm._s(_vm.detail.consumeCalorieMin) +
                          "~" +
                          _vm._s(_vm.detail.consumeCalorieMax) +
                          "千卡"
                      )
                    ]),
                !_vm.isDiy
                  ? _c("p", { staticClass: "yl-tip" }, [
                      _vm._v(
                        "说明：单位千卡，“消耗能量”是用于在APP展示给用户查看，不作为训练能量计算"
                      )
                    ])
                  : _vm._e()
              ]),
              _c("el-form-item", { attrs: { label: "课程难度" } }, [
                _vm._v(" L" + _vm._s(_vm.detail.courseDifficultyLevel) + " ")
              ]),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "练习频次" } },
                [_vm._v(" " + _vm._s(_vm.detail.trainingFrequency) + " ")]
              ),
              _c("el-form-item", { attrs: { label: "器械" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail.fitnessEquipmentTypes.join(",")) + " "
                )
              ]),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "训练总能量" } },
                [
                  _vm._v(" " + _vm._s(_vm.detail.trainingCalorie) + "千卡 "),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("训练总能量用于计算用户训练消耗的能量")
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程内容类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "yl-read",
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.detail.courseType,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "courseType", $$v)
                        },
                        expression: "detail.courseType"
                      }
                    },
                    _vm._l(_vm.dict.courseTypes, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + "课程 ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.detail.courseType === 2
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { required: "", label: "课程视频" } },
                      [
                        _c("div", { staticClass: "flex-box" }, [
                          _vm.detail.videoUrl
                            ? _c("video", {
                                staticClass: "form-video",
                                attrs: {
                                  id: "course-video",
                                  controls: "",
                                  src: _vm.detail.videoUrl
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    ),
                    !_vm.isDiy
                      ? _c(
                          "el-form-item",
                          { attrs: { required: "", label: "课程视频预览" } },
                          [
                            _c("div", { staticClass: "flex-box" }, [
                              _vm.detail.videoCover
                                ? _c("video", {
                                    staticClass: "form-video",
                                    attrs: {
                                      id: "course-video",
                                      controls: "",
                                      src: _vm.detail.videoCover
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e(),
                    !_vm.isDiy
                      ? _c(
                          "el-form-item",
                          { attrs: { required: "", label: "课程阶段" } },
                          [
                            _c(
                              "section",
                              { staticClass: "video-row video-head" },
                              [
                                _c("div", { staticClass: "video-col" }, [
                                  _vm._v("阶段序号")
                                ]),
                                _c("div", { staticClass: "video-col" }, [
                                  _vm._v("阶段名称")
                                ]),
                                _c("div", { staticClass: "video-col" }, [
                                  _vm._v("动作描述")
                                ]),
                                _c("div", { staticClass: "video-col" }, [
                                  _vm._v("阶段开始时间")
                                ]),
                                _c("div", { staticClass: "video-col" }, [
                                  _vm._v("此段时长")
                                ])
                              ]
                            ),
                            _vm._l(_vm.detail.videos, function(item, index) {
                              return _c(
                                "section",
                                { key: index, staticClass: "video-row" },
                                [
                                  _c("div", { staticClass: "video-col" }, [
                                    _vm._v(" " + _vm._s(index + 1) + " ")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "video-col" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        attrs: { readonly: "", type: "text" },
                                        model: {
                                          value: item.videoTitle,
                                          callback: function($$v) {
                                            _vm.$set(item, "videoTitle", $$v)
                                          },
                                          expression: "item.videoTitle"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "video-col" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        attrs: { readonly: "", type: "text" },
                                        model: {
                                          value: item.description,
                                          callback: function($$v) {
                                            _vm.$set(item, "description", $$v)
                                          },
                                          expression: "item.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "video-col" }, [
                                    _c(
                                      "div",
                                      { staticClass: "range-input" },
                                      [
                                        _vm._v(" 第"),
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "100px",
                                            margin: "0 10px"
                                          },
                                          attrs: {
                                            readonly: "",
                                            placeholder: "秒",
                                            type: "number",
                                            min: "0"
                                          },
                                          model: {
                                            value: item.frameStart,
                                            callback: function($$v) {
                                              _vm.$set(item, "frameStart", $$v)
                                            },
                                            expression: "item.frameStart"
                                          }
                                        }),
                                        _vm._v("秒开始 ")
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "video-col" }, [
                                    _c(
                                      "div",
                                      { staticClass: "range-input" },
                                      [
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "100px",
                                            margin: "0 10px"
                                          },
                                          attrs: {
                                            readonly: "",
                                            placeholder: "秒",
                                            type: "number",
                                            min: "0"
                                          },
                                          model: {
                                            value: item.frameSecond,
                                            callback: function($$v) {
                                              _vm.$set(item, "frameSecond", $$v)
                                            },
                                            expression: "item.frameSecond"
                                          }
                                        }),
                                        _vm._v("秒 ")
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.detail.courseType === 1
                ? [
                    _c("el-form-item", { attrs: { label: "课程内容" } }, [
                      _c(
                        "div",
                        _vm._l(_vm.detail.videos, function(item, index) {
                          return _c(
                            "el-card",
                            {
                              key: index,
                              staticClass: "action-card",
                              staticStyle: { width: "800px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix action-info",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [
                                    _vm._v("第" + _vm._s(index + 1) + "个动作")
                                  ]),
                                  _c("span", [
                                    _vm._v("动作编号：" + _vm._s(item.actionNo))
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "动作名称:" + _vm._s(item.videoTitle)
                                    )
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "单位动作时长:" +
                                        _vm._s(item.actionSecond) +
                                        "S"
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-form",
                                { attrs: { "label-width": "100px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "动作形式" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detail.videos[index]
                                              .playType === 1
                                              ? "组类"
                                              : "倒计时类"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  item.playType === 1
                                    ? [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "10px"
                                            },
                                            attrs: { label: "动作次数" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .actionCount
                                                ) +
                                                "次 "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "休息时长" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .relaxTime
                                                ) +
                                                "S "
                                            ),
                                            _c("p", { staticClass: "yl-tip" }, [
                                              _vm._v("0代表不休息")
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  item.playType === 2
                                    ? [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "10px"
                                            },
                                            attrs: { label: "倒计时长" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .countdownSecond
                                                ) +
                                                "S "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "休息时长" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.videos[index]
                                                    .relaxTime
                                                ) +
                                                "S "
                                            ),
                                            _c("p", { staticClass: "yl-tip" }, [
                                              _vm._v("0代表不休息")
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ])
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程封面" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-cell",
                      on: {
                        click: function($event) {
                          return _vm.openImageView(_vm.detail.coverImageUrl)
                        }
                      }
                    },
                    [
                      _c(
                        "el-image",
                        {
                          attrs: {
                            fit: "contain",
                            src: _vm.detail.coverImageUrl
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: _vm.uploadImg }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程介绍" } },
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.detail.courseIntroduce) }
                  })
                ]
              ),
              _vm.detail.courseLabelIds.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "课程标签" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "yl-read",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.detail.courseLabelIds,
                            callback: function($$v) {
                              _vm.$set(_vm.detail, "courseLabelIds", $$v)
                            },
                            expression: "detail.courseLabelIds"
                          }
                        },
                        _vm._l(_vm.labels, function(item, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: item.labelId } },
                            [_vm._v(" " + _vm._s(item.labelName) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "课程排序" } },
                [
                  !_vm.isDiy
                    ? _c("span", [_vm._v(_vm._s(_vm.detail.courseSort))])
                    : _c(
                        "div",
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            model: {
                              value: _vm.detail.courseSort,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "courseSort", $$v)
                              },
                              expression: "detail.courseSort"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.saveSort }
                            },
                            [_vm._v("保存")]
                          )
                        ],
                        1
                      ),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v(
                      "说明:课程排序序号用于决定该课程在本课程大类下的显示排序，APP的数值越小，标签排序越前"
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "在APP显示状态" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detail.appShow ? "立即显示" : "不显示") +
                      " "
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }