var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(
          "提现详情-" +
            _vm._s(
              _vm._f("ftValue2label")(_vm.data.transferStatus, _vm.options)
            )
        )
      ]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 3 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "提现编号" } }, [
                    _vm._v(_vm._s(_vm.data.transferNo))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "提现状态" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("ftValue2label")(
                          _vm.data.transferStatus,
                          _vm.options
                        )
                      )
                    )
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                    _vm._v(_vm._s(_vm.data.applyTime))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "门店编号" } }, [
                    _vm._v(_vm._s(_vm.data.gymNo))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "门店名称" } }, [
                    _vm._v(_vm._s(_vm.data.gymName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                    _vm._v(_vm._s(_vm.data.companyName))
                  ])
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "单据", column: 4 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "要炼应结合计" } },
                    [_vm._v(_vm._s(_vm.totalPay))]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.data.billList,
                    border: "",
                    "header-cell-style": { background: "#F6F6F6" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "billNo",
                      label: "单据编号",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "relationNo",
                      label: "私教课订单/PLus使用编号",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "billAmount",
                      label: "门店收入",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "billDate",
                      label: "单据日期",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "incomeType",
                      label: "单据类型",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  ["私教课订单", "Plus消费"][row.incomeType - 1]
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-descriptions",
                {
                  staticClass: "d2-mt",
                  attrs: { title: "收款账户", column: 1 }
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "开户名称" } }, [
                    _vm._v(_vm._s(_vm.data.bankAccountName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "开户账号" } }, [
                    _vm._v(_vm._s(_vm.data.bankAccount))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "开户银行支行" } },
                    [_vm._v(_vm._s(_vm.data.bankName))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "联行号" } }, [
                    _vm._v(_vm._s(_vm.data.bankNo))
                  ])
                ],
                1
              ),
              _vm.data.transferStatus == 4 || _vm.data.transferStatus == 5
                ? _c(
                    "el-descriptions",
                    {
                      staticClass: "d2-mt",
                      attrs: { title: "发票信息", column: 1 }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "价税合计" } },
                        [_vm._v(_vm._s(_vm.data.priceTaxSubtotal))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票类型" } },
                        [
                          _vm._v(
                            _vm._s(["个人", "公司"][_vm.data.invoiceType - 1])
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票日期" } },
                        [_vm._v(_vm._s(_vm.data.applyTime))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票代码" } },
                        [_vm._v(_vm._s(_vm.data.invoiceCode))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票号码" } },
                        [_vm._v(_vm._s(_vm.data.invoiceNumber))]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "税率" } }, [
                        _vm._v(_vm._s(_vm.data.taxRate))
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "不含税金额" } },
                        [_vm._v(_vm._s(_vm.data.excludingTaxAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票附件" } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { color: "#409eff" },
                              on: {
                                click: function($event) {
                                  return _vm.downloadfun(
                                    "发票.pdf",
                                    _vm.data.invoiceImage
                                  )
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.data.invoiceImage))]
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "收票人信息" } },
                        [
                          _vm._v(
                            _vm._s(
                              [
                                _vm.data.invoiceName
                                  ? _vm.data.invoiceName
                                  : "黄先生",
                                _vm.data.invoicePhone
                                  ? _vm.data.invoicePhone
                                  : "18928803772",
                                _vm.data.invoiceAddress
                                  ? _vm.data.invoiceAddress
                                  : "广东省广州市天河区东圃盈科智谷22栋101"
                              ]
                                .map(function(v) {
                                  return v
                                })
                                .join(" ")
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "物流公司" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("expressCompany")(_vm.data.expressCompany)
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "物流单号" } },
                        [_vm._v(_vm._s(_vm.data.expressNo))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: { "font-weight": "bold", "margin-top": "20px" }
                },
                [_vm._v("提现处理")]
              ),
              _vm._l(_vm.data.logs, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      "font-size": "small",
                      "margin-bottom": "10px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { type: "flex" }
                      },
                      [
                        _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                        _vm.data.transferStatus == 3
                          ? _c("el-col", [
                              _vm._v("原因：" + _vm._s(_vm.data.failureReason))
                            ])
                          : _vm._e(),
                        _c("el-col", [
                          _vm._v("审核人：" + _vm._s(item.operatorName))
                        ]),
                        _c("el-col", [
                          _vm._v("时间：" + _vm._s(item.createTime))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.data.transferImage
                ? _c(
                    "el-row",
                    { staticStyle: { "font-size": "small" } },
                    [
                      _c("span", [_vm._v("打款凭证：")]),
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: _vm.data.transferImage,
                          fit: "cover",
                          "preview-src-list": [_vm.data.transferImage]
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "el-button",
            {
              staticClass: "d2-mt",
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }