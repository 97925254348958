import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";

/**
 * 一些业务的数据字典,⚠️废弃，请在vuex中维护
 */
var initCourseCates = [{
  name: '健身训练',
  key: '10000001'
}, {
  name: '瑜伽',
  key: '10000002'
}, {
  name: '孕产',
  key: '10000003'
}, {
  name: '青少年中考',
  key: '10000004'
}, {
  name: '康复',
  key: '10000005'
}, {
  name: '老年保健',
  key: '10000006'
}, {
  name: '跑步、骑行、登山',
  key: '10000007'
}, {
  name: '冥想',
  key: '10000008'
}];
var actionTypes = [{
  name: '热身',
  key: 1
}, {
  name: '训练',
  key: 2
}, {
  name: '拉伸',
  key: 3
}, {
  name: '瑜伽',
  key: 4
}];
var actionParts = [{
  name: '全身',
  key: '全身'
}, {
  name: '胸部',
  key: '胸部'
}, {
  name: '肩部',
  key: '肩部'
}, {
  name: '手臂',
  key: '手臂'
}, {
  name: '颈部',
  key: '颈部'
}, {
  name: '腹部',
  key: '腹部'
}, {
  name: '腰部',
  key: '腰部'
}, {
  name: '臂部',
  key: '臂部'
}, {
  name: '腿部',
  key: '腿部'
}];
var courseCates = {};
initCourseCates.map(function (item) {
  courseCates[item.key] = item.name;
});
var actionTypesMap = {};
actionTypes.map(function (item) {
  actionTypesMap[item.key] = item.name;
});
export default {
  dict: {
    initCourseCates: initCourseCates,
    courseCates: courseCates,
    actionParts: actionParts,
    actionTypesMap: actionTypesMap,
    actionTypes: actionTypes
  }
};