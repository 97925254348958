var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.onTabClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "官方课程", name: "COURSE_LIST" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "用户自制课程", name: "COURSE_DIY_LIST" }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "课程编号" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "输入课程编号" },
                    model: {
                      value: _vm.form.courseNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "courseNo", $$v)
                      },
                      expression: "form.courseNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "输入课程名称" },
                    model: {
                      value: _vm.form.courseName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "courseName", $$v)
                      },
                      expression: "form.courseName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否在app显示" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.appShow,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "appShow", $$v)
                        },
                        expression: "form.appShow"
                      }
                    },
                    _vm._l(_vm.dict.showOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "base-table",
            {
              ref: "baseTable",
              attrs: { headStyle: _vm.headStyle, request: _vm.request }
            },
            [
              _c("el-table-column", {
                attrs: { width: "100", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.onSelect(scope.row)
                              }
                            }
                          },
                          [_vm._v("选择")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "课程编号", width: "130", prop: "courseNo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(scope.row.courseNo) + " ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "课程名称", prop: "courseName" }
              }),
              _c("el-table-column", {
                attrs: { label: "课程大类", prop: "categoryName" }
              }),
              _c("el-table-column", {
                attrs: { label: "一级分类", prop: "subcategoryFirstName" }
              }),
              _c("el-table-column", {
                attrs: { label: "二级分类", prop: "subcategorySecondName" }
              }),
              _c("el-table-column", {
                attrs: { label: "三级分类", prop: "subcategoryThreeName" }
              }),
              _c("el-table-column", {
                attrs: { label: "教练性别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.genderStr(scope.row.coachGender)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "课程难度" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " L" + _vm._s(scope.row.courseDifficultyLevel) + " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "器械" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.hasEquipment ? "有" : "无") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "170", label: "添加时间", prop: "createTime" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }