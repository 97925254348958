import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 资质审核列表 */
    coach_certAuditList: createAction(request, '/admin/coach/certAuditList'),

    /** 资质审核详情 */
    coach_certAuditDetails: createAction(request, '/admin/coach/certAuditDetails'),

    /** 未认证的教练 */
    coach_nonCertifiedCoachs: createAction(request, '/admin/coach/nonCertifiedCoachs'),

    /** 审核资质 */
    admin_coach_certAudit: createAction(request, '/admin/coach/certAudit'),

    /** 添加备注 */
    admin_coach_auditRemark: createAction(request, '/admin/coach/auditRemark'),

    /** 已认证教练列表 */
    coach_coachList: createAction(request, '/admin/coach/coachList'),

    /** 教练详情 */
    coach_coachDetails: createAction(request, '/admin/coach/coachDetails'),

    /** 设置/取消 官方教练 */
    coach_setOfficialCoach: createAction(request, '/admin/coach/setOfficialCoach'),

    /** 线下审核列表 */
    coach_offlineAuditList: createAction(request, '/admin/coach/offlineAuditList'),

    /** 线下审核详情 */
    coach_offlineAuditDetails: createAction(request, '/admin/coach/offlineAuditDetails'),

    /** 获取资质证书审核列表 */
    coach_recertificationList: createAction(request, '/admin/coach/recertificationList'),

    /** 获取资质证书审核详情 */
    coach_recertificationDetails: createAction(request, '/admin/coach/recertificationDetails'),

    /** 资质证书审核添加备注记录 */
    coach_recertificationRemark: createAction(request, '/admin/coach/recertificationRemark'),

    /** 资质证书审核*/
    coach_auditRecertification: createAction(request, '/admin/coach/auditRecertification'),

    /** 教练线下认证复审备注*/
    coach_offlineAuditRemark: createAction(request, '/admin/coach/offlineAuditRemark'),

    /** 教练线下认证复审*/
    coach_updateAudit: createAction(request, '/admin/coach/updateAudit'),

    /** 教练直播年审*/
    coach_liveAudit: createAction(request, '/admin/coach/liveAudit'),

    /** 等待教练直播年审列表*/
    coach_waitLiveAuditList: createAction(request, '/admin/coach/waitLiveAuditList'),

    /** 退出直播审核详情*/
    coach_exitAudit: createAction(request, '/admin/coach/exitAudit'),

    /** 等待直播审核详情*/
    coach_waitLiveAuditDetails: createAction(request, '/admin/coach/waitLiveAuditDetails'),

    /** 教练直播审核记录*/
    coach_liveAuditList: createAction(request, '/admin/coach/liveAuditList')
  };
});