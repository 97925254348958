export default [{
  title: '健身场地',
  iconSvg: '健身场地',
  authorityValue: 'gym-management',
  children: [{
    title: '门店管理',
    authorityValue: 'gym-manage',
    children: [{
      path: '/settled-stores',
      title: '已入驻门店',
      authorityValue: 'gym-settled-list'
    }, {
      path: '/uncertified-store-list',
      title: '未认证门店',
      authorityValue: 'gym-unauth-list'
    }, {
      path: '/store-entry-intention',
      title: '门店入驻意向',
      authorityValue: 'gym-settled-intention'
    }, {
      path: '/store-entry-audit',
      title: '门店入驻审核',
      authorityValue: 'gym-settled-audit'
    }, {
      path: '/shopp-store-review',
      title: '营业执照更新审核',
      authorityValue: 'gym-license-update-audit'
    }, {
      path: '/shopp-store-legal',
      title: '法人身份证更新审核',
      authorityValue: 'gym-legal-id-update-audit'
    }, {
      path: '/shopp-store-tax',
      title: '税务信息更新审核',
      authorityValue: 'gym-taxpayer-update-audit'
    }]
  }, {
    title: '门店设置',
    authorityValue: 'gym-setting',
    children: [{
      path: '/find-site—banner',
      title: '找场地首页轮播图',
      authorityValue: 'gym-banner'
    }, {
      path: '/store-deposit',
      title: '门店保证金设置',
      authorityValue: 'gym-bond-setting'
    }]
  }]
}];