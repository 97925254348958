export default [{
  title: '私教课交易',
  iconSvg: '私教课交易',
  authorityValue: 'training-order-management',
  children: [// {
  //   title: '私教课订单',
  //   authorityValue: 'training-order-manage',
  //   children: []
  // },
  {
    path: '/course-order-list',
    title: '私教课订单',
    authorityValue: 'training-order-manage'
  }, {
    title: '直播课开播审核',
    authorityValue: 'training-order-live-manage',
    children: [{
      path: '/course-live-wait',
      title: '待审核直播间',
      authorityValue: 'live-unaudited-list'
    }, {
      path: '/course-live-list',
      title: '开播审核记录',
      authorityValue: 'live-audited-record'
    }]
  }, {
    path: '/course-live-player',
    title: '直播实时监控',
    authorityValue: 'live-monitor'
  }, {
    title: '教练评价管理',
    path: '/personal-trainer-coach',
    authorityValue: 'coach-evaluate-manage'
  }, {
    title: '私教课数据',
    authorityValue: 'training-order-data-manage',
    children: [{
      path: '/class-store-live',
      title: '上课门店实况',
      authorityValue: 'training-gym-real-time'
    }, {
      path: '/coach-online-live',
      title: '教练上线实况',
      authorityValue: 'coach-online-real-time'
    }, {
      path: '/private-teaching-live',
      title: '私教课交易实况',
      authorityValue: 'training-order-real-time'
    }, {
      path: '/private-teaching-analysis',
      title: '私教课交易分析',
      authorityValue: 'training-order-overview'
    }, {
      path: '/coach-detail-analysis',
      title: '教练明细分析',
      authorityValue: 'coach-order-sta'
    }, {
      path: '/private-types-analysis',
      title: '私教课类型分析',
      authorityValue: 'training-category-sta'
    }]
  }, {
    title: '私教设置',
    authorityValue: 'training-order-setting',
    children: [{
      path: '/price-agent-province',
      title: '省代理商账号管理',
      authorityValue: 'province-agent-list'
    }, {
      path: '/price-agent-city',
      title: '市代理商账号管理',
      authorityValue: 'city-agent-list'
    }, {
      path: '/price-setting',
      title: '私教课价格设置',
      authorityValue: 'training-price-setting'
    }, {
      path: '/personal-trainer-commission',
      title: '私教课分佣规则',
      authorityValue: 'training-commission-setting'
    }, {
      path: '/coach-cate',
      title: '教练细分类设置',
      authorityValue: 'training-subcategory-setting'
    }, // { path: '/personal-benefit-description', title: '教练收益说明' },
    {
      path: '/personal-trainer-time',
      title: '上课时间设置',
      authorityValue: 'training-time-setting'
    }, {
      path: '/personal-trainer-banner',
      title: '找教练APP轮播图',
      authorityValue: 'training-banner'
    }]
  }]
}];