var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "审批编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入申请编号搜索" },
                model: {
                  value: _vm.form.recertificationNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "recertificationNo", $$v)
                  },
                  expression: "form.recertificationNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提交时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户" } },
            [
              _c("el-input", {
                attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "审批编号", prop: "recertificationNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "审核状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.status,
                              _vm.result
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "用户编号", prop: "userNo" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户昵称", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "提交时间", prop: "createTime", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/qualification-certificate-audit-detail",
                                query: {
                                  recertificationId: scope.row.recertificationId
                                }
                              }
                            }
                          },
                          [_vm._v(" 详情 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }