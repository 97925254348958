var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("店铺保证金设置")]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: {}, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺需缴纳保证金金额:" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.depositAmount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "depositAmount", $$v)
                  },
                  expression: "form.depositAmount"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
        [_vm._v("保存")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }