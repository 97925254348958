var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.$route.query.noticeId ? "编辑公告" : "添加公告"))
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公告标题", prop: "noticeTitle" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.noticeTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "noticeTitle", $$v)
                  },
                  expression: "form.noticeTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公告展示", prop: "noticeShow" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.noticeShow,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "noticeShow", $$v)
                  },
                  expression: "form.noticeShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跳转页面内容" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.towardTypes },
                model: {
                  value: _vm.form.noticeContent.towardType,
                  callback: function($$v) {
                    _vm.$set(_vm.form.noticeContent, "towardType", $$v)
                  },
                  expression: "form.noticeContent.towardType"
                }
              })
            ],
            1
          ),
          _vm.form.noticeContent.towardType == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("base-editor", {
                    attrs: {
                      upToken: _vm.qnToken,
                      content: _vm.form.noticeContent.htmlContent
                    },
                    on: {
                      "update:content": function($event) {
                        return _vm.$set(
                          _vm.form.noticeContent,
                          "htmlContent",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.noticeContent.towardType == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mt-10", attrs: { label: "App跳转链接" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.noticeContent.appUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.form.noticeContent, "appUrl", $$v)
                          },
                          expression: "form.noticeContent.appUrl"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("发布")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }