var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box city-box" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            trigger: "click",
            effect: "dark",
            "visible-arrow": false
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-center",
              attrs: { slot: "reference" },
              slot: "reference"
            },
            [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.proLabel))
              ]),
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: require("../../assets/image/jiantou.png"),
                  alt: ""
                }
              })
            ]
          ),
          _c(
            "div",
            { staticClass: "city" },
            [
              _c(
                "el-scrollbar",
                { staticStyle: { height: "20.1rem" } },
                _vm._l(_vm.proData, function(l) {
                  return _c(
                    "div",
                    {
                      key: l.value || 1,
                      staticClass: "city-item",
                      class: { active: _vm.activePro == l.value },
                      on: {
                        click: function($event) {
                          return _vm.clickItem(l, "proLabel")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(l.label) + " ")]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            trigger: "click",
            "visible-arrow": false
          },
          model: {
            value: _vm.show2,
            callback: function($$v) {
              _vm.show2 = $$v
            },
            expression: "show2"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-center",
              attrs: { slot: "reference" },
              slot: "reference"
            },
            [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.cityLabel))
              ]),
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: require("../../assets/image/jiantou.png"),
                  alt: ""
                }
              })
            ]
          ),
          _c(
            "div",
            { staticClass: "city" },
            [
              _c(
                "el-scrollbar",
                {
                  style: {
                    height: _vm.activePro == 100000 ? "3.5rem" : "20.1rem"
                  }
                },
                _vm._l(_vm.cityDataComp, function(l) {
                  return _c(
                    "div",
                    {
                      key: l.value,
                      staticClass: "city-item",
                      class: { active: _vm.activeCity == l.value },
                      on: {
                        click: function($event) {
                          return _vm.clickItem(l, "cityLabel")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(l.label) + " ")]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }