import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 动态列表 */
    post_postList: createAction(request, '/admin/post/postList'),

    /** 被屏蔽的动态列表 */
    post_blockedPostList: createAction(request, '/admin/post/blockedPostList'),

    /** 屏蔽/取消屏蔽 */
    post_block: createAction(request, '/admin/post/block'),

    /** 动态详情 */
    post_postDetails: createAction(request, '/admin/post/postDetails'),

    /** 设置/取消 为精品 */
    post_setChosen: createAction(request, '/admin/post/setChosen'),

    /** 置顶 （20230308） */
    post_top: createAction(request, '/admin/post/top')
  };
});