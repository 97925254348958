var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("纠纷信息")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关键词" } },
            [
              _c("el-input", {
                staticStyle: { width: "350px" },
                attrs: {
                  placeholder: "输入订单单号/用户编号、昵称、手机号码搜索"
                },
                model: {
                  value: _vm.form.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.createTimeRange.start,
                  max: _vm.form.createTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.createTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "处理状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dealStatus },
                model: {
                  value: _vm.form.dealStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "dealStatus", $$v)
                  },
                  expression: "form.dealStatus"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { request: _vm.request },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "订单号", prop: "orderNo", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "申请时间", prop: "createTime", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "纠纷描述", prop: "xxx", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("HiddenText", {
                      attrs: { text: scope.row.disputeDescription }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "申请用户类型", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.userType,
                              _vm.userTypes
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "申请人", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { label: "处理状态", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("ftValue2label")(
                              scope.row.dealStatus,
                              _vm.dealStatus
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作 ", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/dispute-detail",
                                query: { disputeId: scope.row.disputeId }
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }