import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.reduce";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.code-point-at";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { omit, keys, toNumber, isNaN, isArray } from 'lodash';
export default {
  sleep: function sleep(num) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        return resolve();
      }, num * 1000);
    });
  },
  getScript: function getScript(url, callback) {
    // 异步拉取外部js
    callback = typeof callback === 'function' ? callback : function () {};
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';

    script.onload = script.onreadystatechange = function () {
      if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
        callback();
        script.onload = script.onreadystatechange = null;
      }
    };

    script.src = url;
    head.appendChild(script);
  },
  toUnicode: function toUnicode(str) {
    var out = '';
    str.split('').map(function (item, index) {
      out += "\\u" + str.codePointAt(index).toString(16);
    });
    return out;
  },
  randStr: function randStr() {
    var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 8;

    // 获取随机数
    function randomString(length, chars) {
      var result = '';

      for (var i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
      }

      return result;
    }

    return randomString(num, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
  },
  // 地址转换为坐标的方法
  getMapPoint: function getMapPoint(address) {
    return new Promise(function (resolve) {
      var geocoder = new AMap.Geocoder();
      geocoder.getLocation(address, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          console.warn('map res', result);
          resolve(_objectSpread(_objectSpread({}, result.geocodes[0].location), {}, {
            address: result.geocodes[0].formattedAddress,
            status: true
          }));
          return;
        }

        resolve({
          lat: 0,
          lng: 0,
          status: false
        });
      });
    });
  },
  // 坐标转换为地址的方法
  getMapAddress: function getMapAddress(point) {
    return new Promise(function (resolve) {
      var geocoder = new AMap.Geocoder();
      geocoder.getAddress(point, function (status, result) {
        if (status === 'complete' && result.regeocode) {
          console.warn('map res', result);
          resolve(result.regeocode.formattedAddress);
          return;
        }

        resolve('暂无');
      });
    });
  },

  /**
   * @description 将单词转换为首字母大写
   * @param {String} word 需要转化的单词
   */
  wordUpper: function wordUpper(word) {
    return word.replace(word[0], word[0].toUpperCase());
  },

  /**
   * @description 在 source 中是否至少有一个 need 中的项目
   * @param {Array} source 数据源
   * @param {Array} need 需要存在的项目
   */
  oneOf: function oneOf(source, need) {
    if (isArray(need)) return need.reduce(function (result, item) {
      return result || source.indexOf(item) >= 0;
    }, false);
    return source.indexOf(need) >= 0;
  },

  /**
   * @description 在 source 包括 need
   * @param {Array} source 数据源
   * @param {Array} need 需要存在的项目
   */
  allIn: function allIn(source, need) {
    if (isArray(need)) return need.reduce(function (result, item) {
      return !(result === false || source.indexOf(item) < 0);
    }, true);
    return source.indexOf(need) >= 0;
  },

  /**
   * @description 检查一个对象是否有子元素
   * @param {Object} item 检查的对象
   * @param {String} keyname 子元素的 keyname
   */
  hasChildren: function hasChildren() {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var keyname = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';
    return item[keyname] && isArray(item[keyname]) && item[keyname].length > 0;
  },

  /**
   * 比较两个数组是否值一样 忽略顺序
   * @param {Array} array1 比较的数组
   * @param {Array} array2 比较的数组
   */
  isIdenticalArray: function isIdenticalArray(array1, array2) {
    var result = true;

    if (array1.length !== array2.length) {
      result = false;
    } else {
      array1.forEach(function (item) {
        if (array2.indexOf(item) < 0) {
          result = false;
        }
      });
    }

    return result;
  },

  /**
   * 比较两个对象是否值一样 忽略顺序
   * @param {Array} array1 比较的对象
   * @param {Array} array2 比较的对象
   */
  isIdenticalObject: function isIdenticalObject(object1, object2) {
    var result = true;
    var keys1 = keys(object1);
    var keys2 = keys(object2);

    if (!isIdenticalArray(keys1, keys2)) {
      result = false;
    } else {
      keys1.forEach(function (keyName) {
        if (object1[keyName] !== object2[keyName]) {
          result = false;
        }
      });
    }

    return result;
  },

  /**
   * @description 将树形数据扁平化 输出数组格式
   * @param {Object} config {Array} data 树形数据
   * @param {Object} config {String} keyChildren 子节点字段名
   * @param {Object} config {Boolean} includeChildren 输出的数据中是否包含子节点数据
   */
  flatTreeToArray: function flatTreeToArray() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$data = _ref.data,
        data = _ref$data === void 0 ? [] : _ref$data,
        _ref$keyChildren = _ref.keyChildren,
        keyChildren = _ref$keyChildren === void 0 ? 'children' : _ref$keyChildren,
        _ref$includeChildren = _ref.includeChildren,
        includeChildren = _ref$includeChildren === void 0 ? false : _ref$includeChildren;

    var _ = this;

    function maker(result, item) {
      result.push(includeChildren ? item : omit(item, [keyChildren]));
      if (_.hasChildren(item, keyChildren)) result = result.concat(item[keyChildren].reduce(maker, []));
      return result;
    }

    return data.reduce(maker, []);
  },

  /**
   * @description 将树形数据扁平化 输出对象格式
   * @param {Object} config {Array} data 树形数据
   * @param {Object} config {String} keyChildren 子节点字段名
   * @param {Object} config {String} keyId 唯一 id 字段名
   * @param {Object} config {Boolean} includeChildren 输出的数据中是否包含子节点数据
   */
  flatTreeToObject: function flatTreeToObject() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref2$data = _ref2.data,
        data = _ref2$data === void 0 ? [] : _ref2$data,
        _ref2$keyChildren = _ref2.keyChildren,
        keyChildren = _ref2$keyChildren === void 0 ? 'children' : _ref2$keyChildren,
        _ref2$keyId = _ref2.keyId,
        keyId = _ref2$keyId === void 0 ? 'id' : _ref2$keyId,
        _ref2$includeChildren = _ref2.includeChildren,
        includeChildren = _ref2$includeChildren === void 0 ? false : _ref2$includeChildren;

    function maker(result, item) {
      result[item[keyId]] = includeChildren ? item : omit(item, [keyChildren]);
      if (hasChildren(item, keyChildren)) Object.assign(result, item[keyChildren].reduce(maker, {}));
      return result;
    }

    return data.reduce(maker, {});
  },

  /**
   * @description 传入一个值 返回处理成数字的结果
   * @param {Any} value 需要处理的值
   */
  getNumberOrZero: function getNumberOrZero(value) {
    var result = toNumber(value);
    return isNaN(result) ? 0 : result;
  }
};