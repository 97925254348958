var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "page-content",
        [
          _c(
            "TableTitleBar",
            { staticStyle: { "margin-top": "0" }, attrs: { title: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                [_vm._v("添加公告")]
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "编号", prop: "noticeNo" }
              }),
              _c("el-table-column", {
                attrs: { label: "公告标题", prop: "noticeTitle" }
              }),
              _c("el-table-column", {
                attrs: { label: "APP显示", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          on: {
                            change: function($event) {
                              return _vm.toggleApp(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.noticeShow,
                            callback: function($$v) {
                              _vm.$set(scope.row, "noticeShow", $$v)
                            },
                            expression: "scope.row.noticeShow"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "发布时间", prop: "createTime", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/notice-message-detail",
                                    query: { noticeId: scope.row.noticeId }
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/notice-message-add",
                                    query: { noticeId: scope.row.noticeId }
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onDel(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }