import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Title from '../title';
import { mapGetters } from 'vuex';
export default {
  components: {
    Title: Title
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/aedbig', ['getRightUser'])), {}, {
    rightUser: function rightUser() {
      return this.getRightUser;
    }
  })
};