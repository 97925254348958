var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("审核未通过详情")]),
      _c(
        "page-content",
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 4 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "缴纳编号" } }, [
                    _vm._v(_vm._s(_vm.record.depositNo))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "提交时间" } }, [
                    _vm._v(_vm._s(_vm.record.depositTime))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                    _vm._v(_vm._s(_vm.record.companyName))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "门店名称" } },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.goRoute(
                                "/settled-stores-detail?gymId=" +
                                  _vm.record.gymId
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.record.gymName))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { direction: "vertical", column: 4, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "转账金额(元）" } },
                    [_vm._v(_vm._s(_vm.record.depositAmount))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "开户名称" } }, [
                    _vm._v(_vm._s(_vm.record.payerAccountName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "开户账号" } }, [
                    _vm._v(_vm._s(_vm.record.payerAccount))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "开户银行支行" } },
                    [_vm._v(_vm._s(_vm.record.payerBankName))]
                  )
                ],
                1
              ),
              _c(
                "el-descriptions",
                { staticClass: "d2-mt", attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "转账凭证" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: _vm.record.depositImage,
                          fit: "fill",
                          lazy: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "d2-mt",
              staticStyle: { "font-size": "small" },
              attrs: { shadow: "never", "body-style": { padding: "20px" } }
            },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("记录")
              ]),
              _vm._l(_vm.record.logs, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.logType === 2
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [_vm._v("备注：")]),
                            _c("el-col", [_vm._v(_vm._s(item.createTime))]),
                            _c("el-col", [_vm._v(_vm._s(item.logTitle))]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    item.logType === 1
                      ? _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c("el-col", [
                              _vm._v("时间：" + _vm._s(item.createTime))
                            ]),
                            _c("el-col", [
                              _vm._v("审核结果：" + _vm._s(item.logTitle))
                            ]),
                            _c("el-col", [
                              _vm._v("操作员：" + _vm._s(item.operatorName))
                            ]),
                            _c("el-col", [
                              _vm._v("原因：" + _vm._s(_vm.record.rejectReason))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "el-button",
            {
              staticClass: "d2-mt",
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }