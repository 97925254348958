var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c("template", { slot: "header" }, [_vm._v("视频审核")]),
      _c("div", { staticClass: "top-box d2-mb" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("CoachInfo", {
              attrs: {
                url: _vm.record.userAvatar,
                introduction: _vm.record.personalStatus,
                nickname: _vm.record.nickname
              }
            }),
            _vm.record.officialCoach
              ? _c("div", { staticClass: "mt-20" }, [_vm._v("官方教练")])
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("form-grid", {
              staticStyle: { "margin-left": "10px" },
              attrs: {
                column: 6,
                options: _vm.userInfos,
                direction: "vertical"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "el-card",
        {
          class: _vm.rate ? "rate" + _vm.rateLink : "",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c("video", {
            staticStyle: { "object-fit": "contain" },
            attrs: {
              id: "leb-video-container",
              preload: "auto",
              width: "640",
              height: "360",
              playsinline: "",
              "webkit-playsinline": ""
            }
          })
        ]
      ),
      _c(
        "el-button",
        {
          staticClass: "d2-mt d2-mt",
          attrs: { type: "primary" },
          on: { click: _vm.rateClick }
        },
        [_vm._v("旋转")]
      ),
      _c(
        "el-card",
        {
          staticClass: "d2-mt",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.auditStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "auditStatus", $$v)
                        },
                        expression: "form.auditStatus"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-center",
                          staticStyle: { height: "32px" }
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("审核通过")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "flex-center",
                          staticStyle: { height: "32px" }
                        },
                        [
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("审核不通过")
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.auditStatus == 3,
                          expression: "form.auditStatus == 3"
                        }
                      ]
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex" } },
                        [
                          _c("div", { staticClass: "d2-mr" }, [
                            _vm._v("不通过的原因:")
                          ]),
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.rejectReasons,
                                callback: function($$v) {
                                  _vm.rejectReasons = $$v
                                },
                                expression: "rejectReasons"
                              }
                            },
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  label:
                                    "整体教练形象不良好（男性体脂率≤15% ，女性体脂率≤25%，无含胸驼背体态）"
                                }
                              }),
                              _c("el-checkbox", {
                                attrs: { label: "不具备健身训练教学能力" }
                              }),
                              _c("el-checkbox", {
                                attrs: {
                                  label: "没有国内/国际健身教练资格认证"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-row", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "100px",
                              width: "500px"
                            }
                          },
                          [
                            _vm._l(_vm.form.rejectReasons, function(_, index) {
                              return [
                                _c(
                                  "div",
                                  { key: index, attrs: { flex: "main" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入不通过的原因"
                                      },
                                      model: {
                                        value: _vm.form.rejectReasons[index],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.rejectReasons,
                                            index,
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.rejectReasons[index]"
                                      }
                                    }),
                                    _c("el-link", {
                                      staticClass: "d2-ml",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                        icon: "el-icon-plus"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addReject(index)
                                        }
                                      }
                                    }),
                                    _vm.form.rejectReasons.length > 1
                                      ? _c("el-link", {
                                          staticClass: "d2-ml",
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                            icon: "el-icon-minus"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.delReject(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审核备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.form.auditRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "auditRemark", $$v)
                      },
                      expression: "form.auditRemark"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }