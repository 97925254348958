import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 保存基金会介绍轮播图 */
    aed_saveFoundationBanner: createAction(request, '/admin/aed/saveFoundationBanner'),

    /** 基金会介绍轮播图 */
    aed_getFoundationBanner: createAction(request, '/admin/aed/getFoundationBanner'),

    /** 删除基金会轮播图 */
    aed_deleteFoundationBanner: createAction(request, '/admin/aed/deleteFoundationBanner'),

    /** 保存要炼公益介绍内容 */
    aed_savePublicWelfare: createAction(request, '/admin/aed/savePublicWelfare'),

    /** 要炼公益介绍 */
    aed_publicWelfare: createAction(request, '/admin/aed/publicWelfare'),

    /** 保存AED位置 */
    aed_saveAed: createAction(request, '/admin/aed/saveAed'),

    /** AED位置列表 */
    aed_aedList: createAction(request, '/admin/aed/aedList'),

    /** 删除AED */
    aed_deleteAed: createAction(request, '/admin/aed/deleteAed'),

    /** 救助技能认证审核列表 */
    aed_aedSkillAuditList: createAction(request, '/admin/aed/aedSkillAuditList'),

    /** 救助技能认证详情 */
    aed_aedSkillAuditDetails: createAction(request, '/admin/aed/aedSkillAuditDetails'),

    /** 保存救助事件分类 */
    aed_saveEventType: createAction(request, '/admin/aed/saveEventType'),

    /** 救助事件分类列表 */
    aed_eventTypeList: createAction(request, '/admin/aed/eventTypeList'),

    /** 删除救助事件分类 */
    aed_deleteEventType: createAction(request, '/admin/aed/deleteEventType'),

    /** 保存救助知识分类 */
    aed_saveKnowledgeType: createAction(request, '/admin/aed/saveKnowledgeType'),

    /** 救助知识分类列表 */
    // aed_knowledgeTypeList: createAction(request, '/admin/aed/knowledgeTypeList'),

    /** 删除救助知识分类 */
    aed_deleteKnowledgeType: createAction(request, '/admin/aed/deleteKnowledgeType'),

    /** 救助事件列表 */
    aed_rescueEventList: createAction(request, '/admin/aed/rescueEventList'),

    /** 保存救助事件 */
    aed_saveRescueEvent: createAction(request, '/admin/aed/saveRescueEvent'),

    /** 救助事件详情 */
    aed_rescueEventDetails: createAction(request, '/admin/aed/rescueEventDetails'),

    /** 删除救助事件 */
    aed_deleteRescueEvent: createAction(request, '/admin/aed/deleteRescueEvent'),

    /** 保存救助知识 */
    aed_saveRescueKnowledge: createAction(request, '/admin/aed/saveRescueKnowledge'),

    /** 救助知识相关接口 */
    aed_rescueKnowledgeList: createAction(request, '/admin/aed/rescueKnowledgeList'),

    /** 救助知识详情 */
    aed_rescueKnowledgeDetails: createAction(request, '/admin/aed/rescueKnowledgeDetails'),

    /** 删除救助知识 */
    aed_deleteRescueKnowledge: createAction(request, '/admin/aed/deleteRescueKnowledge'),

    /** 基金会事迹列表 */
    aed_foundationDeedList: createAction(request, '/admin/aed/foundationDeedList'),

    /** 保存基金会事迹 */
    aed_saveFoundationDeed: createAction(request, '/admin/aed/saveFoundationDeed'),

    /** 删除基金会事迹 */
    aed_deleteFoundationDeed: createAction(request, '/admin/aed/deleteFoundationDeed'),

    /** 基金会事迹详情 */
    aed_foundationDeedDetails: createAction(request, '/admin/aed/foundationDeedDetails'),

    /** 记录呼叫记录列表 */
    aed_rescueList: createAction(request, '/admin/aed/rescueList'),

    /** 呼叫记录详情 */
    aed_rescueDetails: createAction(request, '/admin/aed/rescueDetails'),

    /** 管理后台查询呼救的消息动态 */
    rescue_messages: createAction(request, '/admin/aed/rescueMessages'),

    /** 导入AED位置 */
    aed_saveAedBatch: createAction(request, '/admin/aed/saveAedBatch'),

    /** 结束救援 */
    aed_finish: createAction(request, '/admin/aed/finish')
  };
});