var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Title", [
        _c("span", { staticClass: "off" }, [_vm._v("救援")]),
        _vm._v("人员基本信息")
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "top" }, [
          _vm._m(0),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("姓名:")]),
              _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.rightUser.name))
              ])
            ]),
            _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("电话:")]),
              _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.rightUser.phoneNumber))
              ])
            ]),
            _c("div", { staticClass: "mi" }, [
              _vm._v(" 距离呼救人员 "),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.rightUser.dis || 0))
              ]),
              _vm._v("M ")
            ])
          ])
        ]),
        _c("div", { staticClass: "center", attrs: { flex: "main" } }, [
          _c("div", { staticClass: "label" }, [_vm._v("所在位置:")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.rightUser.location))
          ])
        ]),
        _vm._m(1)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../assets/image/off-user.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../assets/image/o-line.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }