import "core-js/modules/es.object.to-string";
import Vue from 'vue'; // import veLine from 'v-charts/lib/line'

import d2Container from './d2-container'; // 注意 有些组件使用异步加载会有影响
// Vue.component('ve-line', veLine)

Vue.component('d2-container', d2Container);
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
});
Vue.component('d2-count-up', function () {
  return import('./d2-count-up/index.vue');
});
Vue.component('d2-pagination', function () {
  return import('./d2-pagination');
});
Vue.component('d2-layer', function () {
  return import('./d2-layer');
});
Vue.component('goods-card', function () {
  return import('./goods-card');
});