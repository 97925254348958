var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("新增基金会事迹")]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "事迹标题", prop: "foundationDeedTitle" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.foundationDeedTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "foundationDeedTitle", $$v)
                  },
                  expression: "form.foundationDeedTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "事迹封面", prop: "foundationDeedCover" } },
            [
              _c("image-upload", {
                attrs: {
                  srcList: _vm.form.foundationDeedCover,
                  imgWidth: "190px",
                  imgHeight: "132px"
                },
                on: {
                  "update:srcList": function($event) {
                    return _vm.$set(_vm.form, "foundationDeedCover", $event)
                  },
                  "update:src-list": function($event) {
                    return _vm.$set(_vm.form, "foundationDeedCover", $event)
                  }
                }
              }),
              _c("span", [_vm._v("建议上传图片尺寸190*132像素")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "事迹排序", prop: "foundationDeedSort" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.foundationDeedSort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "foundationDeedSort", $$v)
                  },
                  expression: "form.foundationDeedSort"
                }
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v("说明：数值越小，排列越前")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否在APP展示", prop: "foundationDeedShow" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.foundationDeedShow,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "foundationDeedShow", $$v)
                  },
                  expression: "form.foundationDeedShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "事迹内容", prop: "foundationDeedContent" } },
            [
              _c("base-editor", {
                attrs: { content: _vm.form.foundationDeedContent },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "foundationDeedContent", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "defult" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }