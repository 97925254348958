var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        labelWidth: "0",
        fullTitle: "备注",
        visible: _vm.dialogVisible,
        rules: _vm.formRules,
        formData: _vm.dialogFormData,
        isEdit: true,
        width: "600px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "update:formData": function($event) {
          _vm.dialogFormData = $event
        },
        "update:form-data": function($event) {
          _vm.dialogFormData = $event
        },
        confirm: _vm.recertificationRemark
      }
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt-20", attrs: { label: "", prop: "logTitle" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 5, placeholder: "输入备注说明" },
            model: {
              value: _vm.dialogFormData.logTitle,
              callback: function($$v) {
                _vm.$set(_vm.dialogFormData, "logTitle", $$v)
              },
              expression: "dialogFormData.logTitle"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }