//
//
//
//
//
//
//
//
//
//
//
import HeaderTime from '../header-time';
export default {
  props: {
    bilibili: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderTime: HeaderTime
  },
  data: function data() {
    return {};
  },
  methods: {
    handleBilibili: function handleBilibili() {
      this.$emit('update:bilibili', !this.bilibili);
    }
  }
};