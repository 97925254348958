//
//
//
import EditorPreview from '@/components/editor-preview';
export default {
  meta: {
    title: '公告详情',
    auth: true,
    authorityValue: 'app-notice'
  },
  name: 'notice-message-detail',
  components: {
    EditorPreview: EditorPreview
  },
  data: function data() {
    return {
      request: {
        api: 'notice_noticeDetails',
        params: {
          noticeId: this.$route.query.noticeId
        },
        target: 'noticeContent.content'
      }
    };
  },
  methods: {}
};