import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 举报处理 */
    report_dealReport: createAction(request, '/admin/report/dealReport'),

    /** 举报列表 */
    report_reports: createAction(request, '/admin/report/reports'),

    /** 举报详情 */
    report_reportDetails: createAction(request, '/admin/report/reportDetails')
  };
});