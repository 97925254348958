var isEnd = true;
var temp = {
  id: '10000005',
  name: '康复',
  subLables: ['系列', '部位', '损伤'],
  subList: [{
    id: '100005101',
    name: '慢性疼痛',
    subList: [{
      id: '100005201',
      name: '肩部',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100005202',
      name: '腰部',
      isEnd: isEnd,
      subList: []
    }, {
      id: '100005203',
      isEnd: isEnd,
      name: '膝部',
      subList: []
    }]
  }, {
    id: '100005102',
    name: '骨科术后康复',
    subList: [{
      id: '100005204',
      name: '肩部',
      subList: [{
        id: '100005301',
        name: '肩锁关节脱位',
        subList: []
      }, {
        id: '100005302',
        name: '肩袖修复',
        subList: []
      }, {
        id: '100005303',
        name: '盂唇修复',
        subList: []
      }, {
        id: '100005304',
        name: '肱骨干骨折',
        subList: []
      }]
    }, {
      id: '100005205',
      name: '脊柱',
      subList: [{
        id: '100005305',
        name: '腰椎融合术',
        subList: []
      }]
    }, {
      id: '100005206',
      name: '膝关节',
      subList: [{
        id: '100005306',
        name: '髌骨骨折',
        subList: []
      }, {
        id: '100005307',
        name: '前十字韧带重建',
        subList: []
      }, {
        id: '100005308',
        name: '半月板修复',
        subList: []
      }, {
        id: '100005309',
        name: '胫骨平台骨折',
        subList: []
      }, {
        id: '100005310',
        name: '腘绳肌拉伤',
        subList: []
      }]
    }, {
      id: '100005207',
      name: '髋部',
      subList: [{
        id: '100005311',
        name: '髋臼骨折',
        subList: []
      }, {
        id: '100005312',
        name: '髋关节镜',
        subList: []
      }, {
        id: '100005313',
        name: '股骨近端骨折',
        subList: []
      }, {
        id: '100005314',
        name: '股骨干骨折',
        subList: []
      }, {
        id: '100005315',
        name: '腹股沟拉伤',
        subList: []
      }]
    }, {
      id: '100005208',
      name: '足部',
      subList: [{
        id: '100005316',
        name: '踝关节骨折',
        subList: []
      }, {
        id: '100005317',
        name: '跟腱重建',
        subList: []
      }]
    }]
  }]
};
export default temp;