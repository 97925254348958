var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-cascader",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          options: _vm.categories,
          props: { expandTrigger: "hover", checkStrictly: true }
        },
        on: { change: _vm.handleChange }
      },
      "el-cascader",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }