import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /** 公告 - 公告列表 */
    notice_notices: createAction(request, '/admin/notice/notices'),

    /** 公告 - 保存公告 */
    notice_saveNotice: createAction(request, 'admin/notice/saveNotice'),

    /** 公告 - 公告详情 */
    notice_noticeDetails: createAction(request, '/admin/notice/noticeDetails'),

    /** 公告 - 删除公告 */
    notice_deleteNotice: createAction(request, '/admin/notice/deleteNotice'),

    /** 公告 - 显示公告 */
    notice_showNotice: createAction(request, '/admin/notice/showNotice')
  };
});