var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("邀好友赚钱攻略")]),
      _c("base-editor", {
        attrs: { upToken: _vm.qnToken, content: _vm.form.invitationGuide },
        on: {
          "update:content": function($event) {
            return _vm.$set(_vm.form, "invitationGuide", $event)
          }
        }
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确认发布")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }