var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _vm._v(_vm._s(_vm.editId ? "修改投票活动" : "发布投票活动"))
      ]),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "180px" } },
        [
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票标题" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.form.voteTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "voteTitle", $$v)
                  },
                  expression: "form.voteTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票活动介绍" } },
            [
              _c("base-editor", {
                attrs: { upToken: _vm.qnToken, content: _vm.form.voteIntro },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.form, "voteIntro", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "活动投票封面" } },
            [
              _c(
                "div",
                {
                  staticClass: "upload-img-cell",
                  on: {
                    click: function($event) {
                      return _vm.openUploadModal("coverUrl")
                    }
                  }
                },
                [
                  _c(
                    "el-image",
                    { attrs: { fit: "contain", src: _vm.form.coverUrl } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: { src: _vm.uploadImg }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c("span", [_vm._v("建议上传图片尺寸690*270像素")])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票选项" } },
            [
              _vm._l(_vm.form.options, function(e, i) {
                return _c(
                  "el-card",
                  {
                    key: i,
                    staticStyle: {
                      border: "1px solid #c0c0c0",
                      width: "100%",
                      height: "100%",
                      padding: "20px"
                    }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "header" }, slot: "header" },
                      [
                        _c("span", [_vm._v("选项" + _vm._s(i + 1) + "：")]),
                        !e.optionId
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { float: "right" },
                                on: {
                                  click: function($event) {
                                    return _vm.toDelVote(i)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _vm._v("选项排序："),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { type: "number", maxlength: "3" },
                          model: {
                            value: e.optionIndex,
                            callback: function($$v) {
                              _vm.$set(e, "optionIndex", $$v)
                            },
                            expression: "e.optionIndex"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _vm._v("选项标题："),
                        _c("el-input", {
                          staticStyle: { width: "200px", "margin-top": "10px" },
                          attrs: { maxlength: "20" },
                          model: {
                            value: e.optionTitle,
                            callback: function($$v) {
                              _vm.$set(e, "optionTitle", $$v)
                            },
                            expression: "e.optionTitle"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: { margin: "10px 0" },
                        attrs: { type: "flex" }
                      },
                      [
                        _c("span", [_vm._v("选项图片：")]),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-cell",
                            on: {
                              click: function($event) {
                                return _vm.openUploadModal("optionImage", e)
                              }
                            }
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { fit: "contain", src: e.optionImage } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px"
                                      },
                                      attrs: { src: _vm.uploadImg }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("p", [_vm._v("建议上传图片尺寸555*447像素")])
                      ]
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: { margin: "10px 0" },
                        attrs: { type: "flex" }
                      },
                      [
                        _c("span", [_vm._v("排名图片：")]),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-cell",
                            on: {
                              click: function($event) {
                                return _vm.openUploadModal("rankImage", e)
                              }
                            }
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { fit: "contain", src: e.rankImage } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px"
                                      },
                                      attrs: { src: _vm.uploadImg }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("p", [_vm._v("建议上传图片尺寸120*120像素")])
                      ]
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex" } },
                      [
                        _c("span", { staticStyle: { width: "80px" } }, [
                          _vm._v("图文介绍：")
                        ]),
                        _c("base-editor", {
                          attrs: {
                            upToken: _vm.qnToken,
                            content: e.optionIntro
                          },
                          on: {
                            "update:content": function($event) {
                              return _vm.$set(e, "optionIntro", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.toAddVote }
                },
                [_vm._v("添加投票选项")]
              )
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "每人每天可免费投多少票" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { type: "number", maxlength: "5" },
                model: {
                  value: _vm.form.freeVoteCount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "freeVoteCount", _vm._n($$v))
                  },
                  expression: "form.freeVoteCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "投票截止时间" } },
            [
              _c("range-datetime", {
                attrs: {
                  min: _vm.form.startTime,
                  max: _vm.form.endTime,
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form, "startTime", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form, "endTime", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadBtn },
                  on: {
                    click: function($event) {
                      return _vm.saveTrue()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.editId ? "保存" : "立即发布"))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { token: _vm.qnToken },
            on: { confirm: _vm.setCover }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }