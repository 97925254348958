import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Title from '../title';
import { mapGetters } from 'vuex';
export default {
  props: ['show'],
  components: {
    Title: Title
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin/aedbig', ['getRightMsg'])), {}, {
    tabData: function tabData() {
      var _this$getRightMsg;

      return (_this$getRightMsg = this.getRightMsg) === null || _this$getRightMsg === void 0 ? void 0 : _this$getRightMsg.messages;
    }
  })
};