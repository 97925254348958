import afterAll from './afterAll.vue';
import stayReceiv from './stayReceiv.vue';
import stayExamine from './stayExamine.vue';
import stayExchange from './stayExchange.vue';
var arr = {
  afterAll: afterAll,
  stayReceiv: stayReceiv,
  stayExamine: stayExamine,
  stayExchange: stayExchange
};
export default arr;