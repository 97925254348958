var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("分享文案")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型" } },
            [
              _c("base-select", {
                attrs: { options: _vm.handleStatus, clearable: false },
                model: {
                  value: _vm.form.type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "d2-mb",
              attrs: { slot: "extra", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.edit()
                }
              },
              slot: "extra"
            },
            [_vm._v("增加文案")]
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "文案内容", prop: "content" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#F56C6C" },
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editVisible,
            title: _vm.isEdit ? "修改文案" : "增加文案",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.handleStatus, clearable: false },
                    model: {
                      value: _vm.nowRow.type,
                      callback: function($$v) {
                        _vm.$set(_vm.nowRow, "type", $$v)
                      },
                      expression: "nowRow.type"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "文案内容" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { clearable: "", maxlength: "20" },
                    model: {
                      value: _vm.nowRow.content,
                      callback: function($$v) {
                        _vm.$set(_vm.nowRow, "content", $$v)
                      },
                      expression: "nowRow.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }