import "core-js/modules/es.array.map";
import _objectSpread from "D:/project/yaolian_admin_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";

function washTree(list, labelKey, valueKey) {
  var childrenKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';

  var convert = function convert(list) {
    return list === null || list === void 0 ? void 0 : list.map(function (item) {
      var _item$childrenKey;

      var _item = _objectSpread(_objectSpread({}, item), {}, {
        label: item[labelKey],
        value: item[valueKey]
      }); // console.log('item[childrenKey]:', item[childrenKey])


      if (item[childrenKey] && ((_item$childrenKey = item[childrenKey]) === null || _item$childrenKey === void 0 ? void 0 : _item$childrenKey.length) > 0) {
        _item.children = convert(item[childrenKey]);
      }

      return _item;
    });
  };

  return convert(list);
}

export { washTree };