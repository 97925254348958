var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("用户钱包余额")]),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "注册时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.registerRange.start,
                  max: _vm.form.registerRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.registerRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.registerRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户身份" } },
            [
              _c("base-select", {
                attrs: { options: _vm.usersIdentitys },
                model: {
                  value: _vm.form.authedCoach,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "authedCoach", $$v)
                  },
                  expression: "form.authedCoach"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "TableTitleBar",
        { attrs: { title: "查询统计" } },
        [
          _c("span", { staticClass: "space" }, [
            _vm._v("可用余额：￥" + _vm._s(_vm.extraData.balanceAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("可提现金额： ￥" + _vm._s(_vm.extraData.baseAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("不可提现金额：￥" + _vm._s(_vm.extraData.rewardAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("历史增加金额总计：￥" + _vm._s(_vm.extraData.addAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("历史扣减金额总计：￥" + _vm._s(_vm.extraData.subAmount))
          ]),
          _c("span", { staticClass: "space" }, [
            _vm._v("历史冻结金额总计：￥" + _vm._s(_vm.extraData.lockedAmount))
          ]),
          _c(
            "el-button",
            {
              attrs: { slot: "extra", type: "default" },
              on: { click: _vm.download2 },
              slot: "extra"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "注册时间", prop: "createTime", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户编号", prop: "userNo" }
          }),
          _c("el-table-column", { attrs: { label: "昵称", prop: "nickname" } }),
          _c("el-table-column", {
            attrs: { label: "可用余额(元）", prop: "balanceAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "剩余可提现金额(元）", prop: "baseAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "不可提现金额(元）", prop: "rewardAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "未到账金额(元）", prop: "captureAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "已提现金额(元）", prop: "transferAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "被冻结金额(元）", prop: "lockedAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "待审核金额(元）", prop: "auditingAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "xxx" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/app-user-wallet-detail",
                                query: { userId: scope.row.userId }
                              }
                            }
                          },
                          [_vm._v(" 查看账单明细 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      { attrs: { type: "text" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/app-user-wallet-wallet",
                                query: { userId: scope.row.userId }
                              }
                            }
                          },
                          [_vm._v(" 查看钱包记录 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }