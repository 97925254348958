var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("提现手续费设置")]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "APP用户提现手续费：提现金额的" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "", type: "number" },
                  model: {
                    value: _vm.form.transferFeeRate,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "transferFeeRate", $$v)
                    },
                    expression: "form.transferFeeRate"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }