var refundStatus = [{
  label: '待审核',
  value: 1
}, {
  label: '已通过',
  value: 2
}, {
  label: '已拒绝',
  value: 3
}, {
  label: '商家已收货',
  value: 4
}, {
  label: '商家已发货',
  value: 5
}, {
  label: '用户已签收',
  value: 6
}, {
  label: '已完成',
  value: 7
}, {
  label: '待审核即将超时',
  value: 101
}, {
  label: '待收货即将超时',
  value: 102
}, {
  label: '待换新货即将超时',
  value: 103
}];
export { refundStatus };