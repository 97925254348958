//
//
//
//
//
//
//
import HeaderTime from '../header-time';
export default {
  components: {
    HeaderTime: HeaderTime
  },
  data: function data() {
    return {};
  }
};