//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      activities: [{
        content: '温馨提示：由于近期订单较多，我们将尽快为您发货，请您耐心等待',
        timestamp: '2018-04-15 09:05:37',
        type: 'danger'
      }, {
        content: '温馨提示：由于近期订单较多，我们将尽快为您发货，请您耐心等待',
        timestamp: '2018-04-13 09:05:37'
      }, {
        content: '温馨提示：由于近期订单较多，我们将尽快为您发货，请您耐心等待',
        timestamp: '2018-04-11 09:05:37'
      }, {
        content: '温馨提示：由于近期订单较多，我们将尽快为您发货，请您耐心等待',
        timestamp: '2018-04-11 09:05:37'
      }, {
        content: '温馨提示：由于近期订单较多，我们将尽快为您发货，请您耐心等待',
        timestamp: '2018-04-11 09:05:37'
      }]
    };
  }
};