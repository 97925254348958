//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  type: 4,
  depositTimeRange: {
    start: '',
    end: ''
  },
  realName: '',
  depositNo: ''
};
export default {
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'finance_coachDepositList',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};