var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("救助知识分类")]),
      _c(
        "page-content",
        [
          _c(
            "TableTitleBar",
            { staticStyle: { "margin-top": "0" }, attrs: { title: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                [_vm._v("新增分类")]
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "分类排序", prop: "typeSort" }
              }),
              _c("el-table-column", {
                attrs: { label: "分类名", prop: "typeName" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onAdd(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onDel(scope.row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("AddEventCateDialog", {
            attrs: {
              formData: _vm.addEventCateDialogForm,
              visible: _vm.addEventCateDialogVisible,
              isEdit: _vm.isEdit
            },
            on: {
              "update:formData": function($event) {
                _vm.addEventCateDialogForm = $event
              },
              "update:form-data": function($event) {
                _vm.addEventCateDialogForm = $event
              },
              "update:visible": function($event) {
                _vm.addEventCateDialogVisible = $event
              },
              onSuccess: function($event) {
                return _vm.handleSearch(_vm.form)
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }