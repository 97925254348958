export default [{
  title: '直播课管理',
  iconSvg: '直播课管理',
  authorityValue: "live-management",
  children: [{
    path: '/live-cate',
    title: '直播课分类',
    authorityValue: "live-category-manage"
  }, {
    path: '/live-list',
    title: '直播课管理',
    authorityValue: "live-manage"
  }]
}];