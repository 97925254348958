var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dv-full-screen-container",
    { staticClass: "container", style: _vm.style },
    [
      _c(
        "div",
        {
          staticClass: "header flex-center-between",
          staticStyle: { "justify-content": "center" }
        },
        [_c("HeaderTitle")],
        1
      ),
      _c("div", { staticClass: "bottom flex-center-between" }, [
        _c(
          "div",
          { staticClass: "left flex-center-between" },
          [
            !_vm.isNewLeft
              ? _c("LeftWarn", {
                  on: { change: _vm.changeLeft, load: _vm.load }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right flex-center-between" },
          [!_vm.isNewRight ? _c("RightMsg") : _vm._e()],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }