// 首页大屏接口
export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * @description 资讯列表
     */
    LARGE_SCREEN_NEWS: function LARGE_SCREEN_NEWS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/news',
        data: data
      });
    },

    /**
     * @description 资讯详情
     */
    LARGE_SCREEN_NEWS_DETAILS: function LARGE_SCREEN_NEWS_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/newsDetails',
        data: data
      });
    },

    /**
     * @description 门店数据
     */
    LARGE_SCREEN_GYM_DATA: function LARGE_SCREEN_GYM_DATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/gymData',
        data: data
      });
    },

    /**
     * @description 课程数据
     */
    LARGE_SCREEN_COURSE_DATA: function LARGE_SCREEN_COURSE_DATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/courseData',
        data: data
      });
    },

    /**
     * @description 运动数据
     */
    LARGE_SCREEN_SPORT_DATA: function LARGE_SCREEN_SPORT_DATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/sportData',
        data: data
      });
    },

    /**
     * @description 用户趋势
     */
    LARGE_SCREEN_USER_TRENDS: function LARGE_SCREEN_USER_TRENDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/userTrends',
        data: data
      });
    },

    /**
     * @description 交易数据
     */
    LARGE_SCREEN_TRADE: function LARGE_SCREEN_TRADE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/trade',
        data: data
      });
    },

    /**
     * @description 用户数量
     */
    LARGE_SCREEN_USER_COUNT: function LARGE_SCREEN_USER_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/userCount',
        data: data
      });
    },

    /**
     * @description 救助事件轨迹
     */
    LARGE_SCREEN_RESCUE_DETAILS: function LARGE_SCREEN_RESCUE_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/rescueDetails',
        data: data
      });
    },

    /**
     * @description 救助事件列表
     */
    LARGE_SCREEN_RESCUE_LIST: function LARGE_SCREEN_RESCUE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/rescueList',
        data: data
      });
    },

    /**
     * @description 救援人列表
     */
    LARGE_SCREEN_RESCUER_LIST: function LARGE_SCREEN_RESCUER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/rescuerList',
        data: data
      });
    },

    /**
     * @description AED列表
     */
    LARGE_SCREEN_AED_LIST: function LARGE_SCREEN_AED_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/aedList',
        data: data
      });
    },

    /**
     * @description 热力图经纬度
     */
    LARGE_SCREEN_USER_HEARMAP: function LARGE_SCREEN_USER_HEARMAP() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/large-screen/userHeatmap',
        data: data
      });
    }
  };
});