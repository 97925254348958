/**
 * 私教课相关
 */
export default (function (_ref) {
  var request = _ref.request;
  return {
    PTRAIN_TIME_DETAIL: function PTRAIN_TIME_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/orderTimeSetting',
        data: data
      });
    },
    PTRAIN_TIME_SET: function PTRAIN_TIME_SET() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/saveOrderTimeSetting',
        data: data
      });
    },
    PTRAIN_BANNER_DETAIL: function PTRAIN_BANNER_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/getBanner',
        data: data
      });
    },
    PTRAIN_BANNER_DEL: function PTRAIN_BANNER_DEL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/deleteBanner',
        data: data
      });
    },
    PTRAIN_BANNER_SAVE: function PTRAIN_BANNER_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/coach/saveBanner',
        data: data
      });
    },
    PTRAIN_COMMISSION_DETAIL: function PTRAIN_COMMISSION_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/orderCommissionSetting',
        data: data
      });
    },
    PTRAIN_COMMISSION_SAVE: function PTRAIN_COMMISSION_SAVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/saveOrderCommissionSetting',
        data: data
      });
    },
    PTRAIN_COACH_EVALUATES: function PTRAIN_COACH_EVALUATES() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/evaluates',
        data: data
      });
    },
    PTRAIN_COACH_EVALUATE_DETAIL: function PTRAIN_COACH_EVALUATE_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/evaluateDetails',
        data: data
      });
    },
    PTRAIN_COACH_EVALUATE_BLOCK: function PTRAIN_COACH_EVALUATE_BLOCK() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/blockEvaluate',
        data: data
      });
    },
    //获取待审核的私教课开播审核
    PTRAIN_COACH_EVALUATE_WAIT: function PTRAIN_COACH_EVALUATE_WAIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/waitAuditLiveList',
        data: data
      });
    },
    //获取待审核的私教课开播审核详情
    PTRAIN_COACH_EVALUATE_WAIT_DETAILS: function PTRAIN_COACH_EVALUATE_WAIT_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/liveAuditDetails',
        data: data
      });
    },
    //离开待审核的私教课开播审核详情页面
    PTRAIN_COACH_EVALUATE_QUIT_DETAILS: function PTRAIN_COACH_EVALUATE_QUIT_DETAILS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/quitLiveAudit',
        data: data
      });
    },
    //审核 私教课开播审核
    PTRAIN_COACH_EVALUATE_AUDIT_LIVE: function PTRAIN_COACH_EVALUATE_AUDIT_LIVE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/auditLive',
        data: data
      });
    },
    // 私教课开播审核记录
    PTRAIN_COACH_EVALUATE_AUDIT_LIVE_LIST: function PTRAIN_COACH_EVALUATE_AUDIT_LIVE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/training/liveAuditList',
        data: data
      });
    }
  };
});