var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "edit-wrap" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "180px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "新闻标题" } },
                [_vm._v(" " + _vm._s(_vm.detail.articleTitle) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "yl-read",
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.detail.articleTypeId,
                        callback: function($$v) {
                          _vm.$set(_vm.detail, "articleTypeId", $$v)
                        },
                        expression: "detail.articleTypeId"
                      }
                    },
                    _vm._l(_vm.cates, function(item) {
                      return _c("el-option", {
                        key: item.articleTypeId,
                        attrs: {
                          label: item.articleTypeName,
                          value: item.articleTypeId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "新闻封面" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-cell",
                      on: {
                        click: function($event) {
                          return _vm.openImageView(_vm.detail.articleCover)
                        }
                      }
                    },
                    [
                      _c(
                        "el-image",
                        {
                          attrs: {
                            fit: "contain",
                            src: _vm.detail.articleCover
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: _vm.uploadImg }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "新闻排序" } },
                [_vm._v(" " + _vm._s(_vm.detail.articleSort) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "是否在APP展示" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detail.articleShow ? "展示" : "暂不展示") +
                      " "
                  )
                ]
              ),
              _c("el-form-item", { attrs: { label: "新闻内容" } }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.detail.articleContent) }
                })
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }